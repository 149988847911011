import React, { memo } from 'react';
import { styled } from 'stitches.config';

import { MainMarket } from '~api/market/types';
import { Box } from '~components/atoms/Box';
import { Card } from '~components/atoms/Card';
import { Flag } from '~components/atoms/Flag';
import { Separator } from '~components/atoms/Separator';
import { Text } from '~components/atoms/Typography';
import { CountryName } from '~constants/flag';
import { Market } from '~types/events';
import { getParsedExtraData } from '~utils/sportHelpers';

import { useLiveEventCard } from '../hooks/useLiveEventCard';

import { LiveEventContent } from './LiveEventContent';
import { OddButtonGroup } from './OddButtonGroup';

export const PrimitiveLiveEventCard = styled(Card, {
  backgroundColor: '$liveEventCardBgActiveColor',
  p: '$2 $3',
  cursor: 'pointer',
  '&:not(:last-child)': {
    mb: '$1',
  },
  '&:hover': {
    backgroundColor: '$liveEventCardBgActiveColor',
  },
  '&[data-state=active]': {
    backgroundColor: '$liveEventCardBgActiveColor',
    boxShadow: '$containerBorderActive',
  },
  '@xs_sm': {
    backgroundColor: '$blackDarker',
  },
});

interface LiveEventCardProps {
  sportId: number;
  countryId: number;
  leagueId: number;
  eventId: string;
  isActive: boolean;
  showLeagues?: boolean;
  countryName?: string;
  leagueName?: string;
  isFavoriteEvents?: boolean;
  mainSportMarket?: MainMarket | undefined;
}

export const LiveEventCard = memo(
  ({
    sportId,
    countryId,
    leagueId,
    eventId,
    isActive,
    showLeagues,
    countryName,
    leagueName,
    isFavoriteEvents,
    mainSportMarket,
  }: LiveEventCardProps) => {
    const {
      isLoading,
      isMobile,
      startDate,
      sportName,
      market,
      marketsCount,
      name: eventName,
      extraData,
      handleEventCardClick,
    } = useLiveEventCard(
      sportId,
      countryId,
      leagueId,
      eventId,
      isFavoriteEvents,
      mainSportMarket,
    );

    if (!getParsedExtraData(extraData) && !isLoading) {
      return null;
    }

    return (
      <PrimitiveLiveEventCard
        data-state={isActive ? 'active' : 'inActive'}
        onClick={handleEventCardClick}
      >
        {showLeagues && (
          <>
            <Box
              fullWidth
              flexRow
              alignCenter
              justifyContentStart
              gap={2}
              css={{ pt: '$1' }}
            >
              <Flag
                countryName={countryName as CountryName}
                size={isMobile ? 'sm' : 'md'}
              />
              <Text level={isMobile ? '12-16' : '16-20'}>{leagueName}</Text>
            </Box>
            <Separator
              shrinkOut={3}
              verticalSpace={3}
              css={{ backgroundColor: '$gray' }}
            />
          </>
        )}
        <LiveEventCardWrapper
          {...{
            isMobile,
            isLoading,
            eventName,
            sportName,
            extraData,
            startDate,
            marketsCount,
            market,
            eventId,
            sportId,
            isFavoriteEvents,
          }}
        />
      </PrimitiveLiveEventCard>
    );
  },
);

interface LiveEventCardWrapperProps {
  isLoading: boolean;
  isMobile: boolean;
  eventName: string | undefined;
  sportName: string | undefined;
  extraData: string | undefined;
  startDate: string | undefined;
  marketsCount: number | undefined;
  market: Market | undefined;
  eventId: string;
  sportId: number;
  isFavoriteEvents?: boolean;
}

const LiveEventCardWrapper = memo(
  ({
    isMobile,
    isLoading,
    eventName,
    sportName,
    extraData,
    startDate,
    marketsCount,
    market,
    eventId,
    sportId,
    isFavoriteEvents,
  }: LiveEventCardWrapperProps) => {
    return (
      <Box flexCol gap={2} fullWidth>
        <LiveEventContent
          isMobile={isMobile}
          isLoading={isLoading}
          eventName={eventName}
          sportName={sportName}
          extraData={extraData}
          startDate={startDate}
          marketsCount={marketsCount}
          market={isMobile ? market : undefined}
          eventId={eventId}
          sportId={sportId}
        />
        {!isMobile && (
          <OddButtonGroup
            isLoading={isLoading}
            market={market}
            eventId={eventId}
            sportId={sportId}
            isFavoriteEvents={isFavoriteEvents}
          />
        )}
      </Box>
    );
  },
);
