import React, { MouseEvent } from 'react';

import { BetslipHistoryItem, OpenedBet } from '~api/betslip/types';
import { Box } from '~components/atoms/Box';
import { Button } from '~components/atoms/Button';
import { Separator } from '~components/atoms/Separator';
import { ShareBet } from '~components/atoms/ShareBet';
import { Text } from '~components/atoms/Typography';
import { SPORT_BETSLIP_TYPE_CODES } from '~components/molecules/Betslip/constants';
import { WinboostPopover } from '~components/WinboostPopover';
import { BETSLIP_STATUS, CURRENCY_SYMBOLS } from '~constants/common';
import { useTranslation } from '~hooks/useTranslation';
import { useWebsiteSettings } from '~hooks/useWebsiteSettings';
import { useAppDispatch, useAppSelector } from '~store';
import { setCashOutBet } from '~store/slices/openBetSlice';
import { formatDateTime, TIME_FORMATS } from '~utils/formatDateTime';
import { commaSeparatedNumber } from '~utils/numberUtils';

import {
  getBetType,
  getSectionName,
  getStatusIcon,
  getSystemBetOptionsLabel,
} from '../../historyUtils';
import { BetslipHistoryStatusCell } from '../BetslipHistoryTable/components/BetslipHistoryStatusCell';

interface BetslipHistoryMobileItemMainProps {
  betslipHistoryItem: BetslipHistoryItem;
}

export const BetslipHistoryMobileItemMain = ({
  betslipHistoryItem,
}: BetslipHistoryMobileItemMainProps) => {
  const dispatch = useAppDispatch();
  const { currency } = useAppSelector((state) => state.settings);
  const {
    status,
    createdAt,
    type,
    details,
    possibleWin,
    stake,
    totalOdd,
    winAmount,
    code,
    availableCashout,
    winBoostMultiplier,
    winBoostAmount,
    originalWinAmount,
    systemBetCombinationSize,
  } = betslipHistoryItem;
  const { localized } = useTranslation();
  const { odds, getFormattedOdd } = useWebsiteSettings();
  const StatusIcon = getStatusIcon(status);
  const isSystemBet = type === SPORT_BETSLIP_TYPE_CODES.SYSTEM;

  const handleCashOutClick = (e: MouseEvent) => {
    e.stopPropagation();
    e.preventDefault();
    dispatch(setCashOutBet(betslipHistoryItem as unknown as OpenedBet));
  };

  let betTypeText = getBetType(type);

  if (isSystemBet) {
    betTypeText += ` - ${getSystemBetOptionsLabel(
      details.length,
      systemBetCombinationSize,
    )};`;
  }

  return (
    <>
      <Box flexRow alignCenter justifyContentBetween>
        <Box flexRow gap={2} alignCenter>
          {StatusIcon && <StatusIcon />}
          <Box flexRow css={{ maxWidth: '85px' }}>
            <Box flexCol>
              <Text level="12-20" color="grayMedium" ellipsis>
                {getSectionName(details)}
                {', '}
                {betTypeText}
              </Text>
              <Box flexRow alignCenter gap={1}>
                <Text
                  level="12-20"
                  color="grayMedium"
                  ellipsis
                  css={{ width: '85px' }}
                >
                  ID: {code}
                </Text>
                {status === 1 ? (
                  <ShareBet
                    data={{ url: window.location.origin + '?share=' + code }}
                  />
                ) : null}
              </Box>
            </Box>
          </Box>
        </Box>
        <Box flexCol alignEnd>
          <BetslipHistoryStatusCell status={status} />
          <Text level="12-20" color="grayMedium">
            {formatDateTime(createdAt, TIME_FORMATS.DATE_TIME_12H)}
          </Text>
        </Box>
      </Box>
      <Separator
        verticalSpace={0}
        shrinkOut={3}
        css={{
          backgroundColor: '$grayDarker',
          my: '6px',
        }}
      />
      <Box flexRow justifyContentBetween>
        <Text level="12-20" color="grayMedium">
          {localized('betslipHistory.mobile.stake')}
        </Text>
        <Text level="12-20" fontWeight="medium">
          {commaSeparatedNumber(stake)} {CURRENCY_SYMBOLS[currency]}
        </Text>
      </Box>
      <Box flexRow justifyContentBetween>
        <Text level="12-20" color="grayMedium">
          {localized('betslipHistory.mobile.totalOdd')}
        </Text>
        <Text level="12-20" color="yellow" fontWeight="medium">
          {isSystemBet ? '-' : getFormattedOdd(totalOdd, odds)}
        </Text>
      </Box>
      <Separator
        verticalSpace={0}
        shrinkOut={3}
        css={{
          backgroundColor: '$grayDarker',
          my: '6px',
        }}
      />
      <Box flexRow justifyContentBetween>
        <Box flexRow gap={2} alignCenter>
          <Text level="12-20">
            {localized(
              `betslipHistory.mobile.${status !== 1 ? 'win' : 'possibleWin'}`,
            )}
          </Text>
          {!!winBoostMultiplier && status !== BETSLIP_STATUS.CASH_OUT && (
            <WinboostPopover
              status={status}
              winBoostAmount={winBoostAmount}
              winBoostMultiplier={winBoostMultiplier}
              originalWinAmount={originalWinAmount}
            />
          )}
        </Box>
        <Text level="12-20" color="yellow" fontWeight="medium">
          {commaSeparatedNumber(status !== 1 ? winAmount : possibleWin)}{' '}
          {CURRENCY_SYMBOLS[currency]}
        </Text>
      </Box>
      {status === 1 && availableCashout ? (
        <Button
          fullWidth
          onClick={handleCashOutClick}
          css={{
            p: '1$',
            gap: '$1',
            my: '$1',
            backgroundColor: '$greenColorIndicator',
          }}
        >
          <Text color="white" level="14-20">
            {localized('betslip.cashOut')}
          </Text>
          <Text level="14-20" color="yellow">
            {commaSeparatedNumber(availableCashout)}{' '}
            {CURRENCY_SYMBOLS[currency]}
          </Text>
        </Button>
      ) : null}
      <Separator
        verticalSpace={2}
        shrinkOut={3}
        css={{
          backgroundColor: '$grayDarker',
        }}
      />
    </>
  );
};
