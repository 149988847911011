import React from 'react';

import { Box } from '~components/atoms/Box';
import { Text } from '~components/atoms/Typography';
import { BetslipHistoryWrapper } from '~components/molecules/BetslipHistorySection/BetslipHistoryWrapper';
import { BonusesWrapper } from '~components/molecules/Bonuses';
import { TransactionHistory } from '~components/molecules/UserProfile/components/Payments/components/TransactionHistory';
import useIsInIframe from '~hooks/useIsInIframe';
import { ContentsMap } from '~types/general';

import { useUserProfileDialog } from '../../hooks/useUserProfileDialog';
import { IframeSidebar } from '../IframeSidebar';
import { UserProfileSidebar } from '../UserProfileSidebar';

import { contentsTexts, USER_PROFILE_NAVIGATION_TABS } from './constants';
import { UserPaymentsWrapper } from './UserPaymentsWrapper';
import { UserPersonalProfileWrapper } from './UserPersonalProfileWrapper';

const userProfileContentsMap: ContentsMap = {
  [USER_PROFILE_NAVIGATION_TABS.PERSONAL_PROFILE]: (
    <UserPersonalProfileWrapper />
  ),
  [USER_PROFILE_NAVIGATION_TABS.BALANCE_MANAGEMENT]: <UserPaymentsWrapper />,
  [USER_PROFILE_NAVIGATION_TABS.HISTORY]: <BetslipHistoryWrapper />,
  [USER_PROFILE_NAVIGATION_TABS.BONUSES]: <BonusesWrapper />,
  [USER_PROFILE_NAVIGATION_TABS.PAYMENT_HISTORY]: (
    <TransactionHistory isAllTransactions key="payment-transactions" />
  ),
};

export const UserProfileDialog = () => {
  const { localized, profile, mainTab } = useUserProfileDialog();

  const isInIframe = useIsInIframe();

  if (!profile) {
    return null;
  }

  return (
    <Box
      css={{
        p: '$1',
        borderRadius: '$10',
        backgroundColor: '$userProfileDialogBgColor',
        '@xs_sm': {
          backgroundColor: 'transparent',
        },
      }}
    >
      <Box flexRow gap={1} css={{ p: '$2 0 $3 $4' }}>
        <Text color="grayMedium" level="sm-3" fontWeight="medium">
          {localized(`userProfile.menuTitles.${mainTab}`)}:
        </Text>
        <Text color="grayMedium" level="sm-3">
          {localized(contentsTexts[mainTab as USER_PROFILE_NAVIGATION_TABS])}
        </Text>
      </Box>
      <Box flexRow gap={1}>
        {isInIframe ? (
          <IframeSidebar />
        ) : (
          <UserProfileSidebar profile={profile} />
        )}
        <Box
          flexCol
          gap={3}
          css={{
            borderRadius: '$8',
            backgroundColor: '$grayDark',
            p: '$2 $3 $3',
            width: '754px',
            height: 'auto',
            overflowY: 'hidden',
          }}
        >
          {mainTab && userProfileContentsMap[mainTab]}
        </Box>
      </Box>
    </Box>
  );
};
