import { Countries } from '~api/category/types';
import {
  MULTI_ACCORDION_ROLES,
  MultiAccordionRoot,
} from '~components/molecules/MultiAccordion';

import { CountryMenuItem } from '../CountryMenuItem';
import { useCountryMenu } from '../hooks/useCountryMenu';

interface CountryMenuMobileProps {
  sportId: number;
  countries: Countries;
}

export const CountryMenuMobile = ({
  sportId,
  countries,
}: CountryMenuMobileProps) => {
  const { openCountries, handleOnValueChange, getLeaguesData } =
    useCountryMenu(countries);

  if (!countries.length && !openCountries.length) {
    return null;
  }

  return (
    <MultiAccordionRoot
      type="multiple"
      role={MULTI_ACCORDION_ROLES.PARENT}
      value={openCountries}
      onValueChange={handleOnValueChange}
    >
      {countries.map(({ id, events, name, leagues, code }) => (
        <CountryMenuItem
          key={id}
          {...{
            id,
            events,
            name,
            leagues,
            sportId,
            code,
            getLeaguesData,
            countryMenuRole: MULTI_ACCORDION_ROLES.PARENT,
          }}
        />
      ))}
    </MultiAccordionRoot>
  );
};
