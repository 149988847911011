import { useEffect, useLayoutEffect, useState } from 'react';

import { useLazyGetUserLimits } from '~api/auth/authQueries';
import {
  useLazyGetBannerByGroupName,
  useLazyGetMenuContent,
} from '~api/content/contentQueries';
import { BannerGroup } from '~api/content/types';
import { useLazyGetFavoriteMarkets } from '~api/market/marketQueries';
import { useLazySettings } from '~api/partner/partnerQueries';
import {
  PaymentSettings,
  SportsBettingConfig,
  WebsiteSettingsResponse,
} from '~api/partner/types';
import { useGetData } from '~api/sportEvent/sportEventQueries';
import { UserProfileData } from '~api/user/types';
import { useLazyGetUserProfile } from '~api/user/userQueries';
import {
  bannerGroupNameMobile,
  bannerGroupNameWeb,
} from '~components/atoms/BannerList';
import { SPORT_BETSLIP_TYPE_OPTIONS } from '~components/molecules/Betslip/constants';
import { prepareTopInplayRespData } from '~components/molecules/LiveMenu/utils/eventsParsing';
import { CHANNEL_TYPES } from '~constants/common';
import { SIGNAL_R_SOCKET_NAMES } from '~constants/signalR';
import { THEMES } from '~constants/ui';
import { useAppEventsUpdate } from '~hooks/useAppEventsUpdate';
import { useListenTokens } from '~hooks/useListenTokens';
import { useMedia } from '~hooks/useMedia';
import { useNetworkStatus } from '~hooks/useNetworkStatus';
import { useTransactionsUpdate } from '~hooks/useTransactionsUpdate';
import { useUpdateWebsiteSettings } from '~hooks/useUpdateSettings';
import {
  startTokenRefreshScheduler,
  stopTokenRefreshScheduler,
} from '~services/auth/tokenRefreshScheduler';
import { useAppDispatch, useAppSelector } from '~store';
import { setStakeType } from '~store/slices/betslipSlice';
import { addEventsWithMarkets } from '~store/slices/eventsSlice';
import {
  setIsTopLiveEventsLoaded,
  setTopLiveEvents,
} from '~store/slices/liveGroupsSlice';
import { setDefaultMainMarketsSelected } from '~store/slices/liveMenuSlice';
import {
  setFavoriteMarkets,
  setMainMarkets,
} from '~store/slices/mainMarketsSlice';
import {
  setAvailablePaymentSettings,
  setPaymentSettings,
} from '~store/slices/paymentsSlice';
import {
  setDataLoaded,
  setUpcomingEvents,
} from '~store/slices/prematchMenuSlice';
import {
  setWebsiteSettings,
  updateWebsiteSettings,
} from '~store/slices/settingsSlice';
import {
  startSignalRAuthedSocket,
  startSignalRSocket,
  stopSignalRAuthedSocket,
  stopSignalRSocket,
} from '~store/slices/signalRSocketsSlice';
import {
  setIsTopSportEventsLoaded,
  setIsTopTournamentsLoaded,
  setTopSportEvents,
  setTopTournaments,
} from '~store/slices/sportGroupsSlice';
import {
  login,
  logout,
  setIsUserProfileLoaded,
  setProfile,
} from '~store/slices/userSlice';
import { setBannerData, setMenuContents } from '~store/slices/userUISlice';
import { COOKIES_NAMES, getCookie } from '~utils/cookies';
import { getTheme } from '~utils/getTheme';

import { semaBetTheme } from '../stitches.config';

import BetonlyFaviconIcon from './assets/betonly-logo.webp';
import SemabetFaviconIcon from './assets/semabet-logo.webp';

export const useOnAppInit = () => {
  const { isOnline, isReconnected, isDisconnected } = useNetworkStatus();
  const dispatch = useAppDispatch();
  const { lazyGetUserProfileQuery } = useLazyGetUserProfile();
  const { isUserLoggedIn } = useAppSelector((state) => state.userState);
  const { activePaymentTab } = useAppSelector((state) => state.payments);
  const { stakeType } = useAppSelector((state) => state.betslip);
  const { lazyGetDataQuery } = useGetData();
  const { lazyGetFavoriteMarketsQuery } = useLazyGetFavoriteMarkets();
  const { lazyGetBannerByGroupNameQuery } = useLazyGetBannerByGroupName();
  const { lazySettingsQuery } = useLazySettings();
  const { lazyGetMenuContentQuery } = useLazyGetMenuContent();
  const { lazyGetUserLimitsQuery } = useLazyGetUserLimits();
  const { isMobileOrTablet, isMobile, isTablet } = useMedia();
  const [isSettingsLoaded, setIsSettingsLoaded] = useState(false);
  const { language } = useAppSelector((state) => state.websiteSettings);

  const loadProfile = async () => {
    try {
      dispatch(setIsUserProfileLoaded(false));
      const { data } = await lazyGetUserProfileQuery();

      if (data) {
        dispatch(setProfile(data as UserProfileData));
        dispatch(setIsUserProfileLoaded(true));
      } else {
        dispatch(logout());
      }
    } catch (error) {
      console.error('Error while loading profile', error);
      dispatch(logout());
    }
  };

  const loadFavoriteMarkets = async () => {
    const favoriteMarketsData = await lazyGetFavoriteMarketsQuery().unwrap();

    dispatch(setFavoriteMarkets(favoriteMarketsData));
  };

  const loadSettings = async () => {
    const websiteSettings = await lazySettingsQuery().unwrap();
    const { paymentProviderSettings } = websiteSettings;

    dispatch(setWebsiteSettings(websiteSettings));

    if (
      !websiteSettings.allowSystemBets &&
      stakeType === SPORT_BETSLIP_TYPE_OPTIONS.SYSTEM
    ) {
      dispatch(setStakeType(SPORT_BETSLIP_TYPE_OPTIONS.SINGLE));
    }

    if (paymentProviderSettings.length) {
      dispatch(setAvailablePaymentSettings(paymentProviderSettings));

      if (activePaymentTab) {
        const providerValue = paymentProviderSettings.find(
          (provider) => provider.paymentProvider === activePaymentTab,
        );

        if (providerValue) {
          dispatch(setPaymentSettings(providerValue)); // Setting default provider payment settings
        }
      }
    }

    setIsSettingsLoaded(true);
  };

  const loadMenuContentData = async () => {
    const data = await lazyGetMenuContentQuery(
      '?names=footer&names=header',
    ).unwrap();

    dispatch(setMenuContents(data));
  };

  const loadData = async () => {
    try {
      const data = await lazyGetDataQuery({}).unwrap();
      const {
        mainMarkets,
        topMatchesInPlay,
        topGames,
        topTournaments,
        upcoming,
      } = data;

      if (mainMarkets && mainMarkets.length) {
        dispatch(setMainMarkets(mainMarkets));
        const defaultSelectedMainMarkets: Record<number, number> = {};

        mainMarkets.forEach(({ id, markets }) => {
          const marketId = markets[0]?.id || null;

          if (marketId) {
            defaultSelectedMainMarkets[id] = marketId;
          }
        });
        dispatch(setDefaultMainMarketsSelected(defaultSelectedMainMarkets));
      }

      if (upcoming && upcoming.length) {
        dispatch(setUpcomingEvents(upcoming));
      }

      if (topTournaments && topTournaments.length) {
        dispatch(setTopTournaments(topTournaments));
        dispatch(setIsTopTournamentsLoaded(true));
      }

      if (topGames && topGames.length) {
        dispatch(setTopSportEvents(topGames));
        dispatch(setIsTopSportEventsLoaded(true));
      }

      if (topMatchesInPlay) {
        const { allMarkets, allEvents } =
          prepareTopInplayRespData(topMatchesInPlay);

        dispatch(
          addEventsWithMarkets({ events: allEvents, markets: allMarkets }),
        );
        dispatch(setTopLiveEvents(topMatchesInPlay));
        dispatch(setIsTopLiveEventsLoaded(true));
      }

      dispatch(setDataLoaded(true));
    } catch (e) {
      console.log(e);
    }
  };

  const loadUserLimits = async () => {
    try {
      const {
        minStake = 0,
        maxStake = 0,
        minStakePreMatch = 0,
        maxStakePreMatch = 0,
        minStakeInPlay = 0,
        maxStakeInPlay = 0,
        maxWinAmount = 0,
        duplicateBetsCountLimit = 0, // set default value properly
        sportMinSelectionCount = 0, // set default value properly
        sportMaxSelectionCount = 0, // set default value properly
        depositLimitMin = 0, // set default value properly
        depositLimitMax = 0, // set default value properly
        withdrawalLimitMin = 0, // set default value properly
        withdrawalLimitMax = 0, // set default value properly
      } = await lazyGetUserLimitsQuery().unwrap();

      const newBetsConfig: Partial<SportsBettingConfig> = {};
      const newPaymentSettings: Partial<PaymentSettings> = {};
      const otherSettings: Partial<WebsiteSettingsResponse> = {};

      if (minStake) {
        newBetsConfig.sportMinStake = minStake;
      }

      if (maxStake) {
        newBetsConfig.sportMaxStake = maxStake;
      }

      if (minStakePreMatch) {
        newBetsConfig.minStakePreMatch = minStakePreMatch;
      }

      if (maxStakePreMatch) {
        newBetsConfig.maxStakePreMatch = maxStakePreMatch;
      }

      if (minStakeInPlay) {
        newBetsConfig.minStakeInPlay = minStakeInPlay;
      }

      if (maxStakeInPlay) {
        newBetsConfig.maxStakeInPlay = maxStakeInPlay;
      }

      if (sportMinSelectionCount) {
        newBetsConfig.sportMinSelectionCount = sportMinSelectionCount;
      }

      if (sportMaxSelectionCount) {
        newBetsConfig.sportMaxSelectionCount = sportMaxSelectionCount;
      }

      if (maxWinAmount) {
        otherSettings.maxWinAmount = maxWinAmount;
      }

      if (duplicateBetsCountLimit) {
        otherSettings.duplicateBetsCountLimit = duplicateBetsCountLimit;
      }

      if (depositLimitMax) {
        newPaymentSettings.depositLimitMax = depositLimitMax;
      }

      if (depositLimitMin) {
        newPaymentSettings.depositLimitMin = depositLimitMin;
      }

      if (withdrawalLimitMax) {
        newPaymentSettings.withdrawalLimitMax = withdrawalLimitMax;
      }

      if (withdrawalLimitMin) {
        newPaymentSettings.withdrawalLimitMin = withdrawalLimitMin;
      }

      dispatch(
        updateWebsiteSettings({
          otherSettings,
          newBetsConfig,
          newPaymentSettings,
        }),
      );
    } catch (e) {
      console.log(e);
    }
  };

  useAppEventsUpdate();
  useTransactionsUpdate();
  useListenTokens();
  useUpdateWebsiteSettings();

  useLayoutEffect(() => {
    const accessToken = getCookie(COOKIES_NAMES.ACCESS_TOKEN);
    const refreshToken = getCookie(COOKIES_NAMES.REFRESH_TOKEN);

    if (accessToken && refreshToken) {
      dispatch(login());
    }
  }, []);

  // useEffect(() => {
  //   const loadingNode = document.getElementById('loading');
  //
  //   if (loadingNode) {
  //     loadingNode.remove();
  //   }
  // }, []);

  useLayoutEffect(() => {
    const theme = getTheme();

    if (theme === THEMES.SEMA_BET) {
      document.documentElement.classList.add(semaBetTheme.className);
    }
  }, []);

  useEffect(() => {
    loadMenuContentData();
    loadSettings();
    loadData();
    dispatch(startSignalRSocket([SIGNAL_R_SOCKET_NAMES.INPLAY]));

    return () => {
      dispatch(stopSignalRSocket());
    };
  }, [language]);

  useEffect(() => {
    const ongoingGroup: string[] = isMobileOrTablet
      ? bannerGroupNameMobile
      : bannerGroupNameWeb;

    const loadBannerGroups = async () => {
      try {
        const channelType = isMobile
          ? CHANNEL_TYPES.MOBILE
          : isTablet
            ? CHANNEL_TYPES.TABLET
            : CHANNEL_TYPES.WEB;

        const { data: groups } = await lazyGetBannerByGroupNameQuery({
          names: ongoingGroup,
          channelType: channelType,
        });
        const groupsMapping: Record<string, BannerGroup> = {};

        if (groups) {
          ongoingGroup.map((groupName) => {
            groupsMapping[groupName] =
              groups.find(({ name }) => name === groupName) ||
              ({} as BannerGroup);
          });
          dispatch(setBannerData(groupsMapping));
        }
      } catch (error) {
        console.error('Failed getting banners', error);
      }
    };

    loadBannerGroups();
  }, [isUserLoggedIn, isMobile, isTablet, isMobileOrTablet, language]);

  useEffect(() => {
    if (isUserLoggedIn) {
      startTokenRefreshScheduler();
      loadProfile();
      loadFavoriteMarkets();

      setTimeout(() => {
        dispatch(
          startSignalRAuthedSocket([SIGNAL_R_SOCKET_NAMES.NOTIFICATIONS]),
        );
      }, 5000);
    } else {
      dispatch(stopSignalRAuthedSocket());
      stopTokenRefreshScheduler();
    }
  }, [isUserLoggedIn, language]);

  useEffect(() => {
    if (isDisconnected) {
      dispatch(stopSignalRSocket());
    }
  }, [isDisconnected]);

  useEffect(() => {
    if (isOnline && isReconnected) {
      dispatch(startSignalRSocket([SIGNAL_R_SOCKET_NAMES.INPLAY]));
    }
  }, [isOnline, isReconnected]);

  useEffect(() => {
    function getFaviconUrl() {
      const hostname = window.location.hostname;

      if (
        hostname.includes('semabet.ug') ||
        hostname.includes('semabet.co.zm') ||
        hostname.includes('semabet.co.mz') ||
        hostname.includes('semabet.co.tz')
      ) {
        return SemabetFaviconIcon;
      }

      if (hostname.includes('betonly')) {
        return BetonlyFaviconIcon;
      }

      return null;
    }

    if (document && document.querySelector("link[rel='icon']")) {
      const element = document.querySelector("link[rel='icon']");

      if (element) {
        const favicon = getFaviconUrl();

        if (favicon) {
          element.setAttribute('href', favicon);
        }
      }
    }
  }, []);

  useEffect(() => {
    if (isUserLoggedIn && isSettingsLoaded) {
      loadUserLimits();
    }
  }, [isUserLoggedIn, isSettingsLoaded]);
};
