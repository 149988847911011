import React from 'react';

import { Box } from '~components/atoms/Box';
import { Text } from '~components/atoms/Typography';
import { Jackpot } from '~types/jackpot';
import { formatDateTime, TIME_FORMATS } from '~utils/formatDateTime';
import { getSportIcon } from '~utils/getSportIcon';

interface JackpotMenuListItemProps {
  jackpot: Jackpot;
  isActive?: boolean;
  shouldHighlightEvents?: boolean;
}

export const MenuListItem = ({
  jackpot,
  isActive,
  shouldHighlightEvents = false,
}: JackpotMenuListItemProps) => {
  const iconSize = { width: 20, height: 20 };

  return (
    <>
      <Box flexRow alignCenter gap={2}>
        <Box
          css={{
            ...iconSize,
            color: '$grayMedium',
          }}
        >
          {getSportIcon(jackpot.sportName, iconSize)}
        </Box>
        <Box flexCol>
          <Text level="14-24">{jackpot.name}</Text>
          <Text level="12-20" color="grayMedium">{`${
            jackpot.sportName
          }. ${formatDateTime(
            jackpot.firstEventStartDate,
            TIME_FORMATS.DATE_TIME_12H,
          )}`}</Text>
        </Box>
      </Box>

      {shouldHighlightEvents ? (
        <Box
          css={{
            p: '0 1rem',
            borderRadius: '$6',
            backgroundColor: !isActive ? '$grayDark' : '$blueDark',
          }}
        >
          <Text level="12-20" color={isActive ? 'white' : 'grayMedium'}>
            {jackpot.events.length}
          </Text>
        </Box>
      ) : (
        <Text level="12-20" color="grayMedium">
          {jackpot.events.length}
        </Text>
      )}
    </>
  );
};
