import React, { useRef } from 'react';

import { Box } from '~components/atoms/Box';
import { Separator } from '~components/atoms/Separator';
import { useComponentObserver } from '~hooks/useComponentObserver';
import { useMedia } from '~hooks/useMedia';
import { useAppDispatch, useAppSelector } from '~store';
import { setBetslipFooterHeight } from '~store/slices/userUISlice';

import { SPORT_BETSLIP_TYPE_OPTIONS } from '../../constants';

import { BetAdjustmentButtons } from './BetAdjustmentButtons';
import { BetslipAggregatedValue } from './BetslipAggregatedValue';
import { BetslipErrors } from './BetslipErrors';
import { BetslipSubmitButton } from './BetslipSubmitButton';

const betSlipBottomPanelStyle = {
  display: 'flex',
  flexDirection: 'column',
  borderRadius: '$10',
  background: '$panelBgColor',
};

interface BetslipFooterProps {
  isStatic?: boolean;
  quickBet?: boolean;
}

export const BetslipFooter = ({
  isStatic,
  quickBet = false,
}: BetslipFooterProps) => {
  const { isMobileOrTablet } = useMedia();
  const { stakeType, events } = useAppSelector((state) => state.betslip);
  const isMultipleOrSystemBet =
    stakeType === SPORT_BETSLIP_TYPE_OPTIONS.MULTIPLE ||
    stakeType === SPORT_BETSLIP_TYPE_OPTIONS.SYSTEM;
  const componentRef = useRef<HTMLDivElement>(null);
  const dispatch = useAppDispatch();

  useComponentObserver(componentRef, (height) => {
    dispatch(setBetslipFooterHeight(height));
  });

  if (quickBet && !events.length) {
    return null;
  }

  return (
    <Box
      ref={componentRef}
      css={{
        ...betSlipBottomPanelStyle,
        '@xs_sm': {
          width: '100%',
          position: isStatic ? 'static' : 'fixed',
          bottom: 0,
        },
      }}
    >
      {isMultipleOrSystemBet && !!events.length && (
        <>
          <BetslipAggregatedValue />
        </>
      )}
      {isMobileOrTablet && <Separator verticalSpace={0} shrinkOut={0} />}
      <BetslipErrors />
      <Separator
        verticalSpace={0}
        shrinkOut={0}
        css={{ backgroundColor: '$betslipFooterSeparatorBgColor' }}
      />
      <BetAdjustmentButtons />
      <Separator
        verticalSpace={0}
        shrinkOut={0}
        css={{ backgroundColor: '$betslipFooterSeparatorBgColor' }}
      />
      <BetslipSubmitButton quickBet={quickBet} />
    </Box>
  );
};
