import { useCallback, useEffect, useState } from 'react';

import { useLazyGetEventLive } from '~api/live/liveQueries';
import { MainMarket } from '~api/market/types';
import { EVENT_STATUS, MARKET_STATUS, QUERY_PARAMS } from '~constants/common';
import { useMedia } from '~hooks/useMedia';
import { useQueryParams } from '~hooks/useQueryParams';
import { useReconnectedRequest } from '~hooks/useReconnectedRequest';
import { useRouterQuery } from '~hooks/useRouterQuery';
import { useAppDispatch, useAppSelector } from '~store';
import { removeMarkets, rewriteEvent } from '~store/slices/eventsSlice';
import { setLoadingEventId } from '~store/slices/liveMenuSlice';
import { Market, SportEvent, StoreSportEvent } from '~types/events';
import { groupByMarketId } from '~utils/markets';
import { areTwoObjectsEqual } from '~utils/objectHelpers';
import { QueryParams } from '~utils/url';

import { useLiveEventConnection } from './useLiveEventConnection';

export const useInplayEvent = () => {
  const dispatch = useAppDispatch();
  const { isMobileOrTablet } = useMedia();
  const search = useQueryParams();
  const { eventId, sportId } = search;
  const { updateQueryParams } = useRouterQuery();
  const { favoriteMarkets } = useAppSelector((state) => state.mainMarkets);
  const [isEventLoaded, setIsEventLoaded] = useState(false);
  const { eventsMap, marketsMap } = useAppSelector((state) => state.events);
  const { mainMarkets } = useAppSelector((state) => state.mainMarkets);
  const [eventData, setEventData] = useState<StoreSportEvent>();
  const [marketGroups, setMarketGroups] = useState<Record<string, Market[]>>(
    {},
  );
  const { lazyGetEventLiveQuery, lazyGetEventLiveIsFetching } =
    useLazyGetEventLive();
  const { language } = useAppSelector((state) => state.websiteSettings);
  const handleLoadEventData = useCallback(async (eventId: string) => {
    setIsEventLoaded(false);
    dispatch(setLoadingEventId(eventId));
    try {
      const responseEventData: SportEvent =
        await lazyGetEventLiveQuery(eventId).unwrap();

      const { markets = [], ...eventData } = responseEventData || {};

      const storeEvent = {
        ...eventData,
        marketIds: markets.map(({ id }) => id),
      };

      setEventData(storeEvent);
      setMarketGroups(groupByMarketId(markets));
      dispatch(rewriteEvent(responseEventData));
    } catch (e) {
      console.log(e);
    } finally {
      dispatch(setLoadingEventId(null));
      setIsEventLoaded(true);
    }
  }, []);

  const handleBackPress = () => {
    const searchCopy = { ...search };

    delete searchCopy.eventId;
    updateQueryParams(searchCopy as QueryParams<typeof QUERY_PARAMS>, true);
  };

  useEffect(() => {
    if (eventId) {
      handleLoadEventData(eventId);
    }

    return () => {
      setIsEventLoaded(false);
      setEventData(undefined);
      setMarketGroups((prevState) => {
        if (sportId) {
          const { markets: mainSportMarkets } = mainMarkets.find(
            ({ id }) => id === parseInt(sportId),
          ) || { markets: [] };

          if (mainSportMarkets.length) {
            const mainSportMarketsIds = mainSportMarkets.map(
              ({ id }: MainMarket) => id,
            );
            const marketIdsToRemove: string[] = [];

            Object.values(prevState).forEach((marketGroup) => {
              marketGroup.forEach((market) => {
                if (!mainSportMarketsIds.includes(+market.marketId)) {
                  marketIdsToRemove.push(market.id);
                }
              });
            });

            dispatch(removeMarkets(marketIdsToRemove));
          }
        }

        return {};
      });
    };
  }, [search, language]);

  useReconnectedRequest(() => eventId && handleLoadEventData(eventId));

  useEffect(() => {
    if (eventId) {
      const mapEvent = eventsMap[eventId];

      if (mapEvent && !areTwoObjectsEqual(mapEvent, eventData)) {
        setEventData(mapEvent);
      }
    }
  }, [eventId, eventData, eventsMap]);

  useEffect(() => {
    if (eventData && isEventLoaded) {
      const eventSportId = eventData?.sportId;
      const eventMarketIds = eventData?.marketIds || [];

      const mapUpdatedMarkets = eventMarketIds
        .map((item) => marketsMap[item])
        .filter(
          (item) =>
            item &&
            item.status === MARKET_STATUS.ACTIVE &&
            item.selections.length,
        );

      const ongoingSportFavorites = favoriteMarkets.find(
        (favoriteMarket) =>
          favoriteMarket.sportId === +(sportId || eventSportId || 0),
      );

      const mapUpdatedMarketsFavorites = mapUpdatedMarkets.map((market) => {
        if (market?.marketId) {
          return {
            ...market,
            favorite: ongoingSportFavorites?.marketIds.includes(
              parseInt(market.marketId),
            ),
          };
        }

        return market;
      });

      const resGroups = groupByMarketId(mapUpdatedMarketsFavorites);

      if (!areTwoObjectsEqual(resGroups, marketGroups)) {
        setMarketGroups(resGroups);
      }
    }
  }, [marketsMap, isEventLoaded, eventData, favoriteMarkets]);

  useEffect(() => {
    if (isMobileOrTablet) {
      const { status = EVENT_STATUS.ACTIVE } = eventData || {};

      if (
        ![
          EVENT_STATUS.ACTIVE,
          EVENT_STATUS.IN_PROGRESS,
          EVENT_STATUS.ABOUT_TO_START,
        ].includes(status)
      ) {
        const searchCopy = { ...search };

        delete searchCopy.eventId;
        updateQueryParams(searchCopy as QueryParams<typeof QUERY_PARAMS>, true);
      }
    }
  }, [eventData, isMobileOrTablet]);

  useLiveEventConnection({ loaded: !!eventData });

  return {
    isFetching: lazyGetEventLiveIsFetching,
    isMobileOrTablet,
    eventData,
    isEventLoaded: isEventLoaded,
    marketGroups,
    handleBackPress,
  };
};
