import { useMemo } from 'react';

import { Box } from '~components/atoms/Box';
import { Message } from '~components/atoms/Message';
import { useMedia } from '~hooks/useMedia';
import { useTranslation } from '~hooks/useTranslation';
import { useAppDispatch, useAppSelector } from '~store';

import {
  BETSLIP_ERRORS,
  getBetslipErrors,
  MIN_SYSTEM_BET_EVENTS,
  SPORT_BETSLIP_TYPE_OPTIONS,
} from '../../constants';
import { useBetslipErrors } from '../../hooks/useBetslipErrors';

export const BetslipErrors = () => {
  const { localizedError, localized } = useTranslation();
  const { isMobileOrTablet } = useMedia();
  const dispatch = useAppDispatch();
  const { betslipErrors, stakeType } = useAppSelector((state) => state.betslip);
  const { currency } = useAppSelector((state) => state.settings);
  const { partnerLimits } = useAppSelector((state) => state.settings);
  const {
    sportMaxStake,
    sportMinStake,
    sportMaxSelectionCount,
    sportMinSelectionCount,
  } = partnerLimits;
  const isSystemBet = stakeType === SPORT_BETSLIP_TYPE_OPTIONS.SYSTEM;

  useBetslipErrors();

  const errorsMap = useMemo(
    () =>
      getBetslipErrors({
        localized,
        localizedError,
        dispatch,
        websiteCurrency: currency,
        isMobileOrTablet,
        sportMaxStake,
        sportMinStake,
        sportMaxSelectionCount,
        sportMinSelectionCount: isSystemBet
          ? MIN_SYSTEM_BET_EVENTS
          : sportMinSelectionCount,
      }),
    [
      currency,
      isMobileOrTablet,
      sportMaxStake,
      sportMinStake,
      sportMaxSelectionCount,
      sportMinSelectionCount,
    ],
  );

  if (!betslipErrors.length) return null;

  return (
    <Box
      flexCol
      css={{
        gap: '$1',
        padding: '$4',
      }}
    >
      {betslipErrors.map((errorKey: BETSLIP_ERRORS) => {
        const errorData = errorsMap[errorKey];

        return <Message key={errorKey} {...errorData} />;
      })}
    </Box>
  );
};
