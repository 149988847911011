import React, { useState } from 'react';

import { Box } from '~components/atoms/Box';
import { Event } from '~components/molecules/Jackpot/Settled';
import { AccordionWrapper } from '~components/molecules/Jackpot/Settled/components/SettledContent/AccordionWrapper';
import {
  StyledJackpotAccordionContent,
  StyledJackpotAccordionRoot,
} from '~components/molecules/Jackpot/styled.components';
import { useAppSelector } from '~store';

export const ContentLarge = () => {
  const [openItems, setOpenItems] = useState<string[]>([]);
  const { settledJackpots } = useAppSelector((state) => state.jackpot);

  return (
    <StyledJackpotAccordionRoot
      type="multiple"
      value={openItems}
      onValueChange={setOpenItems}
    >
      {settledJackpots.map((settledJackpot) => {
        const { id } = settledJackpot;
        const isJackpotOpened = openItems.includes(id);

        return (
          <AccordionWrapper
            isJackpotOpened={isJackpotOpened}
            settledJackpot={settledJackpot}
          >
            <StyledJackpotAccordionContent>
              <Box
                flexCol
                gap={1}
                css={{
                  backgroundColor: '$gray',
                  borderRadius: '$8',
                }}
              >
                <Box flexCol gap={2} css={{ padding: '$2' }}>
                  {settledJackpot.events.map((event) => (
                    <Event event={event} />
                  ))}
                </Box>
              </Box>
            </StyledJackpotAccordionContent>
          </AccordionWrapper>
        );
      })}
    </StyledJackpotAccordionRoot>
  );
};
