import React, { forwardRef } from 'react';
import * as AccordionUI from '@radix-ui/react-accordion';
import { styled } from 'stitches.config';

import { Box } from '~components/atoms/Box';
import { Tag } from '~components/atoms/Tag';
import { Text } from '~components/atoms/Typography';
import { InfoIcon } from '~icons';
import { slideDown, slideUp } from '~utils/keyframes';

interface AccordionTriggerProps {
  count: string;
  text: string;
}

interface AccordionContentProps {
  children: React.ReactNode;
}

export const AccordionItem = styled(AccordionUI.Item, {
  position: 'relative',
  '&:focus-within': {
    position: 'relative',
    zIndex: 1,
  },
});

export const StyledTrigger = styled(AccordionUI.Trigger, {
  all: 'unset',
  fontFamily: 'inherit',
  flex: 1,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  fontSize: '$md',
  lineHeight: '$44',
  color: '$grayMedium',
  cursor: 'pointer',
  transition: 'border-radius 300ms cubic-bezier(0.87, 0, 0.13, 1)',
  '&:hover *, &[data-state="open"] *': {
    color: '$white',
  },
});

export const StyledHeader = styled(AccordionUI.Header, {
  all: 'unset',
  display: 'flex',
  '&:focus-within': {
    position: 'relative',
    zIndex: 1,
  },
});

export const StyledContent = styled(AccordionUI.Content, {
  overflow: 'hidden',
  background: '$gray',
  borderRadius: '$10',
  marginTop: '$3',
  // with an additional wrapper with styles animation works smoother
  '& > .contentWrapper': {
    p: '$2',
    display: 'flex',
    flexDirection: 'column',
    gap: '$2',
  },
  transition: 'border-radius 700ms cubic-bezier(0.87, 0, 0.13, 1)',
  '&[data-state="open"]': {
    animation: `${slideDown} 300ms cubic-bezier(0.87, 0, 0.13, 1)`,
    transitionDelay: 0,
  },
  '&[data-state="closed"]': {
    animation: `${slideUp} 300ms cubic-bezier(0.87, 0, 0.13, 1)`,
  },
});

export const AccordionTrigger = forwardRef<
  HTMLButtonElement,
  AccordionTriggerProps
>(({ count, text, ...props }, forwardedRef) => (
  <StyledHeader>
    <StyledTrigger {...props} ref={forwardedRef}>
      <Box flexRow alignCenter justifyContentStart gap={2}>
        <InfoIcon />
        <Text
          css={{
            fontSize: '$sm',
            lineHeight: '$16',
            '@xs_sm': {
              fontSize: '$xxs',
              lineHeight: '$20',
            },
          }}
        >
          {text}
        </Text>
      </Box>
      <Box>
        <Tag
          color="gray"
          text={count}
          css={{
            '@xs_sm': {
              fontSize: '$xxs',
              lineHeight: '$20',
            },
          }}
        />
      </Box>
    </StyledTrigger>
  </StyledHeader>
));

export const AccordionContent = forwardRef<
  HTMLDivElement,
  AccordionContentProps
>(({ children, ...props }, forwardedRef) => (
  <StyledContent {...props} ref={forwardedRef}>
    <Box className="contentWrapper">{children}</Box>
  </StyledContent>
));
