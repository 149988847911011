import { useEffect } from 'react';

import { Box } from '~components/atoms/Box';
import { Text } from '~components/atoms/Typography';
import { useTranslation } from '~hooks/useTranslation';
import { useAppDispatch } from '~store';
import { setSelectedFavoriteEventId } from '~store/slices/eventsSlice';
import { Market } from '~types/events';

import { MarketsList } from './MarketsList';

interface InplayEventMarketsProps {
  marketGroups: Record<string, Market[]>;
  eventId: string;
  isEventLoaded: boolean;
  isMobileOrTablet: boolean;
}

export const InplayEventMarkets = ({
  marketGroups,
  eventId,
  isEventLoaded,
  isMobileOrTablet,
}: InplayEventMarketsProps) => {
  const { localized } = useTranslation();
  const dispatch = useAppDispatch();

  useEffect(() => {
    return () => {
      dispatch(setSelectedFavoriteEventId(''));
    };
  }, []);

  return (
    <Box fullWidth flexCol>
      <Text
        level="16-24"
        css={{
          p: '$2 $4',
          backgroundColor: '$gray !important',
          borderRadius: isMobileOrTablet
            ? '0'
            : Object.values(marketGroups).length
            ? '$8 $8 0 0'
            : '$8',
        }}
      >
        {localized('inplayEvent.marketsLabel')}
      </Text>
      <Box
        css={{
          background: '$inplayMarketsAccordionContentBgColor',
          borderRadius: '0 0 $8 $8',
          '& > div': {
            p: '0 !important',
            borderRadius: '0 0 $8 $8',
          },
        }}
      >
        <MarketsList
          marketGroups={marketGroups}
          eventId={eventId}
          isEventLoaded={isEventLoaded}
        />
      </Box>
    </Box>
  );
};
