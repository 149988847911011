import { Box } from '~components/atoms/Box';
import { useMedia } from '~hooks/useMedia';
import { useTranslation } from '~hooks/useTranslation';

export const EmptyEvents = () => {
  const { isMobileOrTablet } = useMedia();
  const { localized } = useTranslation();

  return (
    <Box
      flexRow
      justifyCenter
      css={{
        p: isMobileOrTablet ? '$9 0' : `$11 0`,
        color: '$grayMedium',
        fontSize: '$20',
        lineHeight: '$32',
        '@xs_sm': {
          fontSize: '$12',
          lineHeight: '$20',
          pb: '96px',
        },
      }}
    >
      {localized('leagueEvents.emptyEvents')}
    </Box>
  );
};
