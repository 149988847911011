import React, { useMemo } from 'react';
import { SerializedError } from '@reduxjs/toolkit';

import { useCreateJackpotBet } from '~api/betslip/betslipMutations';
import { DIALOGS } from '~components/atoms/AbsoluteDialogs';
import { Box } from '~components/atoms/Box';
import { Button } from '~components/atoms/Button';
import { Message } from '~components/atoms/Message';
import { Text } from '~components/atoms/Typography';
import {
  getJackpotErrors,
  JACKPOT_ERRORS,
} from '~components/molecules/Jackpot/constants';
import {
  countJackpotStakes,
  countJackpotTotalTickets,
} from '~components/molecules/Jackpot/utils';
import { CURRENCY_SYMBOLS, MESSAGE_TYPES } from '~constants/common';
import { useMedia } from '~hooks/useMedia';
import { useTranslation } from '~hooks/useTranslation';
import { useAppDispatch, useAppSelector } from '~store';
import { openDialog } from '~store/slices/globalDialogSlice';
import {
  addJackpotMessage,
  clearJackpotStakesById,
  getJackpotMessages,
  selectJackpotStakesById,
} from '~store/slices/jackpotSlice';
import { Jackpot } from '~types/jackpot';
import { getErrorMessage } from '~utils/backendErrors';
import { formatNumberToStringWithSpaces } from '~utils/numberUtils';

interface FooterInfoProps {
  label: string;
  value: string | number;
}

interface ContentFooterProps {
  jackpot: Jackpot;
}

export const ContentFooter = ({ jackpot }: ContentFooterProps) => {
  const { isMobile, isMobileOrTablet } = useMedia();
  const { localized, localizedError } = useTranslation();
  const dispatch = useAppDispatch();
  const { currency } = useAppSelector((state) => state.settings);
  const jackpotStakes = useAppSelector(selectJackpotStakesById(jackpot.id));
  const { isUserLoggedIn } = useAppSelector((state) => state.userState);
  const messages = useAppSelector(getJackpotMessages);

  const { createJackpotBetMutation, createJackpotBetIsLoading } =
    useCreateJackpotBet();

  const eventStakesMap = countJackpotStakes(jackpotStakes);
  const isSubmitButtonDisabled =
    Object.values(eventStakesMap).length < jackpot.events.length ||
    createJackpotBetIsLoading;

  const { price } = jackpot;
  const totalTickets = countJackpotTotalTickets(
    jackpotStakes,
    jackpot.events.length,
  );
  const totalPrice = totalTickets * price;

  const errorsMap = useMemo(
    () =>
      getJackpotErrors(localized, localizedError, dispatch, isMobileOrTablet),
    [isMobileOrTablet],
  );

  const handleSubmitJackpotClick = async () => {
    if (isUserLoggedIn) {
      try {
        const response = await createJackpotBetMutation({
          jackpotId: jackpot.id,
          eventSelections: jackpotStakes,
        }).unwrap();

        if (response?.balance) {
          dispatch(clearJackpotStakesById(jackpot.id));
          dispatch(
            addJackpotMessage({
              type: MESSAGE_TYPES.SUCCESS,
              text: `${localized('jackpot.jackpotCreatedMessage')}`,
            }),
          );
        }
      } catch (err: unknown) {
        const errorMessage = getErrorMessage(err as SerializedError);
        const errorData = errorsMap[errorMessage as JACKPOT_ERRORS];

        dispatch(
          addJackpotMessage(
            errorData ?? {
              text: errorMessage,
              type: MESSAGE_TYPES.WARNING,
            },
          ),
        );

        console.error('Failed to create jackpot bet: ', err);
      }
    } else {
      dispatch(openDialog(DIALOGS.SIGN_IN));
    }
  };

  const handleClearAllClick = () =>
    dispatch(clearJackpotStakesById(jackpot.id));

  const FooterInfo = ({ label, value }: FooterInfoProps) => (
    <Box
      alignCenter
      gap={2}
      css={{
        display: 'flex',
        flexDirection: isMobile ? 'row' : 'column',
        ...(isMobile && { justifyContent: 'space-between' }),
      }}
    >
      <Text level={isMobile ? '12-20' : '18-24'}>{label}</Text>
      <Text level={isMobile ? '12-20' : '18-24'} color="yellow">
        {value}
      </Text>
    </Box>
  );

  return (
    <Box
      fullWidth
      flexCol
      gap={isMobile ? 3 : 5}
      css={{
        backgroundColor: '$grayDark',
        padding: isMobile ? '$3' : '$9',
        borderRadius: '$8',
      }}
    >
      <Box
        fullWidth
        css={{
          display: 'flex',
          flexDirection: isMobile ? 'column' : 'row',
          justifyContent: 'space-around',
        }}
      >
        <FooterInfo
          label={localized('jackpot.price')}
          value={`${formatNumberToStringWithSpaces(price)} ${
            CURRENCY_SYMBOLS[currency]
          }`}
        />
        <FooterInfo
          label={localized('jackpot.totalTickets')}
          value={totalTickets}
        />
        <FooterInfo
          label={localized('jackpot.totalPrice')}
          value={`${formatNumberToStringWithSpaces(totalPrice)} ${
            CURRENCY_SYMBOLS[currency]
          }`}
        />
      </Box>
      {messages.length > 0 &&
        messages.map((message) => <Message key={message.type} {...message} />)}

      <Box fullWidth>
        <Button
          size={isMobile ? 'small' : 'large'}
          fullWidth
          isLoading={createJackpotBetIsLoading}
          disabled={isSubmitButtonDisabled}
          onClick={handleSubmitJackpotClick}
        >
          {localized('jackpot.buyTicket')}
        </Button>
      </Box>
      <Box fullWidth>
        <Button
          size={isMobile ? 'small' : 'large'}
          fullWidth
          disabled={jackpotStakes.length === 0}
          variant="secondary"
          onClick={handleClearAllClick}
        >
          {localized('jackpot.clearAll')}
        </Button>
      </Box>
    </Box>
  );
};
