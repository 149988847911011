export enum HTTP_STATUS_CODES {
  BAD_REQUEST = '400',
  UNAUTHORIZED = '401',
  NOT_FOUND = '404',
  NOT_AVAILABLE = '500',
}

export enum LANGUAGE {
  EN = 'en',
}

export enum QUERY_PARAMS {
  SPORT_ID = 'sportId',
  COUNTRY_ID = 'countryId',
  LEAGUE_ID = 'leagueId',
  EVENT_ID = 'eventId',
  JACKPOT_ID = 'jackpotId',
  NEXT_HOUR = 'nextHour',
  DATE_TO = 'dateTo',
  MENU = 'menu',
  SHARE = 'share',
  REF = 'ref',
  GROUP = 'group',
  NAME = 'name',
  CHANNEL_TYPE = 'channelType',
  VERIFY_EMAIL_TOKEN = 'verifyEmailToken',
}

export type QueryParameters = {
  [K in QUERY_PARAMS]?: string;
};

export const SEARCH_TIMEOUT_MS = 2000;

export const SEARCH_TERM_MIN_LENGTH = 3;

export const MAXIMUM_UPLOAD_FILE_SIZE = 10 * 1024 * 1024; // 10MB

export enum MESSAGE_TYPES {
  WARNING = 'warning',
  ERROR = 'error',
  SUCCESS = 'success',
}

export enum CURRENCY_SYMBOLS {
  UGX = 'UGX',
  NGN = '₦',
  MZ = 'MZN',
  UA = '₴',
  TZ = 'TZS',
  ZMK = 'ZMW',
}

export enum CURRENCY_CODES {
  NGN = 'NGN',
  UGX = 'UGX',
  MZ = 'MZ',
  UA = 'UA',
  TZ = 'TZ',
  ZMK = 'ZMK',
}

export const DEFAULT_CURRENCY = CURRENCY_SYMBOLS.NGN;

export enum TOGGLE_DATA_STATE {
  OPEN = 'open',
  CLOSED = 'closed',
}

export enum EVENT_STATUS {
  ACTIVE = 1,
  IN_PROGRESS = 2,
  FINISHED = 3,
  CANCELLED = 4,
  POSTPONED = 5,
  INTERRUPTED = 6,
  ABANDONED = 7,
  COVERAGE_LOST = 8,
  ABOUT_TO_START = 9,
}

export enum SELECTION_STATUS {
  OPEN = 1,
  SUSPENDED = 2,
  SETTLED = 3,
  PLACEHOLDER = -1,
  TOTAL = -2,
  PLACEHOLDER_TOTAL = -3,
}

export enum MARKET_STATUS {
  ACTIVE = 1,
  INACTIVE = 2,
  SUSPENDED = 4,
  PLACEHOLDER = -1,
}

export enum GENDER_OPTIONS {
  MALE = '1',
  FEMALE = '2',
}

export const genderOptions = (
  localized: (key: string, args?: object) => string,
) => [
  {
    label: localized('userProfile.personalProfileInfo.male'),
    value: GENDER_OPTIONS.MALE,
  },
  {
    label: localized('userProfile.personalProfileInfo.female'),
    value: GENDER_OPTIONS.FEMALE,
  },
];

export const LEGAL_AGE = 18;

export enum BETSLIP_STATUS {
  OPEN = 1,
  WIN = 2,
  LOST = 3,
  PARTIAL_WIN = 4,
  PARTIAL_LOST = 5,
  VOID = 6,
  CUT_WIN = 7,
  CASH_OUT = 8,
}

export enum BETSLIP_DETAIL_STATUS {
  NOT_RESULTED = 0,
  LOSER = 1,
  WINNER = 2,
  REFUND = 3,
  HALF_LOST = 4,
  HALF_WON = 5,
  CASH_OUT = 6,
}

export const BETSLIP_WIN_STATUSES = [
  BETSLIP_STATUS.WIN,
  BETSLIP_STATUS.CUT_WIN,
  BETSLIP_STATUS.PARTIAL_WIN,
  BETSLIP_STATUS.PARTIAL_LOST,
];

export const BET_STATUS_I18N: Record<BETSLIP_STATUS, string> = {
  [BETSLIP_STATUS.OPEN]: 'betslipHistory.betslipHistoryTable.status.open',
  [BETSLIP_STATUS.WIN]: 'betslipHistory.betslipHistoryTable.status.win',
  [BETSLIP_STATUS.LOST]: 'betslipHistory.betslipHistoryTable.status.lost',
  [BETSLIP_STATUS.PARTIAL_WIN]:
    'betslipHistory.betslipHistoryTable.status.partialWin',
  [BETSLIP_STATUS.PARTIAL_LOST]:
    'betslipHistory.betslipHistoryTable.status.partialLost',
  [BETSLIP_STATUS.VOID]: 'betslipHistory.betslipHistoryTable.status.void',
  [BETSLIP_STATUS.CUT_WIN]: 'betslipHistory.betslipHistoryTable.status.cutWin',
  [BETSLIP_STATUS.CASH_OUT]:
    'betslipHistory.betslipHistoryTable.status.cashOut',
};

export const BET_STATUS_DETAIL_I18N: Record<BETSLIP_DETAIL_STATUS, string> = {
  [BETSLIP_DETAIL_STATUS.NOT_RESULTED]:
    'betslipHistory.betslipHistoryTable.detailStatus.notResulted',
  [BETSLIP_DETAIL_STATUS.LOSER]:
    'betslipHistory.betslipHistoryTable.detailStatus.lost',
  [BETSLIP_DETAIL_STATUS.WINNER]:
    'betslipHistory.betslipHistoryTable.detailStatus.winner',
  [BETSLIP_DETAIL_STATUS.REFUND]:
    'betslipHistory.betslipHistoryTable.detailStatus.refund',
  [BETSLIP_DETAIL_STATUS.HALF_LOST]:
    'betslipHistory.betslipHistoryTable.detailStatus.halfLost',
  [BETSLIP_DETAIL_STATUS.HALF_WON]:
    'betslipHistory.betslipHistoryTable.detailStatus.halfWon',
  [BETSLIP_DETAIL_STATUS.CASH_OUT]:
    'betslipHistory.betslipHistoryTable.detailStatus.cashOut',
};

export const DOT = '.';

export const CARD_LENGTH = 16;

export const BET_HISTORY_EVENT_CODE_LENGTH = 8;

export const REF_CODE_KEY = 'ref';

export enum CHANNEL_TYPES {
  ALL = 'ALL',
  WEB = 'WEB',
  MOBILE = 'MOBILE',
  TABLET = 'TABLET',
}

export enum ROUTE_TAB_NAMES {
  JACKPOT = 'jackpot',
  BASE = '/',
  NOT_FOUND = '*',
  INFO = 'info/:page',
  VERIFY_MESSANGER = 'verification/complete-messenger-auth/:token',
}
