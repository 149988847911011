import { useEffect } from 'react';

import { DIALOGS } from '~components/atoms/AbsoluteDialogs';
import { BETSLIP_TOGGLE_TYPES } from '~components/molecules/Betslip/constants';
import { QUERY_PARAMS, REF_CODE_KEY } from '~constants/common';
import { useHash } from '~hooks/useHash';
import { useMedia } from '~hooks/useMedia';
import { useQueryParams } from '~hooks/useQueryParams';
import { useRouterQuery } from '~hooks/useRouterQuery';
import { useAppDispatch } from '~store';
import { setShareCode, toggleBetslipType } from '~store/slices/betslipSlice';
import { openDialog } from '~store/slices/globalDialogSlice';

export const useShare = () => {
  const dispatch = useAppDispatch();
  const { removeQueryParams } = useRouterQuery();
  const { isMobileOrTablet } = useMedia();
  const { share, ref } = useQueryParams();
  const hash = useHash();

  useEffect(() => {
    if (share) {
      setTimeout(() => {
        removeQueryParams([QUERY_PARAMS.SHARE]);
        dispatch(setShareCode(share));
        dispatch(toggleBetslipType(BETSLIP_TOGGLE_TYPES.BETSLIP));
        if (isMobileOrTablet) {
          dispatch(openDialog(DIALOGS.BETSLIP));
        }
      }, 300);
    }
  }, [share]);

  useEffect(() => {
    const [hashVal, refVal] = hash.split('?');

    if (hashVal === '#register') {
      dispatch(openDialog(DIALOGS.SIGN_UP));
    }

    if (refVal && refVal.length) {
      const ref = refVal.split('=')[1];

      localStorage.setItem(REF_CODE_KEY, ref as string);
      removeQueryParams([QUERY_PARAMS.REF], true);
    }
  }, [hash, ref]);

  useEffect(() => {
    if (ref && ref.length) {
      setTimeout(() => {
        localStorage.setItem(REF_CODE_KEY, ref);
        removeQueryParams([QUERY_PARAMS.REF]);
      }, 300);
    }
  }, [ref]);
};
