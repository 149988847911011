import { BetAmount, BetslipItems } from '~api/betslip/types';
import { SportsBettingConfig } from '~api/partner/types';
import { MIN_SYSTEM_BET_EVENTS } from '~components/molecules/Betslip/constants';
import { EVENT_STATUS } from '~constants/common';
import { SystemBetOption, SystemBetOptions } from '~types/betslip';
import { StoreSportEvent } from '~types/events';
import { calculateCombinations } from '~utils/numberUtils';

interface GetBetslipStakeProps {
  amount: BetAmount;
  separateBonusBalance?: boolean;
  isBet?: boolean;
  systemBetOption?: SystemBetOption | null;
}

/*
  If separateBonusBalance is true, we sum main and bonus amounts
  If separateBonusBalance is false, we need to return either main or bonus amount,
  depending on which balance is selected
*/
export const getBetTotalAmount = (
  amount: BetAmount = { main: '0', bonus: '0' },
  separateBonusBalance: boolean = false,
  systemBetOption?: SystemBetOption | null,
): number => {
  const { main = 0, bonus = 0 } = amount;
  const mainAmount = Number(main);
  const bonusAmount = Number(bonus);

  if (systemBetOption) {
    return Number(systemBetOption.combinationsCount) * mainAmount;
  }

  return separateBonusBalance
    ? mainAmount + bonusAmount
    : mainAmount || bonusAmount;
};

// We can have either stake (if separateBonusBalance is false)
// or realStake with bonusStake (if true) to check changes and stake with bonusStake to place a bet
export const getBetslipStake = ({
  amount,
  separateBonusBalance = false,
  isBet = false,
}: GetBetslipStakeProps) => {
  const mainAmount = Number(amount?.main || '0');
  const bonusAmount = Number(amount?.bonus || '0');

  // Payload for placing a bet request
  if (isBet) {
    return {
      stake: mainAmount,
      ...(separateBonusBalance && { bonusStake: bonusAmount }),
    };
  }

  // Payload for calculating possible win request
  return separateBonusBalance
    ? { realStake: mainAmount, bonusStake: bonusAmount }
    : { stake: mainAmount };
};

export const calculateSystemBetOptions = (events: number): SystemBetOptions => {
  const results: SystemBetOptions = [];

  if (events < MIN_SYSTEM_BET_EVENTS) {
    return results;
  }

  for (let i = 2; i <= events - 1; i++) {
    // Start from 2 as 2/3 is the smallest valid system bet
    const combinationsCount = calculateCombinations(events, i);

    results.push({
      label: `${i}/${events} (${combinationsCount} opt.)`,
      value: i.toString(),
      combinationsCount: combinationsCount.toString(),
    });
  }

  return results;
};

export const getIsLiveBetslip = (
  eventsMap: Record<string, StoreSportEvent>,
  events: BetslipItems,
) => {
  return events.some(({ eventId }) => {
    const { status } = eventsMap[eventId] || {};

    return status === EVENT_STATUS.IN_PROGRESS;
  });
};

export const getMinMaxStake = (
  partnerLimits: SportsBettingConfig,
  isLive: boolean,
): { min: number; max: number } => {
  const {
    sportMaxStake,
    sportMinStake,
    maxStakeInPlay,
    minStakeInPlay,
    maxStakePreMatch,
    minStakePreMatch,
  } = partnerLimits;

  if (isLive) {
    return {
      min: minStakeInPlay || sportMinStake,
      max: maxStakeInPlay || sportMaxStake,
    };
  } else {
    return {
      min: minStakePreMatch || sportMinStake,
      max: maxStakePreMatch || sportMaxStake,
    };
  }
};
