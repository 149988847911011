import React from 'react';

import { SportMenuMobileSkeleton } from '~components/atoms/Skeletons';
import { LiveSportMenuMobile } from '~components/molecules/LiveMenu/components/LiveSportMenuMobile';
import { useMedia } from '~hooks/useMedia';

import { useLiveMenu } from '../hooks/useLiveMenu';

import { LiveSportMenu } from './LiveSportMenu';

export const LiveMenu = () => {
  const { liveSports, isLoaded } = useLiveMenu();
  const { isMobileOrTablet } = useMedia();

  return isMobileOrTablet ? (
    <>
      {!isLoaded ? (
        <SportMenuMobileSkeleton isLive />
      ) : (
        <LiveSportMenuMobile sports={liveSports} />
      )}
    </>
  ) : (
    <LiveSportMenu inPlayMenuSports={liveSports} />
  );
};
