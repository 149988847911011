import React from 'react';
import { Dispatch } from 'redux';

import { DIALOGS } from '~components/atoms/AbsoluteDialogs';
import { Content as ActiveContent } from '~components/molecules/Jackpot/Active';
import { JACKPOT_MENU_ITEMS } from '~components/molecules/Jackpot/Menu';
import { Content as SettledContent } from '~components/molecules/Jackpot/Settled';
import { Rules } from '~components/molecules/Jackpot/Settled/components/Rules';
import {
  USER_PAYMENT_TABS,
  USER_PROFILE_NAVIGATION_TABS,
} from '~components/molecules/UserProfile/components/UserProfileDialog/constants';
import { USER_PROFILE_DIALOG_MOBILE_STATE } from '~components/molecules/UserProfile/components/UserProfileDialogMobile';
import { MESSAGE_TYPES } from '~constants/common';
import { openDialog } from '~store/slices/globalDialogSlice';
import {
  setMobileUserProfileDialogActiveContent,
  setMobileUserProfileDialogState,
} from '~store/slices/mobileSlice';
import { setTabsState } from '~store/slices/personalDetailsSlice';

type LocalizedFunction = (key: string, args?: object) => string;

export enum JACKPOT_ERRORS {
  LOW_BALANCE = 'lowBalance',
  JACKPOT_NOT_ACTIVE = 'jackpotStatusNotEligibleForBets',
}

export enum JACKPOT_DETAILS_TABS {
  EVENTS = 'events',
  RULES = 'rules',
}

export const JACKPOT_DETAILS_TABS_LIST = [
  JACKPOT_DETAILS_TABS.EVENTS,
  JACKPOT_DETAILS_TABS.RULES,
];

export const getJackpotErrors = (
  localized: LocalizedFunction,
  localizedError: LocalizedFunction,
  dispatch: Dispatch,
  isMobileOrTablet: boolean,
) => ({
  [JACKPOT_ERRORS.LOW_BALANCE]: {
    text: localizedError(`betslip.errors.${JACKPOT_ERRORS.LOW_BALANCE}`),
    type: MESSAGE_TYPES.WARNING,
    action: {
      text: localized('buttons.deposit'),
      action: () => {
        if (!isMobileOrTablet) {
          dispatch(openDialog(DIALOGS.USER_PROFILE));
          dispatch(
            setTabsState({
              mainTab: USER_PROFILE_NAVIGATION_TABS.BALANCE_MANAGEMENT,
              contentTab: USER_PAYMENT_TABS.DEPOSIT,
            }),
          );
        } else {
          dispatch(openDialog(DIALOGS.USER_PROFILE_MOBILE));
          dispatch(
            setMobileUserProfileDialogState(
              USER_PROFILE_DIALOG_MOBILE_STATE.CONTENT,
            ),
          );
          dispatch(
            setMobileUserProfileDialogActiveContent(USER_PAYMENT_TABS.DEPOSIT),
          );
        }
      },
    },
  },
  [JACKPOT_ERRORS.JACKPOT_NOT_ACTIVE]: {
    text: localized(`jackpot.errors.${JACKPOT_ERRORS.JACKPOT_NOT_ACTIVE}`),
    type: MESSAGE_TYPES.WARNING,
  },
});

export const contentMap = {
  [JACKPOT_MENU_ITEMS.ACTIVE_JACKPOTS]: <ActiveContent />,
  [JACKPOT_MENU_ITEMS.SETTLED_JACKPOTS]: <SettledContent />,
  [JACKPOT_MENU_ITEMS.RULES]: <Rules />,
};
