import React from 'react';

import { MainMarket } from '~api/market/types';
import {
  AccordionContent,
  AccordionTrigger,
  StyledAccordionItem,
} from '~components/atoms/Accordion';
import { Box } from '~components/atoms/Box';
import { EventsList } from '~components/molecules/MainHighlights/EventsList';
import { MainMarketNamesList } from '~components/molecules/MainHighlights/MainMarketNamesList';
import { MainMarketSelectionNamesList } from '~components/molecules/MainHighlights/MainMarketSelectionNamesList';
import {
  HeaderBottomStyled,
  HeaderTopStyled,
} from '~components/molecules/MainHighlights/styled.components';
import { TopPrematchDateGroupIds } from '~constants/mainPage';
import { formatDateTime, TIME_FORMATS } from '~utils/formatDateTime';

import { useTopPrematchDateGroupItem } from '../../hooks/useTopPrematchDateGroupItem';

interface TopPrematchDateGroupItemProps {
  dateGroup: TopPrematchDateGroupIds;
  mainMarkets: MainMarket[];
  maxMarketsCount: number | undefined;
  isOpen: boolean;
}

export const TopPrematchDateGroupItem = ({
  dateGroup,
  mainMarkets,
  maxMarketsCount,
  isOpen,
}: TopPrematchDateGroupItemProps) => {
  const { matches, date, mainSportMarkets } = useTopPrematchDateGroupItem(
    dateGroup,
    mainMarkets,
    maxMarketsCount,
  );

  return (
    <>
      <StyledAccordionItem
        value={date}
        css={{
          '&:not(:last-child)': {
            mb: '$1',
          },
        }}
      >
        <AccordionTrigger>
          <HeaderTopStyled
            css={{
              pr: '$2',
              mr: '-$4',
              borderBottom: 0,
            }}
          >
            <Box>{formatDateTime(dateGroup.date, TIME_FORMATS.DATE)}</Box>
            {isOpen && <MainMarketNamesList mainMarkets={mainSportMarkets} />}
          </HeaderTopStyled>
        </AccordionTrigger>
        <AccordionContent
          css={{
            p: 0,
            backgroundColor: 'transparent',
            '& > .contentWrapper': {
              p: 0,
            },
          }}
        >
          <HeaderBottomStyled>
            <MainMarketSelectionNamesList mainMarkets={mainSportMarkets} />
          </HeaderBottomStyled>

          {matches && !!matches.length && (
            <EventsList activeEventIds={matches} mainMarkets={mainMarkets} />
          )}
        </AccordionContent>
      </StyledAccordionItem>
    </>
  );
};
