import { Box } from '~components/atoms/Box';
import { Skeleton } from '~components/atoms/Skeleton';

export const LeagueEventsMenuMobileSkeleton = () => {
  return (
    <Box flexCol css={{ backgroundColor: '$black' }}>
      <Box
        flexCol
        css={{
          gap: '1px',
          p: '$2 $1',
        }}
      >
        <Box
          css={{
            p: '$2 $3',
            background: '$gray',
            borderRadius: '$6 $6 0 0',
          }}
        >
          <Skeleton css={{ height: '$6', border: 'none' }} />
        </Box>
        <Box
          flexCol
          css={{
            position: 'relative',
            background: '$gray',
            p: '5px',
            borderRadius: '0 0 $6 $6',
          }}
        >
          <Skeleton
            css={{
              height: '$5',
              border: 'none',
              m: '$2 2px',
            }}
          />

          <Box flexCol gap={1}>
            {Array.from({ length: 6 }, (_, key) => {
              return (
                <Box
                  key={key}
                  css={{ p: '$3', background: '$black', borderRadius: '$8' }}
                >
                  <Skeleton css={{ height: '$8', border: 'none' }} />
                  <Skeleton css={{ height: '$4', border: 'none', mt: '$3' }} />
                </Box>
              );
            })}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
