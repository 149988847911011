import React, { ReactNode, useMemo } from 'react';
import { styled } from 'stitches.config';

import { Box } from '~components/atoms/Box';
import { Logo } from '~components/atoms/Logo/Logo';
import { Separator } from '~components/atoms/Separator';
import { SettingsDropdown } from '~components/atoms/SettingsDropdown';
import { SignIn } from '~components/molecules/Authentication/components/sign-in/SignIn';
import { SignUp } from '~components/molecules/Authentication/components/sign-up/SignUp';
import { UserProfileBar } from '~components/molecules/UserProfile/components/UserProfileBar/UserProfileBar';
import { HeaderMenu } from '~components/organisms/Header/HeaderMenu';
import { CURRENCY_CODES } from '~constants/common';
import { useMedia } from '~hooks/useMedia';
import { useAppSelector } from '~store';

const StyledHeader = styled('header', {
  position: 'relative',
  fontFamily: 'inherit',
  height: '140px',
  maxWidth: '1920px',
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  backgroundColor: '$headerBgColor',
  p: '10px $5',
  '@xs_sm': {
    p: '10px $4',
    borderBottom: '1px solid $headerBottomBorder',
    height: 'unset',
  },
  '@md': {
    height: '128px',
  },
  variants: {
    noBottomBorder: {
      true: {
        borderBottom: 'none',
      },
    },
    inModal: {
      true: {
        backgroundColor: '$modalHeaderBgColor',
        '@xs_sm': {
          p: '10px  52px 10px $4 !important',
          borderBottom: '1px solid $headerBottomBorder',
        },
      },
    },
  },
});

interface HeaderProps {
  children?: ReactNode;
  showSettings?: boolean;
  noBottomBorder?: boolean;
  inModal?: boolean;
}

export const Header = ({
  children,
  noBottomBorder = false,
  inModal = false,
  showSettings,
}: HeaderProps) => {
  const { isUserLoggedIn } = useAppSelector((state) => state.userState);
  const { currency, separateBonusBalance } = useAppSelector(
    (state) => state.settings,
  );
  const isUganda = useMemo(() => currency === CURRENCY_CODES.UGX, [currency]);
  const { isMobile, isLaptop } = useMedia();
  const { isWindowScrolled, isDialogScrolled } = useAppSelector(
    (state) => state.userUIState,
  );

  // When user is logged in on the mobile device, and has separated bonus balance and scrolling the page,
  // we are showing only the user balance in the header and hiding the logo
  const isSmallMobileScrolled =
    separateBonusBalance &&
    isUserLoggedIn &&
    isMobile &&
    (isWindowScrolled || isDialogScrolled);

  const logoCss = useMemo(
    () => ({
      width: 'unset',
      height: 'unset',
      '@md_lg_xl': {
        width: !isUganda ? '216px' : '264px',
        height: '64px',
      },
      '@xs_sm': {
        width: '92px',
        height: '26px',
      },
      '@xxs': {
        width: '56px',
        height: '16px',
      },
    }),
    [isUganda],
  );

  return (
    <Box
      css={{
        backgroundColor: '$headerBgColor',
        gridArea: 'header',
      }}
      flexRow
      justifyCenter
      fullWidth
    >
      <StyledHeader noBottomBorder={noBottomBorder} inModal={inModal}>
        <Box
          flexCol
          fullWidth
          css={{
            gap: isLaptop ? '10px' : '$3',
          }}
        >
          <Box flexRow justifyContentBetween alignCenter>
            {!isSmallMobileScrolled && (
              <Box
                flexRow
                css={{
                  gap: '60px',
                }}
                alignCenter
                justifyContentStart
              >
                <Logo type="header" css={logoCss} />
              </Box>
            )}
            {!isUserLoggedIn ? (
              <Box
                flexRow
                alignCenter
                gap={2}
                css={{
                  div: {
                    height: '44px',
                    '@xs_sm': {
                      height: '28px',
                    },
                  },
                }}
              >
                <SignIn />
                <SignUp />
                {showSettings && <SettingsDropdown />}
              </Box>
            ) : (
              <UserProfileBar isSmallMobileScrolled={isSmallMobileScrolled} />
            )}
            {children}
          </Box>
          {!inModal && (
            <>
              <Separator
                css={{ backgroundColor: '$gray' }}
                verticalSpace={0}
                shrinkOut={5}
              />
              <HeaderMenu />
            </>
          )}
        </Box>
      </StyledHeader>
    </Box>
  );
};
