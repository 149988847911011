import React, { memo } from 'react';

import { OpenedBet } from '~api/betslip/types';
import { Box } from '~components/atoms/Box';
import { Button } from '~components/atoms/Button';
import { Separator } from '~components/atoms/Separator';
import { ShareBet } from '~components/atoms/ShareBet';
import { StakeBalancesInfo } from '~components/atoms/StakeBalancesInfo/StakeBalancesInfo';
import { Text } from '~components/atoms/Typography';
import { BetslipHistoryStatusCell } from '~components/molecules/BetslipHistorySection/Sports/BetslipHistoryTable/components/BetslipHistoryStatusCell';
import { WinboostPopover } from '~components/WinboostPopover';
import { BETSLIP_STATUS, CURRENCY_SYMBOLS } from '~constants/common';
import { useTranslation } from '~hooks/useTranslation';
import { useAppDispatch, useAppSelector } from '~store';
import { setCashOutBet, setIsCashoutOpen } from '~store/slices/openBetSlice';
import { formatDateTime, TIME_FORMATS } from '~utils/formatDateTime';
import { commaSeparatedNumber } from '~utils/numberUtils';

import {
  SPORT_BETSLIP_TYPE_CODES,
  SPORT_BETSLIP_TYPES_MAP,
} from '../../constants';

import { OpenBetsDetails } from './OpenBetDetails';

interface OpenBetProps {
  bet: OpenedBet;
}

const textStyles = {
  fontSize: '$sm',
  lineHeight: '$16',
  '@xs_sm': {
    fontSize: '$xxs',
    lineHeight: '$20',
  },
};

export const OpenBet = memo(({ bet }: OpenBetProps) => {
  const dispatch = useAppDispatch();
  const { currency } = useAppSelector((state) => state.settings);
  const { localized } = useTranslation();
  const copyBetslipCodeLinkPrefix = window.location.origin + '?share=';
  const {
    type,
    code,
    createdAt,
    stake,
    totalOdd,
    possibleWin,
    details,
    availableCashout,
    bonusBalanceUsages,
    winBoostAmount,
    winBoostMultiplier,
    originalWinAmount,
    status,
  } = bet;

  const betType = SPORT_BETSLIP_TYPES_MAP[type];
  const isSystemBet = type === SPORT_BETSLIP_TYPE_CODES.SYSTEM;
  const handleCashOutClick = () => {
    dispatch(setCashOutBet(bet));
    dispatch(setIsCashoutOpen(true));
  };

  return (
    <Box
      flexCol
      css={{
        p: '$2 $3',
        gap: '$2',
        borderRadius: '$8',
        backgroundColor: '$openBetItemBgColor',
      }}
    >
      <Box flexRow justifyContentBetween alignCenter>
        <Text color="grayMedium" css={{ ...textStyles }}>
          {localized('betslip.betTypes.' + betType)}
        </Text>
        <BetslipHistoryStatusCell status={1} isLarge />
      </Box>
      <Box flexRow justifyContentBetween gap={2}>
        <Box
          flexRow
          css={{
            color: '$grayMedium',
            whiteSpace: 'nowrap',
          }}
          gap={1}
          alignCenter
        >
          <Text color="grayMedium" css={{ ...textStyles }}>
            ID: {code}
          </Text>
          <ShareBet data={{ url: copyBetslipCodeLinkPrefix + code }} />
        </Box>
        <Text css={{ ...textStyles }}>
          {formatDateTime(createdAt, TIME_FORMATS.DATE_TIME_24H_WITH_SECONDS)}
        </Text>
      </Box>
      <Separator
        verticalSpace={0}
        shrinkOut={3}
        css={{
          backgroundColor: '$grayDarker',
        }}
      />
      <Box flexRow justifyContentBetween>
        <Box flexRow gap={2} alignCenter>
          <Text color="grayMedium" css={{ ...textStyles }}>
            {localized('betslip.openBetsCard.stakeLabel')}
          </Text>
          {!!bonusBalanceUsages?.length && (
            <StakeBalancesInfo
              balanceChanges={bonusBalanceUsages}
              fullStake={stake}
            />
          )}
        </Box>
        <Box flexRow gap={2}>
          <Text textTransform="uppercase" css={{ ...textStyles }}>
            {commaSeparatedNumber(stake)} {CURRENCY_SYMBOLS[currency]}
          </Text>
        </Box>
      </Box>
      <Box flexRow justifyContentBetween>
        <Text color="grayMedium" css={{ ...textStyles }}>
          {localized('betslip.openBetsCard.oddsLabel')}
        </Text>
        <Text color="yellow" css={{ ...textStyles }}>
          {isSystemBet ? '-' : commaSeparatedNumber(totalOdd)}
        </Text>
      </Box>
      <Separator
        verticalSpace={0}
        shrinkOut={3}
        css={{
          backgroundColor: '$grayDarker',
        }}
      />
      <Box flexRow justifyContentBetween>
        <Box flexRow alignCenter gap={2}>
          <Text css={{ ...textStyles }}>
            {localized('betslip.openBetsCard.possibleWinLabel')}
          </Text>
          {!!winBoostAmount && status !== BETSLIP_STATUS.CASH_OUT && (
            <WinboostPopover
              status={1}
              winBoostAmount={winBoostAmount}
              winBoostMultiplier={winBoostMultiplier}
              originalWinAmount={originalWinAmount}
            />
          )}
        </Box>
        <Text color="yellow" css={{ ...textStyles }}>
          {commaSeparatedNumber(possibleWin)} {CURRENCY_SYMBOLS[currency]}
        </Text>
      </Box>
      {!!availableCashout && !isSystemBet && (
        <Button
          onClick={handleCashOutClick}
          css={{ backgroundColor: '$greenColorIndicator', gap: '$1' }}
        >
          <Text color="white" level="14-20">
            {localized('betslip.cashOut')}
          </Text>
          <Text level="11-16" color="yellow">
            {commaSeparatedNumber(availableCashout)}{' '}
            {CURRENCY_SYMBOLS[currency]}
          </Text>
        </Button>
      )}
      <Separator
        verticalSpace={0}
        shrinkOut={3}
        css={{
          backgroundColor: '$grayDarker',
        }}
      />
      <OpenBetsDetails details={details} />
    </Box>
  );
});
