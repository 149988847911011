import { styled } from 'stitches.config';

import { Box } from '~components/atoms/Box';

export const StyledSearchResultListHeader = styled(Box, {
  position: 'sticky',
  top: 0,
  width: '100%',
  p: '0 $4 $3',
  backgroundColor: '$panelBgColor',
});

export const StyledSearchResultListWrapper = styled(Box, {
  position: 'absolute',
  top: '$10',
  left: 0,
  right: 0,
  height: 'calc(100vh - 160px)', // TODO: to be refactored
  zIndex: 1,
  backgroundColor: '$panelBgColor',
  variants: {
    isRelative: {
      true: {
        position: 'relative',
        top: 0,
        height: 'auto',
        pt: '$2',
      },
    },
  },
});
