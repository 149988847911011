import { useEffect, useState } from 'react';

import { MainMarket } from '~api/market/types';

export const useLiveTopEventItem = (
  mainMarkets: MainMarket[],
  maxMarketsCount: number | undefined,
) => {
  const [mainSportMarkets, setMainSportMarkets] = useState<MainMarket[]>([]);

  useEffect(() => {
    let markets = [...mainMarkets];

    const isMaxMarketsCountValid =
      typeof maxMarketsCount === 'number' && !isNaN(maxMarketsCount);

    if (isMaxMarketsCountValid) {
      markets = markets.slice(0, Math.max(0, maxMarketsCount));
    }

    setMainSportMarkets(markets);
  }, [mainMarkets, maxMarketsCount]);

  return {
    mainSportMarkets,
  };
};
