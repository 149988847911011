import React, { useState } from 'react';

import { SettingsDropdownPopover } from '~components/atoms/SettingsDropdown/SettingsDropdownPopover';
import { StyledDropdownTrigger } from '~components/atoms/SettingsDropdown/styled.components';
import { StyledUserProfileBarContainer } from '~components/molecules/UserProfile/components/UserProfileBar';
import useIsInIframe from '~hooks/useIsInIframe';
import { SettingsIcon } from '~icons';

export const SettingsDropdown = () => {
  const isInIframe = useIsInIframe();
  const [isOpen, setIsOpen] = useState(false);

  return (
    <StyledUserProfileBarContainer
      isSquare
      isOpened={isOpen}
      flexRow
      justifyCenter
      alignCenter
      css={{
        borderColor: `${isInIframe ? '$blueDark' : '$green'} !important`,
        background: `${isInIframe ? 'transparent' : isOpen ? '$greenOpacity' : 'none'}`,
        '> div': {
          color: `${isInIframe && isOpen ? '$yellow' : '$white'}`,
        },
      }}
    >
      <SettingsDropdownPopover onOpenChange={setIsOpen}>
        <StyledDropdownTrigger as="div">
          <SettingsIcon width={20} height={20} />
        </StyledDropdownTrigger>
      </SettingsDropdownPopover>
    </StyledUserProfileBarContainer>
  );
};
