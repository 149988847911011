import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  BalanceChangeResponse,
  BetAmount,
  BetslipItem,
  BetslipItems,
} from '~api/betslip/types';
import {
  BETSLIP_ERRORS,
  BETSLIP_TOGGLE_TYPES,
  ODD_ASK_TYPES,
  SPORT_BETSLIP_TYPE_OPTIONS,
} from '~components/molecules/Betslip/constants';
import { RootState } from '~store';
import { SystemBetOption } from '~types/betslip';
import { JackpotBetsHistory } from '~types/jackpot';

export interface BetslipState {
  activeTab: BETSLIP_TOGGLE_TYPES;
  oddAskType: ODD_ASK_TYPES | null;
  stakeType: SPORT_BETSLIP_TYPE_OPTIONS;
  systemBetOption: SystemBetOption | null;
  singleBetsAmountMap: Record<string, BetAmount>;
  aggregatedBetAmount: BetAmount;
  multipleBetBonusAmount: number;
  betErrorCode: number;
  multipleBetBonusDescriptions: string[];
  duplicatedMultipleBetStakes: string[];
  events: BetslipItems;
  rebet: BetslipItems;
  rebetSingleBetsAmountMap: Record<string, BetAmount>;
  rebetStakeType: SPORT_BETSLIP_TYPE_OPTIONS;
  rebetAggregatedBetAmount: BetAmount;
  inactiveEvents: string[];
  betslipErrors: BETSLIP_ERRORS[];
  isProcessing: boolean;
  isProcessed: boolean;
  isProcessingFailed: boolean;
  isBetslipChanged: string[];
  isBetslipHistoryLoading: boolean;
  shareCode: string | null;
  betErrorEvent: string | null;
  balanceChangesOnBetData: BalanceChangeResponse | null;
  isBalanceChangesLoaded: boolean;
  isBalanceChangesLoading: boolean;
  oddsCount: number;
  totalOdd: number;
  reloadBetslip: boolean;
  jackpotsBetsHistory: JackpotBetsHistory[];
  selectedJackpotHistoryId: null | string;
}

const initialState: BetslipState = {
  activeTab: BETSLIP_TOGGLE_TYPES.BETSLIP,
  oddAskType: null,
  stakeType: SPORT_BETSLIP_TYPE_OPTIONS.MULTIPLE,
  systemBetOption: null,
  singleBetsAmountMap: {},
  aggregatedBetAmount: {},
  betErrorCode: 0,
  betErrorEvent: null,
  multipleBetBonusAmount: 0,
  multipleBetBonusDescriptions: [],
  duplicatedMultipleBetStakes: [],
  events: [],
  rebet: [],
  rebetSingleBetsAmountMap: {},
  rebetStakeType: SPORT_BETSLIP_TYPE_OPTIONS.MULTIPLE,
  rebetAggregatedBetAmount: {},
  inactiveEvents: [],
  betslipErrors: [],
  isProcessing: false,
  isProcessed: false,
  isProcessingFailed: false,
  isBetslipChanged: [],
  isBetslipHistoryLoading: false,
  shareCode: null,
  balanceChangesOnBetData: null,
  isBalanceChangesLoaded: false,
  isBalanceChangesLoading: false,
  oddsCount: 0,
  totalOdd: 0,
  reloadBetslip: false,
  jackpotsBetsHistory: [],
  selectedJackpotHistoryId: null,
};

export const betslipSlice = createSlice({
  name: 'betslip',
  initialState,
  reducers: {
    toggleBetslipType: (state, action: PayloadAction<BETSLIP_TOGGLE_TYPES>) => {
      state.activeTab = action.payload;
    },
    setStakeType: (
      state,
      action: PayloadAction<SPORT_BETSLIP_TYPE_OPTIONS>,
    ) => {
      state.isBetslipChanged = [];
      if (action.payload !== SPORT_BETSLIP_TYPE_OPTIONS.SYSTEM) {
        state.systemBetOption = null;
      }

      state.stakeType = action.payload;
    },
    setOddAskType: (state, action: PayloadAction<ODD_ASK_TYPES>) => {
      state.oddAskType = action.payload;
    },
    setSystemBetOption: (
      state,
      action: PayloadAction<SystemBetOption | null>,
    ) => {
      state.systemBetOption = action.payload;
    },
    setAdjustedBetAmount: (state, action: PayloadAction<BetAmount>) => {
      if (state.stakeType === SPORT_BETSLIP_TYPE_OPTIONS.SINGLE) {
        const nextSingleBetsAmountMap: Record<string, BetAmount> = {
          ...state.singleBetsAmountMap,
        };

        state.events.forEach(({ selectionId }) => {
          nextSingleBetsAmountMap[selectionId] = {
            ...nextSingleBetsAmountMap[selectionId],
            ...action.payload,
          };
        });
        state.singleBetsAmountMap = nextSingleBetsAmountMap;
      } else {
        state.aggregatedBetAmount = {
          ...state.aggregatedBetAmount,
          ...action.payload,
        };
      }
    },
    setMultipleBetBonus: (
      state,
      action: PayloadAction<{
        totalBonus: number;
        bonusDescriptions: string[];
      }>,
    ) => {
      state.balanceChangesOnBetData = {
        isBalanceSufficient: true,
        balanceChanges: [],
        totalActiveBonusesCount: 0,
      };
      const { totalBonus = 0, bonusDescriptions = [] } = action.payload || {};

      state.multipleBetBonusAmount = totalBonus;
      state.multipleBetBonusDescriptions = bonusDescriptions;
    },
    setBetslipErrors: (state, action: PayloadAction<BETSLIP_ERRORS[]>) => {
      state.betslipErrors = action.payload;
    },
    setDuplicatedMultipleBetStakes: (
      state,
      action: PayloadAction<string[]>,
    ) => {
      state.duplicatedMultipleBetStakes = action.payload;
    },
    addStake: (state, action: PayloadAction<BetslipItem>) => {
      if (state.rebet.length) {
        state.rebet = [];
      }

      if (state.events.length === 0) {
        state.singleBetsAmountMap = {};
      }

      if (state.activeTab === BETSLIP_TOGGLE_TYPES.OPEN_BETS) {
        state.activeTab = BETSLIP_TOGGLE_TYPES.BETSLIP;
      }

      if (state.events.length === 1 && state.events[0]) {
        const isDifferentEventId =
          state.events[0].eventId !== action.payload.eventId;

        if (isDifferentEventId) {
          // We can have isDifferentEventId and SYSTEM bet, in that case - don't change type
          if (state.stakeType === SPORT_BETSLIP_TYPE_OPTIONS.SINGLE) {
            state.stakeType = SPORT_BETSLIP_TYPE_OPTIONS.MULTIPLE;
          }
        } else {
          state.stakeType = SPORT_BETSLIP_TYPE_OPTIONS.SINGLE;
        }
      }

      if (
        state.events.length > 1 &&
        state.stakeType === SPORT_BETSLIP_TYPE_OPTIONS.MULTIPLE
      ) {
        const isEventInBetSlip = state.events.some(
          (event) => event.eventId === action.payload.eventId,
        );

        if (isEventInBetSlip) {
          state.events = [
            ...state.events.filter(
              ({ eventId }) => eventId !== action.payload.eventId,
            ),
            action.payload,
          ];

          return;
        }
      }

      state.events = [...state.events, action.payload];
    },
    setStakes: (state, action: PayloadAction<BetslipItems>) => {
      if (state.events.length === 0) {
        state.isBetslipChanged = [];
        state.aggregatedBetAmount = {};
        state.multipleBetBonusAmount = 0;
      }

      state.events = [...action.payload];
    },
    removeStakeBySelection: (state, action: PayloadAction<string>) => {
      const selectionId = action.payload;

      state.events = state.events.filter(
        (item) => item.selectionId !== selectionId,
      );

      if (Array.isArray(state.isBetslipChanged)) {
        state.isBetslipChanged = state.isBetslipChanged.filter(
          (item) => item !== selectionId,
        );
      }

      if (state.events.length === 0) {
        state.aggregatedBetAmount = {};
        state.multipleBetBonusAmount = 0;
        state.isBetslipChanged = [];
      }

      if (state.singleBetsAmountMap[selectionId]) {
        delete state.singleBetsAmountMap[selectionId];
      }
    },
    setSingleBetAmount: (
      state,
      action: PayloadAction<{
        id: string;
        amount: BetAmount;
      }>,
    ) => {
      state.balanceChangesOnBetData = {
        isBalanceSufficient: true,
        balanceChanges: [],
        totalActiveBonusesCount: 0,
      };
      state.rebet = [];
      const { id, amount } = action.payload;
      const nextSingleBetsAmountMap = { ...state.singleBetsAmountMap };

      nextSingleBetsAmountMap[id] = amount;
      state.singleBetsAmountMap = nextSingleBetsAmountMap;
    },
    setSingleBetAmountMap: (
      state,
      action: PayloadAction<Record<string, BetAmount>>,
    ) => {
      state.balanceChangesOnBetData = {
        isBalanceSufficient: true,
        balanceChanges: [],
        totalActiveBonusesCount: 0,
      };
      state.rebet = [];
      state.singleBetsAmountMap = action.payload;
    },
    setBetslipProcessing: (state, action: PayloadAction<boolean>) => {
      state.isProcessing = action.payload;
    },
    setBetslipProcessingFailed: (state, action: PayloadAction<boolean>) => {
      state.isProcessingFailed = action.payload;
      state.isProcessing = false;
      state.isProcessed = true;
    },
    setBetslipProcessed: (state, action: PayloadAction<boolean>) => {
      state.isProcessing = false;
      state.isProcessingFailed = false;
      const isProcessed = action.payload;

      state.isProcessed = isProcessed;
      if (isProcessed) {
        state.rebet = state.events;
        state.rebetAggregatedBetAmount = state.aggregatedBetAmount;
        state.rebetSingleBetsAmountMap = state.singleBetsAmountMap;
        state.rebetStakeType = state.stakeType;
        state.events = [];
        state.singleBetsAmountMap = {};
        state.aggregatedBetAmount = {};
        state.multipleBetBonusAmount = 0;
        state.singleBetsAmountMap = {};
        state.multipleBetBonusDescriptions = [];
        state.duplicatedMultipleBetStakes = [];
        state.betslipErrors = [];
        state.inactiveEvents = [];
      }
    },
    setAggregatedBetAmount: (state, action: PayloadAction<BetAmount>) => {
      state.balanceChangesOnBetData = {
        isBalanceSufficient: true,
        balanceChanges: [],
        totalActiveBonusesCount: 0,
      };
      state.aggregatedBetAmount = action.payload;
    },
    setBetslipChanged: (state, action: PayloadAction<string>) => {
      const selectionId = action.payload;

      !state.isBetslipChanged.includes(selectionId) &&
        state.isBetslipChanged.push(selectionId);
    },
    resetBetslipChanged: (state) => {
      state.isBetslipChanged = [];
    },
    setIsBetslipHistoryLoading: (state, action: PayloadAction<boolean>) => {
      state.isBetslipHistoryLoading = action.payload;
    },
    setShareCode: (state, action: PayloadAction<string | null>) => {
      state.shareCode = action.payload;
    },
    setBalanceChangesOnBetData: (
      state,
      action: PayloadAction<BalanceChangeResponse | null>,
    ) => {
      state.balanceChangesOnBetData = action.payload;
      state.isBalanceChangesLoaded = true;
    },
    setIsBonusChangesLoading: (state, action: PayloadAction<boolean>) => {
      state.isBalanceChangesLoading = action.payload;
    },
    removeAll: (state) => {
      state.stakeType = SPORT_BETSLIP_TYPE_OPTIONS.SINGLE;
      state.events = [];
      state.singleBetsAmountMap = {};
      state.aggregatedBetAmount = {};
      state.multipleBetBonusAmount = 0;
      state.singleBetsAmountMap = {};
      state.multipleBetBonusDescriptions = [];
      state.duplicatedMultipleBetStakes = [];
      state.betslipErrors = [];
      state.inactiveEvents = [];
      state.isBetslipChanged = [];
      state.systemBetOption = null;
    },
    setBetErrorCode: (state, action: PayloadAction<number>) => {
      state.betErrorCode = action.payload;
    },
    setBetErrorEvent: (state, action: PayloadAction<string | null>) => {
      state.betErrorEvent = action.payload;
    },
    setOddsCount: (state, action: PayloadAction<number>) => {
      state.oddsCount = action.payload;
    },
    setTotalOdd: (state, action: PayloadAction<number>) => {
      state.totalOdd = action.payload;
    },
    setRebet: (state, action: PayloadAction<BetslipItems>) => {
      state.rebet = action.payload;
    },
    setReloadBetslip: (state, action: PayloadAction<boolean>) => {
      state.reloadBetslip = action.payload;
    },
    setJackpotsBetsHistory: (state, action) => {
      state.jackpotsBetsHistory = action.payload;
    },
    setSelectedJackpotHistoryId: (
      state,
      action: PayloadAction<string | null>,
    ) => {
      state.selectedJackpotHistoryId = action.payload;
    },
  },
});

export const getJackpotsBetsHistory = (state: RootState) =>
  state.betslip.jackpotsBetsHistory;

export const selectJackpotHistoryById = (id?: string) =>
  createSelector(getJackpotsBetsHistory, (jackpotBetsHistory) => {
    return jackpotBetsHistory.find((jackpot) => jackpot.id === id);
  });

export const {
  toggleBetslipType,
  setStakeType,
  setSystemBetOption,
  setAdjustedBetAmount,
  setStakes,
  removeStakeBySelection,
  setOddAskType,
  setSingleBetAmount,
  setMultipleBetBonus,
  setDuplicatedMultipleBetStakes,
  setBetslipErrors,
  setBetslipProcessing,
  setBetslipProcessed,
  setBetslipProcessingFailed,
  addStake,
  setBetslipChanged,
  setIsBetslipHistoryLoading,
  setShareCode,
  setBalanceChangesOnBetData,
  setIsBonusChangesLoading,
  removeAll,
  setBetErrorCode,
  setBetErrorEvent,
  setOddsCount,
  resetBetslipChanged,
  setTotalOdd,
  setRebet,
  setReloadBetslip,
  setSingleBetAmountMap,
  setAggregatedBetAmount,
  setJackpotsBetsHistory,
  setSelectedJackpotHistoryId,
} = betslipSlice.actions;

export default betslipSlice.reducer;
