import { Box } from '~components/atoms/Box';
import { Text } from '~components/atoms/Typography';
import { InfoWrapper } from '~components/molecules/Jackpot/Event/InfoWrapper';
import { SmallScreenEventHeader } from '~components/molecules/Jackpot/Event/SmallScreenEventHeader';
import {
  EventInfoText,
  EventListItemWrapper,
} from '~components/molecules/Jackpot/Settled/styled.components';

import { SettledEventsProps } from './Event';

export const EventSmall = ({
  event,
  mainMarketSelection,
}: SettledEventsProps) => {
  const { name, homeScore, awayScore } = event;

  // Name has the following format: 'HomeTeam vs. AwayTeam'
  const [homeTeam, awayTeam] = name.split('vs.');

  const ScoreText = ({ score }: { score?: number }) => (
    <Text
      css={{
        color: '$yellow',
        fontSize: '14px',
        lineHeight: '24px',
        '@xs_sm': {
          fontSize: '12px',
          lineHeight: '20px',
        },
      }}
    >
      {score ?? '-'}
    </Text>
  );

  return (
    <EventListItemWrapper>
      <SmallScreenEventHeader event={event} />
      <Box
        flexCol
        justifyCenter
        alignStart
        css={{
          padding: '$2 $3',
          borderRight: '1px solid $gray',
        }}
      >
        <EventInfoText>{homeTeam}</EventInfoText>
        <EventInfoText>{awayTeam}</EventInfoText>
      </Box>
      <Box
        flexCol
        justifyCenter
        alignCenter
        css={{
          borderRight: '1px solid $gray',
        }}
      >
        <ScoreText score={homeScore} />
        <ScoreText score={awayScore} />
      </Box>
      <InfoWrapper title={mainMarketSelection?.name ?? '-'} />
    </EventListItemWrapper>
  );
};
