import React, { memo } from 'react';
import { styled } from 'stitches.config';

import { MainMarket } from '~api/market/types';
import { Market } from '~api/sportEvent/types';
import { ArrowOddIndicator } from '~components/atoms/BetCoefficientIndicator';
import { Box } from '~components/atoms/Box';
import { Skeleton } from '~components/atoms/Skeleton';
import { ToggleGroup, ToggleGroupItem } from '~components/atoms/ToggleGroup';
import { Text } from '~components/atoms/Typography';
import { useOddButtonGroup } from '~components/molecules/LiveMenu/components/useOddButtonGroup';
import { SELECTION_STATUS } from '~constants/common';
import { LockIcon } from '~icons';

const ContentWrapper = styled(Box, {
  display: 'flex',
  justifyContent: 'space-between',
  flexGrow: 1,
});

interface OddContentProps {
  label: string;
  odd: number;
  status?: SELECTION_STATUS;
}

export const OddContent = memo(({ label, odd, status }: OddContentProps) => {
  return (
    <ContentWrapper flexRow justifyCenter={!label.length} alignCenter>
      <>
        {!!label.length && (
          <Text level="sm-4" ellipsis css={{ maxWidth: '30px' }}>
            {label}
          </Text>
        )}
        {status === SELECTION_STATUS.OPEN ? (
          <ArrowOddIndicator odd={odd} />
        ) : (
          <Box css={{ color: '$white' }}>
            <LockIcon width={12} height={16} color={'white'} />
          </Box>
        )}
      </>
    </ContentWrapper>
  );
});

interface OddButtonGroupProps {
  isLoading: boolean;
  market?: Market;
  eventId: string;
  sportId: number;
  mainMarketId?: number | null;
  mainMarketData?: MainMarket[];
  isFavoriteEvents?: boolean;
  fullHeight?: boolean;
}

export const OddButtonGroup = memo(
  ({
    isLoading,
    market,
    eventId,
    sportId,
    isFavoriteEvents,
    fullHeight,
  }: OddButtonGroupProps) => {
    const {
      isTotalMarket,
      activeButtons,
      onValueChange,
      isMobileOrTablet,
      defaultMainMarket,
    } = useOddButtonGroup({ market, isFavoriteEvents, eventId, sportId });

    if (isLoading) {
      return <Skeleton css={{ width: '100%', height: '$6' }} />;
    }

    const totalMerketLine = market?.line || '-';

    return (
      <ToggleGroup
        type="multiple"
        variant="bet"
        onValueChange={onValueChange}
        value={activeButtons}
        rovingFocus={false}
        loop={false}
        css={{
          '@xs_sm': {
            width: 'max-content',
            justifyContent: 'flex-end',
            height: fullHeight ? '100%' : 'unset',
          },
        }}
      >
        {isTotalMarket && (
          <ToggleGroupItem
            key={totalMerketLine}
            value={'-'}
            type="bet"
            disabled={true}
            css={{
              background: '$grayDarkMain2 !important',
              display: 'flex',
              justifyContent: 'center',
              '@xs_sm': {
                minWidth: '52px',
                maxWidth: '52px',
              },
            }}
          >
            <Text
              level="12-20"
              css={{
                color: '$grayMedium !important',
              }}
            >
              {totalMerketLine}
            </Text>
          </ToggleGroupItem>
        )}
        {market?.selections && market.selections.length
          ? market.selections.map(({ id, name, odd, status }) => {
              return (
                <ToggleGroupItem
                  key={id}
                  value={id}
                  type="bet"
                  disabled={status !== SELECTION_STATUS.OPEN || !market}
                  css={{
                    '@xs_sm': {
                      minWidth: '52px',
                      width: '100%',
                    },
                  }}
                >
                  <OddContent
                    label={isMobileOrTablet ? '' : name}
                    odd={odd}
                    status={status}
                  />
                </ToggleGroupItem>
              );
            })
          : defaultMainMarket?.selections
            ? defaultMainMarket.selections.map(({ id }) => {
                return (
                  <ToggleGroupItem
                    key={id}
                    value={id}
                    type="bet"
                    disabled
                    css={{
                      '@xs_sm': {
                        width: '52px',
                      },
                    }}
                  >
                    <Text
                      level="14-20"
                      css={{ width: '100%', textAlign: 'center' }}
                    >
                      -
                    </Text>
                  </ToggleGroupItem>
                );
              })
            : null}
      </ToggleGroup>
    );
  },
);
