import { Box } from '~components/atoms/Box';
import { Text } from '~components/atoms/Typography';
import { useTranslation } from '~hooks/useTranslation';

export const OpenBetsEmpty = () => {
  const { localized } = useTranslation();

  return (
    <Box flexCol alignCenter justifyCenter gap={2}>
      <Text
        color="grayMedium"
        css={{
          fontSize: '$lg',
          lineHeight: '$40',
          '@xs_sm': {
            fontSize: '$md',
            lineHeight: '$32',
            pt: '32px',
          },
        }}
      >
        {localized('betslip.openBetsEmpty.title')}
      </Text>
      <Text
        color="grayMedium"
        textAlign="center"
        css={{
          fontSize: '$sm',
          lineHeight: '$20',
          '@xs_sm': {
            fontSize: '$xxs',
            lineHeight: '$16',
            width: '230px',
          },
        }}
      >
        {localized('betslip.openBetsEmpty.description')}
      </Text>
    </Box>
  );
};
