import React from 'react';
import { styled } from 'stitches.config';

import { Box } from '~components/atoms/Box';
import { Loader } from '~components/atoms/Loader';
import { Text } from '~components/atoms/Typography';
import { BetslipBalancesPopover } from '~components/molecules/Betslip/components/BetslipFooter/BetslipBalancesPopover';
import { SPORT_BETSLIP_TYPE_OPTIONS } from '~components/molecules/Betslip/constants';
import { useBetslipAggregatedValues } from '~components/molecules/Betslip/hooks/useBetslipAggregatedValues';
import { WinboostPopover } from '~components/WinboostPopover';
import { CURRENCY_SYMBOLS } from '~constants/common';
import { useMedia } from '~hooks/useMedia';
import { useTranslation } from '~hooks/useTranslation';
import { useAppSelector } from '~store';
import { getBetTotalAmount } from '~utils/betslip';
import { commaSeparatedNumber, formatNumber } from '~utils/numberUtils';

const BetslipInfoWrapper = styled(Box, {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
});

export const BetslipTotalInfo = () => {
  const { isMobileOrTablet } = useMedia();
  const { localized } = useTranslation();
  const {
    stakeType,
    aggregatedBetAmount,
    balanceChangesOnBetData,
    totalOdd: reduxTotalOdd,
    oddsCount: reduxOddsCount,
    systemBetOption,
    isBalanceChangesLoading,
  } = useAppSelector((state) => state.betslip);
  const { isUserLoggedIn } = useAppSelector((state) => state.userState);
  const { currency, separateBonusBalance } = useAppSelector(
    (state) => state.settings,
  );

  const {
    totalOdd,
    winBoostMultiplier,
    possibleWin,
    winBoostAmount,
    originalWinAmount,
  } = useBetslipAggregatedValues();

  const isSystemBet = stakeType === SPORT_BETSLIP_TYPE_OPTIONS.SYSTEM;
  const shouldShowBetPopover =
    isUserLoggedIn &&
    (balanceChangesOnBetData?.balanceChanges?.length || isSystemBet);

  return (
    <Box flexCol gap={2}>
      <BetslipInfoWrapper css={{ mt: isMobileOrTablet ? '$1' : 'unset' }}>
        <Box flexRow alignCenter gap={2} css={{ color: '$grayMedium' }}>
          <Text level={isMobileOrTablet ? '11-16' : '13-16'}>
            {localized('betslip.multipleFooterValue.bet')}
          </Text>
          {shouldShowBetPopover && <BetslipBalancesPopover index={0} />}
        </Box>
        <Text level={isMobileOrTablet ? '11-16' : '13-16'}>
          {commaSeparatedNumber(
            getBetTotalAmount(
              aggregatedBetAmount,
              separateBonusBalance,
              systemBetOption,
            ),
          )}
          {CURRENCY_SYMBOLS[currency]}
        </Text>
      </BetslipInfoWrapper>
      {!isSystemBet && (
        <BetslipInfoWrapper>
          <Text level={isMobileOrTablet ? '11-16' : '13-16'}>
            {localized('betslip.multipleFooterValue.odds')}
          </Text>
          <Text color="yellow" level={isMobileOrTablet ? '11-16' : '13-16'}>
            {reduxOddsCount
              ? localized('betslip.multipleFooterValue.multiple') +
                ' ' +
                `(${reduxOddsCount}) x `
              : null}
            {formatNumber(reduxTotalOdd || totalOdd)}
          </Text>
        </BetslipInfoWrapper>
      )}
      {/* TODO: Uncomment when bonus logic is implemented on the backend */}
      {/*{!isSystemBet && !!+winBoostAmount && (*/}
      {/*  <BetslipInfoWrapper>*/}
      {/*    <Box*/}
      {/*      css={{ color: '$grayMedium' }}*/}
      {/*      flexRow*/}
      {/*      justifyContentStart*/}
      {/*      alignCenter*/}
      {/*      gap={2}*/}
      {/*    >*/}
      {/*      <Text underline level={isMobileOrTablet ? '11-16' : '13-16'}>*/}
      {/*        {localized('betslip.bonus')}*/}
      {/*      </Text>*/}
      {/*      {winBoostDescriptions?.length ? (*/}
      {/*        <BetslipMultipleBonus*/}
      {/*          multipleBetBonusDescriptions={winBoostDescriptions}*/}
      {/*        />*/}
      {/*      ) : null}*/}
      {/*    </Box>*/}
      {/*    <Text*/}
      {/*      color="yellow"*/}
      {/*      underline*/}
      {/*      level={isMobileOrTablet ? '11-16' : '13-16'}*/}
      {/*    >*/}
      {/*      {winBoostAmount} {CURRENCY_SYMBOLS[currency]}*/}
      {/*    </Text>*/}
      {/*  </BetslipInfoWrapper>*/}
      {/*)}*/}
      <BetslipInfoWrapper>
        <Box flexRow alignCenter gap={2}>
          <Text underline level={isMobileOrTablet ? '11-16' : '13-16'}>
            {localized('betslip.multipleFooterValue.possibleWin')}
          </Text>
          {!!Number(winBoostAmount) && (
            <WinboostPopover
              status={1}
              winBoostAmount={winBoostAmount}
              winBoostMultiplier={winBoostMultiplier}
              originalWinAmount={originalWinAmount}
            />
          )}
        </Box>
        {isBalanceChangesLoading ? (
          <Loader
            css={{
              width: '13px',
              height: '13px',
              color: '$yellow',
              display: 'flex',
              alignItems: 'center',
            }}
          />
        ) : (
          <Text
            color="yellow"
            underline
            level={isMobileOrTablet ? '11-16' : '13-16'}
          >
            {`${commaSeparatedNumber(possibleWin) || '0'} ${CURRENCY_SYMBOLS[currency]}`}
          </Text>
        )}
      </BetslipInfoWrapper>
    </Box>
  );
};
