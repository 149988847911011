import {
  useLazyGetDataQuery,
  useLazyGetFavoriteEventInplayQuery,
  useLazyGetFavoriteEventPrematchQuery,
  useLazyGetFavoriteTournamentsQuery,
  useLazyGetLiveHighlightsQuery,
  useLazyGetMoreUpcomingEventsQuery,
  useLazyGetPrematchEventsByLeagueIdQuery,
  useLazyGetTopEventInplayQuery,
  useLazyGetTopEventPrematchQuery,
  useLazyGetUpcomingEventsQuery,
} from './sportEventApi';

export const useLazyGetPrematchEventsByLeagueId = () => {
  const [query, { data, error, isLoading, isFetching }] =
    useLazyGetPrematchEventsByLeagueIdQuery();

  return {
    lazyGetPrematchEventsByLeagueIdQuery: query,
    lazyGetPrematchEventsByLeagueIdData: data,
    lazyGetPrematchEventsByLeagueIdError: error,
    lazyGetPrematchEventsByLeagueIdIsLoading: isLoading,
    lazyGetPrematchEventsByLeagueIdIsFetching: isFetching,
  };
};

export const useLazyGetFavoriteTournaments = () => {
  const [query, { data, error, isLoading }] =
    useLazyGetFavoriteTournamentsQuery();

  return {
    lazyGetFavoriteTournamentsQuery: query,
    lazyGetFavoriteTournamentsData: data,
    lazyGetFavoriteTournamentsError: error,
    lazyGetFavoriteTournamentsIsLoading: isLoading,
  };
};

export const useLazyGetTopEventInplay = () => {
  const [query, { data, error, isFetching }] = useLazyGetTopEventInplayQuery();

  return {
    lazyGetTopEventInplayQuery: query,
    lazyGetTopEventInplayData: data,
    lazyGetTopEventInplayError: error,
    lazyGetTopEventInplayIsFetching: isFetching,
  };
};

export const useLazyGetTopEventPrematch = () => {
  const [query, { data, error, isFetching }] =
    useLazyGetTopEventPrematchQuery();

  return {
    lazyGetTopEventPrematchQuery: query,
    lazyGetTopEventPrematchData: data,
    lazyGetTopEventPrematchError: error,
    lazyGetTopEventPrematchIsFetching: isFetching,
  };
};

export const useLazyGetFavoriteEventInplay = () => {
  const [query, { data, error, isFetching }] =
    useLazyGetFavoriteEventInplayQuery();

  return {
    lazyGetFavoriteEventInplayQuery: query,
    lazyGetFavoriteEventInplayData: data,
    lazyGetFavoriteEventInplayError: error,
    lazyGetFavoriteEventInplayIsFetching: isFetching,
  };
};

export const useLazyGetFavoriteEventPrematch = () => {
  const [query, { data, error, isFetching }] =
    useLazyGetFavoriteEventPrematchQuery();

  return {
    lazyGetFavoriteEventPrematchQuery: query,
    lazyGetFavoriteEventPrematchData: data,
    lazyGetFavoriteEventPrematchError: error,
    lazyGetFavoriteEventPrematchIsFetching: isFetching,
  };
};

export const useLazyGetUpcomingEvents = () => {
  const [query, { data, error, isFetching }] = useLazyGetUpcomingEventsQuery();

  return {
    lazyGetUpcomingEventsQuery: query,
    lazyGetUpcomingEventsData: data,
    lazyGetUpcomingEventsError: error,
    lazyGetUpcomingEventsIsFetching: isFetching,
  };
};

export const useLazyGetMoreUpcomingEvents = () => {
  const [query, { data, error, isFetching }] =
    useLazyGetMoreUpcomingEventsQuery();

  return {
    lazyGetMoreUpcomingEventsQuery: query,
    lazyGetMoreUpcomingEventsData: data,
    lazyGetMoreUpcomingEventsError: error,
    lazyGetMoreUpcomingEventsIsFetching: isFetching,
  };
};

export const useLazyGetLiveHighlights = () => {
  const [query, { data, error, isFetching }] = useLazyGetLiveHighlightsQuery();

  return {
    lazyGetLiveHighlightsQuery: query,
    lazyGetLiveHighlightsData: data,
    lazyGetLiveHighlightsError: error,
    lazyGetLiveHighlightsIsFetching: isFetching,
  };
};

export const useGetData = () => {
  const [query, { data, error, isLoading }] = useLazyGetDataQuery();

  return {
    lazyGetDataQuery: query,
    lazyGetDataData: data,
    lazyGetDataError: error,
    lazyGetDataIsLoading: isLoading,
  };
};
