import {
  AccordionContent,
  AccordionTrigger,
  StyledAccordionItem,
  StyledAccordionRoot,
} from '~components/atoms/Accordion';
import { Box } from '~components/atoms/Box';
import { Breadcrumb } from '~components/atoms/Breadcrumb';
import { FavoriteLeagueButton } from '~components/atoms/FavoriteLeagueButton';
import { LeagueMenuList } from '~components/atoms/LeagueMenuList';
import { Select } from '~components/atoms/Select';
import { LeagueEventsMenuMobileSkeleton } from '~components/atoms/Skeletons';
import { StyledBoardComponentWrapper } from '~components/atoms/StyledBoardComponentWrapper';
import { ToggleSportMarket } from '~components/atoms/ToggleSportMarket';
import { LeagueEventMobile } from '~components/molecules/PrematchEvents/components/LeagueEvent/LeagueEventMobile';
import { useLeagueEventsMenuMobile } from '~components/molecules/PrematchEvents/hooks/useLeagueEventsMenuMobile';
import { useAppSelector } from '~store';
import { ellipsis } from '~utils/cssUtils';
import { zIndex } from '~utils/zIndex';

export const LeagueEventsMenuMobile = () => {
  const {
    country,
    countryId,
    isLoading,
    leagueEventsData,
    leagueName,
    leagues,
    mainMarkets,
    openItems,
    shouldRender,
    showLeagues,
    sportId,
    leagueId,
    events,
    sportName,
    countryName,
    setOpenItems,
    setShowLeagues,
  } = useLeagueEventsMenuMobile();
  const { mobileHeaderHeight } = useAppSelector((state) => state.mobileState);
  const { isUserLoggedIn } = useAppSelector((state) => state.userState);

  if (isLoading) {
    return <LeagueEventsMenuMobileSkeleton />;
  }

  if (!leagueEventsData.length || !shouldRender) {
    return null;
  }

  return (
    <Box flexCol css={{ backgroundColor: '$black' }}>
      <Box
        flexCol
        css={{
          position: 'fixed',
          top: mobileHeaderHeight,
          zIndex: zIndex.fixed,
          width: '100vw',
        }}
      >
        <Box css={{ height: '$1', backgroundColor: '$gray' }} />
        <Breadcrumb />
      </Box>
      <Box flexCol gap={2} css={{ mb: '$1', pt: '56px' }}>
        {!!leagueName && (
          <Box flexRow gap={1} css={{ px: '$1' }}>
            {isUserLoggedIn && (
              <Box
                flexRow
                alignCenter
                justifyCenter
                css={{
                  width: '$8',
                  height: '$8',
                  backgroundColor: '$grayDark',
                  borderRadius: '$6',
                  color: '$grayMedium',
                  '&:hover': {
                    color: '$white',
                  },
                }}
              >
                <FavoriteLeagueButton
                  width={20}
                  height={20}
                  id={leagueId!}
                  name={leagueName}
                  events={events || 0}
                  sportId={parseInt(sportId!)}
                  countryId={parseInt(countryId!)}
                  sportName={sportName}
                  countryName={countryName!}
                />
              </Box>
            )}
            <Select
              placeholder={leagueName}
              shouldClose={!showLeagues}
              onClose={() => setShowLeagues(false)}
              isCustomChildren
              css={{
                height: '$8',
                flexGrow: 1,
                pl: '$1',
                color: '$white',
                fontSize: '$14',
                lineHeight: '$24',
                fontWeight: '$medium',
                ...ellipsis,
              }}
              contentCss={{ left: '-6px' }}
              iconSize={16}
              isCustomContentWidth
              onOpenChange={setShowLeagues}
              notPreventClickBackdrop
            >
              <Box flexCol css={{ width: 'calc(100vw - 24px)', zIndex: 1000 }}>
                <LeagueMenuList
                  countryId={parseInt(countryId!)}
                  sportId={parseInt(sportId!)}
                  initialLeagues={[]}
                  leagues={leagues || []}
                  countryName={country?.name || ''}
                  onClick={() => setShowLeagues(false)}
                />
              </Box>
            </Select>
          </Box>
        )}
        <Box flexRow css={{ px: '$1' }}>
          <ToggleSportMarket sportId={parseInt(sportId!)} noMargin />
        </Box>
      </Box>
      <StyledBoardComponentWrapper flexCol gap={1} noBackgroundColor>
        <StyledAccordionRoot
          type="multiple"
          value={openItems}
          onValueChange={setOpenItems}
        >
          <Box
            gap={1}
            css={{
              position: 'relative',
              display: 'flex',
              flexDirection: 'column-reverse',
            }}
          >
            {leagueEventsData &&
              leagueEventsData
                // reversed and used column-reverse to have first accordion focused instead of the last one,
                // hence preventing scrolling to the bottom but in terms of UI everything is the same
                .slice()
                .reverse()
                .map(({ date, events }, dateIndex) => {
                  return (
                    <StyledAccordionItem value={date} key={date}>
                      <AccordionTrigger mobileEventMenu>
                        {date}
                      </AccordionTrigger>
                      <AccordionContent>
                        <Box flexCol gap={1} css={{ mt: '31px' }}>
                          {events.map(({ eventId, marketIds }, index) => (
                            <LeagueEventMobile
                              eventId={eventId}
                              marketIds={marketIds}
                              key={eventId}
                              isFirstEvent={index === 0}
                              mainMarkets={mainMarkets}
                              isFirstDate={
                                dateIndex === leagueEventsData.length - 1
                              }
                            />
                          ))}
                        </Box>
                      </AccordionContent>
                    </StyledAccordionItem>
                  );
                })}
            {showLeagues && (
              <Box
                fullWidth
                fullHeight
                css={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  background: '$blackOpacity8',
                  backdropFilter: 'blur(4px)',
                  zIndex: 2,
                }}
              />
            )}
          </Box>
        </StyledAccordionRoot>
      </StyledBoardComponentWrapper>
    </Box>
  );
};
