import React, { useState } from 'react';
import * as Accordion from '@radix-ui/react-accordion';

import { Box } from '~components/atoms/Box';
import { Text } from '~components/atoms/Typography';
import {
  Banner,
  Event,
  WinningLines,
} from '~components/molecules/Jackpot/Settled';
import {
  StyledSmallJackpotAccordionContent,
  StyledSmallJackpotAccordionTrigger,
} from '~components/molecules/Jackpot/SmallScreenJackpot';
import { StyledJackpotAccordionRoot } from '~components/molecules/Jackpot/styled.components';
import { useTranslation } from '~hooks/useTranslation';
import { ChevronDownIcon, ChevronUpIcon } from '~icons';
import { Jackpot } from '~types/jackpot';

interface JackpotDetailsSmallProps {
  selectedJackpot: Jackpot;
}

export const SettledJackpotDetailsSmall = ({
  selectedJackpot,
}: JackpotDetailsSmallProps) => {
  const { localized } = useTranslation();

  const [openItems, setOpenItems] = useState<string[]>([]);

  const { id, events, details } = selectedJackpot;
  const winnersAccordionId = id + '-winners';
  const resultsAccordionId = id + '-results';

  const toggleItem = (id: string) => {
    if (openItems.includes(id)) {
      setOpenItems(openItems.filter((item) => item !== id));
    } else {
      setOpenItems([...openItems, id]);
    }
  };

  return (
    <StyledJackpotAccordionRoot
      type="multiple"
      value={openItems}
      onValueChange={setOpenItems}
    >
      <Box flexCol gap={1}>
        <Banner key={selectedJackpot.id} jackpot={selectedJackpot} />

        <Accordion.Item value={winnersAccordionId}>
          <Box
            flexCol
            gap={1}
            css={{
              backgroundColor: '$gray',
              borderRadius: '$8',
            }}
          >
            <StyledSmallJackpotAccordionTrigger
              as={Box}
              onClick={() => toggleItem(winnersAccordionId)}
            >
              <Text level="14-20"> {localized('jackpot.winners')}</Text>
              {openItems.includes(winnersAccordionId) ? (
                <ChevronUpIcon />
              ) : (
                <ChevronDownIcon />
              )}
            </StyledSmallJackpotAccordionTrigger>

            <StyledSmallJackpotAccordionContent>
              <WinningLines lines={details.lines} />
            </StyledSmallJackpotAccordionContent>
          </Box>
        </Accordion.Item>

        <Accordion.Item value={resultsAccordionId}>
          <Box
            flexCol
            gap={1}
            css={{
              backgroundColor: '$gray',
              borderRadius: '$8',
            }}
          >
            <StyledSmallJackpotAccordionTrigger
              as={Box}
              onClick={() => toggleItem(resultsAccordionId)}
            >
              <Text level="14-20"> {localized('jackpot.results')}</Text>
              {openItems.includes(resultsAccordionId) ? (
                <ChevronUpIcon />
              ) : (
                <ChevronDownIcon />
              )}
            </StyledSmallJackpotAccordionTrigger>

            <StyledSmallJackpotAccordionContent>
              <Box flexCol gap={2} css={{ padding: '$2' }}>
                {events.map((event) => (
                  <Event event={event} key={event?.id} />
                ))}
              </Box>
            </StyledSmallJackpotAccordionContent>
          </Box>
        </Accordion.Item>
      </Box>
    </StyledJackpotAccordionRoot>
  );
};
