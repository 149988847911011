import { Box } from '~components/atoms/Box';
import { Text } from '~components/atoms/Typography';
import { CURRENCY_SYMBOLS } from '~constants/common';
import { useMedia } from '~hooks/useMedia';
import { useAppSelector } from '~store';
import { JackpotDetails } from '~types/jackpot';
import { formatNumberToStringWithSpaces } from '~utils/numberUtils';

import { styled } from '../../../../../../../stitches.config';
import { printPossibleWinLines } from '../../../utils';

interface JackpotInfoProps {
  details: JackpotDetails;
  amount: number;
}

const InfoText = styled(Text, {
  textShadow: '0px 2px 4px #0A0F14',
  textTransform: 'uppercase',
});

export const BannerInfo = ({ details, amount }: JackpotInfoProps) => {
  const { isMobile } = useMedia();
  const { currency } = useAppSelector((state) => state.settings);

  // Sorted possible win lines from the biggest to the smallest
  const sortedLines = [...details.lines].sort((a, b) => b.line - a.line);
  // Possible win lines except the biggest one, to show them separately
  const possibleWinLines = sortedLines.slice(1).map(({ line }) => line);

  return (
    <Box fullHeight flexCol gap={3}>
      <Box>
        <InfoText level={isMobile ? '14-20' : '24-40'}>{`Predict ${
          sortedLines[0]?.line || ''
        } games to win `}</InfoText>
        <InfoText
          color="yellow"
          fontWeight="bold"
          level={isMobile ? '16-24' : '32-44'}
        >
          {`${formatNumberToStringWithSpaces(amount)} ${
            CURRENCY_SYMBOLS[currency]
          }`}
        </InfoText>
        {possibleWinLines.length > 0 && (
          <InfoText
            level={isMobile ? '14-20' : '24-40'}
          >{`Prizes for ${printPossibleWinLines(
            possibleWinLines,
          )} correct predictions too!`}</InfoText>
        )}
      </Box>
    </Box>
  );
};
