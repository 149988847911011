import React, { useEffect, useLayoutEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { styled } from 'stitches.config';

import { DIALOGS } from '~components/atoms/AbsoluteDialogs';
import { Box } from '~components/atoms/Box';
import { useInitialBetslipState } from '~components/molecules/Betslip/hooks/useInitialBetslipState';
import { Header, MobileHeaderContainer } from '~components/organisms/Header';
import { IframeHeader } from '~components/organisms/IframeHeader';
import { MobileContentContainer } from '~components/organisms/MobileContentContainer';
import { MobileFooter } from '~components/organisms/MobileFooter';
import { MOBILE_MENU, MobileTab } from '~components/organisms/MobileTab';
import { QUERY_PARAMS } from '~constants/common';
import { useInplayHubConnection } from '~hooks/useInplayHubConnection';
import useIsInIframe from '~hooks/useIsInIframe';
import { useQueryParams } from '~hooks/useQueryParams';
import { useRouterQuery } from '~hooks/useRouterQuery';
import { useShare } from '~hooks/useShare';
import { AppRoutes } from '~routes';
import { useAppDispatch } from '~store';
import { openDialog } from '~store/slices/globalDialogSlice';
import { setMobileActiveTab } from '~store/slices/mobileSlice';
import { setWindowScrolled } from '~store/slices/userUISlice';
import { SPORT_MENUS } from '~types/sportMenus';
import debounce from '~utils/debounce';
import { getParamFromQuery } from '~utils/url';

import { SwipeComponent } from '../SwipeComponent';

const MobileLayoutContainer = styled(Box, {
  height: '100%',
});

export const MobileLayout = () => {
  const { name } = useQueryParams();
  const { search } = useLocation();
  const { menu } = useQueryParams();
  const { removeQueryParams } = useRouterQuery();
  const dispatch = useAppDispatch();

  const [, secondSegment] = location.pathname.split('/');
  const verifyEmailToken = getParamFromQuery(
    search,
    QUERY_PARAMS.VERIFY_EMAIL_TOKEN,
  );

  const isInFrame = useIsInIframe();

  useShare();
  useInitialBetslipState();
  useInplayHubConnection();

  useEffect(() => {
    if (verifyEmailToken) {
      dispatch(openDialog(DIALOGS.VERIFY_EMAIL_TOKEN));
    }
  }, [verifyEmailToken]);

  useEffect(() => {
    removeQueryParams([QUERY_PARAMS.NEXT_HOUR]);
  }, []);

  useLayoutEffect(() => {
    if (menu === SPORT_MENUS.PREMATCH) {
      dispatch(setMobileActiveTab(MOBILE_MENU.SPORT));
    }

    if (menu === SPORT_MENUS.LIVE) {
      dispatch(setMobileActiveTab(MOBILE_MENU.LIVE));
    }
  }, []);

  useEffect(() => {
    const handleWindowScroll = debounce(100, () => {
      dispatch(setWindowScrolled(!!window.scrollY));
    });

    window.addEventListener('scroll', handleWindowScroll);

    return () => {
      window.removeEventListener('scroll', handleWindowScroll);
    };
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [name]);

  if (secondSegment && !secondSegment.length) {
    return (
      <>
        {isInFrame ? <IframeHeader /> : <Header noBottomBorder />}
        <AppRoutes />
        {!isInFrame && <MobileFooter />}
      </>
    );
  }

  return (
    <>
      <SwipeComponent />
      <MobileLayoutContainer>
        <MobileHeaderContainer>
          {isInFrame ? (
            <IframeHeader />
          ) : (
            <Header noBottomBorder showSettings />
          )}
        </MobileHeaderContainer>
        <MobileContentContainer>
          <AppRoutes />
        </MobileContentContainer>
        <MobileTab />
      </MobileLayoutContainer>
    </>
  );
};
