import React, { ReactNode, useMemo } from 'react';
import { styled } from 'stitches.config';

import { SelectWithLabel } from '~/components/atoms/SelectWithLabel';
import { DIALOGS } from '~components/atoms/AbsoluteDialogs';
import { Flag } from '~components/atoms/Flag';
import { SettingsDropdown } from '~components/atoms/SettingsDropdown';
import {
  USER_BONUSES_TABS,
  USER_PROFILE_NAVIGATION_TABS,
} from '~components/molecules/UserProfile/components/UserProfileDialog';
import { USER_PROFILE_DIALOG_MOBILE_STATE } from '~components/molecules/UserProfile/components/UserProfileDialogMobile';
import { IframeHeaderItem } from '~components/organisms/IframeHeader/IframeHeaderItem';
import { LANGUAGES } from '~constants/language';
import { ODDS } from '~constants/odds';
import { TIMEZONES } from '~constants/timezones';
import { IFRAME_HEADER_HEIGHT, IFRAME_HEADER_MARGIN } from '~constants/ui';
import { useMedia } from '~hooks/useMedia';
import { useWebsiteSettings } from '~hooks/useWebsiteSettings';
import { BetHistoryIcon, BonusesIcon } from '~icons';
import { useAppDispatch, useAppSelector } from '~store';
import { openDialog } from '~store/slices/globalDialogSlice';
import {
  setMobileUserProfileDialogActiveContent,
  setMobileUserProfileDialogState,
} from '~store/slices/mobileSlice';
import { setContentTab, setMainTab } from '~store/slices/personalDetailsSlice';

const StyledContainer = styled('div', {
  background: '$black',
  display: 'flex',
  justifyContent: 'center',
});
const StyledIframeHeader = styled('div', {
  height: IFRAME_HEADER_HEIGHT,
  display: 'flex',
  alignItems: 'center',
  padding: '$4',
  gap: '$5',
  maxWidth: '1920px',
  width: '100%',
});

const StyledButtonsWrapper = styled('div', {
  display: 'flex',
  alignItems: 'center',
  gap: '$5',
});

export const IframeHeader = () => {
  const dispatch = useAppDispatch();
  const { isMobile, isTablet, isMobileOrTablet } = useMedia();
  const {
    timezone,
    onTimezoneChange,
    onLanguageChange,
    language,
    onOddsChange,
    odds,
  } = useWebsiteSettings();
  const { isUserLoggedIn } = useAppSelector((state) => state.userState);

  const items = useMemo(() => {
    return isMobileOrTablet
      ? [
          {
            icon: BetHistoryIcon,
            title: 'userProfile.menuTitles.history',
            onClick: () => {
              dispatch(openDialog(DIALOGS.USER_PROFILE_MOBILE));
              dispatch(
                setMobileUserProfileDialogState(
                  USER_PROFILE_DIALOG_MOBILE_STATE.CONTENT,
                ),
              );
              dispatch(
                setMobileUserProfileDialogActiveContent(
                  USER_PROFILE_NAVIGATION_TABS.HISTORY,
                ),
              );
            },
          },
          {
            icon: BonusesIcon,
            title: 'userProfile.menuTitles.bonuses',
            onClick: () => {
              dispatch(openDialog(DIALOGS.USER_PROFILE_MOBILE));
              dispatch(
                setMobileUserProfileDialogState(
                  USER_PROFILE_DIALOG_MOBILE_STATE.CONTENT,
                ),
              );
              dispatch(
                setMobileUserProfileDialogActiveContent(
                  USER_PROFILE_NAVIGATION_TABS.BONUSES,
                ),
              );
            },
          },
        ]
      : [
          {
            icon: BetHistoryIcon,
            title: 'userProfile.menuTitles.history',
            onClick: () => {
              dispatch(openDialog(DIALOGS.USER_PROFILE));
              dispatch(
                setMainTab({ tab: USER_PROFILE_NAVIGATION_TABS.HISTORY }),
              );
            },
          },
          {
            icon: BonusesIcon,
            title: 'userProfile.menuTitles.bonuses',
            onClick: () => {
              dispatch(openDialog(DIALOGS.USER_PROFILE));
              dispatch(
                setMainTab({ tab: USER_PROFILE_NAVIGATION_TABS.BONUSES }),
              );
              dispatch(setContentTab(USER_BONUSES_TABS.ACTIVE_BONUSES));
            },
          },
        ];
  }, [isMobileOrTablet, dispatch]);

  const langOptions = useMemo(() => {
    return LANGUAGES.map((lang) => ({
      ...lang,
      label: (
        <div style={{ display: 'flex', gap: '8px', alignItems: 'center' }}>
          <Flag countryName={lang.flag} size="sm" /> {lang.label}
        </div>
      ),
      title: lang.value.toUpperCase(),
    }));
  }, [LANGUAGES]);

  const flagsMap = useMemo(() => {
    const result: Record<string, ReactNode> = {};

    LANGUAGES.forEach((lang) => {
      result[lang.value as string] = <Flag countryName={lang.flag} size="sm" />;
    });

    return result;
  }, [LANGUAGES]);

  const timeZoneLabel = useMemo(() => {
    // TODO => We need to change hardcoded labels bellow
    return isTablet ? 'Time' : 'Time Zone';
  }, [isTablet]);
  const oddsLabel = useMemo(() => {
    return isTablet ? 'Odds' : 'Odds Format';
  }, [isTablet]);
  const languageLabel = useMemo(() => {
    return isTablet ? 'Language' : 'Language';
  }, [isTablet]);

  return (
    <StyledContainer
      css={{
        margin: isMobileOrTablet
          ? 0
          : `${IFRAME_HEADER_MARGIN}px ${IFRAME_HEADER_MARGIN}px 0`,
        borderRadius: isMobileOrTablet ? 0 : '10px',
      }}
    >
      <StyledIframeHeader
        css={{
          justifyContent: isMobileOrTablet ? 'flex-end' : 'space-between',
        }}
      >
        {isUserLoggedIn && (
          <StyledButtonsWrapper>
            {items.map(({ icon, title, onClick }) => (
              <IframeHeaderItem
                isDesktop={!isMobileOrTablet}
                icon={icon}
                title={title}
                key={title}
                onClick={onClick}
              />
            ))}
          </StyledButtonsWrapper>
        )}

        {isMobileOrTablet ? (
          <SettingsDropdown />
        ) : (
          <div
            style={{ display: 'flex', gap: '8px', marginInlineStart: 'auto' }}
          >
            <SelectWithLabel
              value={timezone}
              setValue={onTimezoneChange}
              options={TIMEZONES}
              dropdownWidth="480px"
              style={{ width: '100%' }}
              label={isMobile ? timeZoneLabel.toUpperCase() : timeZoneLabel}
            />
            <SelectWithLabel
              label={isMobile ? oddsLabel.toUpperCase() : oddsLabel}
              value={odds}
              setValue={onOddsChange}
              options={ODDS}
              dropdownWidth="184px"
            />
            <SelectWithLabel
              label={isMobile ? languageLabel.toUpperCase() : languageLabel}
              value={language}
              setValue={onLanguageChange}
              options={langOptions}
              additionalSelectedComponents={flagsMap}
              dropdownWidth="184px"
            />
          </div>
        )}
      </StyledIframeHeader>
    </StyledContainer>
  );
};
