import React from 'react';
import { styled } from 'stitches.config';

import { BANNER_GROUP_NAMES, BannerList } from '~components/atoms/BannerList';
import { ScrollArea } from '~components/atoms/ScrollArea';
import { IFRAME_HEADER_HEIGHT, IFRAME_HEADER_MARGIN } from '~constants/ui';
import useIsInIframe from '~hooks/useIsInIframe';

const StyledRightSidebar = styled('div', {
  gridArea: 'rightSidebar',
  borderRadius: '$10',
  paddingRight: '$1',
  height: 'calc(100vh - 148px)',
  width: '100%',
  overflow: 'auto',
  position: 'relative',
  '@md': {
    height: 'calc(100vh - 136px)',
  },
});

export interface RightSidebarProps {
  children: React.ReactNode;
  setScrollPosition?: React.Dispatch<React.SetStateAction<number>>;
  sticky?: boolean;
}

export const RightSidebar = ({
  children,
  setScrollPosition,
  sticky,
}: RightSidebarProps) => {
  const isInIframe = useIsInIframe();

  return (
    <StyledRightSidebar
      css={{
        ...(sticky && { position: 'sticky', top: 0 }),
        height: isInIframe
          ? `calc(100vh - ${IFRAME_HEADER_HEIGHT}px - ${IFRAME_HEADER_MARGIN}px - 16px)`
          : 'calc(100vh - 156px)',
        '@md': {
          height: isInIframe
            ? `calc(100vh - ${IFRAME_HEADER_HEIGHT}px - ${IFRAME_HEADER_MARGIN}px - 16px)`
            : 'calc(100vh - 144px)',
        },
      }}
    >
      <ScrollArea
        onScroll={(val) => setScrollPosition && setScrollPosition(val)}
      >
        <BannerList names={[BANNER_GROUP_NAMES.RIGHT_TOP]} />
        {children}
        <BannerList names={[BANNER_GROUP_NAMES.RIGHT_BOTTOM]} />
      </ScrollArea>
    </StyledRightSidebar>
  );
};
