import { Select } from '~components/atoms/Select';
import { useTranslation } from '~hooks/useTranslation';
import { StakeTypeIcon } from '~icons';
import { useAppDispatch, useAppSelector } from '~store';
import { setStakeType } from '~store/slices/betslipSlice';

import { getBetslipTypes, SPORT_BETSLIP_TYPE_OPTIONS } from '../../constants';

export const BetslipStakeType = () => {
  const { localized } = useTranslation();
  const dispatch = useAppDispatch();
  const { stakeType } = useAppSelector((state) => state.betslip);
  const { allowSystemBets } = useAppSelector((state) => state.settings);

  const stateTypeOptions = getBetslipTypes(localized);
  const filteredStateTypeOptions = allowSystemBets
    ? stateTypeOptions
    : stateTypeOptions.filter(
        (option) => option.value !== SPORT_BETSLIP_TYPE_OPTIONS.SYSTEM,
      );

  return (
    <Select
      css={{
        gridArea: 'stakeType',
        '& > button': {
          width: '100%',
        },
        '@xs_sm': {
          fontSize: '$xxs',
          lineHeight: '$20',
          borderRadius: '$4',
        },
      }}
      ariaLabel={localized('betslip.oddsAskAriaLabel')}
      value={stakeType}
      onChange={(value) =>
        dispatch(setStakeType(value as SPORT_BETSLIP_TYPE_OPTIONS))
      }
      icon={<StakeTypeIcon />}
      options={filteredStateTypeOptions}
    />
  );
};
