import React from 'react';

import { Box } from '~components/atoms/Box';
import { VoidEventPopover } from '~components/molecules/BetslipHistorySection/components/VoidEventPopover';
import { StyledTableText } from '~components/molecules/BetslipHistorySection/styled.components';
import {
  JACKPOT_SELECTION_STATUS,
  JACKPOT_SELECTION_STATUS_COLORS,
  JACKPOT_SELECTION_STATUS_LABELS,
} from '~constants/jackpot';
import { JackpotBetEvent } from '~types/jackpot';

export const EventStatusCell = ({ event }: { event: JackpotBetEvent }) => {
  const { selections } = event;

  return (
    <Box flexCol>
      {selections.map((selection) => (
        <Box flexRow gap={2} alignCenter>
          <StyledTableText
            key={selection.selectionId}
            color={JACKPOT_SELECTION_STATUS_COLORS[selection.status]}
          >
            {JACKPOT_SELECTION_STATUS_LABELS[selection.status]}
          </StyledTableText>
          {selection.status === JACKPOT_SELECTION_STATUS.REFUND && (
            <VoidEventPopover />
          )}
        </Box>
      ))}
    </Box>
  );
};
