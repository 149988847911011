import { Box } from '~components/atoms/Box';
import { CountryLeague } from '~components/molecules/Jackpot/Event/CountryLeague';
import { InfoWrapper } from '~components/molecules/Jackpot/Event/InfoWrapper';
import {
  EventInfoText,
  EventListItemWrapper,
  LargeScreenEventHeader,
} from '~components/molecules/Jackpot/Settled/styled.components';
import { CountryName } from '~constants/flag';
import { formatDateTime, TIME_FORMATS } from '~utils/formatDateTime';

import { SettledEventsProps } from './Event';

export const EventLarge = ({
  event,
  mainMarketSelection,
}: SettledEventsProps) => {
  const { countryName, leagueName, name, startDate, homeScore, awayScore } =
    event;

  return (
    <EventListItemWrapper>
      {/* Header section */}
      <LargeScreenEventHeader>
        <CountryLeague
          countryName={countryName as CountryName}
          leagueName={leagueName!}
        />
      </LargeScreenEventHeader>
      <InfoWrapper
        isHeader
        title="Score"
        css={{
          borderBottom: '1px solid $gray',
          borderRight: '1px solid $gray',
        }}
      />
      <InfoWrapper
        isHeader
        title="Result"
        css={{ borderBottom: '1px solid $gray' }}
      />

      {/* Event info section */}
      <Box
        flexCol
        justifyCenter
        alignStart
        css={{
          padding: '$2 $3',
          borderRight: '1px solid $gray',
        }}
      >
        <EventInfoText>{`${name.replace('vs.', '-')}`}</EventInfoText>
        <EventInfoText css={{ color: '$grayMedium' }}>
          {formatDateTime(startDate, TIME_FORMATS.DATE_TIME_12H)}
        </EventInfoText>
      </Box>
      <InfoWrapper
        title={`${homeScore} - ${awayScore}`}
        css={{
          borderRight: '1px solid $gray',
        }}
      />
      <InfoWrapper title={mainMarketSelection?.name ?? '-'} />
    </EventListItemWrapper>
  );
};
