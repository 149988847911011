import { useRef } from 'react';
import { styled } from 'stitches.config';

import { Box } from '~components/atoms/Box';
import { CashOut } from '~components/molecules/CashOut';
import { useMedia } from '~hooks/useMedia';
import { useAppSelector } from '~store';

import { BetslipFooter } from './components/BetslipFooter';
import { BetslipOptions } from './components/BetslipOptions';
import { BetslipProcessingBetLoader } from './components/BetslipProcessingBetLoader';
import { BetslipStakes } from './components/BetslipStakes';
import { BetslipToggleButtons } from './components/BetslipToggleButtons';
import { OpenBetHistory } from './components/OpenBetHistory';
import { OpenBets } from './components/OpenBets';
import { useInitialBetslipState } from './hooks/useInitialBetslipState';
import { BETSLIP_TOGGLE_TYPES } from './constants';

const BetslipTopPanel = styled('div', {
  background: '$panelBgColor',
  width: '100%',
  height: '100%',
  borderRadius: '$10',
  borderBottom: 'none',
});

interface BetslipProps {
  scrollPosition?: number;
}

export const Betslip = ({ scrollPosition }: BetslipProps) => {
  const { isMobileOrTablet } = useMedia();
  const { activeTab } = useAppSelector((state) => state.betslip);
  const { isCashoutOpen } = useAppSelector((state) => state.openBets);
  const wrapperRef = useRef<HTMLDivElement>(null);

  useInitialBetslipState();

  return (
    <Box
      flexCol
      ref={wrapperRef}
      gap={1}
      css={{
        position: 'relative',
      }}
    >
      <BetslipTopPanel>
        {isCashoutOpen && !isMobileOrTablet ? (
          <CashOut />
        ) : (
          <>
            <BetslipToggleButtons />
            {activeTab === BETSLIP_TOGGLE_TYPES.BETSLIP ? (
              <>
                <BetslipOptions />
                <BetslipStakes />
              </>
            ) : (
              <>
                <OpenBetHistory />
                <OpenBets />
              </>
            )}
          </>
        )}
      </BetslipTopPanel>
      {activeTab === BETSLIP_TOGGLE_TYPES.BETSLIP ? (
        <BetslipFooter isStatic />
      ) : null}
      <BetslipProcessingBetLoader
        wrapperRef={wrapperRef}
        scrollPosition={scrollPosition}
      />
    </Box>
  );
};
