import { useEffect, useState } from 'react';

import { Box } from '~components/atoms/Box';
import { Breadcrumb } from '~components/atoms/Breadcrumb';
import { DialogMobileContent } from '~components/atoms/DialogMobileContent';
import { Dialog, DialogContent } from '~components/atoms/Modal';
import { JackpotBetEventsList } from '~components/molecules/BetslipHistorySection/Jackpot/JackpotsBetsHistoryMobile/JackpotBetEventsList';
import { useTranslation } from '~hooks/useTranslation';
import { useAppDispatch, useAppSelector } from '~store';
import {
  selectJackpotHistoryById,
  setSelectedJackpotHistoryId,
} from '~store/slices/betslipSlice';
import { BreadcrumbItem } from '~types/breadcrumb';
import { JackpotBetEvent } from '~types/jackpot';

export const JackpotEventDetails = () => {
  const { localized } = useTranslation();
  const [breadcrumbTrail, setBreadcrumbTrail] = useState<BreadcrumbItem[]>([]);
  const dispatch = useAppDispatch();
  const { selectedJackpotHistoryId } = useAppSelector((state) => state.betslip);
  const jackpot = useAppSelector(
    selectJackpotHistoryById(selectedJackpotHistoryId || ''),
  );
  const { events, name } = jackpot || { events: [] };

  const toggleDialog = (value: boolean) => {
    if (!value) {
      dispatch(setSelectedJackpotHistoryId(null));
    }
  };

  useEffect(() => {
    if (!name) return;
    const newTrail = [
      {
        label: localized('userProfile.menuTitles.history'),
        onClickCallback: () => {
          dispatch(setSelectedJackpotHistoryId(null));
        },
        forceCallback: true,
      },
      {
        label: name,
      },
    ];

    setBreadcrumbTrail(newTrail);
  }, [name]);

  return (
    <>
      <Dialog
        open={Boolean(selectedJackpotHistoryId)}
        toggleDialog={toggleDialog}
      >
        <DialogContent hasOverlay top>
          <DialogMobileContent isSecondLevelDialog>
            <Breadcrumb propsTrail={breadcrumbTrail} />
            <Box
              fullWidth
              css={{
                p: '0 $4',
              }}
            >
              <JackpotBetEventsList events={events as JackpotBetEvent[]} />
            </Box>
          </DialogMobileContent>
        </DialogContent>
      </Dialog>
    </>
  );
};
