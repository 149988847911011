import { useEffect, useRef } from 'react';

import { useLazyGetPrematchEventsByLeagueId } from '~api/sportEvent/sportEventQueries';
import { useNextHourToDateTo } from '~hooks/useNextHourToDateTo';
import { useQueryParams } from '~hooks/useQueryParams';
import { useAppDispatch, useAppSelector } from '~store';
import { addEventsWithMarkets } from '~store/slices/eventsSlice';
import {
  setLeagueEvents,
  setSelectedLeagueData,
  setSelectedLeagueId,
} from '~store/slices/prematchMenuSlice';

import { processData } from '../helpers/extractData';

export const useLeagueEventsWithMarkets = () => {
  const { leagueId: activeLeague, sportId } = useQueryParams();
  const { mainMarkets } = useAppSelector((state) => state.mainMarkets);
  const { leagueEvents, selectedLeagueData, selectedLeagueId } = useAppSelector(
    (state) => state.prematchMenu,
  );
  const dateTo = useNextHourToDateTo();
  const prevDateTo = useRef('');
  const {
    lazyGetPrematchEventsByLeagueIdQuery,
    lazyGetPrematchEventsByLeagueIdData: prematchEventsByLeagueData,
    lazyGetPrematchEventsByLeagueIdIsFetching: isFetching,
  } = useLazyGetPrematchEventsByLeagueId();

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (
      (activeLeague && activeLeague !== selectedLeagueId) ||
      (prevDateTo.current !== dateTo && activeLeague)
    ) {
      prevDateTo.current = dateTo || '';
      dispatch(setSelectedLeagueId(activeLeague));
      lazyGetPrematchEventsByLeagueIdQuery({
        leagueId: activeLeague,
        dateTo: dateTo as string,
      });
    }
  }, [activeLeague, selectedLeagueId, dateTo]);

  useEffect(() => {
    if (prematchEventsByLeagueData && mainMarkets.length && sportId) {
      const { preparedData, allEvents, allMarkets } = processData(
        prematchEventsByLeagueData,
        mainMarkets,
        parseInt(sportId),
      );

      dispatch(
        addEventsWithMarkets({ events: allEvents, markets: allMarkets }),
      );

      dispatch(setSelectedLeagueData(prematchEventsByLeagueData));
      dispatch(setLeagueEvents(preparedData));
    }
  }, [prematchEventsByLeagueData, mainMarkets, sportId, activeLeague]);

  return {
    leagueEventsData: leagueEvents,
    prematchEventsByLeagueData: selectedLeagueData,
    isFetching,
  };
};
