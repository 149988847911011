import { useEffect, useState } from 'react';

import { useMedia } from '~hooks/useMedia';
import { useQueryParams } from '~hooks/useQueryParams';
import { useAppSelector } from '~store';
import { GroupItem, Market } from '~types/events';

export const useInplayMarketEventGroups = (
  marketGroups: Record<string, Market[]>,
) => {
  const { isMobileOrTablet } = useMedia();
  const { eventId } = useQueryParams();
  const { isUserLoggedIn } = useAppSelector((state) => state.userState);
  const [isEventLoaded] = useState(false);
  const [selectedGroupId, setSelectedGroupId] = useState('all');

  const { marketGroups: allGroups } = useAppSelector((state) => state.events);

  const getFavoriteMarkets = () => {
    const favoriteGroup: Record<string, Market[]> = {};

    Object.keys(marketGroups).forEach((groupKey) => {
      const ongoingGroup = marketGroups[groupKey];
      const [firstMarket] = ongoingGroup!;

      if (firstMarket!.favorite) {
        favoriteGroup[groupKey] = marketGroups[groupKey]!;
      }
    });

    return isUserLoggedIn
      ? [
          {
            id: 'favorite',
            name: 'Favorite',
            items: favoriteGroup,
          },
        ]
      : [];
  };

  const getSelectedMarketData = () => {
    const ongoing = groupsToShow?.find((group) => group.id === selectedGroupId);

    return ongoing?.items || marketGroups;
  };

  const getMarketGroupsWithAdditionalData = (): GroupItem[] => {
    const groupsWithAdditionalData =
      allGroups && JSON.parse(JSON.stringify(allGroups));

    Object.keys(marketGroups).forEach((groupKey) => {
      groupsWithAdditionalData?.forEach((data: GroupItem) => {
        const id: string = data.id;
        const ongoingGroup = marketGroups[groupKey] && marketGroups[groupKey];
        const marketGroupIds = ongoingGroup && ongoingGroup[0]!.marketGroupIds!;

        if (marketGroupIds?.includes(id)) {
          data.items = {
            ...data?.items,
            [groupKey]: marketGroups[groupKey]!,
          };
        }
      });
    });

    return groupsWithAdditionalData;
  };

  const [groupsToShow, setGroupsToShow] = useState<GroupItem[]>([
    {
      id: 'all',
      name: 'All',
      items: marketGroups,
    },
    ...getFavoriteMarkets(),
    ...getMarketGroupsWithAdditionalData(),
  ]);

  useEffect(() => {
    if (allGroups && Object.keys(marketGroups)) {
      const allGroupsWithAdditionalData: GroupItem[] = [
        {
          id: 'all',
          name: 'All',
          items: marketGroups,
        },
        ...getFavoriteMarkets(),
        ...getMarketGroupsWithAdditionalData(),
      ];

      setGroupsToShow(allGroupsWithAdditionalData);
    }
  }, [marketGroups, allGroups]);

  useEffect(() => {
    setSelectedGroupId('all');
  }, [eventId]);

  return {
    isMobileOrTablet,
    eventId,
    isEventLoaded,
    groupsToShow,
    selectedGroupId,
    setSelectedGroupId,
    getSelectedMarketData,
  };
};
