import { useEffect, useState } from 'react';

import { useOpenBet } from '~api/betslip/betslipMutations';
import { BETSLIP_TYPE } from '~api/betslip/types';
import { DIALOGS } from '~components/atoms/AbsoluteDialogs';
import { useAppDispatch, useAppSelector } from '~store';
import {
  removeAll,
  resetBetslipChanged,
  setAggregatedBetAmount,
  setBetErrorCode,
  setBetErrorEvent,
  setRebet,
  setReloadBetslip,
  setSingleBetAmountMap,
  setStakes,
  setStakeType,
  setSystemBetOption,
} from '~store/slices/betslipSlice';
import { openDialog } from '~store/slices/globalDialogSlice';
import { setIsSwipeOpen } from '~store/slices/mobileSlice';
import {
  getBetslipStake,
  getIsLiveBetslip,
  getMinMaxStake,
} from '~utils/betslip';

import {
  BETSLIP_ERRORS,
  EVENT_ACTIVE_STATUSES,
  MARKET_ACTIVE_STATUS,
  SPORT_BETSLIP_TYPE_OPTIONS,
} from '../constants';

export const useBetslipFooter = (quickBet: boolean = false) => {
  const dispatch = useAppDispatch();
  const { isUserLoggedIn } = useAppSelector((state) => state.userState);
  const {
    rebet,
    rebetAggregatedBetAmount,
    rebetSingleBetsAmountMap,
    rebetStakeType,
    events,
    singleBetsAmountMap,
    betslipErrors,
    aggregatedBetAmount,
    stakeType,
    isBetslipChanged,
    oddAskType,
    systemBetOption,
  } = useAppSelector((state) => state.betslip);
  const { partnerLimits, defaultOddPolicyAcceptance, separateBonusBalance } =
    useAppSelector((state) => state.settings);

  const { marketsMap, eventsMap } = useAppSelector((state) => state.events);
  const [localizationPath, setLocalizationPath] =
    useState<string>('buttons.betNow');
  const { openBetMutation } = useOpenBet();
  const [isDisabled, setIsDisabled] = useState<boolean>(false);

  useEffect(() => {
    const isBetslipEmpty = events.length === 0;

    let isZeroStake;
    let isLowStake;
    let isHighStake;

    const isLiveBetslip = getIsLiveBetslip(eventsMap, events);

    const { min: sportMinStake, max: sportMaxStake } = getMinMaxStake(
      partnerLimits,
      isLiveBetslip,
    );

    if (stakeType === SPORT_BETSLIP_TYPE_OPTIONS.SINGLE) {
      isZeroStake =
        Object.values(singleBetsAmountMap).reduce(
          (acc, { main = 0, bonus = 0 }) =>
            acc + (Number(main) + Number(bonus)),
          0,
        ) === 0;

      isLowStake = Object.values(singleBetsAmountMap).some(
        ({ main = 0, bonus = 0 }) =>
          Number(main) + Number(bonus) < sportMinStake,
      );

      isHighStake = Object.values(singleBetsAmountMap).some(
        ({ main = 0, bonus = 0 }) =>
          Number(main) + Number(bonus) > sportMaxStake,
      );
    } else {
      const { main = 0, bonus = 0 } = aggregatedBetAmount;
      const mainAmount = Number(main);
      const bonusAmount = Number(bonus);

      isLowStake = mainAmount + bonusAmount < sportMinStake;
      isHighStake = mainAmount + bonusAmount > sportMaxStake;

      isZeroStake = mainAmount + bonusAmount === 0;
    }

    if (isZeroStake) {
      setIsDisabled(true);

      return;
    }

    if (isBetslipEmpty) {
      setIsDisabled(true);

      return;
    }

    const isLinesNotMet =
      betslipErrors.includes(BETSLIP_ERRORS.MIN_SELECTIONS) ||
      betslipErrors.includes(BETSLIP_ERRORS.MAX_SELECTIONS);

    if (isLinesNotMet) {
      setIsDisabled(true);

      return;
    }

    const isStakeNotMet =
      betslipErrors.includes(BETSLIP_ERRORS.LOW_BALANCE) ||
      betslipErrors.includes(BETSLIP_ERRORS.LOW_BONUS_BALANCE) ||
      isLowStake ||
      isHighStake;

    if (isStakeNotMet) {
      setIsDisabled(true);

      return;
    }

    setIsDisabled(false);
  }, [
    partnerLimits,
    events,
    stakeType,
    aggregatedBetAmount,
    singleBetsAmountMap,
    betslipErrors,
  ]);

  const handleClick = async () => {
    if (isUserLoggedIn && rebet.length) {
      dispatch(setSystemBetOption(null));
      dispatch(setReloadBetslip(true));
      dispatch(setStakes(rebet));
      dispatch(setRebet([]));
      dispatch(setSingleBetAmountMap(rebetSingleBetsAmountMap));
      dispatch(setAggregatedBetAmount(rebetAggregatedBetAmount));
      dispatch(setStakeType(rebetStakeType));

      return;
    }

    if (isUserLoggedIn) {
      const filteredEvents = events.filter(({ eventId, marketId }) => {
        const isEventFinished = !EVENT_ACTIVE_STATUSES.includes(
          eventsMap[eventId]?.status as number,
        );
        const isMarketFinished =
          marketsMap[marketId]?.status !== MARKET_ACTIVE_STATUS;

        return !isEventFinished && !isMarketFinished;
      });

      if (filteredEvents.length === 0) {
        dispatch(setStakes([]));
        dispatch(resetBetslipChanged());

        return;
      }

      if (stakeType === SPORT_BETSLIP_TYPE_OPTIONS.MULTIPLE) {
        const betSlips = [
          {
            selections: filteredEvents
              .map(({ selectionId, marketId, eventId }) => {
                const { selections = [] } = marketsMap[marketId] || {};
                const odd =
                  selections.find((item) => item.id === selectionId)?.odd || 0;

                return {
                  id: selectionId,
                  eventId,
                  odd,
                };
              })
              .filter((item) => !!item.odd),
            // If separateBonusBalance is true, we need to send the bonus stake separately
            ...getBetslipStake({
              amount: aggregatedBetAmount,
              separateBonusBalance,
              isBet: true,
            }),
          },
        ];

        await openBetMutation({
          betSlips,
          betSlipType: BETSLIP_TYPE.MULTIPLE,
          oddChangePolicy: parseInt(
            (oddAskType || defaultOddPolicyAcceptance) as string,
          ),
        });
      } else if (stakeType === SPORT_BETSLIP_TYPE_OPTIONS.SYSTEM) {
        const betSlips = {
          selections: filteredEvents
            .map(({ selectionId, marketId, eventId }) => {
              const { selections = [] } = marketsMap[marketId] || {};
              const odd =
                selections.find((item) => item.id === selectionId)?.odd || 0;

              return {
                id: selectionId,
                eventId,
                odd,
              };
            })
            .filter((item) => !!item.odd),
          // If separateBonusBalance is true, we need to send the bonus stake separately
          ...getBetslipStake({
            amount: aggregatedBetAmount,
            separateBonusBalance,
            isBet: true,
          }),
        };

        await openBetMutation({
          betSlips,
          systemBetCombinationSize: Number(systemBetOption?.value) || 0,
          oddChangePolicy: parseInt(
            (oddAskType || defaultOddPolicyAcceptance) as string,
          ),
        });
      } else {
        const betSlips = filteredEvents.map(
          ({ selectionId, marketId, eventId }) => {
            const amount = singleBetsAmountMap[selectionId] || {};
            const { selections = [] } = marketsMap[marketId] || {};
            const odd =
              selections.find((item) => item.id === selectionId)?.odd || 0;

            return {
              selections: [
                {
                  eventId,
                  id: selectionId,
                  odd,
                },
              ],
              ...getBetslipStake({ amount, separateBonusBalance, isBet: true }),
            };
          },
        );

        dispatch(setBetErrorCode(0));
        dispatch(setBetErrorEvent(null));
        await openBetMutation({
          betSlips,
          oddChangePolicy: parseInt(
            (oddAskType || defaultOddPolicyAcceptance) as string,
          ),
          betSlipType: BETSLIP_TYPE.SINGLE,
        });
      }
    } else {
      dispatch(openDialog(DIALOGS.SIGN_IN));
    }
  };

  useEffect(() => {
    if (rebet.length && isUserLoggedIn) {
      setLocalizationPath('buttons.rebet');

      return;
    }

    if (isBetslipChanged.length && isUserLoggedIn) {
      if (quickBet) {
        setLocalizationPath('buttons.acceptChangesAndPlaceQuickBet');

        return;
      }

      setLocalizationPath('buttons.acceptChangesAndPlaceBet');

      return;
    }

    if (isUserLoggedIn) {
      setLocalizationPath('buttons.betNow');
    }
  }, [isUserLoggedIn, isBetslipChanged, events, quickBet]);

  const onDeleteClick = () => {
    dispatch(removeAll());
    dispatch(setIsSwipeOpen(null));
  };

  return {
    isDisabled,
    isUserLoggedIn,
    isBetslipChanged,
    localizationPath,
    rebet,
    onClick: handleClick,
    onDeleteClick,
  };
};
