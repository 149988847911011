import { useEffect, useState } from 'react';

import { Market } from '~types/events';

import { AccordionItem, AccordionRoot } from './MarketsAccordion';
import { MarketsListItem } from './MarketsListItem';

interface MarketsListProps {
  marketGroups: Record<string, Market[]>;
  eventId: string;
  isEventLoaded: boolean;
  marketsCount?: number;
}

export const MarketsList = ({
  marketGroups,
  eventId,
  isEventLoaded,
}: MarketsListProps) => {
  const marketKeysArray = Object.keys(marketGroups);
  const [openItems, setOpenItems] = useState<string[]>(marketKeysArray);
  const [marketsKeys, setMarketsKeys] = useState<string[]>(marketKeysArray);

  useEffect(() => {
    if (!isEventLoaded) return;
    const marketKeysArray = Object.keys(marketGroups);

    setMarketsKeys(marketKeysArray);
    setOpenItems(marketKeysArray);
  }, [marketGroups, isEventLoaded]);

  return (
    <AccordionRoot
      type="multiple"
      value={openItems}
      onValueChange={setOpenItems}
      css={{
        background: 'transparent',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      {marketsKeys.map((marketKey) => {
        const marketsData = marketGroups[marketKey];

        if (!marketsData || marketsData?.length === 0) return null;

        return (
          <AccordionItem key={marketsData[0]?.id} value={marketKey}>
            <MarketsListItem marketsData={marketsData} eventId={eventId} />
          </AccordionItem>
        );
      })}
    </AccordionRoot>
  );
};
