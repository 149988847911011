import { MainMarket } from '~api/market/types';
import { StyledAccordionRoot } from '~components/atoms/Accordion';
import { TopPrematchDateGroupsIds } from '~constants/mainPage';

import { useTopPrematchEventsDateGroups } from '../../hooks/useTopPrematchEventsDateGroup';

import { TopPrematchDateGroupItem } from './TopPrematchDateGroupItem';

interface TopPrematchEventsDateGroupProps {
  sportId: string;
  topEventsSportData: TopPrematchDateGroupsIds;
  mainMarkets: MainMarket[];
  maxMarketsCount: number | undefined;
  openDateGroups: string[] | undefined;
}

export const TopPrematchEventsDateGroups = ({
  sportId,
  topEventsSportData,
  mainMarkets = [],
  maxMarketsCount,
  openDateGroups = [],
}: TopPrematchEventsDateGroupProps) => {
  const { handleDateGroupOpenValuesChange } =
    useTopPrematchEventsDateGroups(sportId);

  return (
    <StyledAccordionRoot
      type="multiple"
      value={openDateGroups}
      onValueChange={handleDateGroupOpenValuesChange}
    >
      {(topEventsSportData || []).map((dateGroup) => (
        <TopPrematchDateGroupItem
          key={dateGroup.date}
          dateGroup={dateGroup}
          mainMarkets={mainMarkets}
          isOpen={!!openDateGroups && openDateGroups.includes(dateGroup.date)}
          maxMarketsCount={maxMarketsCount}
        />
      ))}
    </StyledAccordionRoot>
  );
};
