import React, { memo, useMemo } from 'react';

import { PreparedCountry } from '~types/events';

import { MultiAccordionRoot } from '../../MultiAccordion';
import { useLiveCountryMenu } from '../hooks/useLiveCountryMenu';

import { LiveCountryMenuItem } from './LiveCountryMenuItem';

interface LiveCountryMenuProps {
  sportId: number;
  countries: PreparedCountry[];
}

export const LiveCountryMenu = memo(
  ({ sportId, countries }: LiveCountryMenuProps) => {
    const {
      menuRole,
      openCountryLeagues,
      handleOnValueChange,
      getLeagueEventsCount,
      mainSportMarket,
    } = useLiveCountryMenu({ sportId });

    const countriesList = useMemo(
      () =>
        countries.map(({ id: countryId, name, code, leagues }) =>
          leagues.map(
            ({ id: leagueId, name: leagueName, events: leagueEvents }) => (
              <LiveCountryMenuItem
                key={leagueId}
                isOpened={openCountryLeagues.includes(leagueId.toString())}
                {...{
                  leagueId,
                  menuRole,
                  name,
                  leagueName,
                  countryId,
                  sportId,
                  leagueEvents,
                  code,
                  mainSportMarket,
                  getLeagueEventsCount,
                }}
              />
            ),
          ),
        ),
      [
        countries,
        menuRole,
        openCountryLeagues,
        getLeagueEventsCount,
        mainSportMarket,
        sportId,
      ],
    );

    return (
      <MultiAccordionRoot
        type="multiple"
        role={menuRole}
        onValueChange={handleOnValueChange}
        value={openCountryLeagues}
      >
        {countriesList}
      </MultiAccordionRoot>
    );
  },
);
