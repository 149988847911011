import { Box } from '~components/atoms/Box';
import { Separator } from '~components/atoms/Separator';
import { Text } from '~components/atoms/Typography';
import { CURRENCY_CODES } from '~constants/common';
import { useTranslation } from '~hooks/useTranslation';
import { AlertIcon } from '~icons';
import { useAppSelector } from '~store';
export const WarrningAndCopywriting = () => {
  const { localized } = useTranslation();
  const { currency } = useAppSelector((state) => state.settings);
  const isUganda = currency === CURRENCY_CODES.UGX;
  const isNigeria = currency === CURRENCY_CODES.NGN;
  const isZambia = currency === CURRENCY_CODES.ZMK;

  const cardImages = [
    {
      name: 'mastercard',
      src: '/mastercard.png',
    },
    {
      name: 'visa',
      src: '/visa.png',
    },
    {
      name: 'verve',
      src: '/verve.png',
    },
  ];

  if (isUganda)
    return (
      <Box
        fullWidth
        flexCol
        justifyCenter
        alignCenter
        gap={4}
        css={{
          p: '$5 $4',
        }}
      >
        <Box css={{ color: '$white' }}>
          <AlertIcon width={40} height={40} />
        </Box>
        <Text textAlign="center" textTransform="uppercase" level="12-16">
          <b>WARNING</b>
          <br /> You have to be <b>25 years</b> or above to bet.
          <br /> Please play responsibly.
          <br />
          <b> “Betting is addictive and can be psychologically harmful”</b>
        </Text>
        <Separator
          shrinkOut={4}
          verticalSpace={0}
          css={{
            background: '$grayMain',
            ml: '1px',
          }}
        />
        <Text textAlign="center" level="12-16" color={'grayMedium'}>
          Copyright © 2024 All rights reserved. This site is licensed to
          Dayohtech Solutions – SMC Ltd T/A SEMABET® Uganda, which is licensed
          by the National Lotteries and Gaming Regulatory Board of Uganda.
          Licenses #NL GRB-CS-71-861 and #NL GRB-GM-31-115
        </Text>
        <img
          src="/semabet_copywriting.png"
          alt="copywriting"
          style={{ width: '163px', height: '81px', borderRadius: '8px' }}
        />
      </Box>
    );

  if (isNigeria || isZambia) {
    return (
      <Box
        fullWidth
        flexCol
        justifyCenter
        alignCenter
        gap={4}
        css={{
          p: '$5 $4',
        }}
      >
        <Box css={{ color: '$white' }}>
          <AlertIcon width={40} height={40} />
        </Box>
        <Text textAlign="center" textTransform="uppercase" level="12-16">
          <b>WARNING</b>
          <br /> You have to be <b>18 years</b> or above to bet.
          <br /> PLEASE PLAY RESPONSIBLY.
          <br />
          <b> “Betting is addictive and can be psychologically harmful”</b>
        </Text>
        <Separator
          shrinkOut={4}
          verticalSpace={0}
          css={{
            background: '$grayMain',
            ml: '1px',
          }}
        />
        <Text textAlign="center" level="12-16" color={'grayMedium'}>
          Copyright © 2024 All rights reserved. This site is licensed to SHADE
          INTERNATIONAL ENTERTAINMENT LIMITED T/A Bet Only Nigeria, which is
          licensed by the National Lottery Regulatory Commission Abuja. License
          #0001031
        </Text>
        <Box
          flexRow
          fullWidth
          alignCenter
          justifyCenter
          gap={5}
          css={{
            border: '1px solid $grayMain',
            borderRadius: '$10',
            padding: '$4 54px',
            mt: '$6',
            width: 'min-content',
            position: 'relative',
          }}
        >
          <Text
            css={{
              p: '$0 $3',
              position: 'absolute',
              transform: 'translateX(-50%)',
              left: '50%',
              top: -10,
              background: '$grayDark',
            }}
            level={'12-16'}
          >
            {localized('payments.paymentsBy')}
          </Text>
          {cardImages.map(({ name, src }) => (
            <img src={src} alt={name} key={name} />
          ))}
        </Box>
      </Box>
    );
  }
};
