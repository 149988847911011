import { memo } from 'react';

import { Box } from '~components/atoms/Box';
import { Skeleton } from '~components/atoms/Skeleton';
import { isOdd } from '~utils/numberUtils';

export const InplayEventMarketSkeleton = memo(() => {
  return (
    <Box flexCol gap={1} css={{ width: '100%', background: '$black' }}>
      <Box
        css={{
          p: '$2 $4',
          background: '$gray',
          m: '$1 0',
        }}
      >
        <Skeleton css={{ height: '$6', border: 'none' }} />
      </Box>
      <Box
        css={{
          p: '$2 $4',
          background: '$gray',
        }}
      >
        <Skeleton css={{ height: '$6', border: 'none' }} />
      </Box>
      {Array.from({ length: 11 }, (_, key) => {
        return (
          <Box
            key={key}
            css={{
              background: '$grayDark',
              p: '$1',
            }}
          >
            <Skeleton
              css={{ height: '$6', border: 'none', m: '$1 $1 $2 $1' }}
            />
            <Box
              flexRow
              css={{
                gap: '1px',
              }}
            >
              {Array.from({ length: isOdd(key) ? 3 : 2 }, (_, key) => {
                return (
                  <Box
                    key={key}
                    css={{
                      background: '$gray',
                      p: '6px $2',
                      width: '50%',
                      '&:first-child': {
                        borderTopLeftRadius: '$6',
                        borderBottomLeftRadius: '$6',
                      },
                      '&:last-child': {
                        borderTopRightRadius: '$6',
                        borderBottomRightRadius: '$6',
                      },
                    }}
                  >
                    <Skeleton css={{ height: '32px', border: 'none' }} />
                  </Box>
                );
              })}
            </Box>
          </Box>
        );
      })}
    </Box>
  );
});
