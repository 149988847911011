import { memo, useEffect, useState } from 'react';

import { Box } from '~components/atoms/Box';
import { useAppSelector } from '~store';
import { Market } from '~types/events';
import { areTwoObjectsEqual } from '~utils/objectHelpers';

import { SelectionItem } from './SelectionItem';

interface MarketContentProps {
  marketsData: Market[];
  eventId: string;
}

export const MarketContent = memo(
  ({ marketsData, eventId }: MarketContentProps) => {
    const [marketsState, setMarketsState] = useState<Market[]>(marketsData);
    const { marketsMap } = useAppSelector((state) => state.events);

    useEffect(() => {
      const mapMarketsState = marketsState.map(({ id }) => {
        return marketsMap[id];
      }) as Market[];

      if (areTwoObjectsEqual(marketsState, mapMarketsState) || !mapMarketsState)
        return;
      setMarketsState(
        mapMarketsState.filter((item) => item && item?.selections.length),
      );
    }, [marketsMap]);

    if (!marketsState) return null;

    return (
      <Box
        flexCol
        css={{
          gap: '1px',
          overflow: 'hidden',
          background: '$inplayMarketsAccordionContentBgColor',
          border: '1px solid $inplayMarketsContentBorderColor',
          borderRadius: '$10',
        }}
      >
        {marketsState.map((market, index) => (
          <Box
            key={market.id + '-' + index}
            flexRow={market.selections.length <= 3}
            flexCol={market.selections.length > 3}
            css={{
              gap: '1px',
            }}
          >
            {market?.selections.map((selection) => (
              <SelectionItem
                status={selection.status}
                marketId={market.id}
                eventId={eventId}
                key={selection.id}
                selection={selection}
              />
            ))}
          </Box>
        ))}
      </Box>
    );
  },
);
