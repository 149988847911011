import { useEffect, useState } from 'react';

import { BalanceChangeResponse } from '~api/betslip/types';
import {
  DEFAULT_BALANCE_CHANGE,
  SPORT_BETSLIP_TYPE_OPTIONS,
} from '~components/molecules/Betslip/constants';
import { useAppSelector } from '~store';

export const useBetslipAggregatedValues = () => {
  const { stakeType, events, balanceChangesOnBetData } = useAppSelector(
    (state) => state.betslip,
  );
  const { marketsMap } = useAppSelector((state) => state.events);
  const { maxWinAmount } = useAppSelector((state) => state.settings);

  const [totalOdd, setTotalOdd] = useState(1);

  useEffect(() => {
    const odd = events.reduce((acc, { marketId, selectionId }) => {
      const market = marketsMap[marketId];
      const selection = market?.selections.find(
        (selection) => selection.id === selectionId,
      );

      return selection?.odd ? acc * selection.odd : acc;
    }, 1);

    setTotalOdd(odd);
  }, [events, marketsMap]);

  const isSystemBet = stakeType === SPORT_BETSLIP_TYPE_OPTIONS.SYSTEM;
  const {
    balanceChanges = [],
    systemBetOriginalWinAmount = 0,
    systemBetTotalPossibleWin = 0,
    systemBetWinBoostAmount = 0,
  } = balanceChangesOnBetData || ({} as BalanceChangeResponse);

  const {
    totalPossibleWin,
    winBoostAmount,
    winBoostMultiplier,
    winBoostDescriptions = [],
    originalWinAmount,
  } = balanceChanges[0] || DEFAULT_BALANCE_CHANGE;

  const possibleWin =
    totalPossibleWin > maxWinAmount ? maxWinAmount : totalPossibleWin;

  return {
    totalOdd,
    winBoostMultiplier,
    winBoostDescriptions,
    possibleWin: isSystemBet ? systemBetTotalPossibleWin : possibleWin,
    winBoostAmount: isSystemBet ? systemBetWinBoostAmount : winBoostAmount,
    originalWinAmount: isSystemBet
      ? systemBetOriginalWinAmount
      : originalWinAmount,
  };
};
