import { useEffect, useState } from 'react';

import { Market } from '~api/sportEvent/types';
import { SWIPE_COMPONENT_TYPES } from '~components/atoms/SwipeComponent';
import { useMedia } from '~hooks/useMedia';
import { useAppDispatch, useAppSelector } from '~store';
import { addStake, removeStakeBySelection } from '~store/slices/betslipSlice';
import { selectEventStatusById } from '~store/slices/eventsSlice';
import { setIsSwipeOpen } from '~store/slices/mobileSlice';
import { isQuickBet } from '~utils/isQuickBet';

interface OddButtonGroupProps {
  market?: Market;
  eventId: string;
  sportId: number;
  isFavoriteEvents?: boolean;
}

export const useOddButtonGroup = ({
  market,
  sportId,
  eventId,
  isFavoriteEvents,
}: OddButtonGroupProps) => {
  const dispatch = useAppDispatch();
  const { events } = useAppSelector((state) => state.betslip);
  const { quickBet } = useAppSelector((state) => state.settings);
  const status = useAppSelector((state) =>
    selectEventStatusById(state, eventId),
  );

  const { mainMarkets } = useAppSelector((state) => state.mainMarkets);
  const { isMobileOrTablet } = useMedia();
  const {
    loadingEventId,
    marketsOfFavoriteEventsSelected,
    mainMarketsSelected,
  } = useAppSelector((state) => state.liveMenu);

  const marketsSelected = isFavoriteEvents
    ? marketsOfFavoriteEventsSelected
    : mainMarketsSelected;

  const [activeButtons, setActiveButtons] = useState<string[]>([]);

  const onValueChange = (bets: string[]) => {
    const addedItem = bets.find((bet) => !activeButtons.includes(bet));
    const removedItem = activeButtons.find((bet) => !bets.includes(bet));

    if (addedItem && market?.id) {
      const eventStatus = status;

      if (
        events.length === 0 &&
        eventStatus &&
        isQuickBet(eventStatus, quickBet)
      ) {
        dispatch(setIsSwipeOpen(SWIPE_COMPONENT_TYPES.QUICK_BET));
      }

      dispatch(
        addStake({ eventId, marketId: market.id, selectionId: addedItem }),
      );
    }

    if (removedItem) dispatch(removeStakeBySelection(removedItem));
    setActiveButtons(bets);
  };

  const defaultMainMarketId = marketsSelected[sportId];
  const { markets = [] } =
    mainMarkets.find((mainMarket) => mainMarket.id === sportId) || {};

  const defaultMainMarket = markets.find(
    (market) => market.id === defaultMainMarketId,
  );

  useEffect(() => {
    if (loadingEventId === eventId) {
      return;
    }

    if (!events.length) setActiveButtons([]);
    const selected =
      market?.selections
        ?.map((s) => s.id)
        .filter((id) => events.some((event) => event.selectionId === id)) || [];

    setActiveButtons(selected);
  }, [events, market, loadingEventId, eventId]);

  const mainSelectedMarket = markets.find(
    (market) => market.id === marketsSelected[sportId],
  );

  return {
    isTotalMarket: mainSelectedMarket?.name?.toLowerCase().includes('total'),
    activeButtons,
    onValueChange,
    isMobileOrTablet,
    defaultMainMarket,
  };
};
