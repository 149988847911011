import React from 'react';

import { Box } from '~components/atoms/Box';
import { Button } from '~components/atoms/Button';
import { Text } from '~components/atoms/Typography';
import { useAppDispatch } from '~store';
import { setAdjustedBetAmount } from '~store/slices/betslipSlice';
import { commaSeparatedNumber } from '~utils/numberUtils';

import { betslipAdjustmentOptions } from '../../constants';

export const BetAdjustmentButtons = () => {
  const dispatch = useAppDispatch();

  const handleButtonClick = (value: number) => {
    const betAmount = {
      main: value.toString(),
    };

    dispatch(setAdjustedBetAmount(betAmount));
  };

  return (
    <Box
      justifyContentBetween
      flexRow
      gap={2}
      css={{
        p: '$4',
        '@xs_sm': {
          p: '$3 $4',
        },
      }}
    >
      {betslipAdjustmentOptions.map((item) => (
        <Button
          key={`option-${item}`}
          variant="secondary"
          css={{
            flex: 1,
            boxShadow: 'none',
            '&:hover': {
              boxShadow: '$iconButtonBorderActive',
              color: '$white',
            },
          }}
          onClick={() => handleButtonClick(item)}
        >
          <Text
            textAlign="center"
            css={{
              fontSize: '$sm',
              lineHeight: '$32',
              '@xs_sm': {
                lineHeight: '$28',
              },
            }}
          >
            {commaSeparatedNumber(item, true)}
          </Text>
        </Button>
      ))}
    </Box>
  );
};
