import { HubConnection } from '@microsoft/signalr';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { SIGNAL_R_SOCKET_NAMES } from '~constants/signalR';

export interface SingleSocketState {
  isConnected: boolean;
  socket: HubConnection | null;
}

export interface WebSocketSliceState {
  sockets: Record<string, SingleSocketState>;
  authedSockets: Record<string, SingleSocketState>;
  socketKeys: SIGNAL_R_SOCKET_NAMES[];
  authedSocketKeys: SIGNAL_R_SOCKET_NAMES[];
  sessionId: string | null;
}

const initialState: WebSocketSliceState = {
  sockets: {},
  socketKeys: [],
  authedSockets: {},
  authedSocketKeys: [],
  sessionId: null,
};

const signalRSocketsSlice = createSlice({
  name: 'websocket',
  initialState,
  reducers: {
    startSignalRSocket: (
      state,
      action: PayloadAction<SIGNAL_R_SOCKET_NAMES[]>,
    ) => {
      state.socketKeys = action.payload;
    },
    startSignalRAuthedSocket: (
      state,
      action: PayloadAction<SIGNAL_R_SOCKET_NAMES[]>,
    ) => {
      state.authedSocketKeys = action.payload;
    },
    stopSignalRSocket: (state) => {
      state.sockets = {};
    },
    stopSignalRAuthedSocket: (state) => {
      state.authedSockets = {};
    },
    setSocketConnected: (
      state,
      action: PayloadAction<{
        socketName: SIGNAL_R_SOCKET_NAMES;
        socket: HubConnection;
      }>,
    ) => {
      state.sockets = {
        ...state.sockets,
        [action.payload.socketName]: {
          isConnected: true,
          socket: action.payload.socket,
        },
      };
    },
    setAuthedSocketConnected: (
      state,
      action: PayloadAction<{
        socketName: SIGNAL_R_SOCKET_NAMES;
        socket: HubConnection;
      }>,
    ) => {
      state.authedSockets = {
        ...state.authedSockets,
        [action.payload.socketName]: {
          isConnected: true,
          socket: action.payload.socket,
        },
      };
    },
    setSessionId: (state, action: PayloadAction<string | null>) => {
      state.sessionId = action.payload;
    },
  },
});

export const {
  startSignalRSocket,
  stopSignalRAuthedSocket,
  startSignalRAuthedSocket,
  setSocketConnected,
  stopSignalRSocket,
  setAuthedSocketConnected,
  setSessionId,
} = signalRSocketsSlice.actions;

export default signalRSocketsSlice.reducer;
