import { Box } from '~components/atoms/Box';
import { Flag } from '~components/atoms/Flag';
import { EventInfoText } from '~components/molecules/Jackpot/Settled/styled.components';
import { CountryName } from '~constants/flag';

export const CountryLeague = ({
  countryName,
  leagueName,
}: {
  countryName: CountryName;
  leagueName: string;
}) => (
  <Box flexRow alignCenter gap={2}>
    <Flag countryName={countryName} />
    <EventInfoText>{`${countryName}. ${leagueName}`}</EventInfoText>
  </Box>
);
