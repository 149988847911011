import React, { ReactNode, useEffect, useRef } from 'react';
import { styled } from 'stitches.config';

import { Box } from '~components/atoms/Box';
import { ScrollToTopButton } from '~components/atoms/ScrollToTopButton';
import { MOBILE_TAB_HEIGHT } from '~constants/ui';
import useIsInIframe from '~hooks/useIsInIframe';
import { useQueryParams } from '~hooks/useQueryParams';
import { useAppDispatch, useAppSelector } from '~store';
import { addLocationScrollPosition } from '~store/slices/mobileSlice';
import { getPreparedParamsString } from '~utils/scrollPositionLocationUtils';

import { MobileFooter } from '../MobileFooter';

const StyledMobileContentContainer = styled(Box, {
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  width: '100%',
  height: '100%',
  overflowY: 'auto',
  '-webkit-overflow-scrolling': 'touch',
});

export interface MobileContentContainerProps {
  children: ReactNode;
}

export const MobileContentContainer = ({
  children,
}: MobileContentContainerProps) => {
  const { mobileHeaderHeight } = useAppSelector((state) => state.mobileState);
  const componentRef = useRef<HTMLDivElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const search = useQueryParams();
  const dispatch = useAppDispatch();
  const { locationScrollPositions } = useAppSelector(
    (state) => state.mobileState,
  );

  const isInFrame = useIsInIframe();

  const handleScroll = (event: React.UIEvent<HTMLDivElement>) => {
    const resQueryString = getPreparedParamsString(search);

    if (event.currentTarget.scrollTop)
      dispatch(
        addLocationScrollPosition({
          path: resQueryString,
          scroll: event.currentTarget.scrollTop,
        }),
      );
  };

  useEffect(() => {
    if (containerRef.current) {
      const resQueryString = getPreparedParamsString(search);

      containerRef.current.scrollTop =
        locationScrollPositions[resQueryString] || 0;
    }
  }, [search, containerRef, locationScrollPositions]);

  return (
    <StyledMobileContentContainer ref={containerRef} onScroll={handleScroll}>
      <Box
        flexCol
        gap={1}
        css={{
          pt: `${mobileHeaderHeight + 4}px`,
          pb: `calc(${MOBILE_TAB_HEIGHT}px + ${isInFrame ? '0.25rem' : '0px'})`,
        }}
        ref={componentRef}
      >
        {children}
        {!isInFrame && <MobileFooter />}
      </Box>
      <ScrollToTopButton scrollableContainerRef={componentRef} />
    </StyledMobileContentContainer>
  );
};
