import React, { useEffect } from 'react';

import { BANNER_GROUP_NAMES, BannerList } from '~components/atoms/BannerList';
import { Box } from '~components/atoms/Box';
import { contentMap } from '~components/molecules/Jackpot/constants';
import { JACKPOT_MENU_ITEMS, Menu } from '~components/molecules/Jackpot/Menu';
import { SmallJackpotSkeleton } from '~components/molecules/Jackpot/Skeletons/SmallJackpotSkeleton';
import { SmallScreenJackpot } from '~components/molecules/Jackpot/SmallScreenJackpot';
import { useQueryParams } from '~hooks/useQueryParams';
import { useAppDispatch, useAppSelector } from '~store';
import { setSelectedJackpot } from '~store/slices/jackpotSlice';

interface JackpotMobilePageProps {
  isLoading: boolean;
}

export const MobilePage = ({ isLoading }: JackpotMobilePageProps) => {
  const dispatch = useAppDispatch();
  const { menu, jackpotId } = useQueryParams();

  const { activeJackpots, settledJackpots } = useAppSelector(
    (state) => state.jackpot,
  );
  const selectedJackpot = [...activeJackpots, ...settledJackpots].find(
    (jackpot) => jackpot.id === jackpotId,
  );

  useEffect(() => {
    if (selectedJackpot) {
      dispatch(setSelectedJackpot(selectedJackpot));
    }
  }, [selectedJackpot]);

  if (isLoading) {
    return <SmallJackpotSkeleton />;
  }

  // Check url and render proper jackpot page
  if (
    (menu === JACKPOT_MENU_ITEMS.SETTLED_JACKPOTS ||
      menu === JACKPOT_MENU_ITEMS.ACTIVE_JACKPOTS) &&
    jackpotId
  ) {
    return menu === JACKPOT_MENU_ITEMS.ACTIVE_JACKPOTS ? (
      <SmallScreenJackpot
        jackpots={activeJackpots}
        menu={JACKPOT_MENU_ITEMS.ACTIVE_JACKPOTS}
      />
    ) : (
      <SmallScreenJackpot
        jackpots={settledJackpots}
        menu={JACKPOT_MENU_ITEMS.SETTLED_JACKPOTS}
      />
    );
  }

  return (
    <Box flexCol gap={1}>
      <BannerList names={[BANNER_GROUP_NAMES.JACKPOT_HEADER]} />
      <Menu />
      {contentMap[menu as JACKPOT_MENU_ITEMS]}
    </Box>
  );
};
