import {
  useGetPrematchQuery,
  useLazyGetCategoriesByDateToQuery,
  useLazyGetCountriesBySportQuery,
  useLazyGetLeaguesByCountryQuery,
  useLazyGetPrematchQuery,
  useLazyGetSportsQuery,
} from './categoryApi';
import { PrematchOptionalParams } from './types';

export const useLazyGetSports = () => {
  const [query, { data, error, isLoading }] = useLazyGetSportsQuery();

  return {
    lazyGetSportsQuery: query,
    lazyGetSportsData: data,
    lazyGetSportsError: error,
    lazyGetSportsIsLoading: isLoading,
  };
};

export const useLazyGetCategoriesByDateTo = () => {
  const [query, { data, error, isLoading }] =
    useLazyGetCategoriesByDateToQuery();

  return {
    lazyGetCategoriesByDateToQuery: query,
    lazyGetCategoriesByDateToData: data,
    lazyGetCategoriesByDateToError: error,
    lazyGetCategoriesByDateToIsLoading: isLoading,
  };
};

export const useLazyGetCountriesBySport = () => {
  const [query, { data, error, isLoading }] = useLazyGetCountriesBySportQuery();

  return {
    lazyGetCountriesBySportQuery: query,
    lazyGetCountriesBySportData: data,
    lazyGetCountriesBySportError: error,
    lazyGetCountriesBySportIsLoading: isLoading,
  };
};

export const useLazyGetLeaguesByCountry = () => {
  const [query, { data, error, isLoading }] = useLazyGetLeaguesByCountryQuery();

  return {
    lazyGetLeaguesByCountryQuery: query,
    lazyGetLeaguesByCountryData: data,
    lazyGetLeaguesByCountryError: error,
    lazyGetLeaguesByCountryIsLoading: isLoading,
  };
};

export const useGetPrematch = (params: PrematchOptionalParams) => {
  const { data, error, isLoading } = useGetPrematchQuery(params);

  return {
    getPrematchData: data,
    getPrematchError: error,
    getPrematchIsLoading: isLoading,
  };
};

export const useLazyGetPrematch = () => {
  const [query, { data, error, isLoading, isFetching }] =
    useLazyGetPrematchQuery();

  return {
    lazyGetPrematchQuery: query,
    lazyGetPrematchData: data,
    lazyGetPrematchError: error,
    lazyGetPrematchIsLoading: isLoading,
    lazyGetFavoriteEventsIsFetching: isFetching,
  };
};
