import { useEffect, useRef, useState } from 'react';
import { styled } from 'stitches.config';

import { useMedia } from '~hooks/useMedia';
import { useWebsiteSettings } from '~hooks/useWebsiteSettings';
import { CaretDownIcon, CaretUpIcon } from '~icons';

import { Box } from './Box';
import { Text } from './Typography';

enum ODD_COLOR {
  YELLOW = 'yellow',
  GREEN = 'green',
  RED = 'red',
}

// TODO: move to utils
const determineOddColor = (
  prevValue: number | undefined,
  nextValue: number,
): ODD_COLOR => {
  if (!prevValue) {
    return ODD_COLOR.YELLOW;
  }

  if (nextValue > prevValue) {
    return ODD_COLOR.GREEN;
  } else if (nextValue < prevValue) {
    return ODD_COLOR.RED;
  } else {
    return ODD_COLOR.YELLOW;
  }
};

const IndicatorWrapper = styled(Box, {
  position: 'absolute',
  bottom: '-$2',
});

const CrossedIndicatorWrapper = styled(Box, {
  position: 'absolute',
  top: '-$4',
  right: 0,
  variants: {
    sidePrevValue: {
      true: {
        position: 'relative',
        top: 0,
      },
    },
  },
});

const PrimitiveOddIndicator = styled(Box, {
  position: 'relative',
  variants: {
    color: {
      green: {
        color: '$greenColorIndicator',
      },
      yellow: {
        color: '$yellow',
      },
      red: {
        color: '$redColorIndicator',
      },
    },
    active: {
      true: {
        color: '$white',
      },
    },
  },
});

interface OddIndicatorProps {
  odd: number;
  isLeagueEvents?: boolean;
}

export const ArrowOddIndicator = ({ odd }: OddIndicatorProps) => {
  const { isMobileOrTablet } = useMedia();
  const { getFormattedOdd, odds } = useWebsiteSettings();
  const [color, setColor] = useState(ODD_COLOR.YELLOW);
  const previousOddRef = useRef<number | null>(null); // Initialized with null

  useEffect(() => {
    let timer: NodeJS.Timeout;

    if (previousOddRef.current !== null) {
      setColor(determineOddColor(previousOddRef.current, odd));

      timer = setTimeout(() => {
        setColor(ODD_COLOR.YELLOW);
      }, 2000);
    }

    previousOddRef.current = odd;

    return () => clearTimeout(timer);
  }, [odd]);

  return (
    <PrimitiveOddIndicator flexRow justifyCenter color={color} as="span">
      <Text
        level={isMobileOrTablet ? '12-20' : '14-28'}
        css={{
          color: 'inherit',
        }}
        fontWeight="medium"
      >
        {getFormattedOdd(odd, odds)}
      </Text>
      <IndicatorWrapper>
        {color === ODD_COLOR.GREEN && <CaretUpIcon />}
        {color === ODD_COLOR.RED && <CaretDownIcon />}
      </IndicatorWrapper>
    </PrimitiveOddIndicator>
  );
};

interface OddCrossedIndicatorProps {
  odd: number;
  sidePrevValue?: boolean;
  disabled?: boolean;
  onOddChange?: (odd: number) => void;
}

export const OddCrossedIndicator = ({
  odd,
  disabled,
  sidePrevValue,
  onOddChange,
}: OddCrossedIndicatorProps) => {
  const { getFormattedOdd, odds } = useWebsiteSettings();
  const { isMobileOrTablet } = useMedia();
  const [currentOdd, setCurrentOdd] = useState(odd);
  const [previousOdd, setPreviousOdd] = useState(0);

  useEffect(() => {
    if (odd !== currentOdd) {
      setPreviousOdd(currentOdd);
      setCurrentOdd(odd);
      if (onOddChange) {
        onOddChange(odd);
      }
    }
  }, [odd]);

  return (
    <PrimitiveOddIndicator
      flexRow
      justifyCenter
      gap={1}
      css={{
        '@xs': {
          fontSize: '$xxs',
        },
      }}
    >
      {!!previousOdd && previousOdd !== currentOdd && (
        <CrossedIndicatorWrapper sidePrevValue={sidePrevValue}>
          <Text
            lineThrough
            color={
              previousOdd < currentOdd
                ? 'greenColorIndicator'
                : 'redColorIndicator'
            }
            level={isMobileOrTablet ? '11-16' : '13-16'}
            fontWeight="medium"
          >
            {sidePrevValue
              ? `(${getFormattedOdd(previousOdd, odds)})`
              : getFormattedOdd(previousOdd, odds)}
          </Text>
        </CrossedIndicatorWrapper>
      )}
      <Text
        color={disabled ? 'yellowOpacity' : 'yellow'}
        level={isMobileOrTablet ? '11-16' : '13-16'}
        fontWeight="medium"
      >
        {getFormattedOdd(odd, odds)}
      </Text>
    </PrimitiveOddIndicator>
  );
};
