import React, { useEffect } from 'react';

import { useLazyGetOpenBets } from '~api/betslip/betslipQueries';
import { OpenedBet } from '~api/betslip/types';
import { Box } from '~components/atoms/Box';
import { Loader } from '~components/atoms/Loader';
import { Text } from '~components/atoms/Typography';
import { BetHistoryMobileCashout } from '~components/molecules/BetslipHistorySection/Sports/BetslipHistoryMobileContent/BetHistoryMobileCashout';
import { useMedia } from '~hooks/useMedia';
import { useTranslation } from '~hooks/useTranslation';
import { useAppDispatch, useAppSelector } from '~store';
import { setOpenBets } from '~store/slices/openBetSlice';

import { OpenBet } from './OpenBet';
import { OpenBetsEmpty } from './OpenBetsEmpty';

export const OpenBets = () => {
  const { isMobileOrTablet } = useMedia();
  const dispatch = useAppDispatch();
  const { localized } = useTranslation();
  const { openBets = [], cashOutProcessed } = useAppSelector(
    (state) => state.openBets,
  );
  const { isUserLoggedIn } = useAppSelector((state) => state.userState);
  const { lazyGetOpenBetsQuery, lazyGetOpenBetsIsLoading } =
    useLazyGetOpenBets();

  useEffect(() => {
    const loadOpenBets = async () => {
      const openBetsData = await lazyGetOpenBetsQuery().unwrap();

      dispatch(setOpenBets(openBetsData));
    };

    if ((isUserLoggedIn && cashOutProcessed) || !openBets.length) {
      loadOpenBets();
    }

    if (!isUserLoggedIn) {
      dispatch(setOpenBets([]));
    }
  }, [isUserLoggedIn, cashOutProcessed, openBets.length]);

  if (lazyGetOpenBetsIsLoading) {
    return (
      <Box flexCol alignCenter gap={3} css={{ py: '32px' }}>
        <Loader css={{ color: '$grayMedium' }} />
        <Text color="grayMedium" level="12-20" fontWeight="medium">
          {localized('betslip.openBetsLoading')}
        </Text>
      </Box>
    );
  }

  return (
    <Box
      css={{
        p: '$0 $4 $4',
        width: '100%',
        gap: '$2',
      }}
    >
      {openBets.length ? (
        <>
          <Text
            level="sm-2"
            css={{
              mb: '$3',
              '@xs_sm': {
                fontSize: '$xxs',
              },
            }}
          >
            {localized('betslip.yourBets')}:
          </Text>
          <Box
            flexCol
            gap={2}
            css={{
              '@xs_sm': {
                mb: '50px',
              },
            }}
          >
            {openBets.map((bet: OpenedBet) => (
              <OpenBet key={bet.id} bet={bet} />
            ))}
          </Box>
        </>
      ) : (
        <OpenBetsEmpty />
      )}
      {isMobileOrTablet && <BetHistoryMobileCashout />}
    </Box>
  );
};
