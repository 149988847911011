import React, { useState } from 'react';
import { styled } from 'stitches.config';

import { DIALOGS } from '~components/atoms/AbsoluteDialogs';
import { Box } from '~components/atoms/Box';
import { Button } from '~components/atoms/Button';
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '~components/atoms/Popover';
import { Text } from '~components/atoms/Typography';
import { generateLuckyDipStakes } from '~components/molecules/Jackpot/utils';
import { useMedia } from '~hooks/useMedia';
import { useTranslation } from '~hooks/useTranslation';
import { useAppDispatch, useAppSelector } from '~store';
import { openDialog } from '~store/slices/globalDialogSlice';
import {
  clearJackpotMessages,
  getSelectedJackpot,
  setJackpotStakesById,
} from '~store/slices/jackpotSlice';

import { LuckyDipTooltip } from './LuckyDipTooltip';

const LuckyDipWrapper = styled(Box, {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  gap: '$6',
  '@xs': {
    flexDirection: 'column',
    gap: '$1',
  },
});

const LuckyDipButton = styled(Button, {
  p: '$2',
  width: '172px',
  height: '40px',
  '@xs': {
    width: '100%',
    height: '32px',
    p: '$1 0',
    borderRadius: '6px',
  },
});

const LuckyDipText = styled(Text, {
  level: '16-24',
  textDecoration: 'underline',
  textShadow: '0px 2px 4px #0A0F14',
  '&:hover': {
    cursor: 'pointer',
    color: '$yellow',
  },
  '@xs': {
    fontSize: '11px',
    lineHeight: '16px',
  },
});

export const LuckyDip = () => {
  const { localized } = useTranslation();
  const { isMobile, isMobileOrTablet } = useMedia();
  const dispatch = useAppDispatch();
  const selectedJackpot = useAppSelector(getSelectedJackpot);

  const [isOpen, setIsOpen] = useState(false);

  const handleLuckyDipClick = () => {
    const luckyDipStakes = generateLuckyDipStakes(selectedJackpot!.events);

    dispatch(clearJackpotMessages());
    dispatch(
      setJackpotStakesById({
        jackpotId: selectedJackpot!.id,
        stakes: luckyDipStakes,
      }),
    );
  };

  const handleLuckyDipTooltipClick = () => {
    if (isMobileOrTablet) {
      dispatch(openDialog(DIALOGS.LUCKY_DIP));
    }
  };

  return (
    <LuckyDipWrapper>
      <LuckyDipButton onClick={handleLuckyDipClick}>
        <Text
          level={isMobile ? '14-24' : '18-24'}
          color="buttonTextColor"
          fontWeight="bold"
          textAlign="center"
        >
          {localized('jackpot.luckyDip')}
        </Text>
      </LuckyDipButton>

      <Popover isOpen={isOpen}>
        <PopoverContent
          noArrow={isMobileOrTablet}
          side="bottom"
          sideOffset={isMobileOrTablet ? 16 : 8}
          align="start"
          tone="secondary"
          width={468}
        >
          <LuckyDipTooltip />
        </PopoverContent>
        <PopoverTrigger asChild>
          <Box
            onClick={handleLuckyDipTooltipClick}
            onMouseEnter={() => !isMobileOrTablet && setIsOpen(true)}
            onMouseLeave={() => !isMobileOrTablet && setIsOpen(false)}
          >
            <LuckyDipText>{localized('jackpot.whatIsLuckDip')}</LuckyDipText>
          </Box>
        </PopoverTrigger>
      </Popover>
    </LuckyDipWrapper>
  );
};
