import { styled } from 'stitches.config';

import { Button } from '~components/atoms/Button';
import { BetslipSystemBetOptions } from '~components/molecules/Betslip/components/BetslipOptions/BetslipSystemBetOptions';
import {
  MIN_SYSTEM_BET_EVENTS,
  SPORT_BETSLIP_TYPE_OPTIONS,
} from '~components/molecules/Betslip/constants';
import { useTranslation } from '~hooks/useTranslation';
import { useAppDispatch, useAppSelector } from '~store';
import { removeAll } from '~store/slices/betslipSlice';

import { BetslipOddsAsk } from './BetslipOddsAsk';
import { BetslipStakeType } from './BetslipStakeType';

const OptionsContainer = styled('div', {
  display: 'grid',
  gridTemplateColumns: '50% 50%',
  gap: '$2',
});

export const BetslipOptions = () => {
  const { localized } = useTranslation();
  const dispatch = useAppDispatch();
  const { events, stakeType } = useAppSelector((state) => state.betslip);
  const showSystemBetOptions =
    events.length >= MIN_SYSTEM_BET_EVENTS &&
    stakeType === SPORT_BETSLIP_TYPE_OPTIONS.SYSTEM;

  const gridTemplateAreas = showSystemBetOptions
    ? `'stakeType systemBetOptions'
       'oddType remove'`
    : `'stakeType stakeType'
       'oddType remove'`;

  return (
    <OptionsContainer
      css={{
        p: events.length ? '$0 $4 $4' : '$0 $4',
        gridTemplateAreas,
      }}
    >
      <BetslipStakeType />
      {showSystemBetOptions && <BetslipSystemBetOptions />}
      <BetslipOddsAsk />
      <Button
        variant="secondary"
        css={{
          gridArea: 'remove',
          background: '$grayDarkMain2',
          color: '$grayMedium',
          boxShadow: 'none',
          '&:hover': {
            boxShadow: '$removeButtonBorderActive',
            color: '$red',
          },
          '@xs_sm': {
            fontSize: '$xxs',
            lineHeight: '$20',
            borderRadius: '$4',
            p: '4px 0',
          },
        }}
        size="small"
        fullWidth
        onClick={() => dispatch(removeAll())}
      >
        {localized('buttons.removeAll')}
      </Button>
    </OptionsContainer>
  );
};
