import { BANNER_GROUP_NAMES, BannerList } from '~components/atoms/BannerList';
import { RightSidebar } from '~components/organisms/RightSidebar';

export const RightJackpotSidebar = () => {
  return (
    <RightSidebar sticky>
      <BannerList names={[BANNER_GROUP_NAMES.JACKPOT_RIGHT_SIDEBAR]} />
    </RightSidebar>
  );
};
