import { JackpotEvents, JackpotStake } from '~types/jackpot';

export const printPossibleWinLines = (sortedLines: number[]) => {
  return sortedLines
    .map((element, index, array) => {
      if (index === array.length - 1) {
        return element;
      } else if (index === array.length - 2) {
        return element + ' and';
      } else {
        return element + ',';
      }
    })
    .join(' ');
};

/**
 * This function counts the number of stakes for each event in a jackpot.
 * @param stakes - An array of stakes, where each stake is an object of type `JackpotStake` and includes an `eventId` property.
 */
export const countJackpotStakes = (stakes: JackpotStake[]) => {
  const eventStakesMap: Record<string, number> = {};

  stakes.forEach(({ eventId }) => {
    if (!eventStakesMap[eventId]) {
      eventStakesMap[eventId] = 1;
    } else {
      eventStakesMap[eventId]++;
    }
  });

  return eventStakesMap;
};

/**
 * This function calculates the total number of tickets for a jackpot event.
 *
 * @param stakes - An array of stakes, where each stake is an object of type `JackpotStake` and includes an `eventId` property.
 * @param eventsAmount - The total number of events.
 * If the user has selected all events, the function returns the product of all the counts.
 * This is done by reducing the array of counts to a single value by multiplying all the counts together. If there are no counts, the function returns 1.
 */
export const countJackpotTotalTickets = (
  stakes: JackpotStake[],
  eventsAmount: number,
) => {
  const defaultTotalTickets = 1;

  if (!stakes.length) {
    return defaultTotalTickets;
  }

  const eventStakesMap = countJackpotStakes(stakes);
  const eventStakes = Object.values(eventStakesMap);

  // We return 1 if the user has not selected all events
  if (eventStakes.length < eventsAmount) {
    return defaultTotalTickets;
  }

  return Object.values(eventStakesMap).reduce((acc, curr) => acc * curr, 1);
};

export const generateLuckyDipStakes = (events: JackpotEvents) => {
  return events.map((event) => {
    const randomIndex = Math.floor(
      Math.random() * event.market.selections.length,
    );

    return {
      id: event.market.selections[randomIndex]!.id,
      eventId: event.id,
    };
  });
};
