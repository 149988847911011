import { Market } from '~types/events';

import { MarketContent } from './MarketContent';
import { AccordionContent } from './MarketsAccordion';
import { MarketsItemHeader } from './MarketsItemHeader';

interface MarketsListItemProps {
  marketsData: Market[];
  eventId: string;
}

export const MarketsListItem = ({
  marketsData,
  eventId,
}: MarketsListItemProps) => {
  if (!marketsData?.length) return;

  const { name = 'Market', marketId = '', favorite } = marketsData[0] || {};

  return (
    <>
      <MarketsItemHeader
        name={name}
        marketId={parseInt(marketId)}
        isFavoriteMarket={!!favorite}
      />
      <AccordionContent>
        <MarketContent marketsData={marketsData} eventId={eventId} />
      </AccordionContent>
    </>
  );
};
