import * as Accordion from '@radix-ui/react-accordion';
import { styled } from 'stitches.config';

import { Box } from '~components/atoms/Box';
import { slideDown, slideUp } from '~utils/keyframes';

export const SmallJackpotDetailsWrapper = styled(Box, {
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: '$black',
  padding: '$1',
  borderRadius: '$12',
  gap: '$1',

  '@xs_sm': {
    padding: '$2 $4',
    borderRadius: 0,
  },
});

export const StyledJackpotAccordionRoot = styled(Accordion.Root, {
  display: 'flex',
  flexDirection: 'column',
  gap: '$1',
});

export const StyledJackpotAccordionTrigger = styled(Accordion.Trigger, {
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: '$black',
  border: 'none',
  gap: '$1',
  color: '$grayMedium',
  padding: '$2',
  cursor: 'pointer',
});

export const StyledJackpotAccordionContent = styled(Accordion.Content, {
  overflow: 'hidden',
  '&[data-state="open"]': {
    animation: `${slideDown} 200ms ease-in`,
  },
  '&[data-state="closed"]': {
    animation: `${slideUp} 75ms ease-out`,
  },
  '@md': {
    display: 'flex',
    flexDirection: 'column',
    gap: '$1',
  },
  '@xs_sm': {
    display: 'flex',
    flexDirection: 'column',
    gap: '$1',
  },
});
