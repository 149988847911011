import React, { useEffect, useMemo } from 'react';

import { useLazyGetJackpotsBetsHistory } from '~api/jackpot/jackpotQueries';
import { Box } from '~components/atoms/Box';
import { Loader } from '~components/atoms/Loader';
import { Text } from '~components/atoms/Typography';
import { NoBets } from '~components/molecules/BetslipHistorySection/NoBets';
import { useTranslation } from '~hooks/useTranslation';
import { useAppDispatch, useAppSelector } from '~store';
import {
  getJackpotsBetsHistory,
  setJackpotsBetsHistory,
  setSelectedJackpotHistoryId,
} from '~store/slices/betslipSlice';

import { JackpotBetListItem } from './JackpotBetListItem';
import { JackpotEventDetails } from './JackpotEventDetails';

export const JackpotsBetsHistoryMobile = () => {
  const { localized } = useTranslation();
  const dispatch = useAppDispatch();
  const jackpotsBetsHistory = useAppSelector(getJackpotsBetsHistory);
  const {
    lazyGetJackpotsBetsHistoryQuery,
    lazyGetJackpotsBetsHistoryIsLoading,
  } = useLazyGetJackpotsBetsHistory();

  useEffect(() => {
    loadJackpotsBetsHistory();

    return () => {
      dispatch(setSelectedJackpotHistoryId(null));
    };
  }, []);

  const loadJackpotsBetsHistory = async () => {
    const jackpotsBetsHistory =
      await lazyGetJackpotsBetsHistoryQuery().unwrap();

    dispatch(setJackpotsBetsHistory(jackpotsBetsHistory));
  };

  return useMemo(() => {
    if (lazyGetJackpotsBetsHistoryIsLoading) {
      return (
        <Box flexCol alignCenter gap={2} css={{ paddingTop: '32px' }}>
          <Loader css={{ color: '$grayMedium', width: '$6', height: '$6' }} />
          <Text color="grayMedium" level="12-20">
            {localized('betslipHistory.loading')}
          </Text>
        </Box>
      );
    }

    if (!jackpotsBetsHistory.length) {
      return <NoBets />;
    }

    return (
      <Box flexCol gap={1}>
        {jackpotsBetsHistory.map((jackpot) => (
          <JackpotBetListItem key={jackpot.id} jackpot={jackpot} />
        ))}
        <JackpotEventDetails />
      </Box>
    );
  }, [lazyGetJackpotsBetsHistoryIsLoading, jackpotsBetsHistory]);
};
