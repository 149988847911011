import React, { useEffect, useState } from 'react';

import { SportEventItems } from '~api/sportEvent/types';
import { Box } from '~components/atoms/Box';
import { MainPageMobileContent } from '~components/molecules/MainHighlights/MainPageMobileContent';
import {
  SPORT_GROUP_COMPONENTS,
  SPORT_TAB_GROUPS,
} from '~components/molecules/PrematchMenu/constants';
import { usePrematchMenu } from '~components/molecules/PrematchMenu/hooks/usePrematchMenu';
import { useSportMenuGroupsMobile } from '~components/molecules/PrematchMenu/hooks/useSportMenuGroupsMobile';
import { SearchBar } from '~components/molecules/PrematchMenu/PrematchMenu/SearchBar';
import { SearchResultList } from '~components/molecules/SearchEvents/SearchResultList';
import { useTranslation } from '~hooks/useTranslation';
import { AllSportIcon, GameIcon, UpcomingIcon } from '~icons';
import { useAppDispatch, useAppSelector } from '~store';
import {
  setActiveSportTabGroup,
  setSportTabGroups,
} from '~store/slices/mobileSlice';
import { SportTabGroups } from '~types/events';

import { SportMenuMobile } from '../SportMenu/SportMenuMobile';

export const MobilePrematchMenu = () => {
  useSportMenuGroupsMobile();
  const { localized } = useTranslation();
  const dispatch = useAppDispatch();
  const { dataLoaded } = useAppSelector((state) => state.prematchMenu);
  const { groups, shouldOpenTopTournaments } = useAppSelector(
    (state) => state.sportGroupsState,
  );
  const { isLoading } = usePrematchMenu();
  const {
    activeSportTabGroup: activeTabGroup = SPORT_TAB_GROUPS.ALL,
    isFavoriteSelected,
  } = useAppSelector((state) => state.mobileState);

  const [showSearchResults, setShowSearchResults] = useState(false);
  const [searchedItems, setSearchedItems] = useState<SportEventItems>([]);

  useEffect(() => {
    if (!shouldOpenTopTournaments) return;
    const resultGroupsArray: SportTabGroups = [];

    const topExists = groups.some(
      ({ name }) =>
        name === SPORT_GROUP_COMPONENTS.TOP_TOURNAMENT ||
        name === SPORT_GROUP_COMPONENTS.TOP_EVENT,
    );

    if (!dataLoaded) {
      resultGroupsArray.push({
        name: SPORT_TAB_GROUPS.TOP,
        icon: GameIcon,
        label: localized('sportTab.top'),
        id: 0,
      });
    } else {
      if (topExists) {
        resultGroupsArray.push({
          name: SPORT_TAB_GROUPS.TOP,
          icon: GameIcon,
          label: localized('sportTab.top'),
          id: 0,
        });
      }
    }

    resultGroupsArray.push({
      name: SPORT_TAB_GROUPS.UPCOMMING,
      icon: UpcomingIcon,
      label: localized('sportTab.upcoming'),
      id: 2,
    });

    resultGroupsArray.push({
      name: SPORT_TAB_GROUPS.ALL,
      icon: AllSportIcon,
      label: localized('sportTab.all'),
      id: 1,
    });

    if (resultGroupsArray.length && resultGroupsArray[0] && !activeTabGroup) {
      dispatch(setActiveSportTabGroup(resultGroupsArray[0].name));
    }

    dispatch(setSportTabGroups(resultGroupsArray));
  }, [groups, shouldOpenTopTournaments, dataLoaded]);

  return (
    <Box flexCol>
      <SearchBar
        onSearchedItemsChange={setSearchedItems}
        onShowSearchResultsChange={setShowSearchResults}
      />
      {showSearchResults ? (
        <SearchResultList
          isRelative
          hasResults={!!searchedItems.length}
          searchEvents={searchedItems}
        />
      ) : activeTabGroup === SPORT_TAB_GROUPS.UPCOMMING &&
        !isFavoriteSelected ? (
        <MainPageMobileContent />
      ) : (
        <SportMenuMobile isLoading={isLoading} />
      )}
    </Box>
  );
};
