import React, { useEffect, useState } from 'react';

import { BalanceChangeResponse, BetAmount } from '~api/betslip/types';
import { OddCrossedIndicator } from '~components/atoms/BetCoefficientIndicator';
import { Box } from '~components/atoms/Box';
import { Button } from '~components/atoms/Button';
import { Loader } from '~components/atoms/Loader';
import { NumberInput } from '~components/atoms/NumberInput';
import { Text } from '~components/atoms/Typography';
import { BetslipMultipleBonus } from '~components/molecules/Betslip/components/BetslipFooter/BetslipMultipleBonus';
import { betslipTextStyles } from '~components/molecules/Betslip/components/BetslipStakes/BetslipStakeMain';
import { CURRENCY_SYMBOLS } from '~constants/common';
import { useMedia } from '~hooks/useMedia';
import { useTranslation } from '~hooks/useTranslation';
import { DoubleChevronDown } from '~icons';
import { useAppDispatch, useAppSelector } from '~store';
import {
  setAdjustedBetAmount,
  setBetslipChanged,
} from '~store/slices/betslipSlice';
import { Market, Selection } from '~types/events';
import { getBetTotalAmount } from '~utils/betslip';
import { commaSeparatedNumber } from '~utils/numberUtils';
import { isEmptyObject } from '~utils/objectHelpers';

import { DEFAULT_BALANCE_CHANGE } from '../../constants';
import { BetslipBalancesPopover } from '../BetslipFooter/BetslipBalancesPopover';

interface BetslipStakeAmountProps {
  index: number;
  amount: BetAmount;
  selectionData: Selection;
  marketData: Market;
  quickBet?: boolean;
  onAmountChange: ({ id, amount }: { id: string; amount: BetAmount }) => void;
}

export const BetslipStakeAmount = ({
  index,
  quickBet,
  selectionData,
  amount,
  onAmountChange,
}: BetslipStakeAmountProps) => {
  const { id, odd = 0 } = selectionData;
  const dispatch = useAppDispatch();
  const { localized } = useTranslation();
  const { isMobileOrTablet } = useMedia();
  const { events, balanceChangesOnBetData, isBalanceChangesLoading } =
    useAppSelector((state) => state.betslip);
  const { isUserLoggedIn } = useAppSelector((state) => state.userState);
  const { currency, maxWinAmount, separateBonusBalance } = useAppSelector(
    (state) => state.settings,
  );
  const { balanceChanges = [] } =
    balanceChangesOnBetData || ({} as BalanceChangeResponse);

  const { winBoostAmount, winBoostDescriptions = [] } =
    balanceChanges[index] || DEFAULT_BALANCE_CHANGE;
  const [possibleWin, setPossibleWin] = useState(0);

  const isOnlyOneBet = events.length === 1;
  const totalStake = getBetTotalAmount(amount, separateBonusBalance);

  const handleOddChange = () => {
    dispatch(setBetslipChanged(id));
  };

  useEffect(() => {
    setPossibleWin(0);
    if (balanceChangesOnBetData) {
      const balanceChange = balanceChangesOnBetData.balanceChanges.find(
        (item) => item.selectionId === id,
      );

      if (balanceChange) {
        setPossibleWin(balanceChange.totalPossibleWin);

        return;
      }
    }
  }, [amount, balanceChangesOnBetData]);

  return (
    <Box flexCol fullWidth gap={isMobileOrTablet ? 1 : 2}>
      <Box
        flexRow
        justifyCenter
        alignCenter
        gap={2}
        css={{
          '@xs_sm': {
            mt: '$3',
          },
        }}
      >
        {separateBonusBalance ? (
          <Box fullWidth flexRow gap={3} css={{ minWidth: 0 }}>
            <Box fullWidth flexRow alignCenter gap={1} css={{ minWidth: 0 }}>
              <Text css={betslipTextStyles}>
                {localized('betslip.cash') + ':'}
              </Text>
              <NumberInput
                withoutActions
                onChange={(value) =>
                  onAmountChange({
                    id: id!,
                    amount: { ...amount, main: value },
                  })
                }
                value={amount?.main?.toString() || ''}
              />
            </Box>

            <Box fullWidth flexRow alignCenter gap={1} css={{ minWidth: 0 }}>
              <Text css={betslipTextStyles}>
                {localized('betslip.freeBet') + ':'}
              </Text>
              <NumberInput
                withoutActions
                onChange={(value) =>
                  onAmountChange({
                    id: id!,
                    amount: { ...amount, bonus: value },
                  })
                }
                value={amount?.bonus?.toString() || ''}
              />
            </Box>
          </Box>
        ) : (
          <NumberInput
            onChange={(value) =>
              onAmountChange({ id: id!, amount: { main: value } })
            }
            value={amount?.main?.toString() || ''}
          />
        )}

        {index === 0 && !isOnlyOneBet && (
          <Button
            iconButton
            type={'button'}
            css={{
              color: '$grayMedium',
              background: '$gray',
              p: '$3 $2',
              '@xs_sm': {
                fontSize: '$xxs',
                height: '32px',
                p: '$2 $2',
                lineHeight: '$20',
              },
            }}
            onClick={() => dispatch(setAdjustedBetAmount(amount))}
          >
            <DoubleChevronDown width={16} height={16} />
          </Button>
        )}
      </Box>
      <Box
        flexRow
        justifyContentBetween
        alignCenter
        css={{ mt: isMobileOrTablet ? '$1' : 'unset' }}
      >
        <Box flexRow alignCenter gap={1} css={{ color: '$grayMedium' }}>
          <Text level={isMobileOrTablet ? '11-16' : '13-16'}>
            {localized('betslip.multipleFooterValue.bet')}
          </Text>
          {!isEmptyObject(amount) && isUserLoggedIn && (
            <BetslipBalancesPopover index={index} />
          )}
        </Box>
        <Text level={isMobileOrTablet ? '11-16' : '13-16'}>
          {commaSeparatedNumber(
            maxWinAmount < totalStake ? maxWinAmount : totalStake,
          )}{' '}
          {CURRENCY_SYMBOLS[currency]}
        </Text>
      </Box>
      {!quickBet && (
        <Box
          flexRow
          justifyContentBetween
          alignCenter
          css={{
            '@xs_sm': {
              fontSize: '$xxs',
            },
          }}
        >
          <Text level={isMobileOrTablet ? '11-16' : '13-16'}>
            {localized('betslip.multipleFooterValue.odds')}
          </Text>
          <OddCrossedIndicator
            odd={odd}
            sidePrevValue
            onOddChange={handleOddChange}
          />
        </Box>
      )}
      <Box flexRow justifyContentBetween alignCenter>
        <Text underline level={isMobileOrTablet ? '11-16' : '13-16'}>
          {localized('betslip.multipleFooterValue.possibleWin')}
        </Text>
        {isBalanceChangesLoading ? (
          <Loader
            css={{
              width: '13px',
              height: '13px',
              color: '$yellow',
              display: 'flex',
              alignItems: 'center',
            }}
          />
        ) : (
          <Text
            color="yellow"
            underline
            level={isMobileOrTablet ? '11-16' : '13-16'}
          >
            {`${commaSeparatedNumber(possibleWin) || '0'} ${CURRENCY_SYMBOLS[currency]}`}
          </Text>
        )}
      </Box>
      {!!+winBoostAmount && (
        <Box flexRow justifyContentBetween alignCenter>
          <Box
            css={{ color: '$grayMedium' }}
            flexRow
            justifyContentStart
            alignCenter
            gap={2}
          >
            <Text underline level={isMobileOrTablet ? '11-16' : '13-16'}>
              {localized('betslip.bonus')}
            </Text>
            {winBoostDescriptions?.length ? (
              <BetslipMultipleBonus
                multipleBetBonusDescriptions={winBoostDescriptions}
              />
            ) : null}
          </Box>
          <Text
            color="yellow"
            underline
            level={isMobileOrTablet ? '11-16' : '13-16'}
          >
            {winBoostAmount} {CURRENCY_SYMBOLS[currency]}
          </Text>
        </Box>
      )}
    </Box>
  );
};
