import { styled } from 'stitches.config';

import { Box } from '~components/atoms/Box';
import { Text } from '~components/atoms/Typography';
import { CURRENCY_SYMBOLS } from '~constants/common';
import { useAppSelector } from '~store';
import { JackpotLine } from '~types/jackpot';
import { formatNumberToStringWithSpaces } from '~utils/numberUtils';

const WinningLinesWrapper = styled(Box, {
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  gap: '$1',

  '@lg_xl': {
    maxWidth: '380px',
  },
  '@md': {
    gap: '$2',
    padding: '$2',
  },
  '@xs_sm': {
    gap: '$2',
    padding: '$2',
  },
});

const WinningLineWrapper = styled(Box, {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  gap: '$2',
  width: '100%',
  height: '28px',
  background: 'rgba(10, 15, 20, 0.70)',
  borderRadius: '6px',
  padding: '0 $3',
  '@md': {
    height: '36px',
    background: '$blackDarker',
  },
  '@xs_sm': {
    height: '36px',
    background: '$blackDarker',
  },
});

const WinningLineText = styled(Text, {
  fontSize: '14px',
  lineHeight: '20px',
  '@xs_sm': {
    fontSize: '12px',
    lineHeight: '20px',
  },
});

interface WinningLinesProps {
  lines: JackpotLine[];
}

export const WinningLines = ({ lines }: WinningLinesProps) => {
  const { currency } = useAppSelector((state) => state.settings);

  // Sorted possible winning lines from the biggest to the smallest
  const sortedLines = [...lines].sort((a, b) => b.line - a.line);
  const currencySymbol = CURRENCY_SYMBOLS[currency];

  return (
    <WinningLinesWrapper>
      {sortedLines.map(({ line, winners, amountPerWinner }) => (
        <WinningLineWrapper key={line}>
          <WinningLineText>{`${line} correct:`}</WinningLineText>
          <Box flexRow gap={1}>
            <WinningLineText>{`${winners} winner(s),`}</WinningLineText>
            <WinningLineText
              css={{ color: '$yellow' }}
            >{`${formatNumberToStringWithSpaces(
              amountPerWinner,
            )} ${currencySymbol}`}</WinningLineText>
          </Box>
        </WinningLineWrapper>
      ))}
    </WinningLinesWrapper>
  );
};
