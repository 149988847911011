import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface WebsiteSettings {
  timezone: string | null;
  odds: string;
  language: string;
}

const initialState: WebsiteSettings = {
  timezone: null,
  odds: 'decimal',
  language: 'en',
};

export const websiteSettingsSlice = createSlice({
  name: 'websiteSettings',
  initialState,
  reducers: {
    setTimezone: (state, action: PayloadAction<string>) => {
      state.timezone = action.payload;
    },
    setOdds: (state, action: PayloadAction<string>) => {
      state.odds = action.payload;
    },
    setLanguage: (state, action: PayloadAction<string>) => {
      state.language = action.payload;
    },
  },
});

export const { setTimezone, setOdds, setLanguage } =
  websiteSettingsSlice.actions;

export default websiteSettingsSlice.reducer;
