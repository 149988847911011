import React from 'react';

import { useMedia } from '~hooks/useMedia';

import { ContentLarge, ContentMedium, SmallScreenJackpotList } from './index';

export const Content = () => {
  const { isMobileOrTablet, isLaptop, isLargeDesktopOrDesktop } = useMedia();

  if (isLargeDesktopOrDesktop) {
    return <ContentLarge />;
  }

  if (isLaptop) {
    return <ContentMedium />;
  }

  if (isMobileOrTablet) {
    return <SmallScreenJackpotList />;
  }
};
