import React, { useEffect, useState } from 'react';

import {
  useLazyGetActiveJackpots,
  useLazyGetSettledJackpots,
} from '~api/jackpot/jackpotQueries';
import { ErrorPage } from '~components/atoms/ErrorPage/ErrorPage';
import { contentMap } from '~components/molecules/Jackpot/constants';
import { DesktopPage } from '~components/molecules/Jackpot/DesktopPage';
import { JACKPOT_MENU_ITEMS } from '~components/molecules/Jackpot/Menu';
import { MobilePage } from '~components/molecules/Jackpot/MobilePage';
import { QUERY_PARAMS } from '~constants/common';
import { useMedia } from '~hooks/useMedia';
import { useQueryParams } from '~hooks/useQueryParams';
import { useRouterQuery } from '~hooks/useRouterQuery';
import { useTranslation } from '~hooks/useTranslation';
import { useAppDispatch, useAppSelector } from '~store';
import {
  setActiveJackpots,
  setSelectedJackpot,
  setSettledJackpots,
} from '~store/slices/jackpotSlice';
import { QueryParams } from '~utils/url';

export const JackpotPage = () => {
  const { isMobileOrTablet } = useMedia();
  const { localized } = useTranslation();
  const dispatch = useAppDispatch();
  const { menu, jackpotId } = useQueryParams();
  const { updateQueryParams } = useRouterQuery();
  const [isLoading, setIsLoading] = useState(true);
  const { lazyGetActiveJackpotsQuery, lazyGetActiveJackpotsError } =
    useLazyGetActiveJackpots();
  const { lazyGetSettledJackpotsQuery, lazyGetSettledJackpotsError } =
    useLazyGetSettledJackpots();
  const { language } = useAppSelector((state) => state.websiteSettings);

  useEffect(() => {
    loadJackpots();
  }, [language]);

  const loadJackpots = async () => {
    const activeJackpots = await lazyGetActiveJackpotsQuery().unwrap();
    const settledJackpots = await lazyGetSettledJackpotsQuery().unwrap();

    dispatch(setActiveJackpots(activeJackpots));
    dispatch(setSettledJackpots(settledJackpots));

    setIsLoading(false);

    // If no active jackpots
    if (activeJackpots.length === 0 && settledJackpots.length > 0) {
      return updateQueryParams(
        {
          menu: JACKPOT_MENU_ITEMS.SETTLED_JACKPOTS,
        } as QueryParams<typeof QUERY_PARAMS>,
        true,
      );
    } else if (activeJackpots.length === 0 && settledJackpots.length === 0) {
      return updateQueryParams(
        {
          menu: JACKPOT_MENU_ITEMS.RULES,
        } as QueryParams<typeof QUERY_PARAMS>,
        true,
      );
    }

    // Set active jackpot page if no jackpots selected
    if (isMobileOrTablet && !menu) {
      return updateQueryParams(
        {
          menu: JACKPOT_MENU_ITEMS.ACTIVE_JACKPOTS,
        } as QueryParams<typeof QUERY_PARAMS>,
        true,
      );
    }

    // Set default selected jackpot
    if (
      !isMobileOrTablet &&
      (!menu || (!jackpotId && menu === JACKPOT_MENU_ITEMS.ACTIVE_JACKPOTS))
    ) {
      dispatch(setSelectedJackpot(activeJackpots[0]));

      return updateQueryParams(
        {
          jackpotId: activeJackpots[0]?.id,
          menu: JACKPOT_MENU_ITEMS.ACTIVE_JACKPOTS,
        } as QueryParams<typeof QUERY_PARAMS>,
        true,
      );
    }

    // Set active jackpot page if no jackpots selected
    if (isMobileOrTablet && !menu) {
      return updateQueryParams(
        {
          menu: JACKPOT_MENU_ITEMS.ACTIVE_JACKPOTS,
        } as QueryParams<typeof QUERY_PARAMS>,
        true,
      );
    }

    // Redirect to rules, if menu parameter is incorrect
    if (!contentMap[menu as JACKPOT_MENU_ITEMS]) {
      return updateQueryParams(
        {
          menu: JACKPOT_MENU_ITEMS.RULES,
        } as QueryParams<typeof QUERY_PARAMS>,
        true,
      );
    }
  };

  if (lazyGetActiveJackpotsError || lazyGetSettledJackpotsError) {
    return <ErrorPage title={localized('jackpot.errors.errorLoading')} />;
  }

  return isMobileOrTablet ? (
    <MobilePage isLoading={isLoading} />
  ) : (
    <DesktopPage isLoading={isLoading} />
  );
};
