import { SportMainMarkets } from '~api/market/types';
import {
  InPlayMenuSports,
  InPlaySportEvents,
  InPlaySportEventsGroupedByDate,
} from '~api/sportEvent/types';
import { getMainMarketData } from '~components/molecules/PrematchEvents/helpers/getMainMarketsData';
import { EVENT_STATUS } from '~constants/common';
import { Market, StoreSportEvent, StoreSportEvents } from '~types/events';
import { deepClone } from '~utils/deepClone';
import { formatDateTime, TIME_FORMATS } from '~utils/formatDateTime';

type ExtractedEvents = {
  events: InPlaySportEvents;
  countryId: number;
  leagueId: number;
  countryName: string;
  leagueName: string;
};

const extractEventsFromResponse = (data: InPlayMenuSports): ExtractedEvents => {
  const defaultData = {
    events: [],
    countryId: 0,
    leagueId: 0,
    countryName: '',
    leagueName: '',
  };

  if (!data[0]) {
    return defaultData;
  }

  const [firstData] = data;
  const { countries } = firstData || {};

  if (!countries[0]) {
    return defaultData;
  }

  const [country] = countries;
  const { leagues } = country || {};

  if (!leagues[0]) {
    return defaultData;
  }

  const [league] = leagues;
  const { events } = league || {};

  return {
    events,
    countryId: country.id,
    leagueId: league.id,
    countryName: country.name,
    leagueName: league.name,
  };
};

const groupByDayWithTime = (
  data: InPlaySportEvents,
): InPlaySportEventsGroupedByDate => {
  const grouped: Record<string, InPlaySportEvents> = {};

  for (const event of data) {
    const dateTimeKey = formatDateTime(event.startDate, TIME_FORMATS.DATE);

    const eventWithTime = {
      ...event,
      time: formatDateTime(event.startDate, TIME_FORMATS.TIME_12H),
    };

    const eventArray = grouped[dateTimeKey];

    if (eventArray) {
      eventArray.push(eventWithTime);
    } else {
      grouped[dateTimeKey] = [eventWithTime];
    }
  }

  return Object.entries(grouped).map(([date, events]) => ({
    date,
    events: events.map(({ id, markets }) => ({
      eventId: id || '',
      marketIds: markets.map(({ id }) => id),
    })),
  }));
};

export const processData = (
  data: InPlayMenuSports,
  mainMarkets: SportMainMarkets,
  sportId: number,
): {
  preparedData: InPlaySportEventsGroupedByDate;
  allEvents: StoreSportEvents;
  allMarkets: Market[];
} => {
  const allEvents: StoreSportEvents = [];
  const allMarkets: Market[] = [];

  const {
    events: leagueEvents,
    leagueName,
    leagueId,
    countryName,
    countryId,
  } = extractEventsFromResponse(data);

  const events = deepClone(leagueEvents) as InPlaySportEvents;

  const { relevantSport } = getMainMarketData(sportId, mainMarkets);

  events.forEach((event) => {
    const { markets, ...eventData } = event;

    if (!relevantSport) {
      allEvents.push({
        ...eventData,
        status: EVENT_STATUS.ACTIVE,
        marketIds: markets.map(({ id }) => id),
        leagueId,
        countryId,
        leagueName,
        countryName,
        sportId,
        sportName: mainMarkets.find(({ id }) => id === sportId)?.name || '',
      } as StoreSportEvent);
      markets.forEach((market) => {
        allMarkets.push({ ...market });
      });

      return;
    }

    event.markets.forEach((market) => {
      allMarkets.push(market);
    });

    allEvents.push({
      ...event,
      status: EVENT_STATUS.ACTIVE,
      sportId,
      sportName: mainMarkets.find(({ id }) => id === sportId)?.name || '',
      leagueId,
      countryId,
      leagueName,
      countryName,
      marketIds: event.markets.map(({ id }) => id),
    } as StoreSportEvent);
  });

  return { preparedData: groupByDayWithTime(events), allEvents, allMarkets };
};
