import { BANNER_GROUP_NAMES, BannerList } from '~components/atoms/BannerList';
import { LeagueEventsMenuMobile } from '~components/molecules/PrematchEvents/components/LeagueEventsMenuMobile';
import { PrematchEventMarkets } from '~components/molecules/PrematchEvents/components/PrematchEventMarkets';
import { MobilePrematchMenu } from '~components/molecules/PrematchMenu';
import { useQueryParams } from '~hooks/useQueryParams';
import { SPORT_GROUPS } from '~types/sportGroups';

export enum SPORT_TAB_CONTENTS {
  PREMATCH_MENU = 'prematchMenu',
  PREMATCH_EVENTS = 'prematchEvents',
  PREMATCH_EVENT_MARKETS = 'prematchEventMarkets',
}

export const SportTab = () => {
  const { leagueId, eventId, group } = useQueryParams();

  const content = (
    <>
      <BannerList names={[BANNER_GROUP_NAMES.HEADER]} />
      <MobilePrematchMenu />
    </>
  );

  if (!eventId && group === SPORT_GROUPS.TOP_EVENTS) {
    return content;
  }

  if (eventId) {
    return <PrematchEventMarkets />;
  }

  if (leagueId) {
    return <LeagueEventsMenuMobile />;
  }

  return content;
};
