import { QUERY_PARAMS, QueryParameters } from '~constants/common';
import {
  PreparedCountry,
  PreparedLeague,
  PreparedSport,
  PreparedSportData,
} from '~types/events';
import { getItemFromArrayById } from '~utils/arrayHelpers';
import { QueryParams } from '~utils/url';

const getActiveSport = (
  sports: PreparedSportData,
  sportId: string | undefined,
) => {
  return getItemFromArrayById(sports, sportId ? parseInt(sportId) : sportId);
};

const getActiveCountry = (
  countries: PreparedCountry[],
  countryId: string | undefined,
) => {
  return getItemFromArrayById(
    countries,
    countryId ? parseInt(countryId) : countryId,
  );
};

const getActiveLeague = (
  leagues: PreparedLeague[],
  leagueId: string | undefined,
) => {
  return getItemFromArrayById(
    leagues,
    leagueId ? parseInt(leagueId) : leagueId,
  );
};

export const resolveRedirectPath = (
  liveData: PreparedSportData,
  queryParams: QueryParameters,
): QueryParams<typeof QUERY_PARAMS> => {
  const { sportId, countryId, leagueId, eventId } = queryParams;
  const activeSport: PreparedSport = getActiveSport(liveData, sportId)!;
  const activeCountry: PreparedCountry = getActiveCountry(
    activeSport?.countries || [],
    countryId,
  )!;
  const activeLeague: PreparedLeague = getActiveLeague(
    activeCountry?.leagues || [],
    leagueId,
  )!;
  const activeEventId: string =
    (activeLeague?.events || []).find((item) => item === eventId)! ||
    activeLeague.events[0]!;

  return {
    [QUERY_PARAMS.SPORT_ID]: activeSport?.id,
    [QUERY_PARAMS.COUNTRY_ID]: activeCountry?.id,
    [QUERY_PARAMS.LEAGUE_ID]: activeLeague?.id,
    [QUERY_PARAMS.EVENT_ID]: activeEventId,
  } as QueryParams<typeof QUERY_PARAMS>;
};

export const getAllLiveEventsCount = (
  inPlayMenuSports: PreparedSportData | undefined,
) => {
  if (!inPlayMenuSports) {
    return 0;
  }

  return inPlayMenuSports.flatMap((sport) =>
    (sport.countries || []).flatMap((country) =>
      (country.leagues || []).flatMap((league) => league.events),
    ),
  ).length;
};
