import { memo } from 'react';

import { Box } from '~components/atoms/Box';
import { StyledBoardComponentWrapper } from '~components/atoms/StyledBoardComponentWrapper';
import { Market } from '~types/events';
import { isEmptyObject } from '~utils/objectHelpers';

import { InplayEventMarkets } from './components/InplayEventMarkets';
import { useInplayMarketEventGroups } from './hooks/useInplayMarketEventGroups';
import { EmptyMarkets } from './EmptyMarkets';
import MarketGroups from './MarketGroups';

interface InplayMarketEventGroupsProps {
  marketGroups: Record<string, Market[]>;
  isEventLoaded: boolean;
}
export const InplayMarketEventGroups = memo(
  ({ marketGroups, isEventLoaded }: InplayMarketEventGroupsProps) => {
    const {
      eventId,
      groupsToShow,
      selectedGroupId,
      isMobileOrTablet,
      setSelectedGroupId,
      getSelectedMarketData,
    } = useInplayMarketEventGroups(marketGroups);
    const ongoingMarkets = getSelectedMarketData();

    if (!ongoingMarkets || !isEventLoaded) {
      return null;
    }

    if (isEmptyObject(marketGroups)) {
      return <EmptyMarkets />;
    }

    return (
      <Box
        css={{
          backgroundColor: '$inplayMarketEventGroupsBgColor',
          borderRadius: isMobileOrTablet ? '0' : '$12',
        }}
      >
        {groupsToShow && (
          <MarketGroups
            groupsToShow={groupsToShow}
            selectedGroupId={selectedGroupId}
            setSelectedGroupId={setSelectedGroupId}
          />
        )}
        {ongoingMarkets && (
          <StyledBoardComponentWrapper
            css={{
              p: '$1',
              borderRadius: '$12',
              border: 'none',
              backgroundColor: '$inplayMarketEventGroupsBgColor',
            }}
          >
            <InplayEventMarkets
              marketGroups={ongoingMarkets}
              eventId={eventId || ''}
              isEventLoaded={isEventLoaded}
              isMobileOrTablet={isMobileOrTablet}
            />
          </StyledBoardComponentWrapper>
        )}
      </Box>
    );
  },
);
