import { MENU_ICON_TYPES } from '~components/organisms/Header/useHeaderMenu';

export const getIsMenuItemActive = (
  item: MENU_ICON_TYPES,
  url: string,
  menuQuery: string = '',
): boolean => {
  switch (item) {
    case MENU_ICON_TYPES.Inplay:
    case MENU_ICON_TYPES.Sports: {
      return !!(menuQuery.length && url.includes(menuQuery));
    }

    default: {
      return window.location.href.includes(url);
    }
  }
};
