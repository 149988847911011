import React from 'react';
import * as Accordion from '@radix-ui/react-accordion';

import { Text } from '~components/atoms/Typography';
import { Banner } from '~components/molecules/Jackpot/Settled';
import {
  SmallJackpotDetailsWrapper,
  StyledJackpotAccordionTrigger,
} from '~components/molecules/Jackpot/styled.components';
import { useTranslation } from '~hooks/useTranslation';
import { ChevronDownIcon, ChevronUpIcon } from '~icons';
import { Jackpot } from '~types/jackpot';

interface AccordionWrapperProps {
  isJackpotOpened: boolean;
  settledJackpot: Jackpot;
  children: React.ReactNode;
}

export const AccordionWrapper = ({
  isJackpotOpened,
  settledJackpot,
  children,
}: AccordionWrapperProps) => {
  const { localized } = useTranslation();

  return (
    <Accordion.Item value={settledJackpot.id}>
      <SmallJackpotDetailsWrapper>
        <Banner key={settledJackpot.id} jackpot={settledJackpot} />
        {children}
        <StyledJackpotAccordionTrigger>
          <Text level="14-20" color="grayMedium">
            {localized(
              `${
                isJackpotOpened ? 'jackpot.hideDetails' : 'jackpot.showDetails'
              }`,
            )}
          </Text>
          {isJackpotOpened ? <ChevronUpIcon /> : <ChevronDownIcon />}
        </StyledJackpotAccordionTrigger>
      </SmallJackpotDetailsWrapper>
    </Accordion.Item>
  );
};
