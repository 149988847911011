import { useCallback, useEffect } from 'react';

import { useMedia } from '~hooks/useMedia';
import { useQueryParams } from '~hooks/useQueryParams';
import { useAppDispatch, useAppSelector } from '~store';
import { addOpenedSports, setOpenedSports } from '~store/slices/liveMenuSlice';
import { PreparedCountry } from '~types/events';

import { MULTI_ACCORDION_ROLES } from '../../MultiAccordion';

export const useLiveSportMenu = () => {
  const { isMobileOrTablet } = useMedia();
  const dispatch = useAppDispatch();
  const menuRole = MULTI_ACCORDION_ROLES.PARENT;
  const { sportId } = useQueryParams();
  const { openedSports, isLiveMenuLoaded, addedSportId } = useAppSelector(
    (state) => state.liveMenu,
  );

  // useLoadLiveTopGames();

  const handleOnValueChange = useCallback((e: string[]) => {
    dispatch(setOpenedSports(e));
  }, []);

  useEffect(() => {
    if (sportId && isLiveMenuLoaded) {
      dispatch(addOpenedSports(sportId));
    }
  }, [sportId, isLiveMenuLoaded]);

  const getSportEventsCount = useCallback(
    (countries: PreparedCountry[] | undefined) => {
      if (!countries) {
        return 0;
      }

      return countries.flatMap((country) =>
        country.leagues.flatMap((league) => league.events),
      ).length;
    },
    [],
  );

  return {
    isLoading: !!addedSportId,
    menuRole,
    openedSports,
    isMobileOrTablet,
    handleOnValueChange,
    getSportEventsCount,
  };
};
