import { useCallback, useEffect, useState } from 'react';

import { useGetLiveEvents } from '~api/live/liveMutations';
import { useLazyGetLive } from '~api/live/liveQueries';
import { useLazyGetFavoriteEventInplay } from '~api/sportEvent/sportEventQueries';
import { LiveData } from '~api/sportEvent/types';
import { setCounts } from '~features/sportsMenu/sportsMenuSlice';
import { useMedia } from '~hooks/useMedia';
import { useQueryParams } from '~hooks/useQueryParams';
import { useReconnectedRequest } from '~hooks/useReconnectedRequest';
import { useRouterQuery } from '~hooks/useRouterQuery';
import { useAppDispatch, useAppSelector } from '~store';
import { addEventsWithMarkets, replaceEvents } from '~store/slices/eventsSlice';
import { setFavoriteInplayEvents } from '~store/slices/liveGroupsSlice';
import {
  resetAddedLeagueId,
  resetAddedSportId,
  resetLiveMenu,
  resetUpdatedSportMarket,
  setLiveMenuSports,
  setLoadingSportId,
} from '~store/slices/liveMenuSlice';
import { PreparedSportData, SportEvents } from '~types/events';
import { SPORT_GROUPS } from '~types/sportGroups';

import {
  getMainMarketId,
  prepareRespData,
  reduceEventsByLeague,
  reduceEventsBySport,
  reduceOpenedSportEventsBySport,
} from '../utils/eventsParsing';
import { getAllLiveEventsCount, resolveRedirectPath } from '../utils/helpers';

export const useLiveMenu = () => {
  const dispatch = useAppDispatch();
  const { updateQueryParams } = useRouterQuery();
  const search = useQueryParams();
  const { group } = search;
  const { isMobileOrTablet } = useMedia();

  const { isUserLoggedIn } = useAppSelector((state) => state.userState);
  const {
    liveMenuSports: liveMenu,
    addedLeagueId,
    addedSportId,
    mainMarketsSelected,
    updatedSportMarket,
    openedCountryLeagues,
    isLiveMenuLoaded,
  } = useAppSelector((state) => state.liveMenu);
  const [isLoaded, setIsLoaded] = useState(isLiveMenuLoaded);
  const [isLoading, setIsLoading] = useState(false);
  const { mainMarkets } = useAppSelector((state) => state.mainMarkets);

  const { lazyGetLiveData, lazyGetLiveQuery, lazyGetLiveError } =
    useLazyGetLive();
  const { lazyGetFavoriteEventInplayQuery } = useLazyGetFavoriteEventInplay();
  const { getLiveEventsMutation } = useGetLiveEvents();

  useReconnectedRequest(lazyGetLiveQuery);

  const { prematch: prematchCount = 0 } = lazyGetLiveData || {};

  const loadLiveData = useCallback(async () => {
    try {
      const data: LiveData = await lazyGetLiveQuery().unwrap();

      if (!data?.events?.length) return;
      const { preparedData, allMarkets, allEvents } = prepareRespData(data);

      dispatch(setLiveMenuSports(preparedData as PreparedSportData));
      dispatch(
        addEventsWithMarkets({ events: allEvents, markets: allMarkets }),
      );
      setIsLoaded(true);
    } catch (e) {
      console.warn(e);
    }
  }, []);

  const loadFavoriteInplayEvents = async () => {
    if (!isUserLoggedIn) return;
    try {
      const favoriteInplayEvents =
        await lazyGetFavoriteEventInplayQuery().unwrap();

      dispatch(setFavoriteInplayEvents(favoriteInplayEvents));
    } catch (e) {
      console.error('Failed to load favorite inplay events: ', e);
    }
  };

  useEffect(() => {
    if (isLoaded) return;
    loadLiveData();
    loadFavoriteInplayEvents();
  }, [isLoaded]);

  useEffect(() => {
    return () => {
      if (!isMobileOrTablet) {
        setIsLoaded(false);
        dispatch(resetLiveMenu());
        // todo: fix right clean method
        // dispatch(resetMapsState());
      }
    };
  }, []);

  useEffect(() => {
    if (isMobileOrTablet && addedSportId) {
      dispatch(resetAddedSportId());

      return;
    }

    if (addedSportId === null || isLoading) return;

    setIsLoading(true);

    const events = reduceEventsBySport({
      liveMenu,
      openedCountryLeagues,
      sportId: parseInt(addedSportId),
    });

    if (!events.length) return;
    const numberSportId = parseInt(addedSportId);
    const marketId =
      mainMarketsSelected[numberSportId] ||
      getMainMarketId({ mainMarkets, sportId: numberSportId });

    getLiveEventsMutation([
      {
        ids: events,
        marketId,
      },
    ]).then((response) => {
      if ('data' in response) {
        dispatch(replaceEvents(response.data as SportEvents));
        dispatch(resetAddedSportId());
        setIsLoading(false);
      }
    });
  }, [
    addedSportId,
    liveMenu,
    mainMarkets,
    openedCountryLeagues,
    isMobileOrTablet,
    mainMarketsSelected,
  ]);

  useEffect(() => {
    if (isMobileOrTablet && addedLeagueId) {
      dispatch(resetAddedLeagueId());

      return;
    }

    if (addedLeagueId === null || isLoading) return;
    setIsLoading(true);
    const { sportId, events } = reduceEventsByLeague(liveMenu, addedLeagueId);

    if (!events.length) return;
    const marketId =
      mainMarketsSelected[sportId] || getMainMarketId({ mainMarkets, sportId });

    getLiveEventsMutation([
      {
        ids: events,
        marketId,
      },
    ]).then((response) => {
      if ('data' in response) {
        dispatch(replaceEvents(response.data as SportEvents));
        dispatch(resetAddedLeagueId());
        setIsLoading(false);
      }
    });
  }, [addedLeagueId, liveMenu, mainMarkets, mainMarketsSelected]);

  useEffect(() => {
    if (updatedSportMarket) {
      const { sportId, marketId } = updatedSportMarket;

      dispatch(resetUpdatedSportMarket());

      const eventIds = reduceOpenedSportEventsBySport({
        liveMenu,
        sportId,
        openedCountryLeagues,
      });

      //todo: fix right clean method

      //const { eventId } = search;

      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      //dispatch(resetMapsState({ eventIds, marketId: prevMarketId, eventId }));

      if (!eventIds.length) return;

      getLiveEventsMutation([
        {
          ids: eventIds,
          marketId,
        },
      ]).then((response) => {
        if ('data' in response) {
          dispatch(replaceEvents(response.data as SportEvents));
          dispatch(setLoadingSportId(null));
        }
      });
    }
  }, [liveMenu, mainMarkets, updatedSportMarket, openedCountryLeagues, search]);

  useEffect(() => {
    const inPlayEventsCount = getAllLiveEventsCount(liveMenu);

    if (inPlayEventsCount && prematchCount) {
      dispatch(
        setCounts({
          inplay: inPlayEventsCount,
          prematch: prematchCount ? prematchCount : 0,
        }),
      );
    }
  }, [liveMenu]);

  useEffect(() => {
    if (
      group !== SPORT_GROUPS.TOP_EVENTS &&
      liveMenu.length &&
      isLoaded &&
      !isMobileOrTablet
    ) {
      updateQueryParams(resolveRedirectPath(liveMenu, search));
    }
  }, [liveMenu, isLoaded, isMobileOrTablet, group]);

  return {
    liveSports: liveMenu,
    lazyGetLiveError,
    isLoaded,
    isLoading,
  };
};
