import React, { useEffect } from 'react';

import { Box } from '~components/atoms/Box';
import { JACKPOT_MENU_ITEMS } from '~components/molecules/Jackpot/Menu';
import { MenuListItem } from '~components/molecules/Jackpot/MenuList/MenuListItem';
import { QUERY_PARAMS } from '~constants/common';
import { useQueryParams } from '~hooks/useQueryParams';
import { useRouterQuery } from '~hooks/useRouterQuery';
import { useAppDispatch } from '~store';
import { clearJackpotMessages } from '~store/slices/jackpotSlice';
import { Jackpots } from '~types/jackpot';
import { QueryParams } from '~utils/url';

interface JackpotMenuListProps {
  jackpots: Jackpots;
}

export const MenuList = ({ jackpots }: JackpotMenuListProps) => {
  const dispatch = useAppDispatch();
  const { jackpotId, menu } = useQueryParams();
  const { updateQueryParams } = useRouterQuery();

  useEffect(() => {
    if (!menu && !jackpotId && jackpots.length > 0) {
      updateQueryParams(
        {
          jackpotId: jackpots[0]?.id,
        } as QueryParams<typeof QUERY_PARAMS>,
        true,
      );
    }
  }, [jackpotId]);

  const handleActiveJackpotChange = (jackpotId: string) => {
    dispatch(clearJackpotMessages());
    updateQueryParams(
      {
        jackpotId,
        menu: JACKPOT_MENU_ITEMS.ACTIVE_JACKPOTS,
      } as QueryParams<typeof QUERY_PARAMS>,
      true,
    );
  };

  return (
    <Box
      css={{
        backgroundColor: '$black',
        borderRadius: '$8',
      }}
    >
      <Box flexCol css={{ backgroundColor: '$black', borderRadius: '$8' }}>
        {jackpots.map((jackpot, index) => {
          const isActive = jackpotId === jackpot.id;
          const isFirst = index === 0;
          const isLast = index === jackpots.length - 1;
          const isNotLast = index !== jackpots.length - 1;

          return (
            <Box
              key={jackpot.id}
              fullWidth
              flexRow
              alignCenter
              justifyContentBetween
              css={{
                backgroundColor: '$black',
                padding: '$1',
                borderBottom: isNotLast ? '1px solid $grayDark' : 'none',
                borderTopLeftRadius: isFirst ? '$6' : '0',
                borderTopRightRadius: isFirst ? '$6' : '0',
                borderBottomLeftRadius: isLast ? '$6' : '0',
                borderBottomRightRadius: isLast ? '$6' : '0',
              }}
            >
              <Box
                flexRow
                alignCenter
                justifyContentBetween
                fullWidth
                css={{
                  p: '$2 $3',
                  borderRadius: '$6',
                  border: `1px solid ${isActive ? '$green' : 'transparent'}`,
                  backgroundColor: isActive ? '$grayDark' : 'transparent',
                  cursor: 'pointer',
                }}
                onClick={() => handleActiveJackpotChange(jackpot.id)}
              >
                <MenuListItem
                  jackpot={jackpot}
                  isActive={isActive}
                  shouldHighlightEvents
                />
              </Box>
            </Box>
          );
        })}
      </Box>
    </Box>
  );
};
