import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';

import { timeZoneOffsets } from '~constants/timezones';

dayjs.extend(utc);
dayjs.extend(timezone);

export enum TIME_FORMATS {
  DATE = 'date',
  DATE_SLASH_FORMAT = 'date_slash_format',
  TIME_24H = 'time_24h',
  TIME_12H = 'time_12h',
  TIME_12H_WITH_SECONDS = 'time_12h_with_seconds',
  DATE_TIME_24H = 'date_time_24h',
  DATE_TIME_12H = 'date_time_12h',
  DATE_TIME_24H_WITH_SECONDS = 'date_time_24h_with_seconds',
}

export enum DATE_FORMATS {
  DATE = 'DD/MM/YYYY',
  DATE_WITH_DOT = 'DD.MM.YYYY',
  DATE_YEAR_FIRST = 'YYYY-MM-DD',
  MONTH = 'MM/YYYY',
}

function parseCustomTimezone(customTz: string): number {
  const match = customTz.match(/utc([+-]\d{1,2})(?::(\d{2}))?/i);

  if (!match) {
    throw new Error(`Invalid timezone format: ${customTz}`);
  }

  const hours = parseInt(match[1] as string, 10);
  const minutes = match[2] ? parseInt(match[2], 10) : 0;

  return hours * 60 + (hours < 0 ? -minutes : minutes);
}

function getDateInCustomTimezone(date: Date, customTz: string): Date {
  if (customTz && customTz.includes('utc')) {
    const offset = parseCustomTimezone(customTz);

    const originalTime = dayjs(date);

    const adjustedTime = originalTime.add(
      offset - originalTime.utcOffset(),
      'minute',
    );

    return adjustedTime.toDate();
  } else {
    return date;
  }
}

export const formatDateTime = (
  input: string,
  format: TIME_FORMATS = TIME_FORMATS.DATE_TIME_12H,
): string => {
  const storedSettings = localStorage.getItem('persist:websiteSettings');

  if (!storedSettings) {
    throw new Error('Stored settings not found');
  }

  let storedTimeZone;

  if (storedSettings) {
    storedTimeZone = JSON.parse(storedSettings).timezone;
  }

  if (storedTimeZone === 'null' || !storedSettings) {
    storedTimeZone =
      timeZoneOffsets[
        Intl.DateTimeFormat().resolvedOptions()
          .timeZone as keyof typeof timeZoneOffsets
      ];
  }

  const dateBase = new Date(input);

  const date = getDateInCustomTimezone(dateBase, storedTimeZone);

  const day = date.getDate().toString().padStart(2, '0');
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const year = date.getFullYear();

  let hours = date.getHours();

  const minutes = date.getMinutes().toString().padStart(2, '0');
  const seconds = date.getSeconds().toString().padStart(2, '0');
  const amOrPm = hours >= 12 ? 'PM' : 'AM';

  if (hours > 12) hours -= 12;
  if (hours === 0) hours = 12;

  switch (format) {
    case TIME_FORMATS.DATE:
      return `${day}.${month}.${year}`;
    case TIME_FORMATS.DATE_SLASH_FORMAT:
      return `${day}/${month}/${year}`;
    case TIME_FORMATS.TIME_24H:
      return `${date.getHours().toString().padStart(2, '0')}:${minutes}`;
    case TIME_FORMATS.TIME_12H:
      return `${hours}:${minutes} ${amOrPm}`;
    case TIME_FORMATS.TIME_12H_WITH_SECONDS:
      return `${hours.toString().padStart(2, '0')}:${minutes}:${seconds} ${amOrPm}`;
    case TIME_FORMATS.DATE_TIME_24H:
      return `${day}.${month}.${year}, ${date.getHours().toString().padStart(2, '0')}:${minutes}`;
    case TIME_FORMATS.DATE_TIME_12H:
      return `${day}.${month}.${year}, ${hours.toString().padStart(2, '0')}:${minutes} ${amOrPm}`;
    case TIME_FORMATS.DATE_TIME_24H_WITH_SECONDS:
      return `${day}.${month}.${year}, ${date.getHours().toString().padStart(2, '0')}:${minutes}:${seconds}`;
    default:
      throw new Error(`Unsupported time format: ${format}`);
  }
};

export const formatDateToISOString = (date: Date): string => {
  const year = date.getUTCFullYear();
  const month = String(date.getUTCMonth() + 1).padStart(2, '0'); // months are 0-based in JS
  const day = String(date.getUTCDate()).padStart(2, '0');
  const hours = String(date.getUTCHours()).padStart(2, '0');
  const minutes = String(date.getUTCMinutes()).padStart(2, '0');
  const seconds = String(date.getUTCSeconds()).padStart(2, '0');
  const milliseconds = String(date.getUTCMilliseconds()).padStart(3, '0');

  return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.${milliseconds}Z`;
};

export const formatDateToStartOfDayISOString = (date: dayjs.Dayjs): string => {
  const startOfDay = date.startOf('day').toDate();

  return formatDateToISOString(startOfDay);
};

export const formatDateToEndOfDayISOString = (date: dayjs.Dayjs): string => {
  const endOfDay = date.endOf('day').toDate();

  return formatDateToISOString(endOfDay);
};

export const addTwoMinutesToCurrentTimeISO = (): string => {
  const currentTime = new Date();

  currentTime.setMinutes(currentTime.getMinutes() + 2);

  return formatDateToISOString(currentTime);
};

export const calculateTimeRemainingInSeconds = (targetTime: string): number => {
  const now = new Date();
  const futureDate = new Date(targetTime);
  const timeDifferenceInSeconds = Math.floor(
    (futureDate.getTime() - now.getTime()) / 1000,
  );

  return Math.max(0, timeDifferenceInSeconds);
};

export const getDaysHoursMinutesFromSeconds = (timeInSeconds: number) => {
  return {
    days: Math.floor(timeInSeconds / (3600 * 24)),
    hours: Math.floor((timeInSeconds % (3600 * 24)) / 3600),
    minutes: Math.floor((timeInSeconds % 3600) / 60),
  };
};
