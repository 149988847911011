import { BANNER_GROUP_NAMES, BannerList } from '~components/atoms/BannerList';
import { Box } from '~components/atoms/Box';
//import { MatchHighlightsAccordion } from '~components/molecules/MainHighlights/MatchHighlightsAccordion';
import { UpcomingPrematchAccordion } from '~components/molecules/MainHighlights/UpcomingPrematchAccordion';
import { MAIN_PAGE_SECTIONS } from '~constants/mainPage';

export const MainPage = () => {
  return (
    <Box fullHeight fullWidth flexCol gap={1}>
      <BannerList names={[BANNER_GROUP_NAMES.HEADER]} />
      <UpcomingPrematchAccordion label={MAIN_PAGE_SECTIONS.UPCOMING} />
      {/*<MatchHighlightsAccordion label={MAIN_PAGE_SECTIONS.LIVE_HIGHLIGHTS} />*/}
    </Box>
  );
};
