export const enum ACTION_TYPE {
  ADDED = 'added',
  REMOVED = 'removed',
}

interface FindAddedOrRemovedItemReturnType {
  item: string | null;
  action: ACTION_TYPE | null;
}
export const findAddedOrRemovedItem = (
  prevState: string[],
  nextState: string[],
): FindAddedOrRemovedItemReturnType => {
  const [addedItem] = nextState.filter((item) => !prevState.includes(item));
  const [removedItem] = prevState.filter((item) => !nextState.includes(item));

  if (addedItem) {
    return {
      item: addedItem,
      action: ACTION_TYPE.ADDED,
    };
  }

  if (removedItem) {
    return {
      item: removedItem,
      action: ACTION_TYPE.REMOVED,
    };
  }

  return {
    item: null,
    action: null,
  };
};

export const getItemFromArrayById = <
  T extends { id?: string | number; eventId?: string | number },
>(
  array: Array<T>,
  id: string | number | undefined,
) => {
  if (!id) {
    return array[0];
  }

  const foundElement = array.find(
    (element) => (element.eventId || element.id) === id,
  );

  return foundElement ? foundElement : array[0];
};
