import { useCallback, useEffect, useState } from 'react';

import { MainMarket } from '~api/market/types';
import { useQueryParams } from '~hooks/useQueryParams';
import { useAppDispatch, useAppSelector } from '~store';
import {
  addOpenedCountryLeagues,
  setOpenedCountryLeagues,
} from '~store/slices/liveMenuSlice';

import { MULTI_ACCORDION_ROLES } from '../../MultiAccordion';

export const useLiveCountryMenu = ({ sportId }: { sportId: number }) => {
  const dispatch = useAppDispatch();
  const {
    openedCountryLeagues,
    mainMarketsSelected,
    needReopenCard,
    isLiveMenuLoaded,
  } = useAppSelector((state) => state.liveMenu);
  const menuRole = MULTI_ACCORDION_ROLES.CHILD;
  const { leagueId } = useQueryParams();
  const [currentLeagueId, setCurrentLeagueId] = useState(leagueId);
  const { mainMarkets } = useAppSelector((state) => state.mainMarkets);

  const mainSportMarketId = mainMarketsSelected[sportId];
  const { markets = [] } =
    mainMarkets.find((item) => item.id === sportId) || {};

  const defaultMainSportMarket = markets.find(
    (item) => item.id === mainSportMarketId,
  );

  const [mainSportMarket, setMainSportMarket] = useState<
    MainMarket | undefined
  >(defaultMainSportMarket);

  const handleOnValueChange = useCallback(async (e: string[]) => {
    dispatch(setOpenedCountryLeagues(e));
  }, []);

  const getLeagueEventsCount = useCallback(
    (leagueEvents: string[] | undefined) => {
      if (!leagueEvents) {
        return 0;
      }

      return leagueEvents.length;
    },
    [],
  );

  useEffect(() => {
    if (leagueId && (!isLiveMenuLoaded || currentLeagueId !== leagueId)) {
      setCurrentLeagueId(leagueId);
      dispatch(addOpenedCountryLeagues([...openedCountryLeagues, leagueId]));
    }
  }, [leagueId, isLiveMenuLoaded, needReopenCard]);

  useEffect(() => {
    const mainSportMarketId = mainMarketsSelected[sportId];
    const { markets = [] } =
      mainMarkets.find((item) => item.id === sportId) || {};

    if (markets.length) {
      const mainSportMarket = markets.find(
        (item) => item.id === mainSportMarketId,
      );

      setMainSportMarket(mainSportMarket);
    }
  }, [mainMarketsSelected, mainMarkets, sportId]);

  return {
    menuRole,
    openCountryLeagues: openedCountryLeagues,
    mainSportMarket,
    handleOnValueChange,
    getLeagueEventsCount,
  };
};
