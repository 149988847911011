import { Box } from '~components/atoms/Box';
import { DateRangePicker } from '~components/atoms/DateRangePicker';
import { Input } from '~components/atoms/Input';
import { Select } from '~components/atoms/Select';
import { UseBetslipHistoryFiltersReturn } from '~components/molecules/BetslipHistorySection/Sports/useBetslipHistoryFilters';

import { StyledButton, StyledText } from './styled.components';

interface BetslipHistoryFiltersMobileProps {
  setShowFilters: (showFilters: boolean) => void;
  betslipHistoryFiltersParams: UseBetslipHistoryFiltersReturn;
}

export const BetslipHistoryFiltersMobile = ({
  setShowFilters,
  betslipHistoryFiltersParams,
}: BetslipHistoryFiltersMobileProps) => {
  const {
    code,
    isValidationFailed,
    selectedDates,
    statusesOptions,
    statusValue,
    minimumAge,
    handleSetCode,
    handleUpdateHistory,
    localized,
    setSelectedDates,
    setStatusValue,
  } = betslipHistoryFiltersParams;

  const handleShowResults = () => {
    setShowFilters(false);
    handleUpdateHistory();
  };

  return (
    <Box flexCol gap={4}>
      <Box
        flexCol
        gap={2}
        css={{ backgroundColor: '$grayDarkMain2', p: '$3', borderRadius: '$8' }}
      >
        <Box flexRow gap={3}>
          <DateRangePicker
            isFutureDatesDisabled
            onOkRange={(dates) => setSelectedDates(dates)}
            value={selectedDates}
            legalAge={minimumAge}
            isFullWidth
          />
        </Box>
        <Select
          ariaLabel={localized('betslipHistory.status.all')}
          placeholder={localized('betslipHistory.status.all')}
          value={statusValue.toString()}
          onChange={(value) => setStatusValue(value as string)}
          options={statusesOptions}
          css={{
            height: '$7',
            borderRadius: '$6',
            backgroundColor: '$filtersInputMobileBgColor',
            pl: '$1',
          }}
        />
        <Input
          placeholder={localized('betslipHistory.inputPlaceholder')}
          value={code}
          onChange={handleSetCode}
          css={{
            height: '$7',
            borderRadius: '$6',
            backgroundColor: '$filtersInputMobileBgColor',
            padding: '$1 $3',
          }}
        />
      </Box>
      <Box flexRow gap={2}>
        <StyledButton
          css={{
            backgroundColor: '$closeFiltersButtonBgColor',
          }}
          onClick={() => setShowFilters(false)}
        >
          <StyledText>
            {localized('betslipHistory.button.closeFilters')}
          </StyledText>
        </StyledButton>
        <StyledButton
          variant="primary"
          disabled={isValidationFailed}
          onClick={handleShowResults}
        >
          <StyledText
            css={{
              color: '$buttonTextColor !important',
            }}
          >
            {localized('betslipHistory.button.show')}
          </StyledText>
        </StyledButton>
      </Box>
    </Box>
  );
};
