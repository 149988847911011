import { useState } from 'react';

import { UserBonusBalanceChanges } from '~api/betslip/types';
import { Box } from '~components/atoms/Box';
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '~components/atoms/Popover';
import { Text } from '~components/atoms/Typography';
import { CURRENCY_SYMBOLS } from '~constants/common';
import { useMedia } from '~hooks/useMedia';
import { useTranslation } from '~hooks/useTranslation';
import { CloseIcon, InfoIcon } from '~icons';
import { useAppSelector } from '~store';
import { commaSeparatedNumber } from '~utils/numberUtils';

export const StakeBalancesInfo = ({
  balanceChanges,
  fullStake,
}: {
  balanceChanges: UserBonusBalanceChanges;
  fullStake: number;
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const { localized } = useTranslation();
  const { isMobileOrTablet } = useMedia();
  const { currency } = useAppSelector((state) => state.settings);

  const totalBonusAmount = balanceChanges?.reduce(
    (acc, { stake }) => acc + stake,
    0,
  );

  return (
    <Box flexRow>
      <Popover isOpen={isOpen}>
        <PopoverContent
          noArrow={isMobileOrTablet}
          side="bottom"
          sideOffset={isMobileOrTablet ? 0 : 16}
          align="start"
          tone="secondary"
          width={isMobileOrTablet ? '100vw' : 300}
          css={{
            mr: '14px',
            p: '$4',
            '@xs_sm': {
              mr: 'unset',
              background: 'rgba(10, 15, 20, 0.80)',
              backdropFilter: 'blur(4px)',
              borderRadius: '$12',
            },
          }}
        >
          <Box
            flexCol
            css={{
              '@xs_sm': {
                p: '$4',
                background: '$grayDark',
                borderRadius: '$8',
                position: 'relative',
              },
            }}
          >
            <Box flexCol gap={3}>
              {isMobileOrTablet && (
                <Box
                  flexRow
                  css={{
                    position: 'absolute',
                    top: '$3',
                    right: '$3',
                    color: '$grayMedium',
                  }}
                  onClick={() => setIsOpen(false)}
                >
                  <CloseIcon />
                </Box>
              )}
              <Text level="14-20" textTransform="uppercase">
                {localized('betslip.balancePopover.title')}
              </Text>
              <Box flexCol gap={1}>
                <Box
                  flexRow
                  justifyContentBetween
                  css={{
                    backgroundColor: '$gray',
                    borderRadius: '$6',
                    p: '$2 $3',
                  }}
                >
                  <Text
                    level={isMobileOrTablet ? '13-16' : '14-20'}
                    textTransform="capitalize"
                  >
                    {localized('betslip.balancePopover.mainBalance')}
                  </Text>
                  <Text
                    level={isMobileOrTablet ? '13-16' : '14-20'}
                    fontWeight="medium"
                  >
                    {commaSeparatedNumber(fullStake - totalBonusAmount)}
                    {CURRENCY_SYMBOLS[currency]}
                  </Text>
                </Box>
                <Box
                  flexCol
                  gap={2}
                  css={{
                    backgroundColor: '$gray',
                    borderRadius: '$6',
                    p: '$2 $3',
                  }}
                >
                  <Box flexRow justifyContentBetween>
                    <Text
                      level={isMobileOrTablet ? '13-16' : '14-20'}
                      textTransform="capitalize"
                    >
                      {localized('betslip.balancePopover.bonusBalance')}
                    </Text>
                    {!!balanceChanges?.length && (
                      <Text
                        level={isMobileOrTablet ? '13-16' : '14-20'}
                        fontWeight="medium"
                      >
                        {`${commaSeparatedNumber(totalBonusAmount)} ${
                          CURRENCY_SYMBOLS[currency]
                        }`}
                      </Text>
                    )}
                  </Box>
                  {!!balanceChanges?.length && (
                    <Box flexCol gap={1}>
                      {balanceChanges?.map(({ title, stake }) => (
                        <Box
                          key={title}
                          flexRow
                          alignCenter
                          justifyContentBetween
                          css={{
                            backgroundColor: '$grayDark',
                            borderRadius: '$4',
                            p: '$1 $2',
                          }}
                        >
                          <Text level={isMobileOrTablet ? '11-16' : '12-20'}>
                            {title}
                          </Text>
                          <Text
                            level={isMobileOrTablet ? '11-16' : '12-20'}
                            fontWeight="medium"
                          >
                            {commaSeparatedNumber(stake)}
                            {CURRENCY_SYMBOLS[currency]}
                          </Text>
                        </Box>
                      ))}
                    </Box>
                  )}
                </Box>
              </Box>
            </Box>
          </Box>
        </PopoverContent>
        <PopoverTrigger asChild>
          <Box
            onMouseEnter={() => setIsOpen(true)}
            onMouseLeave={() => setIsOpen(false)}
            css={{
              cursor: 'pointer',
              color: '$grayMedium',
              lineHeight: 0,
              '&:hover': { color: '$white' },
            }}
          >
            <InfoIcon width={12} height={12} />
          </Box>
        </PopoverTrigger>
      </Popover>
    </Box>
  );
};
