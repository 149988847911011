import { CSSProperties, FC, ReactNode, useRef, useState } from 'react';
import { styled } from '@stitches/react';
import { Select } from 'antd';
import { BaseSelectRef } from 'rc-select';

import { CheckedIcon, ChevronDownIcon, ChevronUpIcon } from '~icons';

export type SelectOptionType = {
  label: unknown;
  value: unknown;
  title: string;
};
type Props = {
  label?: string;
  value: unknown;
  setValue: (value: unknown) => void;
  options: SelectOptionType[];
  additionalSelectedComponents?: Record<string, ReactNode>;
  dropdownWidth?: string;
  style?: CSSProperties;
};

const StyledSelect = styled(Select, {
  height: '36px',
  variants: {
    withLabel: {
      true: {
        background: '$black !important',
        '> div': {
          background: 'transparent !important',
          '> span': {
            color: 'transparent !important',
          },
        },
      },
    },
  },
});

const StyledLabel = styled('span', {
  fontSize: '14px',
  lineHeight: '20px',
  color: '$grayMedium',
  variants: {
    withLabel: {
      true: {
        fontSize: '12px',
        lineHeight: '16px',
      },
    },
  },
});
const StyledValue = styled('span', {
  fontWeight: 500,
  color: '$white',
  fontSize: '14px',
  lineHeight: '20px',
  variants: {
    withLabel: {
      true: {
        fontSize: '12px',
        lineHeight: '16px',
      },
    },
  },
});
const StyledChevronUpIcon = styled(ChevronUpIcon, { color: '$grayMedium' });
const StyledChevronDownIcon = styled(ChevronDownIcon, { color: '$grayMedium' });
const StyledCheckedIcon = styled(CheckedIcon, {
  color: '$grayMedium',
});

export const SelectWithLabel: FC<Props> = ({
  label,
  value,
  setValue,
  options,
  additionalSelectedComponents,
  dropdownWidth = '100%',
  style = {},
}) => {
  const [isOpen, setOpen] = useState(false);
  const selectRef = useRef<BaseSelectRef>(null);

  return (
    <StyledSelect
      ref={selectRef}
      withLabel={!!label}
      options={options}
      labelRender={(props) => {
        const { title, value } = props as SelectOptionType;

        return (
          <div
            style={{
              display: 'flex',
              gap: '8px',
              justifyContent: !label ? 'flex-start' : 'flex-end',
            }}
          >
            {label && <StyledLabel withLabel={!!label}>{label}:</StyledLabel>}
            <StyledValue withLabel={!!label}>
              <div
                style={{
                  display: 'flex',
                  gap: '8px',
                }}
              >
                <div style={{ flexShrink: 0 }}>
                  {additionalSelectedComponents
                    ? additionalSelectedComponents[value as string] || null
                    : null}
                </div>
                {title}
              </div>
            </StyledValue>
          </div>
        );
      }}
      value={value}
      onChange={setValue}
      suffixIcon={isOpen ? <StyledChevronUpIcon /> : <StyledChevronDownIcon />}
      onDropdownVisibleChange={(open) => setOpen(open)}
      menuItemSelectedIcon={<StyledCheckedIcon />}
      dropdownStyle={
        dropdownWidth === '100%' ? {} : { width: 'auto !important' }
      }
      dropdownRender={(menu) => {
        return <div style={{ width: dropdownWidth }}>{menu}</div>;
      }}
      css={{ ...style }}
    />
  );
};
