import { useEffect, useState } from 'react';

import { MainMarket } from '~api/market/types';
import { TopPrematchDateGroupIds } from '~constants/mainPage';

export const useTopPrematchDateGroupItem = (
  dateGroup: TopPrematchDateGroupIds,
  mainMarkets: MainMarket[],
  maxMarketsCount: number | undefined,
) => {
  const [mainSportMarkets, setMainSportMarkets] = useState<MainMarket[]>([]);
  const { matches, date } = dateGroup;

  useEffect(() => {
    let markets = [...mainMarkets];

    const isMaxMarketsCountValid =
      typeof maxMarketsCount === 'number' && !isNaN(maxMarketsCount);

    if (isMaxMarketsCountValid) {
      markets = markets.slice(0, Math.max(0, maxMarketsCount));
    }

    setMainSportMarkets(markets);
  }, [mainMarkets, maxMarketsCount]);

  return {
    date,
    matches,
    mainSportMarkets,
  };
};
