import { InplayEvent } from '~components/molecules/InplayEvents';
import { LiveTopEvents } from '~components/molecules/LiveMenu/components/LiveTopEvents';
import { LeagueEventsMenu } from '~components/molecules/PrematchEvents/components/LeagueEventsMenu';
import { PrematchEventMarkets } from '~components/molecules/PrematchEvents/components/PrematchEventMarkets';
import { TopPrematchEvents } from '~components/molecules/PrematchEvents/components/TopPrematchEvents/TopPrematchEvents';
import { useGetSportGroups } from '~components/molecules/PrematchMenu/hooks/useGetSportGroups';
import { useQueryParams } from '~hooks/useQueryParams';
import { MainPage } from '~pages/MainPage/MainPage';
import { SPORT_GROUPS } from '~types/sportGroups';
import { SPORT_MENUS } from '~types/sportMenus';

export const useSportsPage = () => {
  const {
    eventId,
    menu = SPORT_MENUS.PREMATCH,
    sportId,
    countryId,
    leagueId,
    group,
  } = useQueryParams();

  useGetSportGroups();

  let BoardComponent;

  BoardComponent = MainPage;

  if (group === SPORT_GROUPS.TOP_EVENTS) {
    if (menu === SPORT_MENUS.PREMATCH) {
      BoardComponent = TopPrematchEvents;
    } else {
      BoardComponent = LiveTopEvents;
    }
  } else if (menu === SPORT_MENUS.PREMATCH) {
    if (leagueId) {
      BoardComponent = LeagueEventsMenu;
    }

    if (eventId) {
      BoardComponent = PrematchEventMarkets;
    }
  } else {
    BoardComponent = InplayEvent;
  }

  return {
    BoardComponent,
    sportId,
    countryId,
    leagueId,
    eventId,
  };
};
