import { useEffect, useState } from 'react';

import { SportMainMarkets } from '~api/market/types';
import {
  useAddFavoriteEvent,
  useDeleteFavoriteEvent,
} from '~api/sportEvent/sportEventMutations';
import { Market } from '~api/sportEvent/types';
import { useAppDispatch, useAppSelector } from '~store';
import { setFavoritePrematchEvents } from '~store/slices/sportGroupsSlice';
import { SportEventMainData, StoreSportEvent } from '~types/events';
import { deepClone } from '~utils/deepClone';
import { areTwoObjectsEqual } from '~utils/objectHelpers';

import { getMainMarketData } from '../../helpers/getMainMarketsData';
import { getMarketDataFormatted } from '../../helpers/marketUtils';
import { useLeagueEventConnection } from '../../hooks/useLeagueEventConnection';

interface LeagueEventHookParams {
  eventId: string;
  marketIds: string[];
  mainMarkets?: SportMainMarkets;
  sportId?: number;
  maxMarketsCount?: number;
}

export const useLeagueEvent = ({
  eventId,
  marketIds,
  mainMarkets,
  sportId,
  maxMarketsCount,
}: LeagueEventHookParams) => {
  const dispatch = useAppDispatch();

  const { eventsMap, marketsMap } = useAppSelector((state) => state.events);
  const { favoritePrematchEvents } = useAppSelector(
    (state) => state.sportGroupsState,
  );
  const [eventData, setEventData] = useState<StoreSportEvent | undefined>(
    eventId ? eventsMap[eventId] : undefined,
  );

  let defaultMarketsData: Market[] = [];

  const { markets: mainMarketsData = [] } =
    mainMarkets?.find(({ id }) => id === sportId) || {};

  if (mainMarketsData.length) {
    defaultMarketsData = marketIds.map(
      (marketId) => marketsMap[marketId],
    ) as Market[];
  }

  if (maxMarketsCount && defaultMarketsData.length > maxMarketsCount) {
    defaultMarketsData = defaultMarketsData.slice(0, maxMarketsCount);
  }

  const [marketData, setMarketData] = useState<Market[]>(
    defaultMarketsData as Market[],
  );
  const { addFavoriteEventMutation } = useAddFavoriteEvent();
  const { deleteFavoriteEventMutation } = useDeleteFavoriteEvent();

  useLeagueEventConnection({ eventId });

  const handleFavoriteClick = async (isEventFavorite: boolean) => {
    if (isEventFavorite) {
      await deleteFavoriteEventMutation({
        eventId,
      });

      dispatch(
        setFavoritePrematchEvents(
          favoritePrematchEvents.filter(
            (favoriteEvent) => favoriteEvent.id !== eventData?.id,
          ),
        ),
      );
    } else {
      await addFavoriteEventMutation({
        eventId,
      });
      if (eventData) {
        dispatch(
          setFavoritePrematchEvents([
            ...favoritePrematchEvents,
            eventData as SportEventMainData,
          ]),
        );
      }
    }
  };

  useEffect(() => {
    if (!sportId || !mainMarkets) return;

    const {
      relevantSport,
      marketNameMap,
      selectionNameMap,
      selectionOrderMap,
      allSelectionsFromMainMarkets,
    } = getMainMarketData(sportId, mainMarkets);

    if (!relevantSport) {
      return;
    }

    const mapMarkets = marketIds.map((marketId) => {
      const market = deepClone(marketsMap[marketId]);

      if (!market) return null;

      const formattedMarket = getMarketDataFormatted({
        marketOriginal: marketsMap[marketId]!,
        marketNameMap,
        allSelectionsFromMainMarkets,
        sportId,
        relevantSport,
        selectionOrderMap,
        selectionNameMap,
      });

      market.selections = formattedMarket.selections;
      market.name = formattedMarket.name;
      market.status = formattedMarket.status;
      market.line = formattedMarket.line;

      return market;
    });

    if (mapMarkets && !areTwoObjectsEqual(marketData, mapMarkets)) {
      setMarketData(mapMarkets);
    }
  }, [marketsMap, marketData]);

  useEffect(() => {
    const mapEvent = eventsMap[eventId];

    const isEventFavorite = favoritePrematchEvents?.find((favoriteEvent) => {
      return favoriteEvent.id === mapEvent?.id;
    });

    if (mapEvent && !areTwoObjectsEqual(mapEvent, eventData)) {
      setEventData({
        ...mapEvent,
        favorite: !!isEventFavorite,
      });
    }
  }, [eventsMap]);

  return {
    eventData,
    marketData,
    handleFavoriteClick,
  };
};
