import { memo } from 'react';

import { Box } from '~components/atoms/Box';
import { Skeleton } from '~components/atoms/Skeleton';

export const LoadingContent = memo(() => {
  return (
    <Box flexCol gap={2}>
      <Box flexCol css={{ gap: '3px' }}>
        <Box flexRow justifyContentBetween>
          <Skeleton
            css={{
              width: '80px',
              height: '$6',
            }}
          />
          <Skeleton
            css={{
              width: '$8',
              height: '$6',
            }}
          />
        </Box>
        <Box flexRow justifyContentBetween>
          <Skeleton
            css={{
              width: '120px',
              height: '$6',
            }}
          />
          <Skeleton
            css={{
              width: '$8',
              height: '$6',
            }}
          />
        </Box>
      </Box>
      <Box flexRow justifyContentBetween>
        <Box flexCol justifyCenter alignStart css={{ gap: '2px' }}>
          <Skeleton
            css={{
              width: '60px',
              height: '$5',
            }}
          />
        </Box>
        <Box flexCol justifyContentEnd alignEnd css={{ gap: '2px' }}>
          <Skeleton
            css={{
              width: '$8',
              height: '$5',
            }}
          />
          <Skeleton
            css={{
              width: '$8',
              height: '$5',
            }}
          />
        </Box>
      </Box>
    </Box>
  );
});
