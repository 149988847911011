import { Box } from '~components/atoms/Box';

interface RulesContentProps {
  content: string;
}

export const RulesContent = ({ content }: RulesContentProps) => {
  return (
    <Box
      flexCol
      alignCenter
      justifyContentBetween
      css={{
        maxWidth: '100vw',
        backgroundColor: '$grayDark',
        '*': {
          color: 'white',
        },
        color: 'white',
        padding: '$4 $5',
        borderRadius: '$10',
        '@xs_sm': {
          borderRadius: 0,
          padding: '$2',
        },
      }}
    >
      <Box
        css={{
          '@lg_xl': {
            gap: '$3',
            flexDirection: 'row',
            alignItems: 'center',
          },
          figure: {
            display: 'flex',
            justifyContent: 'center',
          },
          img: {
            width: '100%',
            height: 'auto',
            maxWidth: '100%',
            maxHeight: '100%',
          },
          '@xs_sm': {
            background: '$grayDark',
            borderRadius: '$8',
            padding: '0 $4',
          },
        }}
      >
        <div dangerouslySetInnerHTML={{ __html: content }} />
      </Box>
    </Box>
  );
};
