import { useEffect, useState } from 'react';

import { useAppSelector } from '~store';

export const useLiveTopEvents = () => {
  const { topLiveEvents } = useAppSelector((state) => state.liveGroupsState);
  const { mainMarkets } = useAppSelector((state) => state.mainMarkets);
  const { maxMarketsCount } = useAppSelector((state) => state.prematchMenu);
  const [topGamesOpen, setTopGamesOpen] = useState<string[]>([]);
  const handleAccordionValueChange = (value: string[]) => {
    setTopGamesOpen(value);
  };

  const getPreparedTopSportEvents = () => {
    return topLiveEvents.map((topSportEvent) => {
      const { sportId, sportName, matches } = topSportEvent;
      const { markets: sportMainMarkets = [] } =
        mainMarkets.find(({ id }) => {
          return id === sportId;
        }) || {};

      return {
        sportId,
        sportName,
        sportMainMarkets,
        matches,
      };
    });
  };

  useEffect(() => {
    if (topLiveEvents[0]?.sportId) {
      setTopGamesOpen([topLiveEvents[0].sportId.toString()]);
    }
  }, [topLiveEvents]);

  return {
    topGamesOpen,
    maxMarketsCount,
    preparedTopLiveEvents: getPreparedTopSportEvents(),
    handleAccordionValueChange,
  };
};
