import { useEffect } from 'react';

import { Box } from '~components/atoms/Box';
import {
  EventMarketsSkeleton,
  InplayEventMarketSkeleton,
} from '~components/atoms/Skeletons';
import { MOBILE_MENU } from '~components/organisms/MobileTab';
import { LIVE_TAB_CONTENTS } from '~components/organisms/MobileTabContents';
import { useQueryParams } from '~hooks/useQueryParams';
import { useAppDispatch, useAppSelector } from '~store';
import { setLiveTabActiveContent } from '~store/slices/mobileSlice';

import { BreadcrumbEventWrapper } from './components/InplayEventMarkets';
import { InplayEventOverview } from './components/InplayEventOverview';
import { useInplayEvent } from './hooks/useInplayEvent';
import { useInplayEventBreadcrumb } from './hooks/useInplayEventBreadcrumb';
import { InplayMarketEventGroups } from './InplayMarketEventGroups';

export const InplayEvent = () => {
  const {
    isFetching,
    isMobileOrTablet,
    eventData,
    isEventLoaded,
    marketGroups,
    handleBackPress,
  } = useInplayEvent();
  const { isOpen } = useAppSelector((state) => state.globalDialog);
  const dispatch = useAppDispatch();
  const search = useQueryParams();
  const { countryName, leagueName, name } = eventData || {};
  const { populateInplayEventBreadcrumbs } = useInplayEventBreadcrumb({
    menu: MOBILE_MENU.LIVE,
    countryName,
    leagueName,
    name,
  });
  const { mobileHeaderHeight } = useAppSelector((state) => state.mobileState);
  const isBreadcrumbAvailable =
    isMobileOrTablet && countryName && leagueName && name;

  const Skeleton = () =>
    isMobileOrTablet ? <InplayEventMarketSkeleton /> : <EventMarketsSkeleton />;

  useEffect(() => {
    if (isMobileOrTablet && !isOpen) {
      dispatch(setLiveTabActiveContent(LIVE_TAB_CONTENTS.LIVE_EVENT));
      populateInplayEventBreadcrumbs();
    }
  }, [isMobileOrTablet, eventData, search]);

  return (
    <Box
      flexCol
      gap={isMobileOrTablet ? 0 : 1}
      css={{
        minHeight: 'calc(100vh - 270px)',
        pt: isBreadcrumbAvailable ? '52px' : 0,
      }}
    >
      {isBreadcrumbAvailable && (
        <BreadcrumbEventWrapper
          mobileHeaderHeight={mobileHeaderHeight}
          handleBackPress={handleBackPress}
        />
      )}
      {eventData && <InplayEventOverview eventData={eventData} />}
      {isFetching || !isEventLoaded ? (
        <Skeleton />
      ) : (
        <InplayMarketEventGroups
          marketGroups={marketGroups}
          isEventLoaded={isEventLoaded}
        />
      )}
    </Box>
  );
};
