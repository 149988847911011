import React, { memo } from 'react';

import { SPORTS } from '~constants/sport';
import { Market } from '~types/events';
import {
  BasketballExtraData,
  HandballExtraData,
  IceHockeyExtraData,
  SnookerExtraData,
  SoccerExtraData,
  TableTennisExtraData,
  TennisExtraData,
  VolleyballExtraData,
} from '~types/sportExtraData';
import { getParsedExtraData } from '~utils/sportHelpers';

import {
  BasketballContent,
  FallbackContent,
  HandballContent,
  IceHockeyContent,
  LoadingContent,
  SnookerContent,
  SoccerContent,
  TableTennisContent,
  TennisContent,
  VolleyballContent,
} from './liveSport';

interface LiveEventContentProps {
  isMobile: boolean;
  isLoading: boolean;
  eventId: string;
  eventName: string | undefined;
  sportName: string | undefined;
  extraData: string | undefined;
  startDate: string | undefined;
  marketsCount: number | undefined;
  market: Market | undefined;
  sportId: number;
}

export const LiveEventContent = memo(
  ({
    isMobile,
    isLoading,
    eventId,
    eventName,
    sportName,
    extraData,
    startDate,
    marketsCount,
    market,
    sportId,
  }: LiveEventContentProps) => {
    if (isLoading) return <LoadingContent />;

    const parsedExtraData = getParsedExtraData(extraData);

    if (parsedExtraData) {
      switch (sportName) {
        case SPORTS.ICE_HOCKEY:
          return (
            <IceHockeyContent
              isMobile={isMobile}
              market={market}
              eventId={eventId}
              sportId={sportId}
              isLoading={isLoading}
              extraData={parsedExtraData as IceHockeyExtraData}
              eventName={eventName}
              startDate={startDate}
              marketsCount={marketsCount}
            />
          );
        case SPORTS.TABLE_TENNIS:
          return (
            <TableTennisContent
              isMobile={isMobile}
              market={market}
              eventId={eventId}
              sportId={sportId}
              isLoading={isLoading}
              extraData={parsedExtraData as TableTennisExtraData}
              eventName={eventName}
              startDate={startDate}
              marketsCount={marketsCount}
            />
          );

        case SPORTS.SOCCER:
          return (
            <SoccerContent
              isMobile={isMobile}
              market={market}
              eventId={eventId}
              sportId={sportId}
              isLoading={isLoading}
              extraData={parsedExtraData as SoccerExtraData}
              eventName={eventName}
              startDate={startDate}
              marketsCount={marketsCount}
            />
          );

        case SPORTS.TENNIS:
          return (
            <TennisContent
              isMobile={isMobile}
              market={market}
              eventId={eventId}
              sportId={sportId}
              isLoading={isLoading}
              extraData={parsedExtraData as TennisExtraData}
              eventName={eventName}
              startDate={startDate}
              marketsCount={marketsCount}
            />
          );

        case SPORTS.BASKETBALL:
          return (
            <BasketballContent
              isMobile={isMobile}
              market={market}
              eventId={eventId}
              sportId={sportId}
              isLoading={isLoading}
              extraData={parsedExtraData as BasketballExtraData}
              eventName={eventName}
              startDate={startDate}
              marketsCount={marketsCount}
            />
          );

        case SPORTS.HANDBALL:
          return (
            <HandballContent
              isMobile={isMobile}
              market={market}
              eventId={eventId}
              sportId={sportId}
              isLoading={isLoading}
              extraData={parsedExtraData as HandballExtraData}
              eventName={eventName}
              startDate={startDate}
              marketsCount={marketsCount}
            />
          );

        case SPORTS.VOLLEYBALL:
          return (
            <VolleyballContent
              isMobile={isMobile}
              market={market}
              eventId={eventId}
              sportId={sportId}
              isLoading={isLoading}
              extraData={parsedExtraData as VolleyballExtraData}
              eventName={eventName}
              startDate={startDate}
              marketsCount={marketsCount}
            />
          );

        case SPORTS.SNOOKER:
          return (
            <SnookerContent
              isMobile={isMobile}
              market={market}
              eventId={eventId}
              sportId={sportId}
              isLoading={isLoading}
              extraData={parsedExtraData as SnookerExtraData}
              eventName={eventName}
              startDate={startDate}
              marketsCount={marketsCount}
            />
          );

        default:
          return (
            <FallbackContent
              isMobile={isMobile}
              market={market}
              eventId={eventId}
              sportId={sportId}
              isLoading={isLoading}
              extraData={parsedExtraData as unknown}
              eventName={eventName}
              startDate={startDate}
              marketsCount={marketsCount}
            />
          );
      }
    }

    return null;
  },
);
