import { useEffect } from 'react';

import { Box } from '~components/atoms/Box';
import { Text } from '~components/atoms/Typography';
import { useTranslation } from '~hooks/useTranslation';
import { useAppDispatch } from '~store';
import { setIsSwipeOpen } from '~store/slices/mobileSlice';
interface BetslipEmptyStakesProps {
  quickBet?: boolean;
}

export const BetslipEmptyStakes = ({ quickBet }: BetslipEmptyStakesProps) => {
  const { localized } = useTranslation();
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(setIsSwipeOpen(null));
  }, [quickBet]);

  return (
    <Box flexCol alignCenter justifyCenter gap={2} css={{ marginTop: '$4' }}>
      <Text
        color="grayMedium"
        css={{
          fontSize: '$lg',
          lineHeight: '$40',
          '@xs_sm': {
            fontSize: '$md',
            lineHeight: '$32',
            pt: '32px',
          },
        }}
      >
        {localized('betslip.empty.title')}
      </Text>
      <Text
        level="sm-3"
        color="grayMedium"
        textAlign="center"
        css={{
          fontSize: '$sm',
          lineHeight: '$20',
          '@xs_sm': {
            fontSize: '$xxs',
            lineHeight: '$16',
            width: '230px',
          },
        }}
      >
        {localized('betslip.empty.description')}
      </Text>
    </Box>
  );
};
