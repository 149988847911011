import { useGetLiveEventsMutation } from '~api/live/liveApi';
import { getErrorCode, getErrorMessage } from '~utils/backendErrors';

export const useGetLiveEvents = () => {
  const [mutation, { data, error, isError, isSuccess, isLoading }] =
    useGetLiveEventsMutation();

  return {
    getLiveEventsMutation: mutation,
    getLiveEventsData: data,
    getLiveEventsError: error,
    getLiveEventsIsError: isError,
    getLiveEventsErrorCode: getErrorCode(error, isError),
    getLiveEventsErrorMessage: getErrorMessage(error),
    getLiveEventsIsSuccess: isSuccess,
    getLiveEventsIsLoading: isLoading,
  };
};
