import { styled } from 'stitches.config';

import { Box } from '~components/atoms/Box';
import { Countdown } from '~components/atoms/Countdown';
import { Text } from '~components/atoms/Typography';
import { formatDateTime, TIME_FORMATS } from '~utils/formatDateTime';

const JackpotDateWrapper = styled(Box, {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'space-between',
  gap: '$4',
  height: '100%',
  '@sm': {
    gap: '$6',
  },
  '@xs': {
    gap: '$2',
  },
});

const JackpotDateText = styled(Text, {
  fontSize: '18px',
  lineHeight: '24px',
  textTransform: 'uppercase',
  '@xs': {
    fontSize: '11px',
    lineHeight: '24px',
  },
});

interface JackpotDateProps {
  startDate: string;
}

export const BannerDate = ({ startDate }: JackpotDateProps) => {
  return (
    <JackpotDateWrapper>
      <JackpotDateText textShadow="default">
        {formatDateTime(startDate, TIME_FORMATS.DATE_TIME_12H)}
      </JackpotDateText>
      <Countdown targetDate={startDate} />
    </JackpotDateWrapper>
  );
};
