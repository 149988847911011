import { createApi } from '@reduxjs/toolkit/query/react';

import { BACKEND_URLS, baseQueryWithReauth } from '~api/common/baseQuery';
import { buildQueryUrl } from '~utils/buildQueryUrl';

import {
  Categories,
  Countries,
  CountriesBySportParams,
  Leagues,
  LeaguesByCountryParams,
  PrematchData,
  PrematchOptionalParams,
  Sports,
} from './types';

export const categoryApi = createApi({
  reducerPath: 'categoryApi',
  baseQuery: (args, api) =>
    baseQueryWithReauth(args, api, { url: BACKEND_URLS.SNAPSHOT }),
  keepUnusedDataFor: 0,
  endpoints: (builder) => ({
    getSports: builder.query<Sports, void>({
      query: () => ({
        url: 'Category/active',
        method: 'GET',
      }),
    }),
    getCategoriesByDateTo: builder.query<Categories, string>({
      query: (dateTo) => ({
        url: `Category/active?DateTo=${dateTo}`,
        method: 'GET',
      }),
    }),
    getCountriesBySport: builder.query<Countries, CountriesBySportParams>({
      query: (params) => {
        const url = buildQueryUrl('Category/active', params);

        return {
          url,
          method: 'GET',
        };
      },
    }),
    getLeaguesByCountry: builder.query<Leagues, LeaguesByCountryParams>({
      query: (params) => {
        const url = buildQueryUrl('Category/active', params);

        return {
          url,
          method: 'GET',
        };
      },
    }),
    getPrematch: builder.query<PrematchData, PrematchOptionalParams>({
      query: (params) => {
        const url = buildQueryUrl('Menu/prematch', params);

        return {
          url,
          method: 'GET',
        };
      },
    }),
  }),
});

export const {
  useLazyGetCategoriesByDateToQuery,
  useLazyGetCountriesBySportQuery,
  useLazyGetSportsQuery,
  useLazyGetLeaguesByCountryQuery,
  useGetPrematchQuery,
  useLazyGetPrematchQuery,
} = categoryApi;
