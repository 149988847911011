import { useEffect, useMemo, useState } from 'react';

import { SportGroups } from '~api/category/types';
import { useMedia } from '~hooks/useMedia';
import { useTranslation } from '~hooks/useTranslation';
import {
  FavoriteGameIcon,
  FavoriteTournamentIcon,
  GameIcon,
  TournamentIcon,
} from '~icons';
import { useAppDispatch, useAppSelector } from '~store';
import { setOpenSportGroups } from '~store/slices/sportGroupsSlice';
import { SportEventsGroups } from '~types/events';

import { SPORT_GROUP_COMPONENTS } from '../constants';

export const useSportMenuGroups = () => {
  const { localized } = useTranslation();
  const { isMobileOrTablet } = useMedia();
  const dispatch = useAppDispatch();
  const { sports } = useAppSelector((state) => state.prematchMenu);
  const [groups, setGroups] = useState<SportEventsGroups>([]);

  const {
    favoriteTournaments = [],
    topTournaments = [],
    topSportEvents = [],
    openSportGroups = [],
  } = useAppSelector((state) => state.sportGroupsState);
  const { isUserLoggedIn } = useAppSelector((state) => state.userState);

  const { favoritePrematchEvents = [] } = useAppSelector(
    (state) => state.sportGroupsState,
  );

  const handleOnValueChange = async (e: string[]) => {
    dispatch(setOpenSportGroups(e));
  };

  useEffect(() => {
    const resArray: SportEventsGroups = [];

    if (topTournaments.length) {
      resArray.push({
        name: SPORT_GROUP_COMPONENTS.TOP_TOURNAMENT,
        label: localized('prematch.topTournaments'),
        id: 1,
        icon: TournamentIcon,
      });
    }

    if (favoriteTournaments.length && isUserLoggedIn) {
      resArray.push({
        name: SPORT_GROUP_COMPONENTS.FAVORITE_TOURNAMENT,
        label: localized('prematch.favoriteTournaments'),
        id: 3,
        icon: FavoriteTournamentIcon,
      });
    }

    if (favoritePrematchEvents.length && isUserLoggedIn) {
      resArray.push({
        name: SPORT_GROUP_COMPONENTS.FAVORITE_EVENT,
        label: localized('prematch.favoriteEvents'),
        id: 4,
        icon: FavoriteGameIcon,
      });
    }

    if (topSportEvents.length) {
      resArray.push({
        name: SPORT_GROUP_COMPONENTS.TOP_EVENT,
        label: localized('prematch.topEvents'),
        id: 5,
        icon: GameIcon,
      });
    }

    setGroups(resArray);
  }, [
    isUserLoggedIn,
    topTournaments,
    favoritePrematchEvents,
    favoriteTournaments,
    topSportEvents,
  ]);

  const groupCountsMemo = useMemo(() => {
    const favoriteTournamentsCount = favoriteTournaments.reduce(
      (total, tournament) => total + tournament.events,
      0,
    );

    const topTournamentsCount = topTournaments.reduce(
      (total, tournament) => total + tournament.events,
      0,
    );

    const favoriteEventsCount = favoritePrematchEvents?.length || null;

    const topEventsCount = topSportEvents.reduce(
      (total, sport) =>
        total +
        sport.dateGroups.reduce(
          (count, dateGroup) => count + dateGroup.matches.length,
          0,
        ),
      0,
    );

    return {
      [SPORT_GROUP_COMPONENTS.FAVORITE_TOURNAMENT]:
        favoriteTournamentsCount || 0,
      [SPORT_GROUP_COMPONENTS.TOP_TOURNAMENT]: topTournamentsCount || null,
      [SPORT_GROUP_COMPONENTS.FAVORITE_EVENT]: favoriteEventsCount,
      [SPORT_GROUP_COMPONENTS.TOP_EVENT]: topEventsCount,
    };
  }, [
    favoriteTournaments,
    favoritePrematchEvents,
    topTournaments,
    topSportEvents,
  ]);

  const sportGroupsMemo = useMemo(() => {
    const sportGroups: SportGroups = (sports || [])?.map(({ id, name }) => ({
      id,
      name,
      favoriteTournaments: [],
      topTournaments: [],
      topEvents: [],
      favoriteEvents: [],
    }));

    favoriteTournaments.forEach((tournament) => {
      sportGroups?.forEach((sportGroup) => {
        if (sportGroup.id === tournament.sportId) {
          sportGroup.favoriteTournaments = [
            ...sportGroup.favoriteTournaments!,
            tournament,
          ];
        }
      });
    });

    topTournaments.forEach((tournament) => {
      sportGroups?.forEach((sportGroup) => {
        if (sportGroup.id === tournament.sportId) {
          sportGroup.topTournaments = [
            ...sportGroup.topTournaments,
            tournament,
          ];
        }
      });
    });
    favoritePrematchEvents.forEach((event) => {
      sportGroups?.forEach((sportGroup) => {
        if (sportGroup.id === event.sportId) {
          sportGroup.favoriteEvents = [...sportGroup.favoriteEvents, event];
        }
      });
    });

    return sportGroups;
  }, [sports, topTournaments, favoriteTournaments, favoritePrematchEvents]);

  return {
    sports,
    groups,
    groupCountsMemo,
    sportGroupsMemo,
    openSportGroups,
    isMobileOrTablet,
    handleOnValueChange,
  };
};
