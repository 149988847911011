import { useEffect, useState } from 'react';

import { BalanceChange } from '~api/betslip/types';
import { useAppDispatch, useAppSelector } from '~store';
import {
  setBetslipErrors,
  setDuplicatedMultipleBetStakes,
} from '~store/slices/betslipSlice';
import { USER_DEFAULT_BALANCE } from '~store/slices/userSlice';
import {
  getBetTotalAmount,
  getIsLiveBetslip,
  getMinMaxStake,
} from '~utils/betslip';
import { areTwoObjectsEqual } from '~utils/objectHelpers';

import {
  BETSLIP_ERRORS,
  MIN_SYSTEM_BET_EVENTS,
  SPORT_BETSLIP_TYPE_OPTIONS,
} from '../constants';

export const useBetslipErrors = () => {
  const dispatch = useAppDispatch();
  const { eventsMap } = useAppSelector((state) => state.events);
  const {
    aggregatedBetAmount,
    events,
    stakeType,
    singleBetsAmountMap,
    balanceChangesOnBetData,
    isBalanceChangesLoaded,
    isBalanceChangesLoading,
    systemBetOption,
  } = useAppSelector((state) => state.betslip);
  const { partnerLimits, separateBonusBalance } = useAppSelector(
    (state) => state.settings,
  );
  const { isUserLoggedIn, defaultBalance } = useAppSelector(
    (state) => state.userState,
  );

  const isMultipleBet = stakeType === SPORT_BETSLIP_TYPE_OPTIONS.MULTIPLE;
  const isSystemBet = stakeType === SPORT_BETSLIP_TYPE_OPTIONS.SYSTEM;
  const isBonusBalanceSelected =
    !separateBonusBalance && defaultBalance === USER_DEFAULT_BALANCE.BONUS;
  const {
    isBalanceSufficient,
    isBonusBalanceSufficient,
    balanceChanges = [],
    totalActiveBonusesCount = 0,
  } = balanceChangesOnBetData || {};
  const [balanceChangesState, setBalanceChangesState] =
    useState<BalanceChange[]>(balanceChanges);

  useEffect(() => {
    if (areTwoObjectsEqual(balanceChanges, balanceChangesState)) return;
    setBalanceChangesState(balanceChanges);
  }, [balanceChanges, balanceChangesState]);

  useEffect(() => {
    const resErrors = [];
    const { sportMaxSelectionCount, sportMinSelectionCount } = partnerLimits;

    const isLiveBetslip = getIsLiveBetslip(eventsMap, events);

    const { min: sportMinStake, max: sportMaxStake } = getMinMaxStake(
      partnerLimits,
      isLiveBetslip,
    );

    if (isBalanceChangesLoading) return;

    if (!events.length) {
      dispatch(setBetslipErrors([]));

      return;
    }

    if (
      isBalanceSufficient !== undefined &&
      !isBalanceSufficient &&
      isUserLoggedIn
    ) {
      resErrors.push(BETSLIP_ERRORS.LOW_BALANCE);
    }

    if (
      (isBonusBalanceSelected || separateBonusBalance) &&
      isBonusBalanceSufficient !== undefined &&
      !isBonusBalanceSufficient &&
      isUserLoggedIn
    ) {
      resErrors.push(BETSLIP_ERRORS.LOW_BONUS_BALANCE);
    }

    if (isMultipleBet || isSystemBet) {
      const betTotalAmount = getBetTotalAmount(
        aggregatedBetAmount,
        separateBonusBalance,
        systemBetOption,
      );

      if (betTotalAmount < sportMinStake && betTotalAmount !== 0) {
        resErrors.push(BETSLIP_ERRORS.LOW_BET);
      }

      if (isMultipleBet && events.length < sportMinSelectionCount) {
        resErrors.push(BETSLIP_ERRORS.MIN_SELECTIONS);
      }

      if (isSystemBet && events.length < MIN_SYSTEM_BET_EVENTS) {
        resErrors.push(BETSLIP_ERRORS.MIN_SELECTIONS);
      }

      if (events.length > sportMaxSelectionCount) {
        resErrors.push(BETSLIP_ERRORS.MAX_SELECTIONS);
      }

      const { matchedBonusRules = 0 } = balanceChanges[0] || {
        matchedBonusRules: 0,
      };

      const bonusStakeAmount = Number(aggregatedBetAmount?.bonus) || 0;

      if (
        isUserLoggedIn &&
        isBalanceSufficient &&
        totalActiveBonusesCount > 0 &&
        matchedBonusRules === 0 &&
        bonusStakeAmount > 0
      ) {
        resErrors.push(BETSLIP_ERRORS.NOT_MET_WINBOOST);
      }

      if (betTotalAmount > sportMaxStake && sportMaxStake) {
        resErrors.push(BETSLIP_ERRORS.MAX_STAKE);
      }

      const seenIds: Record<string, boolean> = {};

      const duplicates = events
        .filter(({ eventId }) => {
          if (seenIds[eventId]) {
            return true;
          }

          seenIds[eventId] = true;

          return false;
        })
        .map(({ eventId }) => eventId);

      if (duplicates.length) {
        resErrors.push(BETSLIP_ERRORS.NOT_COMBINABLE);
        dispatch(setDuplicatedMultipleBetStakes(duplicates));
      } else {
        dispatch(setDuplicatedMultipleBetStakes([]));
      }
    } else {
      const selectionsIds = events.map(({ selectionId }) => selectionId);

      const isTotalStake =
        Object.values(singleBetsAmountMap).reduce(
          (acc, { main = 0, bonus = 0 }) =>
            acc + (Number(main) + Number(bonus)),
          0,
        ) > 0;

      const isAnyStakeTooLow = selectionsIds.some((id) => {
        const betTotalAmount = getBetTotalAmount(
          singleBetsAmountMap[id]!,
          separateBonusBalance,
        );

        return betTotalAmount < sportMinStake && sportMinStake > 0;
      });

      const isAnyStakeTooHigh = selectionsIds.some((id) => {
        const betTotalAmount = getBetTotalAmount(
          singleBetsAmountMap[id]!,
          separateBonusBalance,
        );

        return betTotalAmount > sportMaxStake && sportMaxStake > 0;
      });

      let isAnyBonusMatched;

      if (separateBonusBalance) {
        isAnyBonusMatched = Object.values(singleBetsAmountMap).some(
          ({ bonus = 0 }, index) => {
            const { matchedBonusRules = 0 } = balanceChanges[index] || {};

            if (!balanceChanges[index]) return false;

            return Number(bonus) > 0 && matchedBonusRules === 0;
          },
        );
      } else {
        isAnyBonusMatched = Object.values(singleBetsAmountMap).some(
          ({ main = 0 }) => Number(main) > 0,
        );
      }

      if (isUserLoggedIn && totalActiveBonusesCount > 0 && isAnyBonusMatched) {
        resErrors.push(BETSLIP_ERRORS.NOT_MET_WINBOOST);
      }

      if (isAnyStakeTooHigh && isTotalStake) {
        resErrors.push(BETSLIP_ERRORS.MAX_STAKE);
      }

      if (isAnyStakeTooLow && isTotalStake) {
        resErrors.push(BETSLIP_ERRORS.LOW_BET);
      }
    }

    if (!isUserLoggedIn && events.length) {
      resErrors.push(BETSLIP_ERRORS.LOGIN_TO_BET);
    }

    dispatch(setBetslipErrors(resErrors));
  }, [
    aggregatedBetAmount,
    singleBetsAmountMap,
    events,
    stakeType,
    isUserLoggedIn,
    isBalanceSufficient,
    isBonusBalanceSufficient,
    balanceChangesState,
    totalActiveBonusesCount,
    isBalanceChangesLoaded,
    isBalanceChangesLoading,
  ]);
};
