import React from 'react';

import {
  AccordionContent,
  AccordionTrigger,
  StyledAccordionItem,
  StyledAccordionRoot,
} from '~components/atoms/Accordion';
import { Box } from '~components/atoms/Box';
import { MainPageSkeleton } from '~components/atoms/Skeletons';
import { TouchSlider } from '~components/atoms/TouchSlider/TouchSlider';
import { Text } from '~components/atoms/Typography';
import { SportSliderItem } from '~components/molecules/PrematchMenu/SportSliderItem';
import { MAIN_PAGE_SECTIONS } from '~constants/mainPage';
import { formatDateTime, TIME_FORMATS } from '~utils/formatDateTime';

import { useUpcomingPrematch } from './hooks/useUpcomingPrematch';
import { EventsList } from './EventsList';
import { MatchHighlightsMarketsHeaders } from './MatchHighlightsMarketsHeaders';

interface UpcomingPrematchAccordionProps {
  label: MAIN_PAGE_SECTIONS;
}

export const UpcomingPrematchAccordion = ({
  label,
}: UpcomingPrematchAccordionProps) => {
  const {
    activeSportId,
    activeEventGroups,
    highlightsOpen,
    labelKey,
    mainSportMarkets,
    sports,
    isLoading,
    eventsListRef,
    localized,
    handleAccordionValueChange,
    handleActiveSportIdChange,
  } = useUpcomingPrematch({ label });

  if (!sports.length) {
    return <MainPageSkeleton />;
  }

  return (
    <StyledAccordionRoot
      type="multiple"
      onValueChange={handleAccordionValueChange}
      value={highlightsOpen}
    >
      <StyledAccordionItem value={labelKey}>
        <AccordionTrigger dark>
          <Text level="16-24" css={{ p: '$2 0' }}>
            {localized(`mainPage.sections.${label}`)}
          </Text>
        </AccordionTrigger>
        <AccordionContent
          css={{
            p: 0,
            backgroundColor: '$black',
            '& > .contentWrapper': {
              p: 0,
            },
          }}
        >
          <TouchSlider type="desktop">
            {sports.map(({ id, name }) => (
              <SportSliderItem
                isDesktop
                key={id}
                sport={name}
                className={id.toString() === activeSportId ? 'active' : ''}
                onClick={() => handleActiveSportIdChange(id.toString())}
              />
            ))}
          </TouchSlider>
          <Box ref={eventsListRef}>
            {isLoading ? (
              <MainPageSkeleton withSlider={false} />
            ) : (
              <Box
                flexCol
                fullWidth
                gap={1}
                css={{ background: '$panelBgColor', p: '$1' }}
              >
                {activeEventGroups &&
                  activeEventGroups.map(({ date, matches }) => {
                    const time = formatDateTime(date, TIME_FORMATS.DATE);

                    return (
                      <Box fullWidth flexCol key={date}>
                        <MatchHighlightsMarketsHeaders
                          label={time}
                          mainMarkets={mainSportMarkets}
                        />
                        {matches && !!matches.length && (
                          <EventsList
                            activeEventIds={matches}
                            mainMarkets={mainSportMarkets}
                          />
                        )}
                      </Box>
                    );
                  })}
              </Box>
            )}
          </Box>
        </AccordionContent>
      </StyledAccordionItem>
    </StyledAccordionRoot>
  );
};
