import React from 'react';

import { Box } from '~components/atoms/Box';
import { VoidEventPopover } from '~components/molecules/BetslipHistorySection/components/VoidEventPopover';
import {
  JACKPOT_SELECTION_STATUS,
  JACKPOT_SELECTION_STATUS_COLORS,
  JACKPOT_SELECTION_STATUS_LABELS,
} from '~constants/jackpot';
import { SPORTS } from '~constants/sport';
import { useAppSelector } from '~store';
import { selectMainMarketsBySportId } from '~store/slices/mainMarketsSlice';
import { JackpotBetEvent } from '~types/jackpot';
import { formatDateTime, TIME_FORMATS } from '~utils/formatDateTime';
import { getSportIcon } from '~utils/getSportIcon';

import { InfoContainer, InfoText } from './styled.components';

export const JackpotBetEventListItem = ({
  event,
}: {
  event: JackpotBetEvent;
}) => {
  const {
    sportName,
    countryName,
    leagueName,
    name,
    homeScore,
    awayScore,
    selections,
    sportId,
    marketName,
    startDate,
  } = event;

  const mainMarkets = useAppSelector(selectMainMarketsBySportId(sportId));
  const market = mainMarkets?.markets.find(
    (market) => market.name === marketName,
  );

  if (!market) return null;

  const score = !homeScore || !awayScore ? '-' : `${homeScore} - ${awayScore}`;
  const isStatusVoid = selections.some(
    (selection) => selection.status === JACKPOT_SELECTION_STATUS.REFUND,
  );

  return (
    <Box flexCol css={{ borderRadius: '$8', backgroundColor: '$grayDark' }}>
      <InfoContainer>
        <Box flexCol gap={1}>
          <Box flexRow alignCenter gap={2}>
            <Box css={{ color: '$grayMedium' }}>
              {getSportIcon(sportName as SPORTS, { width: 16, height: 16 })}
            </Box>
            <Box flexCol gap={1}>
              <InfoText color="grayMedium">{`${countryName}. ${leagueName}`}</InfoText>
              <InfoText color="grayMedium">
                {formatDateTime(startDate, TIME_FORMATS.DATE_TIME_12H)}
              </InfoText>
            </Box>
          </Box>
          <InfoText
            color={isStatusVoid ? 'grayMedium' : 'white'}
          >{`${name.replace('vs.', '-')}`}</InfoText>
        </Box>

        <Box flexCol css={{ alignSelf: 'end' }}>
          <InfoText
            color={isStatusVoid ? 'grayMedium' : 'white'}
            textAlign="right"
          >
            {score}
          </InfoText>
        </Box>
      </InfoContainer>
      <InfoContainer
        flexCol
        justifyCenter
        gap={1}
        css={{ borderBottom: 'none' }}
      >
        {selections.map((selection) => {
          const { selectionId, odds, status } = selection;
          const marketSelection = market.selections.find(
            (selection) => selection.id === selectionId,
          );

          if (!marketSelection) return null;

          return (
            <Box flexRow justifyContentBetween key={selectionId}>
              <InfoText
                color={isStatusVoid ? 'grayMedium' : 'white'}
              >{`${marketSelection.name} (${odds})`}</InfoText>
              <Box flexRow gap={2} alignCenter>
                <InfoText
                  textAlign="right"
                  color={JACKPOT_SELECTION_STATUS_COLORS[status]}
                >
                  {JACKPOT_SELECTION_STATUS_LABELS[status]}
                </InfoText>
                {isStatusVoid && <VoidEventPopover />}
              </Box>
            </Box>
          );
        })}
      </InfoContainer>
    </Box>
  );
};
