import { useAppDispatch } from '~store';
import { setTopPrematchEventsOpenDateGroupsBySport } from '~store/slices/sportGroupsSlice';

export const useTopPrematchEventsDateGroups = (sportId: string) => {
  const dispatch = useAppDispatch();

  const handleDateGroupOpenValuesChange = (value: string[]) => {
    dispatch(
      setTopPrematchEventsOpenDateGroupsBySport({ sportId, dateGroups: value }),
    );
  };

  return {
    handleDateGroupOpenValuesChange,
  };
};
