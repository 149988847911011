import { styled } from 'stitches.config';

import { Box } from '~components/atoms/Box';
import { CountryLeague } from '~components/molecules/Jackpot/Event/CountryLeague';
import { EventInfoText } from '~components/molecules/Jackpot/Settled/styled.components';
import { CountryName } from '~constants/flag';
import { JackpotEvent } from '~types/jackpot';
import { formatDateTime, TIME_FORMATS } from '~utils/formatDateTime';

export interface SmallScreenEventHeaderProps {
  event: JackpotEvent;
}

const EventHeaderWrapper = styled(Box, {
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  gridColumn: 'span 3',
  gap: '$2',
  p: '$2 $3',
  borderBottom: '1px solid $gray',
});

export const SmallScreenEventHeader = ({
  event,
}: SmallScreenEventHeaderProps) => {
  const { countryName, leagueName, startDate } = event;

  return (
    <EventHeaderWrapper>
      <CountryLeague
        countryName={countryName as CountryName}
        leagueName={leagueName!}
      />
      <EventInfoText css={{ color: '$grayMedium' }}>
        {formatDateTime(startDate, TIME_FORMATS.DATE_TIME_12H)}
      </EventInfoText>
    </EventHeaderWrapper>
  );
};
