import React, { useContext, useEffect } from 'react';

import {
  SharedRefContext,
  SharedRefContextProps,
} from '~contexts/SharedRefContext';
import { useAppDispatch, useAppSelector } from '~store';
import {
  setMobileUserProfileDialogActiveContent,
  setMobileUserProfileDialogState,
} from '~store/slices/mobileSlice';
import { setDialogScrolled } from '~store/slices/userUISlice';
import debounce from '~utils/debounce';

import { UserProfileContentMobile } from '../components/UserProfileContentMobile';
import { USER_PROFILE_DIALOG_MOBILE_STATE } from '../components/UserProfileDialogMobile';
import { UserProfileSidebar } from '../components/UserProfileSidebar';

import { useUserProfileDialog } from './useUserProfileDialog';

export const useUserProfileDialogMobile = () => {
  const dispatch = useAppDispatch();
  const { profile } = useUserProfileDialog();
  const { mobileUserProfileDialogState } = useAppSelector(
    (state) => state.mobileState,
  );
  const isContentState =
    mobileUserProfileDialogState === USER_PROFILE_DIALOG_MOBILE_STATE.CONTENT;

  const context = useContext(SharedRefContext) as SharedRefContextProps;

  useEffect(() => {
    const { ref: dialogMobileContentWrapperRef } = context;
    const current = dialogMobileContentWrapperRef.current;

    const handleDirectScroll = debounce(400, (event: Event) => {
      const element = event.target as HTMLDivElement;

      dispatch(setDialogScrolled(!!element.scrollTop));
    });

    if (current) {
      current.addEventListener('scroll', handleDirectScroll);
    }

    return () => {
      if (current) {
        current.removeEventListener('scroll', handleDirectScroll);
      }
    };
  }, []);

  useEffect(() => {
    return () => {
      dispatch(
        setMobileUserProfileDialogState(
          USER_PROFILE_DIALOG_MOBILE_STATE.SIDEBAR,
        ),
      );
      dispatch(setMobileUserProfileDialogActiveContent(null));
    };
  }, []);

  const componentsMap: Record<
    USER_PROFILE_DIALOG_MOBILE_STATE,
    React.ReactNode | null
  > = {
    [USER_PROFILE_DIALOG_MOBILE_STATE.SIDEBAR]: (
      <>{profile && <UserProfileSidebar profile={profile} />}</>
    ),
    [USER_PROFILE_DIALOG_MOBILE_STATE.CONTENT]: <UserProfileContentMobile />,
  };

  return {
    profile,
    isContentState,
    componentsMap,
    mobileUserProfileDialogState,
  };
};
