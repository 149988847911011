import { DialogMobileContent } from '~components/atoms/DialogMobileContent';

import { Betslip } from './Betslip';

export const BetslipMobileWrapper = () => {
  return (
    <DialogMobileContent isContentState={false}>
      <Betslip />
    </DialogMobileContent>
  );
};
