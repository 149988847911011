import { useState } from 'react';

import { Betslip } from '~components/molecules/Betslip';
import { RightSidebar } from '~components/organisms/RightSidebar';

export const SportsRightSidebar = () => {
  const [scrollPosition, setScrollPosition] = useState(0);

  return (
    <RightSidebar setScrollPosition={setScrollPosition}>
      <Betslip scrollPosition={scrollPosition} />
    </RightSidebar>
  );
};
