import { useCallback, useEffect, useState } from 'react';

import { BetAmount } from '~api/betslip/types';
import { useTranslation } from '~hooks/useTranslation';
import { useAppDispatch, useAppSelector } from '~store';
import {
  removeStakeBySelection,
  setBetslipProcessed,
  setSingleBetAmount,
  setStakeType,
} from '~store/slices/betslipSlice';
import { setIsSwipeOpen } from '~store/slices/mobileSlice';

import { SPORT_BETSLIP_TYPE_OPTIONS } from '../constants';

import { useBetslipPossibleWinCheck } from './useBetslipPossibleWinCheck';
import { useBetslipStakesActivity } from './useBetslipStakesActivity';

export const useBetslipStakes = (quickBet: boolean = false) => {
  const {
    events,
    singleBetsAmountMap,
    duplicatedMultipleBetStakes,
    stakeType,
    isProcessed,
    isProcessingFailed,
    betErrorCode,
    betErrorEvent,
  } = useAppSelector((state) => state.betslip);
  const { isOpen: isDialogOpen } = useAppSelector(
    (state) => state.globalDialog,
  );

  const { localized, localizedError } = useTranslation();

  useBetslipPossibleWinCheck();
  useBetslipStakesActivity();

  const dispatch = useAppDispatch();
  const [showNotification, setShowNotification] = useState(false);

  const handleRemoveStake = useCallback(
    (selectionId: string) => {
      if (quickBet) {
        dispatch(setIsSwipeOpen(null));
      }

      dispatch(removeStakeBySelection(selectionId));
    },
    [dispatch],
  );

  const handleAmountChange = useCallback(
    ({ id, amount }: { id: string; amount: BetAmount }) => {
      dispatch(setSingleBetAmount({ id, amount }));
    },
    [dispatch],
  );

  useEffect(() => {
    if (isProcessed) {
      setShowNotification(true);
      setTimeout(() => {
        dispatch(setBetslipProcessed(false));
        setShowNotification(false);
      }, 5000);
    }
  }, [isProcessed]);

  useEffect(() => {
    if ((events.length > 1 || isDialogOpen) && quickBet) {
      dispatch(setIsSwipeOpen(null));
    }

    if (events.length && isProcessed) {
      setShowNotification(false);
    }
  }, [events, isProcessed]);

  useEffect(() => {
    if (quickBet) {
      dispatch(setStakeType(SPORT_BETSLIP_TYPE_OPTIONS.SINGLE));
    }
  }, [quickBet]);

  return {
    isProcessingFailed,
    isProcessed,
    duplicatedMultipleBetStakes,
    singleBetsAmountMap,
    stakeType,
    betErrorCode,
    betErrorEvent,
    events,
    showNotification,
    localized,
    localizedError,
    handleAmountChange,
    handleRemoveStake,
  };
};
