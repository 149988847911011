import React, { useState } from 'react';

import { Box } from '~components/atoms/Box';
import { Button } from '~components/atoms/Button';
import { Select } from '~components/atoms/Select';
import { QUERY_PARAMS } from '~constants/common';
import { useMedia } from '~hooks/useMedia';
import { useRouterQuery } from '~hooks/useRouterQuery';
import { useTranslation } from '~hooks/useTranslation';
import { ClockIcon, GlobeIcon } from '~icons';
import { useAppSelector } from '~store';
import { SPORT_MENUS } from '~types/sportMenus';
import { TIME_RANGES } from '~types/timeRanges';
import { getLocalizedTimeRanges } from '~utils/getLocalizedTimeRanges';
import { QueryParams } from '~utils/url';

import { SidebarToggleButtons } from './SidebarToggleButtons';

interface ActionsPanelProps {
  handleSidebarToggle: (value: SPORT_MENUS) => void;
}

export const ActionsPanel = ({ handleSidebarToggle }: ActionsPanelProps) => {
  const { localized } = useTranslation();
  const { isMobileOrTablet } = useMedia();
  const { activeMenu } = useAppSelector((state) => state.sportsMenuState);
  const { removeQueryParams, updateQueryParams } = useRouterQuery();
  const [timeRange, setTimeRange] = useState(TIME_RANGES.ALL);
  const localizedTimeRanges = getLocalizedTimeRanges(localized);

  const handleTimeRangeChange = (value: TIME_RANGES) => {
    setTimeRange(value);
    if (value === TIME_RANGES.ALL) {
      removeQueryParams([QUERY_PARAMS.NEXT_HOUR]);
    } else {
      updateQueryParams({ [QUERY_PARAMS.NEXT_HOUR]: value } as QueryParams<
        typeof QUERY_PARAMS
      >);
    }
  };

  return (
    <>
      {!isMobileOrTablet && (
        <SidebarToggleButtons onToggle={handleSidebarToggle} />
      )}
      <Box>
        {activeMenu === SPORT_MENUS.PREMATCH ? (
          <Select
            ariaLabel={localized('sidebar.timeRanges.ariaLabel')}
            value={timeRange}
            onChange={(value) => handleTimeRangeChange(value as TIME_RANGES)}
            icon={<ClockIcon />}
            options={localizedTimeRanges}
            isCustomContentWidth
            contentWidth={118}
          />
        ) : (
          <Button iconButton>
            <GlobeIcon />
          </Button>
        )}
      </Box>
    </>
  );
};
