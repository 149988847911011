import { Select } from '~components/atoms/Select';
import { useTranslation } from '~hooks/useTranslation';
import { SettingsIcon } from '~icons';
import { useAppDispatch, useAppSelector } from '~store';
import { setOddAskType } from '~store/slices/betslipSlice';

import { getOddTypeOptions, ODD_ASK_TYPES } from '../../constants';

export const BetslipOddsAsk = () => {
  const { localized } = useTranslation();
  const dispatch = useAppDispatch();
  const { oddAskType } = useAppSelector((state) => state.betslip);
  const { defaultOddPolicyAcceptance } = useAppSelector(
    (state) => state.settings,
  );

  const oddTypeOptions = getOddTypeOptions(localized);

  return (
    <Select
      css={{
        gridArea: 'oddType',
        '& > button': {
          width: '100%',
        },
        '@xs_sm': {
          fontSize: '$xxs',
          lineHeight: '$20',
          borderRadius: '$4',
          p: '$1 0',
        },
      }}
      ariaLabel={localized('betslip.oddsAskAriaLabel')}
      value={oddAskType || defaultOddPolicyAcceptance.toString()}
      onChange={(value) => dispatch(setOddAskType(value as ODD_ASK_TYPES))}
      icon={<SettingsIcon />}
      options={oddTypeOptions}
    />
  );
};
