import { memo } from 'react';

import { BANNER_GROUP_NAMES, BannerList } from '~components/atoms/BannerList';
import { InplayEvent } from '~components/molecules/InplayEvents';
import { LiveMenu } from '~components/molecules/LiveMenu/components/LiveMenu';
import { useQueryParams } from '~hooks/useQueryParams';

export enum LIVE_TAB_CONTENTS {
  LIVE_MENU = 'liveMenu',
  LIVE_EVENT = 'liveEvent',
}

export const LiveTab = memo(() => {
  const { eventId } = useQueryParams();

  if (eventId) {
    return <InplayEvent />;
  } else {
    return (
      <>
        <BannerList names={[BANNER_GROUP_NAMES.HEADER]} />
        <LiveMenu />
      </>
    );
  }
});
