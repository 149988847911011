import React, { memo, useCallback, useEffect, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { styled } from 'stitches.config';

import { DIALOGS } from '~components/atoms/AbsoluteDialogs';
import { Box } from '~components/atoms/Box';
import { Footer } from '~components/organisms/Footer';
import { Header } from '~components/organisms/Header';
import { IframeHeader } from '~components/organisms/IframeHeader';
import { QUERY_PARAMS } from '~constants/common';
import { useInplayHubConnection } from '~hooks/useInplayHubConnection';
import useIsInIframe from '~hooks/useIsInIframe';
import { useQueryParams } from '~hooks/useQueryParams';
import { useRouterQuery } from '~hooks/useRouterQuery';
import { useShare } from '~hooks/useShare';
import { AppRoutes } from '~routes';
import { useAppDispatch } from '~store';
import { openDialog } from '~store/slices/globalDialogSlice';
import { getParamFromQuery } from '~utils/url';

const DesktopLayoutContainer = styled(Box, {
  display: 'flex',
  flexDirection: 'column',
  fontFamily: 'inherit',
  minHeight: '100vh',
  maxHeight: '100vh',
  backgroundColor: '$gray',
});

export const DesktopLayout = memo(() => {
  const { name } = useQueryParams();
  const { search } = useLocation();
  const dispatch = useAppDispatch();
  const isInIframe = useIsInIframe();

  useShare();
  useInplayHubConnection();

  const verifyEmailToken = useMemo(
    () => getParamFromQuery(search, QUERY_PARAMS.VERIFY_EMAIL_TOKEN),
    [search],
  );

  const { removeQueryParams } = useRouterQuery();
  const [, secondSegment] = location.pathname.split('/');

  const removeNextHourParam = useCallback(() => {
    removeQueryParams([QUERY_PARAMS.NEXT_HOUR]);
  }, [removeQueryParams]);

  const openVerifyEmailDialog = useCallback(() => {
    if (verifyEmailToken) {
      dispatch(openDialog(DIALOGS.VERIFY_EMAIL_TOKEN));
    }
  }, [verifyEmailToken, dispatch]);

  const scrollToTop = useCallback(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(removeNextHourParam, []);
  useEffect(openVerifyEmailDialog, [verifyEmailToken]);
  useEffect(scrollToTop, [name]);

  if (secondSegment && secondSegment.length) {
    return (
      <>
        {isInIframe ? <IframeHeader /> : <Header showSettings />}
        <AppRoutes />
        {!isInIframe && <Footer isInfoPage />}
      </>
    );
  }

  return (
    <DesktopLayoutContainer>
      {isInIframe ? <IframeHeader /> : <Header showSettings />}
      <AppRoutes />
    </DesktopLayoutContainer>
  );
});
