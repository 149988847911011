import { styled } from 'stitches.config';

import { Box } from '~components/atoms/Box';

export const StyledLeftSidebar = styled('aside', {
  display: 'flex',
  alignItems: 'flex-start',
  flexDirection: 'column',
  gridArea: 'sidebar',
  gap: '$1',
  pl: '$1',
});

export const StyledSportMenuWrapper = styled(Box, {
  background: '$panelBgColor',
  flexShrink: 1,
  flexGrow: 1,
  width: '100%',
  overflow: 'auto',
  borderTopLeftRadius: '$10',
  borderTopRightRadius: '$10',
  p: '$1 $1 0',
  variants: {
    transaparentBackground: {
      true: {
        background: 'transparent',
      },
    },
  },
});

export const StyledSportMenuInnerWrapper = styled(Box, {
  background: '$panelBgColor',
  height: '100%',
  width: '100%',
  overflow: 'hidden',
  borderTopLeftRadius: '$8',
  borderTopRightRadius: '$8',
  variants: {
    transaparentBackground: {
      true: {
        background: 'transparent',
      },
    },
  },
});
