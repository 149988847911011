import { Box } from '~components/atoms/Box';
import { EventStakes } from '~components/molecules/Jackpot/Active/components/Event/EventStakes';
import { SmallScreenEventHeader } from '~components/molecules/Jackpot/Event/SmallScreenEventHeader';
import { EventInfoText } from '~components/molecules/Jackpot/Settled/styled.components';

import { ActiveEventItemProps } from './Event';

export const EventSmall = ({ event, mainMarket }: ActiveEventItemProps) => {
  const { id, name, market: eventMarket } = event;

  // Name has the following format: 'HomeTeam vs. AwayTeam'
  const [homeTeam, awayTeam] = name.split('vs.');

  return (
    <Box
      flexCol
      css={{
        background: '$blackDarker',
        borderRadius: '$8',
      }}
    >
      <Box
        flexRow
        justifyContentBetween
        css={{ height: '40%', borderBottom: '1px solid $gray' }}
      >
        <SmallScreenEventHeader event={event} />
      </Box>

      <Box flexCol gap={3} css={{ padding: '$3 $3' }}>
        <EventInfoText>{`${homeTeam} - ${awayTeam}`}</EventInfoText>
        <EventStakes
          eventId={id}
          eventMarket={eventMarket}
          mainMarket={mainMarket}
        />
      </Box>
    </Box>
  );
};
