import { memo } from 'react';

import { Leagues } from '~api/category/types';
import { Tournaments } from '~api/sportEvent/types';
import { NavigationLink } from '~components/atoms/NavigationLink';
import { LeagueMenuListSkeleton } from '~components/atoms/Skeletons';
import { QUERY_PARAMS } from '~constants/common';
import { useMedia } from '~hooks/useMedia';
import { useQueryParams } from '~hooks/useQueryParams';
import { SPORT_MENUS } from '~types/sportMenus';
import { buildSearchQuery } from '~utils/url';

import { LeagueMenuItem } from './LeagueMenuItem';

interface LeagueMenuListProps {
  countryId: number;
  sportId: number;
  leagues: Leagues | Tournaments;
  initialLeagues: Leagues | Tournaments;
  countryName?: string;
  showItemCountryFlag?: boolean;
  showFavoriteIcon?: boolean;
  loadingCountryId?: number;
  onClick?: () => void;
}

export const LeagueMenuList = memo(
  ({
    loadingCountryId,
    showItemCountryFlag,
    showFavoriteIcon,
    countryId,
    sportId,
    leagues,
    initialLeagues = [],
    countryName,
    onClick,
  }: LeagueMenuListProps) => {
    const {
      leagueId: activeLeague,
      nextHour,
      menu: activeMenu,
    } = useQueryParams();
    const { isMobileOrTablet } = useMedia();

    const showMobileLoading =
      loadingCountryId === countryId && countryName && isMobileOrTablet;

    const onLeagueClick = () => {
      if (onClick) {
        onClick();
      }
    };

    if (showMobileLoading) {
      return <LeagueMenuListSkeleton />;
    }

    return (
      <>
        {(initialLeagues?.length > 0 ? initialLeagues : leagues || []).map(
          ({
            id,
            name,
            events,
            countryName: leagueCountryName,
            countryId: leagueCountryId,
            sportName: leagueSportName,
          }) => (
            <NavigationLink
              onClick={onLeagueClick}
              css={{
                mb: '$1',
                '@xs_sm': {
                  '&:last-child': {
                    mb: 0,
                  },
                },
              }}
              key={id}
              to={{
                search: buildSearchQuery({
                  [QUERY_PARAMS.SPORT_ID]: sportId,
                  [QUERY_PARAMS.COUNTRY_ID]: countryId || leagueCountryId,
                  [QUERY_PARAMS.LEAGUE_ID]: id,
                  [QUERY_PARAMS.MENU]: activeMenu || SPORT_MENUS.PREMATCH,
                  nextHour: nextHour || undefined,
                }),
              }}
              end
            >
              <LeagueMenuItem
                id={id}
                countryId={leagueCountryId!}
                sportName={leagueSportName}
                name={name}
                events={events}
                isActive={activeLeague ? id === parseInt(activeLeague) : false}
                countryName={countryName || leagueCountryName!}
                sportId={sportId}
                showFavoriteIcon={showFavoriteIcon}
                showItemCountryFlag={showItemCountryFlag}
              />
            </NavigationLink>
          ),
        )}
      </>
    );
  },
);
