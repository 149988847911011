import { useEffect, useMemo } from 'react';
import { styled } from 'stitches.config';

import { Select } from '~components/atoms/Select';
import { useTranslation } from '~hooks/useTranslation';
import { useAppDispatch, useAppSelector } from '~store';
import { setSystemBetOption } from '~store/slices/betslipSlice';
import { calculateSystemBetOptions } from '~utils/betslip';

const StyledSystemBetOptionSelector = styled(Select, {
  gridArea: 'systemBetOptions',
  '& > button': {
    width: '100%',
  },
  '@xs_sm': {
    fontSize: '$xxs',
    lineHeight: '$20',
    borderRadius: '$4',
  },
});

export const BetslipSystemBetOptions = () => {
  const { localized } = useTranslation();
  const dispatch = useAppDispatch();
  const { events, systemBetOption } = useAppSelector((state) => state.betslip);

  const systemBetOptions = useMemo(
    () => calculateSystemBetOptions(events.length),
    [events.length],
  );

  useEffect(() => {
    // Check if selected system bet option is still available, if not - select the first one
    const selectedOption = systemBetOptions.find(
      (option) =>
        option.value === systemBetOption?.value &&
        option.combinationsCount === systemBetOption?.combinationsCount,
    );

    if (!selectedOption && systemBetOptions.length > 0) {
      dispatch(setSystemBetOption(systemBetOptions[0]!));
    }
  }, [systemBetOptions]);

  const handleSystemBetOptionChange = (value: string) => {
    const selectedOption = systemBetOptions.find(
      (option) => option.value === value,
    );

    if (selectedOption) {
      dispatch(setSystemBetOption(selectedOption));
    }
  };

  return (
    <StyledSystemBetOptionSelector
      disabled={systemBetOptions.length <= 1}
      ariaLabel={localized('betslip.selectSystemBetOption')}
      value={systemBetOption?.value || systemBetOptions[0]?.value}
      onChange={(value) => handleSystemBetOptionChange(value)}
      options={systemBetOptions}
    />
  );
};
