import { styled } from 'stitches.config';

import { Box } from '~components/atoms/Box';
import { Text } from '~components/atoms/Typography';

// Jackpot event information
export const EventListItemWrapper = styled(Box, {
  width: '100%',
  display: 'grid',
  gridTemplateColumns: '50% 1fr 1fr',
  gridTemplateRows: '40% 60%',
  background: '$blackDarker',
  borderRadius: '$8',
});

export const EventInfoText = styled(Text, {
  fontSize: '14px',
  lineHeight: '24px',

  '@md': {
    fontSize: '13px',
  },
  '@sm': {
    fontSize: '13px',
  },
  '@xs': {
    fontSize: '12px',
  },
});

export const LargeScreenEventHeader = styled(Box, {
  width: '100%',
  display: 'flex',
  alignContent: 'center',
  justifyContent: 'start',
  gap: '$2',
  p: '$2 $4',
  borderBottom: '1px solid $gray',
  borderRight: '1px solid $gray',
});
