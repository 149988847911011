import React, { useEffect, useMemo, useRef, useState } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { registerSW } from 'virtual:pwa-register';

import { AbsoluteDialogs } from '~components/atoms/AbsoluteDialogs';
import { DesktopLayout } from '~components/atoms/layouts/DesktopLayout';
import { MobileLayout } from '~components/atoms/layouts/MobileLayout';
import { useMedia } from '~hooks/useMedia';
import { useAppDispatch, useAppSelector } from '~store';
import { resetLiveMenuSlice } from '~store/slices/liveMenuSlice';
import { resetPrematchSlice } from '~store/slices/prematchMenuSlice';
import { useOnAppInit } from '~useOnAppInit';

const intervalMS = 1000 * 60 * 10;

registerSW({
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  onRegistered(r) {
    r.update();
    r &&
      setInterval(() => {
        r.update();
      }, intervalMS);
  },
});

export const AppWrapper = () => {
  useOnAppInit();
  const { isMobileOrTablet } = useMedia();
  const isMobile = useMemo(() => navigator.userAgent.indexOf('Mobi') > -1, []);

  useEffect(() => {
    const path = window.location.pathname;

    if (path.startsWith('//')) {
      window.location.href = '/notFound';
    }
  }, []);

  useEffect(() => {
    const handleMessage = (event: MessageEvent) => {
      if (event.data && event.data.token) {
        const iframeToken = event.data.token;

        // TODO => Use this token for iframe implementation later
        console.log('iframeToken =>', iframeToken);
      }
    };

    window.addEventListener('message', handleMessage);

    return () => {
      window.removeEventListener('message', handleMessage);
    };
  }, []);

  return (
    <>
      <Router>
        {isMobileOrTablet || isMobile ? <MobileLayout /> : <DesktopLayout />}
        <AbsoluteDialogs />
      </Router>
    </>
  );
};

const App = () => {
  const dispatch = useAppDispatch();
  const [isAppVisible, setIsAppVisible] = useState(true);
  const { language } = useAppSelector((state) => state.websiteSettings);
  const prevLanguageRef = useRef(language);

  useEffect(() => {
    if (language === prevLanguageRef.current) return;
    prevLanguageRef.current = language;

    setIsAppVisible(false);
    dispatch(resetPrematchSlice());
    dispatch(resetLiveMenuSlice());
    setTimeout(() => {
      setIsAppVisible(true);
    }, 200);
  }, [language]);

  if (!isAppVisible) return null;

  return <AppWrapper />;
};

export default App;
