import React, { useMemo, useState } from 'react';
import { CellProps, Column } from 'react-table';

import {
  BetslipHistoryDetails,
  BetslipHistoryItem,
  UserBonusBalanceChanges,
} from '~api/betslip/types';
import { SPORT_BETSLIP_TYPES_MAP } from '~components/molecules/Betslip/constants';
import { BetslipTypeCell } from '~components/molecules/BetslipHistorySection/Sports/BetslipHistoryTable/components/BetslipTypeCell';
import { OddsCell } from '~components/molecules/BetslipHistorySection/Sports/BetslipHistoryTable/components/OddsCell';
import { useTranslation } from '~hooks/useTranslation';

import { TableIconExpandCell } from '../../components/TableIconExpandCell';
import { getSectionName } from '../../historyUtils';

import { BetslipHistoryInnerTable } from './BetslipHistoryInnerTable';
import {
  BetslipHistoryStatusCell,
  DateAndIdCell,
  StakeCell,
  WinAmountCell,
  WinAmountHeaderCell,
} from './components';

export interface Row {
  original: {
    type: keyof typeof SPORT_BETSLIP_TYPES_MAP;
    status: number;
    availableCashout: number;
    details: BetslipHistoryDetails[];
    possibleWin: number;
    bonusBalanceUsages: UserBonusBalanceChanges;
    winBoostAmount: number;
    winBoostMultiplier: number;
    originalWinAmount: number;
  };
}

export const useBetslipHistoryTable = () => {
  const { localized } = useTranslation();
  const [openRows, setOpenRows] = useState<Record<string, boolean>>({});

  const renderExpandedRowContent = (original: BetslipHistoryItem) => {
    return (
      <BetslipHistoryInnerTable betHistoryDetails={original.details || []} />
    );
  };

  const renderIconOuterCell = ({ cell }: CellProps<BetslipHistoryItem>) => {
    return (
      <TableIconExpandCell cell={cell} isOpen={openRows[cell.row.index]} />
    );
  };

  const renderSectionOuterCell = ({ row: { original } }: { row: Row }) => {
    return getSectionName(original?.details);
  };

  const handleOuterTableRowClick = (rowIndex: number) => () => {
    setOpenRows((prev) => ({
      ...prev,
      [rowIndex]: !prev[rowIndex],
    }));
  };

  const getOpenRowsValue = (rowIndex: number) =>
    openRows[rowIndex] ? [openRows[rowIndex]?.toString() || ''] : [];

  const columnsOuter = useMemo(
    () => [
      {
        Header: localized(
          'betslipHistory.betslipHistoryTable.tableHeaders.dateAndId',
        ),
        accessor: 'id',
        Cell: DateAndIdCell,
      },
      {
        Header: localized(
          'betslipHistory.betslipHistoryTable.tableHeaders.section',
        ),
        accessor: 'section',
        Cell: renderSectionOuterCell,
      },
      {
        Header: localized(
          'betslipHistory.betslipHistoryTable.tableHeaders.betType',
        ),
        accessor: 'type',
        Cell: ({ row }) => (
          <BetslipTypeCell betslipHistoryItem={row.original} />
        ),
      },
      {
        Header: localized(
          'betslipHistory.betslipHistoryTable.tableHeaders.stake',
        ),
        accessor: 'stake',
        Cell: StakeCell,
      },
      {
        Header: localized(
          'betslipHistory.betslipHistoryTable.tableHeaders.odds',
        ),
        accessor: 'totalOdd',
        Cell: ({ row }) => (
          <OddsCell type={row.original.type} totalOdd={row.original.totalOdd} />
        ),
      },
      {
        Header: localized(
          'betslipHistory.betslipHistoryTable.tableHeaders.status',
        ),
        accessor: 'status',
        Cell: ({ row }) => (
          <BetslipHistoryStatusCell status={row.original.status} />
        ),
      },
      {
        Header: WinAmountHeaderCell,
        accessor: 'winAmount',
        Cell: WinAmountCell,
      },
      {
        Header: '',
        accessor: 'icon',
        Cell: renderIconOuterCell,
      },
    ],
    [openRows],
  ) as Column<BetslipHistoryItem>[];

  return {
    columnsOuter,
    openRows,
    getOpenRowsValue,
    handleOuterTableRowClick,
    renderExpandedRowContent,
  };
};
