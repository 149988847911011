import { styled } from 'stitches.config';

import { Box } from '~components/atoms/Box';

export const SearchWrapper = styled(Box, {
  position: 'relative',
  '& input:focus + div path': {
    fill: '$white',
  },
  '& input:focus': {
    boxShadow: '$inputBorderFocus',
  },
  '& .prefixIcon, & .prefixIcon svg': {
    width: '$4',
    height: '$4',
  },
});
