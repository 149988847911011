import { memo, useEffect, useMemo, useState } from 'react';

import { SportEventItems } from '~api/sportEvent/types';
import { Box } from '~components/atoms/Box';
import { Checkbox } from '~components/atoms/Checkbox';
import { ScrollArea } from '~components/atoms/ScrollArea';
import { Text } from '~components/atoms/Typography';
import { EVENT_STATUS } from '~constants/common';
import { useTranslation } from '~hooks/useTranslation';

import { SearchResult } from './SearchResult';
import {
  StyledSearchResultListHeader,
  StyledSearchResultListWrapper,
} from './styled.components';

export interface SearchResultListProps {
  hasResults: boolean;
  searchEvents: SportEventItems;
  isRelative?: boolean;
}

export const SearchResultList = memo(
  ({ isRelative = false, hasResults, searchEvents }: SearchResultListProps) => {
    const { localized } = useTranslation();
    const [results, setResults] = useState<SportEventItems>(searchEvents);
    const [showLive, setShowLive] = useState(true);
    const [showPrematch, setShowPrematch] = useState(true);

    const { liveCount, prematchCount } = useMemo(() => {
      let liveCount = 0;
      let prematchCount = 0;

      searchEvents.forEach(({ status }) => {
        const isLive = status === EVENT_STATUS.IN_PROGRESS;

        if (isLive) {
          liveCount++;
        } else {
          prematchCount++;
        }
      });

      return { liveCount, prematchCount };
    }, [searchEvents]);

    useEffect(() => {
      setResults(
        searchEvents.filter(({ status }) => {
          if (showLive && status === EVENT_STATUS.IN_PROGRESS) {
            return true;
          }

          return showPrematch && status !== EVENT_STATUS.IN_PROGRESS;
        }),
      );
    }, [searchEvents, showLive, showPrematch]);

    return (
      <StyledSearchResultListWrapper isRelative={isRelative}>
        <ScrollArea>
          <StyledSearchResultListHeader
            css={{
              borderBottom: hasResults ? '1px solid $gray' : 'none',
            }}
          >
            <Box flexCol>
              <Box flexRow gap={4}>
                <Checkbox
                  checked={showLive}
                  label={localized('search.showLive')}
                  defaultChecked={showLive}
                  additionalLabel={`(${liveCount})`}
                  onCheckedChange={(checked) => {
                    setShowLive(checked as boolean);
                  }}
                />
                <Checkbox
                  checked={showPrematch}
                  label={localized('search.showPrematch')}
                  defaultChecked={showPrematch}
                  additionalLabel={`(${prematchCount})`}
                  onCheckedChange={(checked) => {
                    setShowPrematch(checked as boolean);
                  }}
                />
              </Box>
            </Box>
            <Text
              level="14-20"
              color="grayMedium"
              css={{
                my: '$2',
              }}
            >
              {results.length
                ? `${localized('search.results.title')}: ${results.length}`
                : localized('search.results.noResults')}
            </Text>
          </StyledSearchResultListHeader>
          {results.map(
            (
              {
                id: eventId,
                name,
                startDate,
                leagueName,
                status,
                countryId,
                leagueId,
                sportId,
              },
              index,
            ) => (
              <SearchResult
                key={eventId}
                sportId={sportId}
                countryId={countryId}
                leagueId={leagueId}
                eventId={eventId}
                leagueName={leagueName}
                eventTitle={name}
                eventDate={startDate}
                status={status}
                index={index}
              />
            ),
          )}
        </ScrollArea>
      </StyledSearchResultListWrapper>
    );
  },
);
