import { createApi } from '@reduxjs/toolkit/query/react';

import { BetslipItems } from '~api/betslip/types';
import { BACKEND_URLS, baseQueryWithReauth } from '~api/common/baseQuery';
import { SportMainMarkets } from '~api/market/types';
import { SportEventsMainData } from '~types/events';

import {
  InPlayMenuSports,
  LiveDataMock,
  LoadMoreUpcomingEvents,
  SportData,
  SportEventItems,
  TopInplayEvents,
  TopPrematchEvents,
  Tournaments,
} from './types';

export const sportEventApi = createApi({
  reducerPath: 'sportEventApi',
  baseQuery: (args, api) =>
    baseQueryWithReauth(args, api, { url: BACKEND_URLS.SNAPSHOT }),
  keepUnusedDataFor: 0,
  endpoints: (builder) => ({
    getBetslipAddedEvents: builder.mutation<SportEventItems, BetslipItems>({
      query: (body) => ({
        url: 'EventSelection',
        method: 'POST',
        body,
      }),
    }),
    getPrematchEventsByLeagueId: builder.query<
      LiveDataMock,
      { dateTo: string; leagueId: string }
    >({
      query: ({ leagueId, dateTo }) => ({
        url: `Event/prematch?LeagueId=${leagueId}${
          dateTo ? `&dateTo=${dateTo}` : ''
        }`,
        method: 'GET',
      }),
    }),
    getUpcomingEvents: builder.query<TopPrematchEvents, string | null>({
      query: (sportId) => {
        return {
          url: `Event/upcoming`,
          method: 'GET',
          params: sportId
            ? {
                sportId,
              }
            : {},
        };
      },
    }),
    getMoreUpcomingEvents: builder.query<
      TopPrematchEvents,
      LoadMoreUpcomingEvents
    >({
      query: (params) => {
        return {
          url: `Data/upcoming-games`,
          method: 'GET',
          params: params,
        };
      },
    }),
    getLiveHighlights: builder.query<InPlayMenuSports, void>({
      query: () => ({
        url: 'inplay/highlights',
        method: 'GET',
      }),
    }),
    getFavoriteTournaments: builder.query<Tournaments, void>({
      query: () => ({
        url: 'favorite/tournament',
        method: 'GET',
      }),
    }),
    getTopTournaments: builder.query<Tournaments, void>({
      query: () => ({
        url: 'top/tournament',
        method: 'GET',
      }),
    }),
    setTournamentAsFavorite: builder.mutation<void, Record<string, number>>({
      query: ({ id }) => ({
        url: `favorite/tournament/${id}`,
        method: 'POST',
      }),
    }),
    deleteTournamentFromFavorites: builder.mutation<
      void,
      Record<string, number>
    >({
      query: ({ id }) => ({
        url: `favorite/tournament/${id}`,
        method: 'DELETE',
      }),
    }),
    getTopEventInplay: builder.query<TopInplayEvents, void>({
      query: () => ({
        url: 'top/match/in-play',
        method: 'GET',
      }),
    }),
    getTopEventPrematch: builder.query<TopPrematchEvents, void>({
      query: () => ({
        url: 'top/match/pre-match',
        method: 'GET',
      }),
    }),
    getFavoriteEventInplay: builder.query<SportEventsMainData, void>({
      query: () => ({
        url: 'favorite/match/in-play',
        method: 'GET',
      }),
    }),
    getFavoriteEventPrematch: builder.query<SportEventsMainData, void>({
      query: () => ({
        url: 'favorite/match/pre-match',
        method: 'GET',
      }),
    }),
    addFavoriteEvent: builder.mutation<void, { eventId: string }>({
      query: ({ eventId }) => ({
        url: `favorite/match/${eventId}`,
        method: 'POST',
      }),
    }),
    deleteFavoriteEvent: builder.mutation<void, { eventId: string }>({
      query: ({ eventId }) => ({
        url: `favorite/match/${eventId}`,
        method: 'DELETE',
      }),
    }),
    getMainMarkets: builder.query<SportMainMarkets, void>({
      query: () => ({
        url: 'market/main',
        method: 'GET',
      }),
    }),
    getData: builder.query<SportData, { sportId?: number }>({
      query: (params) => ({
        url: 'data',
        method: 'GET',
        params,
      }),
    }),
  }),
});

export const {
  useLazyGetFavoriteTournamentsQuery,
  useLazyGetTopTournamentsQuery,
  useSetTournamentAsFavoriteMutation,
  useDeleteTournamentFromFavoritesMutation,
  useGetBetslipAddedEventsMutation,
  useLazyGetPrematchEventsByLeagueIdQuery,
  useLazyGetTopEventInplayQuery,
  useLazyGetTopEventPrematchQuery,
  useLazyGetFavoriteEventInplayQuery,
  useLazyGetFavoriteEventPrematchQuery,
  useAddFavoriteEventMutation,
  useDeleteFavoriteEventMutation,
  useLazyGetUpcomingEventsQuery,
  useLazyGetLiveHighlightsQuery,
  useLazyGetMainMarketsQuery,
  useLazyGetDataQuery,
  useLazyGetMoreUpcomingEventsQuery,
} = sportEventApi;
