import { MainMarketSelection } from '~api/market/types';
import { Box } from '~components/atoms/Box';
import { EventStakes } from '~components/molecules/Jackpot/Active/components/Event/EventStakes';
import { CountryLeague } from '~components/molecules/Jackpot/Event/CountryLeague';
import { InfoWrapper } from '~components/molecules/Jackpot/Event/InfoWrapper';
import {
  EventInfoText,
  LargeScreenEventHeader,
} from '~components/molecules/Jackpot/Settled/styled.components';
import { CountryName } from '~constants/flag';
import { formatDateTime, TIME_FORMATS } from '~utils/formatDateTime';

import { ActiveEventItemProps } from './Event';

export const EventLarge = ({ event, mainMarket }: ActiveEventItemProps) => {
  const {
    id,
    countryName,
    leagueName,
    name,
    startDate,
    market: eventMarket,
  } = event;

  return (
    <Box
      flexCol
      css={{
        background: '$blackDarker',
        borderRadius: '$8',
      }}
    >
      {/* Header section */}
      <Box flexRow justifyContentBetween css={{ height: '40%' }}>
        <LargeScreenEventHeader css={{ border: 'none', width: '50%' }}>
          <CountryLeague
            countryName={countryName as CountryName}
            leagueName={leagueName!}
          />
        </LargeScreenEventHeader>
        <Box flexRow css={{ width: '50%' }}>
          {mainMarket.selections.map((selection: MainMarketSelection) => (
            <InfoWrapper
              key={selection.id}
              isHeader
              title={selection.name}
              css={{ width: '100%', height: '100%' }}
            />
          ))}
        </Box>
      </Box>
      {/* Event info section */}
      <Box flexRow justifyContentBetween css={{ height: '60%' }}>
        <Box
          flexCol
          justifyCenter
          alignStart
          css={{
            width: '50%',
            padding: '$2 $3',
          }}
        >
          <EventInfoText>{`${name.replace('vs.', '-')}`}</EventInfoText>
          <EventInfoText css={{ color: '$grayMedium' }}>
            {formatDateTime(startDate, TIME_FORMATS.DATE_TIME_12H)}
          </EventInfoText>
        </Box>
        <Box flexCol justifyCenter css={{ width: '50%', padding: '$2' }}>
          <EventStakes
            eventId={id}
            eventMarket={eventMarket}
            mainMarket={mainMarket}
          />
        </Box>
      </Box>
    </Box>
  );
};
