import { Cross } from '~components/atoms/Cross';
import { Loader } from '~components/atoms/Loader';
import { SearchIcon } from '~icons';

import { Box } from '../Box';
import { Input } from '../Input';

import { SearchWrapper } from './styled.components';
import { useSearch } from './useSearch';

interface SearchProps {
  query: string;
  setQuery: (value: string) => void;
  onSearch: (value: string) => void;
  onClear: () => void;
}

export const Search = ({ query, setQuery, onSearch, onClear }: SearchProps) => {
  const {
    query: searchQuery,
    isLoading,
    localized,
    handleSearch,
    handleClear,
  } = useSearch(query, setQuery, onSearch, onClear);

  return (
    <SearchWrapper>
      <Input
        search
        value={searchQuery}
        placeholder={localized('search.placeholder')}
        onChange={handleSearch}
      />
      <Box
        css={{
          position: 'absolute',
          left: 0,
          top: 0,
          bottom: 0,
          p: '$2',
          lineHeight: 0,
        }}
      >
        {isLoading ? (
          <Loader className="prefixIcon" />
        ) : (
          <SearchIcon className="prefixIcon" />
        )}
      </Box>
      {!!searchQuery && <Cross onClick={handleClear} />}
    </SearchWrapper>
  );
};
