import { useEffect, useState } from 'react';

import { useLazyGetSearchEvents } from '~api/search/searchQueries';
import { SportEventItems } from '~api/sportEvent/types';
import { Box } from '~components/atoms/Box';
import { Card } from '~components/atoms/Card';
import { Search } from '~components/atoms/Search';
import { useAppDispatch } from '~store';
import {
  changeSearchPerformed,
  setSearchQuery,
} from '~store/slices/sportEventSlice';
import { SPORT_MENUS } from '~types/sportMenus';

import { ActionsPanel } from './ActionsPanel';
import { SearchResultList } from './SearchResultList';

interface FiltersPanelProps {
  searchPerformed: boolean;
  query: string;
  handleSidebarToggle: (value: SPORT_MENUS) => void;
}

export const FiltersPanel = ({
  query,
  searchPerformed,
  handleSidebarToggle,
}: FiltersPanelProps) => {
  const [searchEvents, setSearchEvents] = useState<SportEventItems>([]);
  const hasResults = searchEvents?.length > 0;

  const { lazyGetSearchEventsQuery } = useLazyGetSearchEvents();
  const dispatch = useAppDispatch();

  const onSearchHandler = async (term: string) => {
    const sportEvents: SportEventItems =
      await lazyGetSearchEventsQuery(term).unwrap();

    dispatch(changeSearchPerformed(true));
    setSearchEvents(sportEvents);
  };

  const onClearHandle = () => {
    dispatch(changeSearchPerformed(false));
    dispatch(setSearchQuery(''));
  };

  useEffect(() => {
    return () => {
      dispatch(changeSearchPerformed(false));
    };
  }, []);

  return (
    <Card
      flexCol
      gap={2}
      flatBottom={searchPerformed}
      noXPadding={searchPerformed}
      css={{
        position: 'relative',
        flexShrink: 0,
        flexGrow: 0,
      }}
    >
      <Box css={{ px: searchPerformed ? '$4' : '0' }}>
        <Search
          query={query}
          setQuery={(value) => {
            dispatch(setSearchQuery(value));
          }}
          onSearch={onSearchHandler}
          onClear={onClearHandle}
        />
      </Box>
      <ActionsPanel handleSidebarToggle={handleSidebarToggle} />
      {searchPerformed && (
        <SearchResultList hasResults={hasResults} searchEvents={searchEvents} />
      )}
    </Card>
  );
};
