import { DIALOGS } from '~components/atoms/AbsoluteDialogs';
import { Box } from '~components/atoms/Box';
import { Button } from '~components/atoms/Button';
import { Text } from '~components/atoms/Typography';
import { USER_PROFILE_NAVIGATION_TABS } from '~components/molecules/UserProfile/components/UserProfileDialog/constants';
import { USER_PROFILE_DIALOG_MOBILE_STATE } from '~components/molecules/UserProfile/components/UserProfileDialogMobile';
import { useMedia } from '~hooks/useMedia';
import { useTranslation } from '~hooks/useTranslation';
import { ArrowRightIcon } from '~icons';
import { useAppDispatch, useAppSelector } from '~store';
import { openDialog } from '~store/slices/globalDialogSlice';
import {
  setMobileUserProfileDialogActiveContent,
  setMobileUserProfileDialogState,
} from '~store/slices/mobileSlice';
import { setMainTab } from '~store/slices/personalDetailsSlice';

export const OpenBetHistory = () => {
  const { localized } = useTranslation();
  const dispatch = useAppDispatch();
  const { isMobileOrTablet } = useMedia();
  const { isUserLoggedIn } = useAppSelector((state) => state.userState);

  if (!isUserLoggedIn) return null;

  return (
    <Box css={{ p: '0 $4 $4' }}>
      <Button
        fullWidth
        css={{
          background: '$grayDarkMain2',
          textTransform: 'none',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          gap: '$1',
          color: '$grayMedium',
        }}
        onClick={() => {
          if (isMobileOrTablet) {
            dispatch(openDialog(DIALOGS.USER_PROFILE_MOBILE));
            dispatch(
              setMobileUserProfileDialogState(
                USER_PROFILE_DIALOG_MOBILE_STATE.CONTENT,
              ),
            );
            dispatch(
              setMobileUserProfileDialogActiveContent(
                USER_PROFILE_NAVIGATION_TABS.HISTORY,
              ),
            );
          } else {
            dispatch(openDialog(DIALOGS.USER_PROFILE));
            dispatch(setMainTab({ tab: USER_PROFILE_NAVIGATION_TABS.HISTORY }));
          }
        }}
      >
        <Text level="sm-3" color="grayMedium" fontWeight="medium">
          {localized('betslip.openBetsHistory')}
        </Text>
        <ArrowRightIcon />
      </Button>
    </Box>
  );
};
