import { createApi } from '@reduxjs/toolkit/query/react';

import { BACKEND_URLS, baseQueryWithReauth } from '~api/common/baseQuery';

import {
  GetEventsByMarketId,
  InPlaySportEvent,
  LiveData,
  SportEventItem,
} from '../sportEvent/types';

export const liveApi = createApi({
  reducerPath: 'liveApi',
  baseQuery: (args, api) =>
    baseQueryWithReauth(args, api, { url: BACKEND_URLS.SNAPSHOT }),
  keepUnusedDataFor: 0,
  endpoints: (builder) => ({
    getLive: builder.query<LiveData, void>({
      query: () => ({
        url: 'InPlay',
        method: 'GET',
      }),
    }),
    getLiveEvents: builder.mutation<InPlaySportEvent[], GetEventsByMarketId[]>({
      query: (body) => ({
        url: `InPlay/events`,
        method: 'POST',
        body,
      }),
    }),
    getLiveEvent: builder.query<SportEventItem, string>({
      query: (eventId: string) => ({
        url: `inplay/${eventId}`,
        method: 'GET',
      }),
    }),
    getPrematchEvent: builder.query<SportEventItem, string>({
      query: (eventId: string) => ({
        url: `Event/${eventId}`,
        method: 'GET',
      }),
    }),
  }),
});

export const {
  useGetLiveEventsMutation,
  useLazyGetLiveQuery,
  useLazyGetPrematchEventQuery,
  useLazyGetLiveEventQuery,
} = liveApi;
