import React from 'react';
import { styled } from 'stitches.config';

import { Box } from '~components/atoms/Box';
import { NavigationLink } from '~components/atoms/NavigationLink';
import { JACKPOT_MENU_ITEMS } from '~components/molecules/Jackpot/Menu';
import { MenuListItem } from '~components/molecules/Jackpot/MenuList/MenuListItem';
import { QUERY_PARAMS } from '~constants/common';
import { useAppSelector } from '~store';
import { buildSearchQuery } from '~utils/url';

export const StyledJackpotMenuItemWrapper = styled(Box, {
  width: '100%',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
  cursor: 'pointer',
  borderRadius: '$6',
  backgroundColor: '$grayDark',
  height: '60px',
  padding: '$2 $4',

  variants: {
    isActive: {
      true: {
        border: '1px solid  $green',
        backgroundColor: '$blackDark',
      },
    },
  },
});

interface SmallScreenJackpotListProps {
  isActive?: boolean;
}

export const SmallScreenJackpotList = ({
  isActive,
}: SmallScreenJackpotListProps) => {
  const { settledJackpots, activeJackpots } = useAppSelector(
    (state) => state.jackpot,
  );

  const jackpots = isActive ? activeJackpots : settledJackpots;

  return (
    <Box css={{ backgroundColor: '$black', padding: '$2 $4' }}>
      <Box
        flexCol
        gap={2}
        css={{ backgroundColor: '$gray', borderRadius: '$8', padding: '$2' }}
      >
        {jackpots.map((jackpot) => (
          <NavigationLink
            key={jackpot.id}
            to={{
              search: buildSearchQuery({
                [QUERY_PARAMS.MENU]: isActive
                  ? JACKPOT_MENU_ITEMS.ACTIVE_JACKPOTS
                  : JACKPOT_MENU_ITEMS.SETTLED_JACKPOTS,
                [QUERY_PARAMS.JACKPOT_ID]: jackpot.id,
              }),
            }}
            end
          >
            <StyledJackpotMenuItemWrapper>
              <MenuListItem jackpot={jackpot} />
            </StyledJackpotMenuItemWrapper>
          </NavigationLink>
        ))}
      </Box>
    </Box>
  );
};
