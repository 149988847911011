import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { QUERY_PARAMS } from '~constants/common';
import { SPORT_MENUS } from '~types/sportMenus';

type QueryParamsInterface = Partial<Record<QUERY_PARAMS, string>>;

export interface SportsMenuState {
  counts: {
    inplay: number;
    prematch: number;
  };
  liveParams: QueryParamsInterface;
  prematchParams: QueryParamsInterface;
  activeMenu: SPORT_MENUS | null;
}

const initialState: SportsMenuState = {
  counts: {
    inplay: 0,
    prematch: 0,
  },
  liveParams: {},
  prematchParams: {},
  activeMenu: null,
};

interface SetMenuCountsPayload {
  inplay: number;
  prematch: number;
}

export const sportsMenuSlice = createSlice({
  name: 'sportsMenu',
  initialState,
  reducers: {
    setCounts: (state, action: PayloadAction<SetMenuCountsPayload>) => {
      state.counts = action.payload;
    },
    setLiveParams: (state, action: PayloadAction<QueryParamsInterface>) => {
      state.liveParams = action.payload;
    },
    setPrematchParams: (state, action: PayloadAction<QueryParamsInterface>) => {
      state.prematchParams = action.payload;
    },
    setActiveMenu: (state, action: PayloadAction<SPORT_MENUS>) => {
      state.activeMenu = action.payload;
    },
  },
});

export const { setCounts, setLiveParams, setPrematchParams, setActiveMenu } =
  sportsMenuSlice.actions;

export default sportsMenuSlice.reducer;
