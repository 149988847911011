import React, { memo } from 'react';

import { Box } from '~components/atoms/Box';
import { Skeleton } from '~components/atoms/Skeleton';
import { TouchSlider } from '~components/atoms/TouchSlider/TouchSlider';

import { StyledSportSliderItem } from '../../molecules/PrematchMenu/SportSliderItem/styled.components';

interface SportMenuMobileSkeletonProps {
  isLive?: boolean;
  noSportSelectors?: boolean;
}

export const SportMenuMobileSkeleton = memo(
  ({
    isLive = false,
    noSportSelectors = false,
  }: SportMenuMobileSkeletonProps) => {
    return (
      <>
        {!noSportSelectors && (
          <>
            <TouchSlider type="sportGroup">
              {Array.from({ length: 9 }, (_, key) => {
                return (
                  <StyledSportSliderItem
                    key={key}
                    css={{
                      position: 'relative',
                      lineHeight: 0,
                    }}
                  >
                    <Skeleton
                      css={{
                        width: '120px',
                        height: '$5',
                      }}
                    />
                  </StyledSportSliderItem>
                );
              })}
            </TouchSlider>
            <TouchSlider>
              {Array.from({ length: 9 }, (_, key) => {
                return (
                  <StyledSportSliderItem
                    key={key}
                    css={{
                      position: 'relative',
                      lineHeight: 0,
                    }}
                  >
                    <Skeleton
                      css={{
                        width: '60px',
                        height: '$8',
                      }}
                    />
                  </StyledSportSliderItem>
                );
              })}
            </TouchSlider>
          </>
        )}

        <Box flexCol css={{ gap: '1px' }}>
          <Box
            css={{
              p: '$2 $1',
              backgroundColor: '$black',
              '&:last-child': {
                mb: '32px',
              },
            }}
          >
            {isLive && (
              <>
                <Box
                  flexRow
                  justifyContentBetween
                  css={{
                    p: '2px',
                    borderRadius: '$6',
                    border: '1px solid $gray',
                  }}
                >
                  <Skeleton
                    css={{
                      width: '116px',
                      height: '32px',
                      border: 'none',
                    }}
                  />
                  <Skeleton
                    css={{
                      width: '116px',
                      height: '32px',
                      border: 'none',
                    }}
                  />
                  <Skeleton
                    css={{
                      width: '116px',
                      height: '32px',
                      border: 'none',
                    }}
                  />
                </Box>
              </>
            )}
          </Box>
          {Array.from({ length: 12 }, (_, key) => (
            <Box
              key={key}
              css={{
                p: '$4',
                backgroundColor: '$black',
                '&:last-child': {
                  mb: '32px',
                },
              }}
            >
              <Skeleton
                css={{
                  width: '100%',
                  height: '$5',
                  border: 'none',
                }}
              />
            </Box>
          ))}
        </Box>
      </>
    );
  },
);
