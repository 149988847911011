import React from 'react';

import {
  AccordionContent,
  AccordionTrigger,
  StyledAccordionItem,
  StyledAccordionRoot,
} from '~components/atoms/Accordion';
import { Box } from '~components/atoms/Box';
import { MainPageSkeleton } from '~components/atoms/Skeletons';
import { StyledBoardComponentWrapper } from '~components/atoms/StyledBoardComponentWrapper';
import { SPORTS } from '~constants/sport';
import { getSportIcon } from '~utils/getSportIcon';

import { useTopPrematchEvents } from '../../hooks/useTopPrematchEvents';

import { TopPrematchEventsDateGroups } from './TopPrematchEventsDateGroup';

export const TopPrematchEvents = () => {
  const {
    preparedTopSportEvents,
    topEventsData,
    maxMarketsCount,
    topPrematchEventsOpenSports,
    isFetching,
    eventsListRef,
    handleAccordionValueChange,
  } = useTopPrematchEvents();

  if (isFetching) {
    return <MainPageSkeleton />;
  }

  return (
    <StyledBoardComponentWrapper flexCol gap={1} css={{ p: '$1' }}>
      <StyledAccordionRoot
        type="multiple"
        value={topPrematchEventsOpenSports}
        onValueChange={handleAccordionValueChange}
      >
        <Box flexCol gap={1}>
          {preparedTopSportEvents.map(
            ({ sportId, sportName, sportMainMarkets, openDateGroups }) => {
              return (
                <StyledAccordionItem value={sportId.toString()} key={sportId}>
                  <AccordionTrigger grayDark isAlwaysRoundCorners>
                    <Box flexRow alignCenter gap={2}>
                      <Box css={{ lineHeight: 0 }}>
                        {getSportIcon(sportName as SPORTS)}
                      </Box>
                      <Box>{sportName}</Box>
                    </Box>
                  </AccordionTrigger>
                  <AccordionContent
                    css={{
                      p: 0,
                      backgroundColor: 'transparent',
                      '& > .contentWrapper': {
                        p: 0,
                      },
                    }}
                  >
                    <Box flexCol gap={3} css={{ mt: '$1' }} ref={eventsListRef}>
                      <TopPrematchEventsDateGroups
                        sportId={sportId.toString()}
                        topEventsSportData={topEventsData[sportId.toString()]!}
                        mainMarkets={sportMainMarkets}
                        maxMarketsCount={maxMarketsCount}
                        openDateGroups={openDateGroups}
                      />
                    </Box>
                  </AccordionContent>
                </StyledAccordionItem>
              );
            },
          )}
        </Box>
      </StyledAccordionRoot>
    </StyledBoardComponentWrapper>
  );
};
