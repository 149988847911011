import { StyledAccordionRoot } from '~components/atoms/Accordion';
import { Box } from '~components/atoms/Box';

import { LiveTopEventItem } from './LiveTopEventItem';
import { useLiveTopEvents } from './useLiveTopEvents';

export const LiveTopEvents = () => {
  const {
    preparedTopLiveEvents,
    topGamesOpen,
    maxMarketsCount,
    handleAccordionValueChange,
  } = useLiveTopEvents();

  if (!preparedTopLiveEvents) {
    return null;
  }

  return (
    <Box flexCol css={{ background: '$black', borderRadius: '$12' }}>
      <StyledAccordionRoot
        type="multiple"
        onValueChange={handleAccordionValueChange}
        value={topGamesOpen}
      >
        {preparedTopLiveEvents.map(
          ({ sportId, sportName, sportMainMarkets, matches }) => {
            return (
              <LiveTopEventItem
                key={sportId}
                sportId={sportId}
                sportName={sportName}
                matches={matches}
                mainMarkets={sportMainMarkets}
                maxMarketsCount={maxMarketsCount}
              />
            );
          },
        )}
      </StyledAccordionRoot>
    </Box>
  );
};
