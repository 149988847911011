import React, { useState } from 'react';

import { Box } from '~components/atoms/Box';
import { Banner } from '~components/molecules/Jackpot/Active';
import { DetailsTabs } from '~components/molecules/Jackpot/Active/components/Content/DetailsTabs';
import { Event } from '~components/molecules/Jackpot/Active/components/Event';
import { JACKPOT_DETAILS_TABS } from '~components/molecules/Jackpot/constants';
import { RulesContent } from '~components/molecules/Jackpot/Settled/components/Rules/RulesContent';
import { Jackpot } from '~types/jackpot';

import { ContentFooter } from './ContentFooter';

interface ActiveJackpotContentLargeProps {
  selectedJackpot: Jackpot;
}

export const ActiveJackpotDetails = ({
  selectedJackpot,
}: ActiveJackpotContentLargeProps) => {
  const [activeTab, setActiveTab] = useState(JACKPOT_DETAILS_TABS.EVENTS);

  // TODO: when we have language selector, select rules of a particular language
  const translations = selectedJackpot.translations || [];

  const rulesContent = translations[0]?.description || 'No specified rules';

  return (
    <Box flexCol gap={1}>
      <Banner jackpot={selectedJackpot} />
      <Box
        css={{
          display: 'flex',
          flexDirection: 'column',
          backgroundColor: '$black',
          borderRadius: '$12',
          padding: '$1',
          gap: '$1',
        }}
      >
        {/* Selector between jackpot events and rules */}
        <DetailsTabs activeTab={activeTab} setActiveTab={setActiveTab} />

        {activeTab === JACKPOT_DETAILS_TABS.RULES ? (
          <RulesContent content={rulesContent} />
        ) : (
          <Box
            flexCol
            gap={3}
            css={{
              backgroundColor: '$gray',
              borderRadius: '$8',
              padding: '$3',
            }}
          >
            <Box flexCol gap={2}>
              {selectedJackpot?.events?.map((event) => (
                <Event key={event.id} event={event} />
              ))}
            </Box>
            <ContentFooter jackpot={selectedJackpot} />
          </Box>
        )}
      </Box>
    </Box>
  );
};
