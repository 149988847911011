import React, { useState } from 'react';

import { Box } from '~components/atoms/Box';
import { Text } from '~components/atoms/Typography';
import { Event, WinningLines } from '~components/molecules/Jackpot/Settled';
import {
  StyledJackpotAccordionContent,
  StyledJackpotAccordionRoot,
} from '~components/molecules/Jackpot/styled.components';
import { useTranslation } from '~hooks/useTranslation';
import { useAppSelector } from '~store';

import { AccordionWrapper } from './AccordionWrapper';

export const ContentMedium = () => {
  const [openItems, setOpenItems] = useState<string[]>([]);

  const { localized } = useTranslation();
  const { settledJackpots } = useAppSelector((state) => state.jackpot);

  return (
    <StyledJackpotAccordionRoot
      type="multiple"
      value={openItems}
      onValueChange={setOpenItems}
    >
      {settledJackpots.map((settledJackpot) => {
        const { id, details } = settledJackpot;
        const isJackpotOpened = openItems.includes(id);

        return (
          <AccordionWrapper
            key={id}
            isJackpotOpened={isJackpotOpened}
            settledJackpot={settledJackpot}
          >
            <StyledJackpotAccordionContent>
              <Box
                flexCol
                gap={1}
                css={{
                  backgroundColor: '$gray',
                  borderRadius: '$8',
                }}
              >
                <Box css={{ padding: '$2', borderBottom: '1px solid $black' }}>
                  <Text level="14-20"> {localized('jackpot.winners')}</Text>
                </Box>
                <WinningLines lines={details.lines} />
              </Box>
              <Box
                flexCol
                gap={1}
                css={{
                  backgroundColor: '$gray',
                  borderRadius: '$8',
                }}
              >
                <Box css={{ padding: '$2', borderBottom: '1px solid $black' }}>
                  <Text level="14-20"> {localized('jackpot.results')}</Text>
                </Box>
                <Box flexCol gap={2} css={{ padding: '$2' }}>
                  {settledJackpot.events.map((event) => (
                    <Event event={event} />
                  ))}
                </Box>
              </Box>
            </StyledJackpotAccordionContent>
          </AccordionWrapper>
        );
      })}
    </StyledJackpotAccordionRoot>
  );
};
