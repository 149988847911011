export const enum USER_PROFILE_NAVIGATION_TABS {
  PERSONAL_PROFILE = 'personalProfile',
  BALANCE_MANAGEMENT = 'balanceManagement',
  HISTORY = 'history',
  BONUSES = 'bonuses',
  MESSAGES = 'messages',
  PAYMENT_HISTORY = 'transactions',
}

export const enum USER_PROFILE_TABS {
  PERSONAL_DETAILS = 'userProfile.personalProfile.personalDetails',
  ACCOUNT_SETTINGS = 'userProfile.personalProfile.accountSettingsInfo',
  RECENT_SESSIONS = 'userProfile.personalProfile.recentSessions',
}

export const enum USER_PAYMENT_TABS {
  DEPOSIT = 'userProfile.balanceManagement.deposit',
  WITHDRAW = 'userProfile.balanceManagement.withdraw',
  PAYMENT_ACCOUNTS = 'userProfile.balanceManagement.paymentAccounts',
  TRANSACTION_HISTORY = 'userProfile.balanceManagement.transactionHistory',
}

export const enum USER_BONUSES_TABS {
  ACTIVE_BONUSES = 'bonuses.activeBonuses',
  BONUS_HISTORY = 'bonuses.bonusHistory',
}

export const enum USER_BETSLIP_HISTORY_TABS {
  SPORTS = 'betslipHistory.tabs.sports',
  JACKPOT = 'betslipHistory.tabs.jackpot',
}

export const contentsTexts: Record<USER_PROFILE_NAVIGATION_TABS, string> = {
  [USER_PROFILE_NAVIGATION_TABS.MESSAGES]:
    'userProfile.contentsTitles.messages',
  [USER_PROFILE_NAVIGATION_TABS.BONUSES]: 'userProfile.contentsTitles.bonuses',
  [USER_PROFILE_NAVIGATION_TABS.PERSONAL_PROFILE]:
    'userProfile.contentsTitles.personalProfile',
  [USER_PROFILE_NAVIGATION_TABS.HISTORY]: 'userProfile.contentsTitles.history',
  [USER_PROFILE_NAVIGATION_TABS.BALANCE_MANAGEMENT]:
    'userProfile.contentsTitles.balance',
  [USER_PROFILE_NAVIGATION_TABS.PAYMENT_HISTORY]:
    'userProfile.contentsTitles.transactions',
};
