import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { configureStore } from '@reduxjs/toolkit';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import { authApi } from '~api/auth/authApi';
import { betslipApi } from '~api/betslip/betslipApi';
import { bonusApi } from '~api/bonus/bonusApi';
import { categoryApi } from '~api/category/categoryApi';
import { contentApi } from '~api/content/contentApi';
import { emailApi } from '~api/email/emailApi';
import { flutterwaveApi } from '~api/flutterwave/flutterwaveApi';
import { jackpotApi } from '~api/jackpot/jackpotApi';
import { liveApi } from '~api/live/liveApi';
import { marketApi } from '~api/market/marketApi';
import { partnerApi } from '~api/partner/partnerApi';
import { paystackApi } from '~api/paystack/paystackApi';
import { relworxApi } from '~api/relworx/relworxApi';
import { searchApi } from '~api/search/searchApi';
import { sportEventApi } from '~api/sportEvent/sportEventApi';
import { transactionApi } from '~api/transaction/transactionApi';
import { userApi } from '~api/user/userApi';
import sportsMenuReducer from '~features/sportsMenu/sportsMenuSlice';
import betslipReducer from '~store/slices/betslipSlice';
import bonusesReducer from '~store/slices/bonusesSlice';
import breadcrumbReducer from '~store/slices/breadcrumbSlice';
import eventsReducer from '~store/slices/eventsSlice';
import globalDialogReducer from '~store/slices/globalDialogSlice';
import historyReducer from '~store/slices/historySlice';
import jackpotReducer from '~store/slices/jackpotSlice';
import liveGroupsReducer from '~store/slices/liveGroupsSlice';
import liveMenuReducer from '~store/slices/liveMenuSlice';
import mainMarketsReducer from '~store/slices/mainMarketsSlice';
import menusReducer from '~store/slices/menusSlice';
import mobileReducer from '~store/slices/mobileSlice';
import openBetsReducer from '~store/slices/openBetSlice';
import paymentAccountsReducer from '~store/slices/paymentAccountsSlice';
import paymentsReducer from '~store/slices/paymentsSlice';
import personalDetailsReducer from '~store/slices/personalDetailsSlice';
import prematchMenuReducer from '~store/slices/prematchMenuSlice';
import settingsReducer from '~store/slices/settingsSlice';
import signalRSocketsReducer from '~store/slices/signalRSocketsSlice';
import sportEventReducer from '~store/slices/sportEventSlice';
import sportGroupsReducer from '~store/slices/sportGroupsSlice';
import transactionHistoryReducer from '~store/slices/transactionHistorySlice';
import userReducer from '~store/slices/userSlice';
import userUIReducer from '~store/slices/userUISlice';
import websiteSettingsReducer from '~store/slices/websiteSettings';

import ResetEventsMapMiddleware from './middlewares/resetEventsMapMiddleware';
import SocketMiddleware from './middlewares/socketMiddleware';

const persistedBetslipReducer = persistReducer(
  {
    key: 'betslip',
    storage,
    blacklist: [
      'balanceChangesOnBetData',
      'isBalanceChangesLoaded',
      'isBalanceChangesLoading',
    ],
  },
  betslipReducer,
);

const persistedUserUIReducer = persistReducer(
  {
    key: 'userUI',
    storage,
    blacklist: ['isWindowScrolled', 'isDialogScrolled'], // isWindowScrolled and isDialogScrolled will not be persisted
  },
  userUIReducer,
);

const persistedSetitngsReducer = persistReducer(
  {
    key: 'websiteSettings',
    storage,
  },
  websiteSettingsReducer,
);

const persistedSettingsReducer = persistReducer(
  {
    key: 'settings',
    storage,
  },
  settingsReducer,
);

export const store = configureStore({
  reducer: {
    [authApi.reducerPath]: authApi.reducer,
    [userApi.reducerPath]: userApi.reducer,
    [categoryApi.reducerPath]: categoryApi.reducer,
    [sportEventApi.reducerPath]: sportEventApi.reducer,
    [betslipApi.reducerPath]: betslipApi.reducer,
    [marketApi.reducerPath]: marketApi.reducer,
    [liveApi.reducerPath]: liveApi.reducer,
    [transactionApi.reducerPath]: transactionApi.reducer,
    [searchApi.reducerPath]: searchApi.reducer,
    [emailApi.reducerPath]: emailApi.reducer,
    [contentApi.reducerPath]: contentApi.reducer,
    [bonusApi.reducerPath]: bonusApi.reducer,
    [paystackApi.reducerPath]: paystackApi.reducer,
    [flutterwaveApi.reducerPath]: flutterwaveApi.reducer,
    [partnerApi.reducerPath]: partnerApi.reducer,
    [relworxApi.reducerPath]: relworxApi.reducer,
    [jackpotApi.reducerPath]: jackpotApi.reducer,

    userUIState: persistedUserUIReducer,
    websiteSettings: persistedSetitngsReducer,
    betslip: persistedBetslipReducer,
    settings: persistedSettingsReducer,
    payments: paymentsReducer,
    breadcrumb: breadcrumbReducer,
    menus: menusReducer,
    liveMenu: liveMenuReducer,
    prematchMenu: prematchMenuReducer,
    mobileState: mobileReducer,
    userState: userReducer,
    sportEventState: sportEventReducer,
    sportsMenuState: sportsMenuReducer,
    signalRSockets: signalRSocketsReducer,
    globalDialog: globalDialogReducer,
    events: eventsReducer,
    openBets: openBetsReducer,
    mainMarkets: mainMarketsReducer,
    personalDetails: personalDetailsReducer,
    sportGroupsState: sportGroupsReducer,
    liveGroupsState: liveGroupsReducer,
    paymentAccounts: paymentAccountsReducer,
    transactionHistory: transactionHistoryReducer,
    bonuses: bonusesReducer,
    history: historyReducer,
    jackpot: jackpotReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }).concat([
      transactionApi.middleware,
      authApi.middleware,
      userApi.middleware,
      categoryApi.middleware,
      sportEventApi.middleware,
      betslipApi.middleware,
      marketApi.middleware,
      liveApi.middleware,
      searchApi.middleware,
      emailApi.middleware,
      contentApi.middleware,
      bonusApi.middleware,
      paystackApi.middleware,
      flutterwaveApi.middleware,
      partnerApi.middleware,
      relworxApi.middleware,
      jackpotApi.middleware,
      SocketMiddleware,
      ResetEventsMapMiddleware,
    ]),
});

export type AppDispatch = typeof store.dispatch;

export type RootState = ReturnType<typeof store.getState>;

export const useAppDispatch = () => useDispatch<AppDispatch>();

export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export const persistor = persistStore(store);
