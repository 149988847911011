import React, { useEffect, useRef } from 'react';

import { MainMarket } from '~api/market/types';
import { StyledAccordionRoot } from '~components/atoms/Accordion';
import { Box } from '~components/atoms/Box';
import { FavoriteLeagueButton } from '~components/atoms/FavoriteLeagueButton';
import { Flag } from '~components/atoms/Flag';
import { EventMarketsSkeleton } from '~components/atoms/Skeletons';
import { StyledBoardComponentWrapper } from '~components/atoms/StyledBoardComponentWrapper';
import { Text } from '~components/atoms/Typography';
import { EventsList } from '~components/molecules/MainHighlights/EventsList';
import { MatchHighlightsMarketsHeaders } from '~components/molecules/MainHighlights/MatchHighlightsMarketsHeaders';
import { CountryName } from '~constants/flag';
import { SPORTS } from '~constants/sport';
import { getSportIcon } from '~utils/getSportIcon';

import { useLeagueEventsMenu } from '../hooks/useLeagueEventsMenu';

import { EmptyEvents } from './EmptyEvents';

export const LeagueEventsMenu = () => {
  const {
    countryCode,
    countryId,
    countryName,
    events,
    isLoading,
    isUserLoggedIn,
    leagueEventsData,
    leagueId,
    leagueName,
    mainMarkets,
    maxMarketsCount,
    openItems,
    sportId,
    sportName,
    setOpenItems,
    eventsListRef,
  } = useLeagueEventsMenu();
  const skeletonRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (skeletonRef.current) {
      skeletonRef.current.scrollIntoView(false);
    }
  }, [skeletonRef.current, leagueId]);

  if (isLoading) {
    return (
      <Box>
        <Box ref={skeletonRef} css={{ height: 0 }} />
        <EventMarketsSkeleton />
      </Box>
    );
  }

  return (
    <StyledBoardComponentWrapper flexCol gap={1} css={{ p: '$1' }}>
      {sportName && (countryName || countryCode) && (
        <Box
          flexRow
          alignCenter
          gap={2}
          css={{
            height: '$8',
            backgroundColor: '$grayDarkMain2',
            borderRadius: '$8',
            pl: '$3',
          }}
        >
          <Box flexRow alignCenter gap={3} css={{ color: '$grayMedium' }}>
            {getSportIcon(sportName as SPORTS)}
            <Flag countryName={countryName as CountryName} code={countryCode} />
            {isUserLoggedIn && (
              <Box css={{ mr: '$3' }}>
                <FavoriteLeagueButton
                  id={leagueId!}
                  name={leagueName}
                  events={events?.length || 0}
                  sportId={sportId}
                  countryId={countryId!}
                  sportName={sportName}
                  countryName={countryName!}
                />
              </Box>
            )}
          </Box>
          <Text level="16-24">{leagueName}</Text>
        </Box>
      )}
      <StyledAccordionRoot
        type="multiple"
        value={openItems}
        onValueChange={setOpenItems}
      >
        <Box
          gap={1}
          css={{
            display: 'flex',
            flexDirection: 'column-reverse',
          }}
          ref={eventsListRef}
        >
          {leagueEventsData && leagueEventsData.length ? (
            leagueEventsData
              // reversed and used column-reverse to have first accordion focused instead of the last one,
              // hence preventing scrolling to the bottom but in terms of UI everything is the same
              .slice()
              .reverse()
              .map(({ date, events }) => {
                const mainSportMarkets = mainMarkets
                  .find((market) => market.id === sportId)
                  ?.markets.slice(0, maxMarketsCount);

                const cuttedMarkets = mainSportMarkets?.slice(
                  0,
                  maxMarketsCount,
                );

                return (
                  <Box fullWidth flexCol key={date}>
                    <MatchHighlightsMarketsHeaders
                      label={date}
                      mainMarkets={cuttedMarkets as MainMarket[]}
                    />
                    {events && !!events.length && (
                      <EventsList
                        activeEventIds={events.map((item) => item.eventId)}
                        mainMarkets={cuttedMarkets as MainMarket[]}
                        maxMarketsCount={maxMarketsCount}
                      />
                    )}
                  </Box>
                );
              })
          ) : (
            <EmptyEvents />
          )}
        </Box>
      </StyledAccordionRoot>
    </StyledBoardComponentWrapper>
  );
};
