import { useEffect, useState } from 'react';
import { HubConnection } from '@microsoft/signalr';

import {
  SIGNAL_R_SOCKET_CONNECTED_STATUS,
  SIGNAL_R_SOCKET_MESSAGES,
  SIGNAL_R_SOCKET_NAMES,
} from '~constants/signalR';
import { useAppSelector } from '~store';

export const useBetslipStakesActivity = () => {
  const { sockets } = useAppSelector((state) => state.signalRSockets);
  const [socket, setSocket] = useState<HubConnection | null>(null);
  const { events } = useAppSelector((state) => state.betslip);

  useEffect(() => {
    const inplaySocket = sockets?.[SIGNAL_R_SOCKET_NAMES.INPLAY]?.socket;

    if (inplaySocket) {
      setSocket(inplaySocket);
    }
  }, [sockets]);

  useEffect(() => {
    if (socket && socket?.state === SIGNAL_R_SOCKET_CONNECTED_STATUS) {
      const marketIds = events.map(({ marketId, eventId }) => ({
        id: marketId,
        eventId,
      }));

      if (marketIds.length) {
        socket?.invoke(
          SIGNAL_R_SOCKET_MESSAGES.SUBSCRIBE_EVENTS_MESSAGE,
          marketIds,
        );
      }
    }
  }, [socket, socket?.state, events]);
};
