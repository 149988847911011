import { BetslipHistoryItem } from '~api/betslip/types';
import { Box } from '~components/atoms/Box';
import { Text } from '~components/atoms/Typography';
import {
  SPORT_BETSLIP_TYPE_CODES,
  SPORT_BETSLIP_TYPES_MAP,
} from '~components/molecules/Betslip/constants';
import { getSystemBetOptionsLabel } from '~components/molecules/BetslipHistorySection/historyUtils';
import { capitalizeFirstLetter } from '~utils/stringUtils';

interface BetslipTypeCellProps {
  betslipHistoryItem: BetslipHistoryItem;
}

export const BetslipTypeCell = ({
  betslipHistoryItem,
}: BetslipTypeCellProps) => {
  const { type, details, systemBetCombinationSize } = betslipHistoryItem;
  const eventsCount = details.length;

  let betType = capitalizeFirstLetter(SPORT_BETSLIP_TYPES_MAP[type]);

  if (type === SPORT_BETSLIP_TYPE_CODES.MULTIPLE) {
    betType = `${betType} (${eventsCount})`;
  }

  return (
    <Box flexCol>
      <Text level="12-20">{betType}</Text>
      {systemBetCombinationSize && (
        <Text level="12-20">
          {getSystemBetOptionsLabel(eventsCount, systemBetCombinationSize)}
        </Text>
      )}
    </Box>
  );
};
