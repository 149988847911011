import { useState } from 'react';

import { DIALOGS } from '~components/atoms/AbsoluteDialogs';
import { BalanceInfo } from '~components/atoms/BalanceInfo';
import { Box } from '~components/atoms/Box';
import { SettingsDropdown } from '~components/atoms/SettingsDropdown';
import { UserBalancesNavigationDropDown } from '~components/molecules/UserProfile/components/UserBalancesNavigationDropDown';
import { useMedia } from '~hooks/useMedia';
import { useTranslation } from '~hooks/useTranslation';
import { DepositIcon, UserProfileIcon } from '~icons';
import { useAppDispatch, useAppSelector } from '~store';
import { openDialog } from '~store/slices/globalDialogSlice';
import {
  setMobileUserProfileDialogActiveContent,
  setMobileUserProfileDialogState,
} from '~store/slices/mobileSlice';
import { setTabsState } from '~store/slices/personalDetailsSlice';

import {
  StyledChevron,
  StyledDepositButton,
  StyledUserProfileBarContainer,
  StyledUserProfileInfoContainer,
} from '../UserProfileBar';
import {
  USER_PAYMENT_TABS,
  USER_PROFILE_NAVIGATION_TABS,
} from '../UserProfileDialog/constants';
import { USER_PROFILE_DIALOG_MOBILE_STATE } from '../UserProfileDialogMobile';
import { UserProfileNavigationDropDown } from '../UserProfileNavigationDropDown';

interface UserProfileBarProps {
  isSmallMobileScrolled: boolean;
}

export const UserProfileBar = ({
  isSmallMobileScrolled,
}: UserProfileBarProps) => {
  const { localized } = useTranslation();
  const dispatch = useAppDispatch();
  const { isMobile, isMobileOrTablet } = useMedia();
  const [isUserProfilePopoverOpened, setIsUserProfilePopoverOpened] =
    useState(false);
  const [isBalanceOpened, setIsBalanceOpened] = useState(false);

  const { separateBonusBalance } = useAppSelector((state) => state.settings);
  const { profile, isUserProfileLoaded } = useAppSelector(
    (state) => state.userState,
  );
  const { balance = 0, freeBetBalance = 0 } = profile || {};

  const handleUserProfileOpen = () => {
    dispatch(openDialog(DIALOGS.USER_PROFILE_MOBILE));
  };

  const handleDepositButtonClick = () => {
    if (!isMobileOrTablet) {
      dispatch(openDialog(DIALOGS.USER_PROFILE));
      dispatch(
        setTabsState({
          mainTab: USER_PROFILE_NAVIGATION_TABS.BALANCE_MANAGEMENT,
          contentTab: USER_PAYMENT_TABS.DEPOSIT,
        }),
      );
    } else {
      dispatch(openDialog(DIALOGS.USER_PROFILE_MOBILE));
      dispatch(
        setMobileUserProfileDialogState(
          USER_PROFILE_DIALOG_MOBILE_STATE.CONTENT,
        ),
      );
      dispatch(
        setMobileUserProfileDialogActiveContent(USER_PAYMENT_TABS.DEPOSIT),
      );
    }
  };

  if (!profile) return null;

  return (
    <>
      <Box flexRow gap={5} alignCenter fullWidth={isSmallMobileScrolled}>
        {!!profile && (
          <Box flexRow gap={2} alignCenter fullWidth={isSmallMobileScrolled}>
            <StyledUserProfileBarContainer
              fullWidth={isSmallMobileScrolled}
              isOpened={isBalanceOpened}
              fullHeight
              css={
                isMobileOrTablet
                  ? {
                      maxHeight: `${separateBonusBalance ? '2.5' : '2'}rem !important`,
                    }
                  : {
                      maxHeight: `${separateBonusBalance ? '52' : '44'}rem !important`,
                    }
              }
            >
              <StyledDepositButton
                onClick={handleDepositButtonClick}
                separateBonusBalance={separateBonusBalance}
              >
                {isMobile ? (
                  <DepositIcon />
                ) : (
                  localized('userProfile.personalProfileInfo.deposit')
                )}
              </StyledDepositButton>
              {separateBonusBalance ? (
                <Box css={{ padding: '0 $2' }}>
                  <BalanceInfo
                    balance={balance}
                    freeBetBalance={freeBetBalance}
                  />
                </Box>
              ) : (
                <UserBalancesNavigationDropDown
                  onOpenChange={setIsBalanceOpened}
                >
                  <StyledUserProfileInfoContainer as="span">
                    <BalanceInfo
                      balance={balance}
                      freeBetBalance={freeBetBalance}
                    />
                    {isUserProfileLoaded && (
                      <Box
                        flexRow
                        css={{
                          width: '16px',
                          height: '10px',
                          svg: {
                            width: '16px !important',
                            height: '10px !important',
                          },
                          '@xs_sm': {
                            height: '6px',
                            width: '11px',
                            svg: {
                              width: '11px !important',
                              height: '6px !important',
                            },
                          },
                        }}
                      >
                        <StyledChevron className="chevron" />
                      </Box>
                    )}
                  </StyledUserProfileInfoContainer>
                </UserBalancesNavigationDropDown>
              )}
            </StyledUserProfileBarContainer>
            {isMobileOrTablet ? (
              <StyledUserProfileBarContainer
                isSquare
                flexRow
                alignCenter
                justifyCenter
                isOpened={isUserProfilePopoverOpened}
                onClick={handleUserProfileOpen}
                css={{
                  borderColor: `$green !important`,
                  background: isUserProfilePopoverOpened
                    ? `$greenOpacity`
                    : 'none',
                  color: '$white',
                }}
              >
                <UserProfileIcon />
              </StyledUserProfileBarContainer>
            ) : (
              <UserProfileNavigationDropDown
                onOpenChange={(val) => setIsUserProfilePopoverOpened(val)}
              >
                <StyledUserProfileBarContainer
                  isSquare
                  flexRow
                  alignCenter
                  justifyCenter
                  css={{
                    color: '$white',
                  }}
                  isOpened={isUserProfilePopoverOpened}
                >
                  <UserProfileIcon />
                </StyledUserProfileBarContainer>
              </UserProfileNavigationDropDown>
            )}
            <SettingsDropdown />
          </Box>
        )}
      </Box>
    </>
  );
};
