import React from 'react';

import { ScrollArea } from '~components/atoms/ScrollArea';
import { IFRAME_HEADER_HEIGHT, IFRAME_HEADER_MARGIN } from '~constants/ui';
import useIsInIframe from '~hooks/useIsInIframe';

import {
  StyledLeftSidebar,
  StyledSportMenuInnerWrapper,
  StyledSportMenuWrapper,
} from './styled.components';

export interface LeftSidebarProps {
  filtersPanel?: React.ReactNode;
  transaparentBackground?: boolean;
  children: React.ReactNode;
  sticky?: boolean;
}

export const LeftSidebar = ({
  filtersPanel,
  transaparentBackground,
  children,
  sticky,
}: LeftSidebarProps) => {
  const isInIframe = useIsInIframe();

  return (
    <StyledLeftSidebar
      css={{
        ...(sticky && { position: 'sticky', top: 0 }),
        height: isInIframe
          ? `calc(100vh - ${IFRAME_HEADER_HEIGHT}px - ${IFRAME_HEADER_MARGIN}px - 16px)`
          : 'calc(100vh - 156px)',
        '@md': {
          height: isInIframe
            ? `calc(100vh - ${IFRAME_HEADER_HEIGHT}px - ${IFRAME_HEADER_MARGIN}px - 16px)`
            : 'calc(100vh - 144px)',
        },
      }}
    >
      {filtersPanel && filtersPanel}
      <StyledSportMenuWrapper transaparentBackground={transaparentBackground}>
        <StyledSportMenuInnerWrapper
          transaparentBackground={transaparentBackground}
        >
          <ScrollArea>{children}</ScrollArea>
        </StyledSportMenuInnerWrapper>
      </StyledSportMenuWrapper>
    </StyledLeftSidebar>
  );
};
