import React from 'react';
import { Row } from 'react-table';

import { BetslipHistoryDetails } from '~api/betslip/types';
import { Box } from '~components/atoms/Box';
import { NavigationLink } from '~components/atoms/NavigationLink';
import { Text } from '~components/atoms/Typography';
import { QUERY_PARAMS } from '~constants/common';
import { SPORTS } from '~constants/sport';
import { useWebsiteSettings } from '~hooks/useWebsiteSettings';
import { useAppDispatch } from '~store';
import { closeDialog } from '~store/slices/globalDialogSlice';
import { SPORT_MENUS } from '~types/sportMenus';
import { formatDateTime, TIME_FORMATS } from '~utils/formatDateTime';
import { getSportIcon } from '~utils/getSportIcon';
import { splitStringOnVs } from '~utils/stringUtils';
import { buildSearchQuery } from '~utils/url';

import { BetHistoryCellProps } from '../../types';

import { BetslipHistoryStatusCell } from './components/BetslipHistoryStatusCell';

export const BetslipHistorySportCountryInnerCell = ({
  row: {
    original: { sport, league, startDate },
  },
}: {
  row: Row<BetslipHistoryDetails>;
}) => {
  return (
    <Box flexRow alignCenter gap={3}>
      <Box css={{ transform: 'translateY(3px)', color: '$grayMedium' }}>
        {getSportIcon(sport as SPORTS, {
          width: 16,
          height: 16,
        })}
      </Box>
      <Box flexCol>
        <Text level="xxxs-2" ellipsis>
          {league}
        </Text>
        <Text level="xxxs-2" ellipsis>
          {formatDateTime(startDate, TIME_FORMATS.DATE_TIME_12H)}
        </Text>
      </Box>
    </Box>
  );
};

export const BetslipHistoryEventInnerCell = ({
  row,
}: {
  row: Row<BetslipHistoryDetails>;
}) => {
  const dispatch = useAppDispatch();
  const { eventId, event, startDate } = row.original;
  const [homeTeamName, awayTeamName] = splitStringOnVs(event);

  const startDateObj = new Date(startDate);
  const now = new Date();
  const isLive = startDateObj < now;

  const onEventNameClick = () => {
    dispatch(closeDialog());
  };

  return (
    <Box flexCol css={{ paddingRight: '$5' }}>
      <NavigationLink
        key={eventId}
        css={{
          display: 'flex',
          flexDirection: 'column',
        }}
        onClick={onEventNameClick}
        to={{
          search: buildSearchQuery({
            [QUERY_PARAMS.EVENT_ID]: eventId,
            [QUERY_PARAMS.MENU]: isLive
              ? SPORT_MENUS.LIVE
              : SPORT_MENUS.PREMATCH,
          }),
        }}
        end
      >
        <Text level="xxxs-2" css={{ textWrap: 'balance' }}>
          {homeTeamName} -
        </Text>
        <Text level="xxxs-2" css={{ textWrap: 'balance' }}>
          {awayTeamName}
        </Text>
      </NavigationLink>
    </Box>
  );
};

export const BetslipHistoryMarketSelectionInnerCell = ({
  row,
}: {
  row: Row<BetslipHistoryDetails>;
}) => {
  return (
    <Box flexCol alignStart css={{ paddingRight: '$5', maxWidth: '120px' }}>
      <Text level="xxxs-2" css={{ textWrap: 'balance' }}>
        {row.original.market} -
      </Text>
      <Text level="xxxs-2" css={{ textWrap: 'balance' }}>
        {row.original.selection}
      </Text>
    </Box>
  );
};

export const BetslipHistoryOddInnerCell = ({
  row,
}: {
  row: Row<BetslipHistoryDetails>;
}) => {
  const { getFormattedOdd, odds } = useWebsiteSettings();

  return (
    <Box flexCol>
      <Text level="xxxs-2">{getFormattedOdd(row.original.odd, odds)}</Text>
    </Box>
  );
};

export const BetslipHistoryStatusInnerCell = ({
  row: {
    original: { status },
  },
}: BetHistoryCellProps) => {
  return <BetslipHistoryStatusCell status={status} isInnerTable />;
};
