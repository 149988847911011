import { useLazyGetOpenBets } from '~api/betslip/betslipQueries';
import { Box } from '~components/atoms/Box';
import { ToggleGroup, ToggleGroupItem } from '~components/atoms/ToggleGroup';
import { Text } from '~components/atoms/Typography';
import { useTranslation } from '~hooks/useTranslation';
import { useAppDispatch, useAppSelector } from '~store';
import { toggleBetslipType } from '~store/slices/betslipSlice';
import { setOpenBets } from '~store/slices/openBetSlice';

import { BETSLIP_TOGGLE_TYPES } from '../constants';

interface MenuToggle {
  value: BETSLIP_TOGGLE_TYPES;
  label: string;
}

export const BetslipToggleButtons = () => {
  const dispatch = useAppDispatch();
  const { activeTab, events } = useAppSelector((state) => state.betslip);
  const { localized } = useTranslation();
  const { isUserLoggedIn } = useAppSelector((state) => state.userState);
  const { lazyGetOpenBetsQuery } = useLazyGetOpenBets();

  const loadOpenBets = async () => {
    const openBetsData = await lazyGetOpenBetsQuery().unwrap();

    dispatch(setOpenBets(openBetsData));
  };

  const menusToggles: MenuToggle[] = [
    {
      value: BETSLIP_TOGGLE_TYPES.BETSLIP,
      label: localized('betslip.toggleButtons.betslip'),
    },
    {
      value: BETSLIP_TOGGLE_TYPES.OPEN_BETS,
      label: localized('betslip.toggleButtons.openBets'),
    },
  ];

  const handleToggleBetslip = (nextActiveTab: BETSLIP_TOGGLE_TYPES) => {
    if (!nextActiveTab.length) {
      return;
    }

    if (nextActiveTab === BETSLIP_TOGGLE_TYPES.OPEN_BETS && isUserLoggedIn) {
      dispatch(setOpenBets([]));
      loadOpenBets();
    }

    dispatch(toggleBetslipType(nextActiveTab));
  };

  return (
    <Box
      css={{
        p: '$4',
        '@xs_sm': {
          fontSize: '$sm',
          borderRadius: '$6',
        },
      }}
    >
      <ToggleGroup
        type="single"
        onValueChange={handleToggleBetslip}
        value={activeTab}
        css={{
          '@xs_sm': {
            height: '$7',
            borderRadius: '$6',
            p: '2px',
          },
        }}
      >
        {menusToggles.map(({ value, label }) => (
          <ToggleGroupItem value={value} key={value}>
            <Text
              as="span"
              fontWeight="medium"
              css={{
                color: 'inherit',
                fontSize: '$14',
                lineHeight: '$20',
                '@xs_sm': {
                  fontSize: '$sm',
                  borderRadius: '$4',
                  lineHeight: '$20',
                },
              }}
            >
              {label}{' '}
              {value === BETSLIP_TOGGLE_TYPES.BETSLIP &&
                !!events.length &&
                `(${events.length})`}
            </Text>
          </ToggleGroupItem>
        ))}
      </ToggleGroup>
    </Box>
  );
};
