import React, { useState } from 'react';

import { Box } from '~components/atoms/Box';
import { NavigationLink } from '~components/atoms/NavigationLink';
import { Select } from '~components/atoms/Select';
import { JACKPOT_MENU_ITEMS } from '~components/molecules/Jackpot/Menu';
import { MenuListItem } from '~components/molecules/Jackpot/MenuList/MenuListItem';
import { StyledJackpotMenuItemWrapper } from '~components/molecules/Jackpot/Settled';
import { QUERY_PARAMS } from '~constants/common';
import { SPORTS } from '~constants/sport';
import { useAppDispatch } from '~store';
import { clearJackpotMessages } from '~store/slices/jackpotSlice';
import { Jackpot, Jackpots } from '~types/jackpot';
import { getSportIcon } from '~utils/getSportIcon';
import { buildSearchQuery } from '~utils/url';

interface JackpotMenuListSelectorProps {
  jackpots: Jackpots;
  selectedJackpot: Jackpot;
  menu: JACKPOT_MENU_ITEMS;
}

export const MenuListSelector = ({
  jackpots,
  selectedJackpot,
  menu,
}: JackpotMenuListSelectorProps) => {
  const dispatch = useAppDispatch();
  const [showJackpotsList, setShowJackpotsList] = useState(false);
  const { id, name, sportName } = selectedJackpot;

  const handleActiveJackpotChange = () => {
    dispatch(clearJackpotMessages());
    setShowJackpotsList(false);
  };

  return (
    <>
      <Select
        isCustomChildren
        isCustomContentWidth
        notPreventClickBackdrop
        iconSize={16}
        shouldClose={!showJackpotsList}
        onClose={() => setShowJackpotsList(false)}
        onOpenChange={setShowJackpotsList}
        css={{ height: '40px' }}
        icon={getSportIcon(sportName! as SPORTS, { width: 20, height: 20 })}
        placeholder={name}
      >
        <Box flexCol css={{ width: 'calc(100vw - 48px)', zIndex: 1000 }}>
          <Box
            flexCol
            gap={2}
            css={{
              backgroundColor: '$gray',
              borderRadius: '$8',
            }}
          >
            {jackpots.map((jackpot) => (
              <NavigationLink
                to={{
                  search: buildSearchQuery({
                    [QUERY_PARAMS.MENU]: menu,
                    [QUERY_PARAMS.JACKPOT_ID]: jackpot.id,
                  }),
                }}
                end
                key={jackpot.id}
                onClick={handleActiveJackpotChange}
              >
                <StyledJackpotMenuItemWrapper isActive={jackpot.id === id}>
                  <MenuListItem
                    jackpot={jackpot}
                    isActive={jackpot.id === id}
                  />
                </StyledJackpotMenuItemWrapper>
              </NavigationLink>
            ))}
          </Box>
        </Box>
      </Select>
      {showJackpotsList && (
        <Box
          fullWidth
          fullHeight
          css={{
            position: 'absolute',
            top: '13%',
            left: 0,
            background: '$blackOpacity8',
            backdropFilter: 'blur(4px)',
            zIndex: 2,
          }}
        />
      )}
    </>
  );
};
