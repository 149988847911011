import { OpenedBetDetails } from '~api/betslip/types';
import { StyledAccordionRoot } from '~components/atoms/Accordion';
import { useTranslation } from '~hooks/useTranslation';

import { OpenBetsDetailsItem } from './OpenBetsDetailsItem';
import {
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from './styled.components';

interface OpenBetsDetailsProps {
  details: OpenedBetDetails[];
}

export const OpenBetsDetails = ({ details }: OpenBetsDetailsProps) => {
  const count = details.length.toString();
  const { localized } = useTranslation();

  return (
    <StyledAccordionRoot type="multiple">
      <AccordionItem value={'qwe'}>
        <AccordionTrigger
          count={count}
          text={localized('betslip.openBetsCard.detailsInfo')}
        />
        <AccordionContent>
          {details.map((detailsItem) => (
            <OpenBetsDetailsItem
              key={detailsItem.event}
              detailsItem={detailsItem}
            />
          ))}
        </AccordionContent>
      </AccordionItem>
    </StyledAccordionRoot>
  );
};
