import { useCallback, useEffect, useState } from 'react';

import { MainMarket } from '~api/market/types';
import { useInplayEventBreadcrumb } from '~components/molecules/InplayEvents/hooks/useInplayEventBreadcrumb';
import { MOBILE_MENU } from '~components/organisms/MobileTab';
import { LIVE_TAB_CONTENTS } from '~components/organisms/MobileTabContents';
import { MARKET_STATUS, QUERY_PARAMS } from '~constants/common';
import { useMedia } from '~hooks/useMedia';
import { useQueryParams } from '~hooks/useQueryParams';
import { useRouterQuery } from '~hooks/useRouterQuery';
import { useAppDispatch, useAppSelector } from '~store';
import { selectEventById } from '~store/slices/eventsSlice';
import { setIsTopLiveEventsOpen } from '~store/slices/liveGroupsSlice';
import { removeLiveMenuEvent } from '~store/slices/liveMenuSlice';
import { setBreadcrumbsLabel } from '~store/slices/menusSlice';
import { setLiveTabActiveContent } from '~store/slices/mobileSlice';
import { Market } from '~types/events';
import { areTwoObjectsEqual } from '~utils/objectHelpers';
import { QueryParams } from '~utils/url';

import { LIVE_GROUP_COMPONENTS } from '../constants';

export const useLiveEventCard = (
  sportId: number,
  countryId: number,
  leagueId: number,
  eventId: string,
  isFavoriteEvents?: boolean,
  mainSportMarket?: MainMarket | undefined,
) => {
  const dispatch = useAppDispatch();
  const { updateQueryParams } = useRouterQuery();
  const { mainMarkets } = useAppSelector((state) => state.mainMarkets);
  const {
    addedSportId,
    addedLeagueId,
    mainMarketsSelected,
    marketsOfFavoriteEventsSelected,
    loadingSportId,
  } = useAppSelector((state) => state.liveMenu);
  const { isFavoriteEventsOpen } = useAppSelector(
    (state) => state.liveGroupsState,
  );
  const marketsSelected = isFavoriteEvents
    ? marketsOfFavoriteEventsSelected
    : mainMarketsSelected;
  const { group, menu } = useQueryParams();
  const event = useAppSelector((state) => selectEventById(state, eventId));
  const [eventData, setEventData] = useState(event);
  const [marketData, setMarketData] = useState<Market>();
  const { countryName, leagueName, name } = event || {};
  const { populateInplayEventBreadcrumbs } = useInplayEventBreadcrumb({
    menu: MOBILE_MENU.LIVE,
    countryName,
    leagueName,
    name,
  });

  const [isLoading, setIsLoading] = useState<boolean>(!isFavoriteEvents);
  const [isFavoriteEventsLoading, setIsFavoriteEventsLoading] =
    useState<boolean>(!!isFavoriteEvents);

  const { isMobileOrTablet } = useMedia();

  const handleRemoveEvent = useCallback(() => {
    dispatch(
      removeLiveMenuEvent({
        eventId,
        sportId,
        countryId,
        leagueId,
      }),
    );
  }, []);

  useEffect(() => {
    if (!areTwoObjectsEqual(event, eventData)) {
      setEventData(event);
    }
  }, [event]);

  const handleEventCardClick = useCallback(() => {
    if (group) {
      dispatch(setIsTopLiveEventsOpen(false));
      dispatch(setBreadcrumbsLabel(group));
    }

    updateQueryParams(
      {
        [QUERY_PARAMS.COUNTRY_ID]: countryId,
        [QUERY_PARAMS.LEAGUE_ID]: leagueId,
        [QUERY_PARAMS.MENU]: menu,
        [QUERY_PARAMS.SPORT_ID]: sportId,
        [QUERY_PARAMS.EVENT_ID]: eventId,
      } as QueryParams<typeof QUERY_PARAMS>,
      true,
    );

    if (isMobileOrTablet) {
      dispatch(setLiveTabActiveContent(LIVE_TAB_CONTENTS.LIVE_EVENT));
      if (isFavoriteEventsOpen) {
        dispatch(setBreadcrumbsLabel(LIVE_GROUP_COMPONENTS.FAVORITE_EVENT));
      }

      populateInplayEventBreadcrumbs();
    }
  }, [eventId, sportId, countryId, leagueId, event, group, isMobileOrTablet]);

  useEffect(() => {
    const isLeagueLoading = Boolean(
      addedLeagueId &&
        parseInt(addedLeagueId) === leagueId &&
        !isMobileOrTablet,
    );
    const isSportLoading = Boolean(
      addedSportId && parseInt(addedSportId) === sportId && !isMobileOrTablet,
    );

    const isSportEventsLoading = Boolean(
      loadingSportId && loadingSportId === sportId && !isMobileOrTablet,
    );

    if (!isFavoriteEvents) {
      setIsLoading(isSportLoading || isLeagueLoading || isSportEventsLoading);
    } else {
      setIsFavoriteEventsLoading(
        isSportLoading || isLeagueLoading || isSportEventsLoading,
      );
    }
  }, [isMobileOrTablet, addedLeagueId, addedSportId, loadingSportId]);

  useEffect(() => {
    if (isLoading && !isFavoriteEvents) return;
    if (isFavoriteEventsLoading && isFavoriteEvents) return;

    const mapMarkets = (eventData?.markets || []).filter(
      (item) => item,
    ) as Market[];

    if (!mapMarkets) {
      setMarketData(undefined);

      return;
    }

    const eventMarket = mapMarkets?.find(
      ({ marketId }) => parseInt(marketId) === marketsSelected[sportId],
    ) as Market;

    const resMarket = { ...eventMarket };

    const { markets = null } =
      mainMarkets.find(({ id }) => id === sportId) || {};

    if (markets && markets.length) {
      const mainMarketId = mainSportMarket?.id || marketsSelected[sportId];
      const marketData = markets.find(({ id }) => id === mainMarketId);

      resMarket.selections = (resMarket?.selections || []).map(
        ({ selectionId, ...rest }) => {
          const marketItem = marketData?.selections.find(
            ({ id }) => id === selectionId,
          );

          return {
            selectionId,
            ...rest,
            name: marketItem?.name || '',
          };
        },
      );
    }

    if (!areTwoObjectsEqual(resMarket, marketData)) {
      setMarketData(
        resMarket.status === MARKET_STATUS.ACTIVE ? resMarket : undefined,
      );
    }
  }, [
    eventData,
    mainSportMarket,
    marketsSelected,
    isLoading,
    isFavoriteEventsLoading,
  ]);

  return {
    isLoading,
    ...eventData,
    isMobile: isMobileOrTablet,
    market: marketData,
    handleEventCardClick,
    handleRemoveEvent,
  };
};
