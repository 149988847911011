import { createApi } from '@reduxjs/toolkit/query/react';

import { BACKEND_URLS, baseQueryWithReauth } from '~api/common/baseQuery';
import { startTokenRefreshScheduler } from '~services/auth/tokenRefreshScheduler';
import { closeDialog } from '~store/slices/globalDialogSlice';
import { login, logout } from '~store/slices/userSlice';
import { setAuthCookiesData } from '~utils/cookies';

import {
  LoginUserInput,
  LoginUserResponse,
  RefreshTokenInput,
  RefreshTokenResponse,
  ResetPasswordCodeInput,
  ResetPasswordCodeResponse,
  ResetPasswordInput,
  UserLimits,
  UserRegisterInput,
  UserRegisterResponse,
  VerifyEmailTokenInput,
  VerifyEmailTokenResponse,
  VerifyPhoneNewCodeInput,
  VerifyPhoneNewCodeResponse,
  VerifyUserInput,
} from './types';

export const authApi = createApi({
  reducerPath: 'authApi',
  baseQuery: (args, api) =>
    baseQueryWithReauth(args, api, { url: BACKEND_URLS.ID }),
  endpoints: (builder) => ({
    registerUser: builder.mutation<UserRegisterResponse, UserRegisterInput>({
      query: (body) => ({
        url: 'SignUp/user',
        method: 'POST',
        body,
      }),
    }),
    resetPasswordCode: builder.mutation<
      ResetPasswordCodeResponse,
      ResetPasswordCodeInput
    >({
      query: (body) => ({
        url: 'Verification/getresetpasswordcode',
        method: 'POST',
        body,
      }),
    }),
    resetPassword: builder.mutation<null, ResetPasswordInput>({
      query: (body) => ({
        url: 'Verification/resetpassword',
        method: 'POST',
        body,
      }),
    }),
    verifyUser: builder.mutation<null, VerifyUserInput>({
      query: (body) => ({
        url: 'Verification/verifyphone',
        method: 'POST',
        body,
      }),
    }),
    verifyCode: builder.mutation<null, VerifyUserInput>({
      query: (body) => ({
        url: 'Verification/verifycode',
        method: 'POST',
        body,
      }),
    }),
    verifyPhoneNewCode: builder.mutation<
      VerifyPhoneNewCodeResponse,
      VerifyPhoneNewCodeInput
    >({
      query: (body) => ({
        url: 'Verification/verifyphone/newcode',
        method: 'POST',
        body,
      }),
    }),
    verifyEmailToken: builder.mutation<
      VerifyEmailTokenResponse,
      VerifyEmailTokenInput
    >({
      query: (body) => ({
        url: 'Verification/email',
        method: 'POST',
        body,
      }),
    }),
    loginUser: builder.mutation<LoginUserResponse, LoginUserInput>({
      query: (body) => ({
        url: 'Verification/login',
        method: 'POST',
        body,
      }),
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          const { token, refreshToken, expiration } = data;

          setAuthCookiesData({ token, refreshToken, expiration });
          dispatch(login());
          startTokenRefreshScheduler();
        } catch (err) {
          dispatch(logout());
          console.error('Failed to login user', err);
        }
      },
    }),
    refreshToken: builder.mutation<RefreshTokenResponse, RefreshTokenInput>({
      query: (body) => ({
        url: 'Token/refresh',
        method: 'POST',
        body,
      }),
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          const { token, refreshToken, expiration } = data;

          setAuthCookiesData({ token, refreshToken, expiration });
          startTokenRefreshScheduler();
        } catch (err) {
          console.error('Failed to refreshToken', err);
          dispatch(closeDialog());
        }
      },
    }),
    signUpWhatsApp: builder.mutation<{ sessionId: string }, UserRegisterInput>({
      query: (body) => ({
        url: 'Messenger/register-whatsapp',
        method: 'POST',
        body,
      }),
    }),
    signInWhatsApp: builder.mutation<
      { sessionId: string },
      { phoneNumber: string }
    >({
      query: (body) => ({
        url: 'Messenger/login-whatsapp',
        method: 'POST',
        body,
      }),
    }),
    verifyMessagerAuth: builder.mutation<
      LoginUserResponse,
      { confirmationId: string }
    >({
      query: (body) => ({
        url: 'Messenger/verify-messenger',
        method: 'POST',
        body,
      }),
    }),
    getAuthInfo: builder.query<LoginUserResponse, string>({
      query: (sessionId) => ({
        url: `Messenger/get-auth-info/${sessionId}`,
        method: 'GET',
      }),
    }),
    getUserLimits: builder.query<UserLimits, void>({
      query: () => ({
        url: 'Profile/limits',
        method: 'GET',
      }),
    }),
  }),
});

export const {
  useRegisterUserMutation,
  useResetPasswordCodeMutation,
  useResetPasswordMutation,
  useVerifyUserMutation,
  useVerifyCodeMutation,
  useLoginUserMutation,
  useRefreshTokenMutation,
  useVerifyPhoneNewCodeMutation,
  useVerifyEmailTokenMutation,
  useSignUpWhatsAppMutation,
  useSignInWhatsAppMutation,
  useVerifyMessagerAuthMutation,
  useLazyGetAuthInfoQuery,
  useLazyGetUserLimitsQuery,
} = authApi;
