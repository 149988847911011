import { CSS } from '@stitches/react';

import { Box } from '~components/atoms/Box';
import { Text } from '~components/atoms/Typography';

interface EventInfoProps {
  css?: CSS;
  title?: string;
  isHeader?: boolean;
}

export const InfoWrapper = ({
  css,
  title = '',
  isHeader = false,
}: EventInfoProps) => (
  <Box flexRow justifyCenter alignCenter css={css}>
    <Text
      textAlign="center"
      css={{
        color: isHeader ? '$white' : '$yellow',
        fontSize: '14px',
        lineHeight: '24px',
        '@xs_sm': {
          fontSize: '12px',
          lineHeight: '20px',
        },
      }}
    >
      {title}
    </Text>
  </Box>
);
