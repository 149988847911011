import React from 'react';

import { ArrowOddIndicator } from '~components/atoms/BetCoefficientIndicator';
import { Box } from '~components/atoms/Box';
import { SWIPE_COMPONENT_TYPES } from '~components/atoms/SwipeComponent';
import { Text } from '~components/atoms/Typography';
import { SELECTION_STATUS } from '~constants/common';
import { useMedia } from '~hooks/useMedia';
import { LockIcon } from '~icons';
import { useAppDispatch, useAppSelector } from '~store';
import { addStake, removeStakeBySelection } from '~store/slices/betslipSlice';
import { setIsSwipeOpen } from '~store/slices/mobileSlice';
import { Selection } from '~types/events';
import { isQuickBet } from '~utils/isQuickBet';

interface UseMarketSelectionProps {
  selection: Selection | null | undefined;
  index: number;
  eventId: string;
  marketId: string | undefined;
  isTotalLabel?: boolean;
  totalLabel?: string;
}

export const useMarketSelection = ({
  selection,
  eventId,
  marketId,
  isTotalLabel,
  totalLabel,
}: UseMarketSelectionProps) => {
  const { isMobileOrTablet } = useMedia();
  const dispatch = useAppDispatch();
  const { eventsMap } = useAppSelector((state) => state.events);
  const { events } = useAppSelector((state) => state.betslip);
  const { quickBet } = useAppSelector((state) => state.settings);
  const { odd, status, id } = selection || {
    odd: null,
    status: null,
    selectionId: null,
  };
  const isInBetslip = events.some(({ selectionId }) => selectionId === id);

  let selectionContent;

  if (status === SELECTION_STATUS.OPEN) {
    selectionContent = <ArrowOddIndicator odd={odd} isLeagueEvents />;
  } else {
    selectionContent = (
      <Box css={{ color: '$white' }}>
        <LockIcon width={16} height={16} />
      </Box>
    );
  }

  if (!selection) {
    selectionContent = (
      <Text
        level={isMobileOrTablet ? '12-20' : '14-28'}
        fontWeight="medium"
        color="yellow"
      >
        -
      </Text>
    );
  }

  if (isTotalLabel) {
    selectionContent = (
      <Text
        level={isMobileOrTablet ? '12-20' : '14-28'}
        fontWeight="medium"
        color="grayMedium"
      >
        {totalLabel || '-'}
      </Text>
    );
  }

  const handleSelectionClick = () => {
    if (odd && marketId) {
      const eventStatus = eventsMap[eventId]?.status;

      if (
        events.length === 0 &&
        eventStatus &&
        isQuickBet(eventStatus, quickBet)
      ) {
        dispatch(setIsSwipeOpen(SWIPE_COMPONENT_TYPES.QUICK_BET));
      }

      if (isInBetslip) {
        dispatch(removeStakeBySelection(id));
      } else {
        dispatch(addStake({ eventId, marketId, selectionId: id }));
      }
    }
  };

  return {
    selectionContent,
    handleSelectionClick,
    isInBetslip,
    odd,
  };
};
