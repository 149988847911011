import { styled } from 'stitches.config';

import { Box } from '~components/atoms/Box';
import { BannerWrapper } from '~components/molecules/Jackpot';
import { useMedia } from '~hooks/useMedia';
import { Jackpot } from '~types/jackpot';

import { LuckyDip } from '../LuckyDip/LuckyDip';

import { BannerDate } from './BannerDate';
import { BannerInfo } from './BannerInfo';

const BannerItemsWrapper = styled(Box, {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  height: '100%',
  padding: '$6',
  gap: '$2',
});

const MobileBannerItemsWrapper = styled(Box, {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  height: '100%',
  padding: '$3',
  gap: '$2',
});

interface JackpotInfoBackgroundBoxProps {
  jackpot: Jackpot | null;
}

export const Banner = ({ jackpot }: JackpotInfoBackgroundBoxProps) => {
  const { isMobile } = useMedia();

  if (!jackpot) return null;

  const { sportName, details, firstEventStartDate, amount } = jackpot;

  return (
    <BannerWrapper
      sportName={sportName}
      customStyles={{
        border: '4px solid $black',
        minHeight: isMobile ? '120px !important' : '212px',
      }}
    >
      {isMobile ? (
        <MobileBannerItemsWrapper>
          <Box flexRow justifyContentBetween gap={2}>
            <BannerInfo details={details} amount={amount} />
            <BannerDate startDate={firstEventStartDate} />
          </Box>
          <LuckyDip />
        </MobileBannerItemsWrapper>
      ) : (
        <BannerItemsWrapper>
          <Box flexCol gap={3}>
            <BannerInfo details={details} amount={amount} />
            <LuckyDip />
          </Box>
          <BannerDate startDate={firstEventStartDate} />
        </BannerItemsWrapper>
      )}
    </BannerWrapper>
  );
};
