import React from 'react';

import { Box } from '~components/atoms/Box';
import { Skeleton } from '~components/atoms/Skeleton';

export const SmallJackpotSkeleton = () => {
  const jackpotArray = Array(4).fill(0);
  const defaultSkeletonStyles = { width: '100%', border: 'none' };

  return (
    <Box fullWidth flexCol justifyCenter css={{ padding: '$2 $4' }}>
      <Box
        flexCol
        gap={2}
        css={{
          padding: '$2',
          backgroundColor: '$blackDark',
          borderRadius: '$8',
        }}
      >
        <Skeleton css={{ height: '212px', ...defaultSkeletonStyles }} />
        <Box
          flexCol
          gap={2}
          css={{
            padding: '$2',
            backgroundColor: '$gray',
            borderRadius: '$8',
          }}
        >
          {jackpotArray.map((_, index) => (
            <Skeleton
              key={index}
              css={{ height: '60px', ...defaultSkeletonStyles }}
            />
          ))}
        </Box>
      </Box>
    </Box>
  );
};
