import { useCallback, useEffect, useState } from 'react';

import { useLazyGetLeaguesByCountry } from '~api/category/categoryQueries';
import {
  Countries,
  Leagues,
  LeaguesByCountryParams,
} from '~api/category/types';
import { QUERY_PARAMS } from '~constants/common';
import { useNextHourToDateTo } from '~hooks/useNextHourToDateTo';
import { useQueryParams } from '~hooks/useQueryParams';
import { useAppDispatch, useAppSelector } from '~store';
import {
  setLeaguesData,
  setOpenedCountries,
  setSports,
} from '~store/slices/prematchMenuSlice';
import { conditionalAdd } from '~utils/objectHelpers';

import { MULTI_ACCORDION_ROLES } from '../../MultiAccordion';

export const useCountryMenu = (
  initialCountries: Countries,
  accordionSportId?: string,
) => {
  const { sportId } = useQueryParams();
  const dispatch = useAppDispatch();
  const countryMenuRole = MULTI_ACCORDION_ROLES.CHILD;
  const { openedCountries, leaguesData, sports } = useAppSelector(
    (state) => state.prematchMenu,
  );

  const [curSportOpenedCurrentCountry, setCurSportOpenedCurrentCountry] =
    useState(openedCountries);

  const [loadingCountryId, setLoadingCountryId] = useState<number>();

  const { lazyGetLeaguesByCountryQuery } = useLazyGetLeaguesByCountry();
  const dateTo = useNextHourToDateTo();

  const getLeaguesData = useCallback(
    (countryId: number) => {
      return countryId in leaguesData
        ? (leaguesData[countryId] as Leagues)
        : [];
    },
    [leaguesData],
  );

  const handleOnValueChange = useCallback(
    async (e: string[]) => {
      const countryId = e.find((item) => !openedCountries.includes(item));

      if (countryId) {
        setLoadingCountryId(parseInt(countryId));
        const params: LeaguesByCountryParams = {
          countryId: parseInt(countryId),
        };

        conditionalAdd(params, QUERY_PARAMS.DATE_TO, dateTo);
        const leagues: Leagues =
          await lazyGetLeaguesByCountryQuery(params).unwrap();

        if (sports && sportId) {
          const resSports = sports.map((sport) => {
            if (sport.id === parseInt(sportId)) {
              return {
                ...sport,
                countries: sport.countries.map((country) => {
                  if (country.id === parseInt(countryId)) {
                    return {
                      ...country,
                      leagues,
                    };
                  }

                  return country;
                }),
              };
            }

            return sport;
          });

          dispatch(setSports(resSports));
        }

        dispatch(setLeaguesData({ id: countryId, leagues }));
      }

      if (accordionSportId) {
        setCurSportOpenedCurrentCountry(e);
      }

      dispatch(setOpenedCountries(e));
    },
    [openedCountries, leaguesData, sports, sportId, accordionSportId],
  );

  const handleLoadLeagues = async ({ countryId }: { countryId: number }) => {
    const leagues: Leagues = await lazyGetLeaguesByCountryQuery({
      countryId,
    }).unwrap();

    dispatch(setOpenedCountries([countryId.toString()]));
    dispatch(setLeaguesData({ id: countryId.toString(), leagues }));
  };

  useEffect(() => {
    if (initialCountries.length === 0 || openedCountries.length) {
      return;
    }

    const { id } =
      initialCountries.find((country) => country?.leagues?.length > 0) || {};

    if (id) {
      dispatch(setOpenedCountries([id.toString()]));
    } else if (initialCountries[0]) {
      const countryId = initialCountries[0].id;

      handleLoadLeagues({ countryId });
    }
  }, [initialCountries]);

  return {
    loadingCountryId,
    countryMenuRole,
    openCountries: accordionSportId
      ? curSportOpenedCurrentCountry
      : openedCountries,
    handleOnValueChange,
    getLeaguesData,
  };
};
