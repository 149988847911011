import { styled } from 'stitches.config';

import { Box } from '~components/atoms/Box';

export const StyledWrapper = styled(Box, {
  display: 'flex',
  width: '100%',
  overflow: 'auto',
});

export const StyledPage = styled(Box, {
  display: 'grid',
  backgroundColor: '$gray',
  gridTemplateColumns: '324px 1fr 324px',
  gridTemplateAreas: `
          'sidebar content rightSidebar'
        `,
  gap: '$1',
  maxWidth: '1920px',
  width: '100%',
  pt: '$2',
  pb: '$2',

  '@md': {
    gridTemplateColumns: '286px 1fr 286px',
  },
  variants: {
    isJackpot: {
      true: {
        '@md': {
          gridTemplateColumns: '324px 1fr',
          gridTemplateAreas: 'sidebar content',
        },
      },
    },
  },
});
