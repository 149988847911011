import { useEffect, useState } from 'react';

import { MainMarket } from '~api/market/types';
import {
  useLazyGetMoreUpcomingEvents,
  useLazyGetUpcomingEvents,
} from '~api/sportEvent/sportEventQueries';
import {
  LoadMoreUpcomingEvents,
  TopPrematchDateGroup,
  TopPrematchEvent,
} from '~api/sportEvent/types';
import {
  PrematchUpcomingHighlightsData,
  TopPrematchDateGroupsIds,
} from '~constants/mainPage';
import { useTranslation } from '~hooks/useTranslation';
import { useAppDispatch, useAppSelector } from '~store';
import { addEventsWithMarkets } from '~store/slices/eventsSlice';
import { setActiveMainHighlightsSportId } from '~store/slices/liveMenuSlice';
import { setScrollTopMainPage } from '~store/slices/mobileSlice';
import {
  setUpcomingEvents,
  setUpcomingEventsLoading,
} from '~store/slices/prematchMenuSlice';
import { SportEvent } from '~types/events';

import {
  preparePrematchUpcomingHighlightsData,
  SportOptions,
} from '../helpers';

const limit = 20;

export const useMainPageMobile = () => {
  const { localized } = useTranslation();

  const dispatch = useAppDispatch();
  const { mobileMainPageTab, scrollTopMainPage } = useAppSelector(
    (state) => state.mobileState,
  );
  const { upcomingEvents, upcomingEventsLoading } = useAppSelector(
    (state) => state.prematchMenu,
  );
  const { liveHighlightsSports, activeMainHighlightsSportId } = useAppSelector(
    (state) => state.liveMenu,
  );
  const { data: preparedData, sports: sportsDefault } =
    preparePrematchUpcomingHighlightsData(upcomingEvents || []);
  const [highlightsData, setHighlightsData] =
    useState<PrematchUpcomingHighlightsData>(preparedData);
  const { mainMarkets } = useAppSelector((state) => state.mainMarkets);
  const { mainMarketsSelected } = useAppSelector((state) => state.liveMenu);
  const [sports, setSports] = useState<SportOptions>(sportsDefault);
  const [isAllLoaded, setIsAllLoaded] = useState<boolean>(false);

  const [activeEventGroups, setActiveEventGroups] =
    useState<TopPrematchDateGroupsIds>(
      highlightsData[activeMainHighlightsSportId as string] || [],
    );

  let defaultMainSportMarket: MainMarket | undefined;

  if (mainMarkets.length && activeMainHighlightsSportId) {
    const mainSport = mainMarkets.find(
      (sport) => sport.id === parseInt(activeMainHighlightsSportId),
    );

    if (mainSport) {
      const markets = [...mainSport.markets];
      const marketSelected =
        markets.find(
          ({ id }) =>
            id === mainMarketsSelected[parseInt(activeMainHighlightsSportId)],
        ) || markets[0];

      if (marketSelected) {
        defaultMainSportMarket = marketSelected;
      } else {
        defaultMainSportMarket = markets[0];
      }
    }
  }

  const [mainSportMarket, setMainSportMarkets] = useState<MainMarket>(
    defaultMainSportMarket as MainMarket,
  );

  const { lazyGetUpcomingEventsQuery } = useLazyGetUpcomingEvents();
  const { lazyGetMoreUpcomingEventsQuery } = useLazyGetMoreUpcomingEvents();

  const handleActiveSportIdChange = async (sportId: string) => {
    dispatch(setActiveMainHighlightsSportId(sportId));
    setIsAllLoaded(false);
    loadUpcoming(sportId);
  };

  const loadUpcoming = async (sportId: string) => {
    dispatch(setUpcomingEventsLoading(true));
    try {
      const response = await lazyGetUpcomingEventsQuery(sportId).unwrap();

      if (upcomingEvents) {
        const responseAddedData = (response || []).filter(({ dateGroups }) => {
          return dateGroups.length;
        })[0];
        const responseAddedSportId = responseAddedData?.sportId;

        const updatedData = [...upcomingEvents];
        const sportDataIndex = updatedData.findIndex(
          (sportData) => sportData.sportId === responseAddedSportId,
        );

        if (sportDataIndex !== -1) {
          updatedData[sportDataIndex] = responseAddedData as TopPrematchEvent;
        }

        dispatch(setUpcomingEvents(updatedData));
      } else {
        dispatch(setUpcomingEvents(response));
      }
    } catch (e) {
      console.error('Failed to load data: ', e);
    } finally {
      dispatch(setUpcomingEventsLoading(false));
    }
  };

  const loadMoreUpcoming = async () => {
    const currentSportDateGroups = upcomingEvents?.find(
      (sportData) => sportData.sportId === Number(activeMainHighlightsSportId),
    )?.dateGroups;

    const totalLoaded = currentSportDateGroups?.reduce(
      (acc, { matches }) => acc + matches.length,
      0,
    ) as number;

    const payload: LoadMoreUpcomingEvents = {
      sportId: activeMainHighlightsSportId as string,
      page: Math.floor(totalLoaded / limit) + 1,
      limit,
    };

    try {
      const response = await lazyGetMoreUpcomingEventsQuery(payload).unwrap();

      if (upcomingEvents) {
        const responseAddedData = (response || []).filter(
          ({ dateGroups }) => dateGroups.length,
        )[0] as TopPrematchEvent;
        const responseAddedSportId = responseAddedData?.sportId;

        const totalEventsLoaded = responseAddedData.dateGroups.reduce(
          (acc, { matches }) => acc + matches.length,
          0,
        );

        if (totalEventsLoaded < limit) {
          setIsAllLoaded(true);
        }

        const updatedData = upcomingEvents.map((sportData) => ({
          ...sportData,
          dateGroups: sportData.dateGroups.map((group) => ({
            ...group,
            matches: [...group.matches],
          })),
        }));

        const sportDataIndex = updatedData.findIndex(
          (sportData) => sportData.sportId === responseAddedSportId,
        );

        if (sportDataIndex !== -1) {
          const updatingData = updatedData[sportDataIndex] as TopPrematchEvent;

          responseAddedData.dateGroups.forEach(({ matches, date }) => {
            const foundDateGroupIndex = updatingData.dateGroups.findIndex(
              (group) => group.date === date,
            );

            if (foundDateGroupIndex !== -1) {
              const foundDateGroup = {
                ...updatingData.dateGroups[foundDateGroupIndex],
              } as TopPrematchDateGroup;

              foundDateGroup.matches = [
                ...(foundDateGroup.matches as SportEvent[]),
                ...matches,
              ];
              updatingData.dateGroups[foundDateGroupIndex] = foundDateGroup;
            } else {
              updatingData.dateGroups.push({
                date,
                matches,
              });
            }
          });
        }

        dispatch(setUpcomingEvents(updatedData));
      } else {
        dispatch(setUpcomingEvents(response));
      }
    } catch (e) {
      console.error('Failed to load data: ', e);
    }
  };

  useEffect(() => {
    if (!upcomingEvents) return;

    const {
      data: preparedData,
      events,
      markets,
      sports,
    } = preparePrematchUpcomingHighlightsData(upcomingEvents);

    dispatch(addEventsWithMarkets({ events, markets }));
    const activeSportIdValue =
      activeMainHighlightsSportId || (sports[0]?.id.toString() as string);

    dispatch(setActiveMainHighlightsSportId(activeSportIdValue));
    setSports(sports);
    setHighlightsData(preparedData);
    setActiveEventGroups(
      preparedData[activeSportIdValue as string] as TopPrematchDateGroupsIds,
    );
    dispatch(setUpcomingEventsLoading(false));
  }, [upcomingEvents, liveHighlightsSports, mobileMainPageTab]);

  useEffect(() => {
    if (mainMarkets.length && activeMainHighlightsSportId) {
      const mainSport = mainMarkets.find(
        (sport) => sport.id === parseInt(activeMainHighlightsSportId),
      );

      if (mainSport) {
        const markets = [...mainSport.markets];
        const marketSelected =
          markets.find(
            ({ id }) =>
              id === mainMarketsSelected[parseInt(activeMainHighlightsSportId)],
          ) || markets[0];

        if (marketSelected) {
          setMainSportMarkets(marketSelected);
        } else {
          setMainSportMarkets(markets[0] as MainMarket);
        }
      }
    }
  }, [mainMarkets, activeMainHighlightsSportId, mainMarketsSelected]);

  useEffect(() => {
    if (scrollTopMainPage) {
      window.scrollTo(0, 0);
      dispatch(setScrollTopMainPage(false));
    }
  }, [scrollTopMainPage]);

  return {
    activeTab: mobileMainPageTab,
    activeSportId: activeMainHighlightsSportId,
    activeEventGroups,
    mainSportMarket,
    sports,
    isLoading: upcomingEventsLoading,
    isAllLoaded,
    localized,
    handleActiveSportIdChange,
    loadMore: loadMoreUpcoming,
  };
};
