import { useLocation } from 'react-router-dom';

import { EVENT_STATUS, QUERY_PARAMS } from '~constants/common';
import {
  setActiveMenu,
  setLiveParams,
  setPrematchParams,
} from '~features/sportsMenu/sportsMenuSlice';
import { useQueryParams } from '~hooks/useQueryParams';
import { useRouterQuery } from '~hooks/useRouterQuery';
import { useTranslation } from '~hooks/useTranslation';
import { useAppDispatch } from '~store';
import {
  changeSearchPerformed,
  setSearchQuery,
} from '~store/slices/sportEventSlice';
import { SPORT_MENUS } from '~types/sportMenus';
import { getParamFromQuery, getQueryParams, QueryParams } from '~utils/url';

export const useSearchResult = (
  status: EVENT_STATUS,
  sportId: number,
  countryId: number,
  leagueId: number,
  eventId: string,
  index?: number,
) => {
  const { nextHour } = useQueryParams();
  const { updateQueryParams } = useRouterQuery();

  const { localized } = useTranslation();
  const { search } = useLocation();

  const dispatch = useAppDispatch();
  const isLive = status === EVENT_STATUS.IN_PROGRESS;
  const isFirst = index === 0;

  const handleSearchResultClick = () => {
    const queryParams = getQueryParams(search);

    const activeMenu =
      getParamFromQuery(search, QUERY_PARAMS.MENU) || SPORT_MENUS.PREMATCH;
    const menuToOpen = isLive ? SPORT_MENUS.LIVE : SPORT_MENUS.PREMATCH;

    if (activeMenu !== menuToOpen) {
      if (menuToOpen === SPORT_MENUS.PREMATCH) {
        dispatch(setLiveParams(queryParams));
      } else {
        dispatch(setPrematchParams(queryParams));
      }
    }

    dispatch(changeSearchPerformed(false));
    dispatch(setSearchQuery(''));

    updateQueryParams(
      {
        [QUERY_PARAMS.SPORT_ID]: sportId || undefined,
        [QUERY_PARAMS.COUNTRY_ID]: countryId || undefined,
        [QUERY_PARAMS.LEAGUE_ID]: leagueId || undefined,
        [QUERY_PARAMS.MENU]: isLive ? SPORT_MENUS.LIVE : SPORT_MENUS.PREMATCH,
        [QUERY_PARAMS.EVENT_ID]: eventId || undefined,
        nextHour: nextHour || undefined,
      } as QueryParams<typeof QUERY_PARAMS>,
      true,
    );

    dispatch(setActiveMenu(menuToOpen));
  };

  return {
    isLive,
    isFirst,
    localized,
    handleSearchResultClick,
  };
};
