import { memo, useEffect, useState } from 'react';

import { MainMarket } from '~api/market/types';
import { areTwoObjectsEqual } from '~utils/objectHelpers';

import {
  MULTI_ACCORDION_ROLES,
  MultiAccordionContent,
  MultiAccordionItem,
  MultiAccordionTrigger,
} from '../../MultiAccordion';

import { LiveEventList } from './LiveEventList';

interface LiveSportMenuItemProps {
  isOpened: boolean;
  leagueId: number;
  menuRole: MULTI_ACCORDION_ROLES;
  name: string;
  leagueName: string;
  countryId: number;
  sportId: number;
  leagueEvents: string[];
  code: string | null;
  getLeagueEventsCount: (events: string[]) => number;
  mainSportMarket?: MainMarket | undefined;
}

export const LiveCountryMenuItem = ({
  isOpened,
  leagueId,
  menuRole,
  name,
  leagueName,
  countryId,
  sportId,
  leagueEvents,
  code,
  getLeagueEventsCount,
  mainSportMarket,
}: LiveSportMenuItemProps) => {
  const [leagueEventsData, setLeagueEventsData] =
    useState<string[]>(leagueEvents);

  useEffect(() => {
    if (areTwoObjectsEqual(leagueEventsData, leagueEvents)) return;
    setLeagueEventsData(leagueEvents);
  }, [leagueEvents]);

  return (
    <LiveCountryMenuItemContent
      {...{
        isOpened,
        leagueId,
        menuRole,
        name,
        leagueName,
        getLeagueEventsCount,
        countryId,
        sportId,
        leagueEvents,
        mainSportMarket,
        code,
      }}
      leagueEvents={leagueEventsData || []}
    />
  );
};

export const LiveCountryMenuItemContent = memo(
  ({
    isOpened,
    leagueId,
    menuRole,
    name,
    leagueName,
    getLeagueEventsCount,
    countryId,
    sportId,
    leagueEvents,
    mainSportMarket,
    code,
  }: LiveSportMenuItemProps) => {
    return (
      <MultiAccordionItem
        value={leagueId.toString()}
        key={leagueId}
        role={menuRole}
      >
        <MultiAccordionTrigger
          css={{
            '@xs_sm': {
              background: '$black',
              borderBottom: !isOpened ? '1px solid $gray' : 'none',
              borderTopLeftRadius: '0 !important',
              borderTopRightRadius: '0 !important',
            },
          }}
          title={name}
          secondTitle={leagueName}
          count={getLeagueEventsCount(leagueEvents)}
          role={menuRole}
          country={name}
          code={code}
        />
        <MultiAccordionContent
          css={{
            pt: '$3',
            '@xs_sm': {
              borderRadius: '$10',
              pt: '$1',
            },
          }}
          role={menuRole}
        >
          <LiveEventList
            mainSportMarket={mainSportMarket}
            countryId={countryId}
            sportId={sportId}
            leagueId={leagueId}
            events={leagueEvents}
          />
        </MultiAccordionContent>
      </MultiAccordionItem>
    );
  },
);
