import { useEffect, useState } from 'react';
import { HubConnection } from '@microsoft/signalr';

import {
  SIGNAL_R_SOCKET_CONNECTED_STATUS,
  SIGNAL_R_SOCKET_MESSAGES,
  SIGNAL_R_SOCKET_NAMES,
} from '~constants/signalR';
import { useMedia } from '~hooks/useMedia';
import { useQueryParams } from '~hooks/useQueryParams';
import { useAppSelector } from '~store';

interface EventsMessageData {
  ids: string[];
  MarketId: number;
}

export const useInplayHubConnection = () => {
  const { group } = useQueryParams();
  const { sockets } = useAppSelector((state) => state.signalRSockets);
  const {
    liveMenuSports: liveMenu,
    mainMarketsSelected,
    openedSports,
    openedCountryLeagues,
    marketsOfFavoriteEventsSelected,
    liveHighlightsSports = [],
    activeMainHighlightsSportId,
  } = useAppSelector((state) => state.liveMenu);
  const { favoriteInplayEvents = [] } = useAppSelector(
    (state) => state.liveGroupsState,
  );
  const { isMobileOrTablet } = useMedia();
  const { topLiveEvents = [] } = useAppSelector(
    (state) => state.liveGroupsState,
  );

  const { upcomingEvents, selectedLeagueData = [] } = useAppSelector(
    (state) => state.prematchMenu,
  );
  const { mainMarkets } = useAppSelector((state) => state.mainMarkets);
  const [socket, setSocket] = useState<HubConnection | null>(null);
  const [eventsData, setEventsData] = useState<EventsMessageData[]>([]);

  useEffect(() => {
    const eventsDataArr: EventsMessageData[] = [];

    liveMenu.forEach(({ id: sportId, countries }) => {
      if (!openedSports.includes(sportId.toString())) return;
      countries.forEach(({ leagues }) => {
        leagues.forEach(({ events, id }) => {
          if (!openedCountryLeagues.includes(id.toString())) return;
          const selectedMarketId = mainMarketsSelected[sportId]
            ? mainMarketsSelected[sportId]
            : null;

          if (selectedMarketId && (selectedMarketId as number)) {
            const savedMarket = eventsDataArr.find(
              (item) => item.MarketId === selectedMarketId,
            );

            if (savedMarket) {
              savedMarket.ids = [...savedMarket.ids, ...events];
            } else {
              eventsDataArr.push({
                ids: events,
                MarketId: selectedMarketId as number,
              });
            }

            return;
          }
        });
      });
    });

    const favoriteEventsDataArr: EventsMessageData[] = [];

    if (favoriteInplayEvents.length) {
      favoriteInplayEvents.forEach(({ id: eventId, sportId }) => {
        const marketId = marketsOfFavoriteEventsSelected[sportId];

        if (marketId) {
          const savedMarket = favoriteEventsDataArr.find(
            (item) => item.MarketId === marketId,
          );

          if (savedMarket) {
            savedMarket.ids.push(eventId);
          } else {
            favoriteEventsDataArr.push({
              ids: [eventId],
              MarketId: marketId,
            });
          }
        }
      });
    }

    const topEventsDataArr: EventsMessageData[] = [];

    if (group) {
      topLiveEvents.forEach(({ matches }) => {
        matches.forEach(({ id: EventId, markets }) => {
          if (markets) {
            markets.forEach(({ marketId }) => {
              const existingMarketData = topEventsDataArr.find(
                (item) => item.MarketId === parseInt(marketId),
              );

              if (existingMarketData) {
                const { ids } = existingMarketData;

                ids.push(EventId);
              } else {
                topEventsDataArr.push({
                  ids: [EventId],
                  MarketId: parseInt(marketId),
                });
              }
            });
          }
        });
      });
    }

    const liveHighlightsDataArr: EventsMessageData[] = [];

    if (liveHighlightsSports) {
      liveHighlightsSports.forEach(({ id: sportId, countries }) => {
        if (sportId !== parseInt(activeMainHighlightsSportId || '')) return;
        countries.forEach(({ leagues }) => {
          leagues.forEach(({ events }) => {
            events.forEach(({ id: EventId = '', markets }) => {
              if (isMobileOrTablet) {
                const selectedMarketId = mainMarketsSelected[sportId]
                  ? mainMarketsSelected[sportId]
                  : null;

                if (selectedMarketId && (selectedMarketId as number)) {
                  const savedMarket = liveHighlightsDataArr.find(
                    (item) => item.MarketId === selectedMarketId,
                  );

                  if (savedMarket) {
                    savedMarket.ids.push(EventId);
                  } else {
                    liveHighlightsDataArr.push({
                      ids: [EventId],
                      MarketId: selectedMarketId as number,
                    });
                  }
                }
              } else {
                if (markets.length) {
                  markets.forEach(({ marketId }) => {
                    const savedMarket = liveHighlightsDataArr.find(
                      (item) => item.MarketId === parseInt(marketId),
                    );

                    if (savedMarket) {
                      savedMarket.ids.push(EventId);
                    } else {
                      liveHighlightsDataArr.push({
                        ids: [EventId],
                        MarketId: parseInt(marketId),
                      });
                    }
                  });
                } else {
                  const mainSportMarkets = mainMarkets.find(
                    (item) => item.id === sportId,
                  )?.markets;

                  mainSportMarkets?.forEach(({ id: marketId }) => {
                    const savedMarket = liveHighlightsDataArr.find(
                      (item) => item.MarketId === marketId,
                    );

                    if (savedMarket) {
                      savedMarket.ids.push(EventId);
                    } else {
                      liveHighlightsDataArr.push({
                        ids: [EventId],
                        MarketId: marketId,
                      });
                    }
                  });
                }
              }
            });
          });
        });
      });
    }

    const upcomingEventsDataArr: EventsMessageData[] = [];

    if (upcomingEvents && upcomingEvents.length) {
      upcomingEvents.forEach(({ sportId, dateGroups }) => {
        if (isMobileOrTablet && activeMainHighlightsSportId) {
          if (sportId !== parseInt(activeMainHighlightsSportId)) return;
        }

        const mainSportMarkets = mainMarkets.find((item) => item.id === sportId)
          ?.markets;

        dateGroups.forEach(({ matches }) => {
          matches.forEach(({ id: EventId = '' }) => {
            mainSportMarkets?.forEach(({ id: marketId }) => {
              const savedMarket = upcomingEventsDataArr.find(
                (item) => item.MarketId === marketId,
              );

              if (savedMarket) {
                savedMarket.ids.push(EventId);
              } else {
                upcomingEventsDataArr.push({
                  ids: [EventId],
                  MarketId: marketId,
                });
              }
            });
          });
        });
      });
    }

    const leagueEventsDataArr: EventsMessageData[] = [];

    if (selectedLeagueData && selectedLeagueData.length) {
      const { id: sportId, countries = [] } = selectedLeagueData[0] || {};
      const { leagues = [] } = countries[0] || {};
      const { events = [] } = leagues[0] || {};

      const mainSportMarkets =
        mainMarkets.find(({ id }) => {
          return id === sportId;
        })?.markets || [];

      events.forEach(({ id: EventId = '' }) => {
        mainSportMarkets?.forEach(({ id: marketId }) => {
          const savedMarket = leagueEventsDataArr.find(
            (item) => item.MarketId === marketId,
          );

          if (savedMarket) {
            savedMarket.ids.push(EventId);
          } else {
            leagueEventsDataArr.push({
              ids: [EventId],
              MarketId: marketId,
            });
          }
        });
      });
    }

    const resultEventsData = [...eventsDataArr];

    if (group) {
      topEventsDataArr.forEach(({ ids, MarketId }) => {
        const isInResultData = resultEventsData.find(
          (item) => item.MarketId === MarketId,
        );

        if (isInResultData) {
          isInResultData.ids = [...isInResultData.ids, ...ids];
        } else {
          resultEventsData.push({ ids, MarketId });
        }
      });
    }

    if (liveHighlightsDataArr.length) {
      liveHighlightsDataArr.forEach(({ ids, MarketId }) => {
        const isInResultData = resultEventsData.find(
          (item) => item.MarketId === MarketId,
        );

        if (isInResultData) {
          isInResultData.ids = [...isInResultData.ids, ...ids];
        } else {
          resultEventsData.push({ ids, MarketId });
        }
      });
    }

    if (favoriteEventsDataArr.length) {
      favoriteEventsDataArr.forEach(({ ids, MarketId }) => {
        const isInResultData = resultEventsData.find(
          (item) => item.MarketId === MarketId,
        );

        if (isInResultData) {
          isInResultData.ids = [...isInResultData.ids, ...ids];
        } else {
          resultEventsData.push({ ids, MarketId });
        }
      });
    }

    if (upcomingEventsDataArr.length) {
      upcomingEventsDataArr.forEach(({ ids, MarketId }) => {
        const isInResultData = resultEventsData.find(
          (item) => item.MarketId === MarketId,
        );

        if (isInResultData) {
          isInResultData.ids = [...isInResultData.ids, ...ids];
        } else {
          resultEventsData.push({ ids, MarketId });
        }
      });
    }

    if (leagueEventsDataArr.length) {
      leagueEventsDataArr.forEach(({ ids, MarketId }) => {
        const isInResultData = resultEventsData.find(
          (item) => item.MarketId === MarketId,
        );

        if (isInResultData) {
          isInResultData.ids = [...isInResultData.ids, ...ids];
        } else {
          resultEventsData.push({ ids, MarketId });
        }
      });
    }

    setEventsData(resultEventsData);
  }, [
    group,
    topLiveEvents,
    liveMenu,
    mainMarkets,
    openedCountryLeagues,
    favoriteInplayEvents,
    marketsOfFavoriteEventsSelected,
    isMobileOrTablet,
    activeMainHighlightsSportId,
    openedSports,
    mainMarketsSelected,
    liveHighlightsSports,
    upcomingEvents,
    selectedLeagueData,
  ]);

  useEffect(() => {
    const inplaySocket = sockets?.[SIGNAL_R_SOCKET_NAMES.INPLAY]?.socket;

    if (inplaySocket) {
      setSocket(inplaySocket);
    }
  }, [sockets]);

  const subscribeEvents = async (eventsData: EventsMessageData[]) => {
    try {
      const groupedData: Record<number, string[]> = {};

      eventsData.forEach((item) => {
        const marketId = item.MarketId;

        if (!groupedData[marketId]) {
          groupedData[marketId] = [];
        }

        groupedData[marketId] = (groupedData[marketId] || []).concat(item.ids);
      });

      const resPayload: { MarketId: number; ids: string[] }[] = [];

      Object.entries(groupedData).forEach(([key, value]) => {
        resPayload.push({ MarketId: parseInt(key), ids: value });
      });

      await socket?.invoke(
        SIGNAL_R_SOCKET_MESSAGES.SUBSCRIBE_EVENTS_MARKETS_BY_MARKET_IDS,
        resPayload,
      );
    } catch (e) {
      console.error('Failed to subscribe events: ', e);
    }
  };

  useEffect(() => {
    if (socket && socket.state === SIGNAL_R_SOCKET_CONNECTED_STATUS) {
      socket.invoke(SIGNAL_R_SOCKET_MESSAGES.SUBSCRIBE_INPLAY_MENU);
    }

    return () => {
      if (socket && socket.state === SIGNAL_R_SOCKET_CONNECTED_STATUS) {
        socket.invoke(
          SIGNAL_R_SOCKET_MESSAGES.UNSUBSCRIBE_EVENTS_MARKETS_BY_MARKET_IDS,
        );
        socket.invoke(SIGNAL_R_SOCKET_MESSAGES.UNSUBSCRIBE_INPLAY_MENU);
      }
    };
  }, [socket, socket?.state]);

  useEffect(() => {
    if (socket && socket.state === SIGNAL_R_SOCKET_CONNECTED_STATUS) {
      subscribeEvents(eventsData);
    }
  }, [socket, socket?.state, eventsData]);
};
