import React from 'react';

import { BetAmount } from '~api/betslip/types';
import { Box } from '~components/atoms/Box';
import { NumberInput } from '~components/atoms/NumberInput';
import { Text } from '~components/atoms/Typography';
import { BetslipTotalInfo } from '~components/molecules/Betslip/components/BetslipFooter/BetslipTotalInfo';
import { StyledBetslipValueContainer } from '~components/molecules/Betslip/components/BetslipFooter/styled.components';
import { betslipTextStyles } from '~components/molecules/Betslip/components/BetslipStakes/BetslipStakeMain';
import { useTranslation } from '~hooks/useTranslation';
import { useAppDispatch, useAppSelector } from '~store';
import { setAdjustedBetAmount } from '~store/slices/betslipSlice';

import { SPORT_BETSLIP_TYPE_OPTIONS } from '../../constants';

export const BetslipAggregatedValue = () => {
  const { localized } = useTranslation();
  const dispatch = useAppDispatch();
  const { stakeType, aggregatedBetAmount } = useAppSelector(
    (state) => state.betslip,
  );
  const { separateBonusBalance } = useAppSelector((state) => state.settings);

  const isSystemBet = stakeType === SPORT_BETSLIP_TYPE_OPTIONS.SYSTEM;

  const handleAggregatedBetAmountChange = (amount: BetAmount) => {
    dispatch(setAdjustedBetAmount(amount));
  };

  return (
    <StyledBetslipValueContainer>
      <Box flexRow alignCenter>
        {separateBonusBalance && !isSystemBet ? (
          <Box fullWidth flexRow gap={3} css={{ minWidth: 0 }}>
            <Box fullWidth flexRow alignCenter gap={1} css={{ minWidth: 0 }}>
              <Text css={betslipTextStyles}>
                {localized('betslip.cash') + ':'}
              </Text>
              <NumberInput
                withoutActions
                onChange={(value) =>
                  handleAggregatedBetAmountChange({
                    ...aggregatedBetAmount,
                    main: value,
                  })
                }
                value={aggregatedBetAmount?.main?.toString() || ''}
              />
            </Box>

            <Box fullWidth flexRow alignCenter gap={1} css={{ minWidth: 0 }}>
              <Text css={betslipTextStyles}>
                {localized('betslip.freeBet') + ':'}
              </Text>
              <NumberInput
                withoutActions
                onChange={(value) =>
                  handleAggregatedBetAmountChange({
                    ...aggregatedBetAmount,
                    bonus: value,
                  })
                }
                value={aggregatedBetAmount?.bonus?.toString() || ''}
              />
            </Box>
          </Box>
        ) : (
          <NumberInput
            fullWidth
            onChange={(value) => {
              handleAggregatedBetAmountChange({ main: value });
            }}
            value={aggregatedBetAmount?.main?.toString() || ''}
          />
        )}
      </Box>
      <BetslipTotalInfo />
    </StyledBetslipValueContainer>
  );
};
