import { Box } from '~components/atoms/Box';
import { Skeleton } from '~components/atoms/Skeleton';

export const LeagueMenuListSkeleton = () => {
  return (
    <Box flexCol gap={1}>
      {Array.from({ length: 6 }, (_, key) => {
        return (
          <Box
            css={{
              p: '10px $3',
              background: '$grayDark',
              borderRadius: '$6',
            }}
            key={key}
          >
            <Skeleton css={{ height: '$5', width: '100%', border: 'none' }} />
          </Box>
        );
      })}
    </Box>
  );
};
