import { Route, Routes } from 'react-router-dom';

import { NotFoundPage } from '~components/atoms/NotFoundPage';
import { MobileTabContents } from '~components/organisms/MobileTabContents';
import { VerifyMessangerPage } from '~components/VerifyMessangerPage';
import { ROUTE_TAB_NAMES } from '~constants/common';
import { useMedia } from '~hooks/useMedia';
import { InfoPage } from '~pages/InfoPage';
import { JackpotPage } from '~pages/JackpotPage';
import { SportsPage } from '~pages/SportsPage';

export const AppRoutes = () => {
  const { isMobileOrTablet } = useMedia();

  return isMobileOrTablet ? (
    <Routes>
      <Route path={ROUTE_TAB_NAMES.BASE} element={<MobileTabContents />} />
      <Route path={ROUTE_TAB_NAMES.INFO} element={<InfoPage />} />
      <Route path={ROUTE_TAB_NAMES.JACKPOT} element={<JackpotPage />} />
      <Route path={ROUTE_TAB_NAMES.NOT_FOUND} element={<NotFoundPage />} />
      <Route
        path={ROUTE_TAB_NAMES.VERIFY_MESSANGER}
        element={<VerifyMessangerPage />}
      />
    </Routes>
  ) : (
    <Routes>
      <Route path={ROUTE_TAB_NAMES.BASE} element={<SportsPage />} />
      <Route path={ROUTE_TAB_NAMES.INFO} element={<InfoPage />} />
      <Route path={ROUTE_TAB_NAMES.JACKPOT} element={<JackpotPage />} />
      <Route path={ROUTE_TAB_NAMES.NOT_FOUND} element={<NotFoundPage />} />
      <Route
        path={ROUTE_TAB_NAMES.VERIFY_MESSANGER}
        element={<VerifyMessangerPage />}
      />
    </Routes>
  );
};
