import React, { memo } from 'react';

import { OddCrossedIndicator } from '~components/atoms/BetCoefficientIndicator';
import { Box } from '~components/atoms/Box';
import { Button } from '~components/atoms/Button';
import { Flag } from '~components/atoms/Flag';
import { NavigationLink } from '~components/atoms/NavigationLink';
import { Text } from '~components/atoms/Typography';
import { MOBILE_MENU } from '~components/organisms/MobileTab';
import { EVENT_STATUS, QUERY_PARAMS } from '~constants/common';
import { CountryName } from '~constants/flag';
import { useMedia } from '~hooks/useMedia';
import { AlertIcon, CloseIcon } from '~icons';
import { useAppDispatch } from '~store';
import { setBetslipChanged } from '~store/slices/betslipSlice';
import { closeDialog } from '~store/slices/globalDialogSlice';
import { setMobileActiveTab } from '~store/slices/mobileSlice';
import { Market, Selection, StoreSportEvent } from '~types/events';
import { SPORT_MENUS } from '~types/sportMenus';
import { formatDateTime, TIME_FORMATS } from '~utils/formatDateTime';
import { buildSearchQuery } from '~utils/url';

const textStyles = {
  fontSize: '$13',
  lineHeight: '$16',
  '@xs_sm': {
    fontSize: '$xxs',
    color: '$white',
    lineHeight: '$20',
  },
};

interface BetslipStakeMainMobileProps {
  index: number;
  isDuplicated: boolean;
  isBetFinished: boolean;
  eventData: StoreSportEvent;
  selectionData: Selection;
  marketData: Market;
  onRemove: (selectionId: string) => void;
  isSingleMode?: boolean;
  quickBet?: boolean;
}

function BetslipStakeMainMobile({
  isDuplicated,
  quickBet,
  isBetFinished,
  eventData,
  selectionData,
  marketData,
  onRemove,
  isSingleMode,
}: BetslipStakeMainMobileProps) {
  const dispatch = useAppDispatch();
  const { isMobileOrTablet } = useMedia();
  const {
    id: eventId,
    leagueName,
    countryName,
    startDate,
    name,
    sportId,
    countryId,
    leagueId,
    status,
  } = eventData;
  const isActiveStatus = [
    EVENT_STATUS.ACTIVE,
    EVENT_STATUS.IN_PROGRESS,
    EVENT_STATUS.ABOUT_TO_START,
  ].includes(status);
  const startDateObj = new Date(startDate);
  const now = new Date();
  const isLive = isActiveStatus && startDateObj < now;
  const { name: marketName } = marketData;
  const { name: selectionName, odd, id } = selectionData;
  const [homeTeam, awayTeam] = name.split(' vs. ');

  const handleOddChange = () => {
    dispatch(setBetslipChanged(id));
  };

  const onEventNameClick = () => {
    dispatch(setMobileActiveTab(isLive ? MOBILE_MENU.LIVE : MOBILE_MENU.SPORT));
    dispatch(closeDialog());
  };

  return (
    <>
      <Box justifyContentBetween flexRow alignStart>
        <Box flexCol alignStart justifyContentStart gap={1}>
          <Box flexRow gap={2} alignCenter>
            <Flag countryName={countryName as CountryName} />
            <Text
              ellipsis
              color={isBetFinished ? 'grayMediumOpacity' : 'grayMedium'}
              css={{
                fontSize: '$xs',
                lineHeight: '$16',
                '@xs_sm': {
                  fontSize: '$xxs',
                  lineHeight: '$24',
                },
              }}
            >
              {leagueName}
            </Text>
          </Box>
          <Text
            ellipsis
            color={isBetFinished ? 'grayMediumOpacity' : 'grayMedium'}
            level={isMobileOrTablet ? '11-16' : '13-16'}
          >
            {formatDateTime(startDate, TIME_FORMATS.DATE_TIME_12H)}
          </Text>
        </Box>

        <Box
          alignCenter
          flexRow
          gap={1}
          justifyContentEnd
          css={{ color: '$red', '& > svg': { width: '$3', height: '$3' } }}
        >
          {isDuplicated && <AlertIcon />}
          <Button
            iconButton
            noBorder
            noBackground
            css={{
              color: '$grayMedium',
              width: '$4',
              height: '$4',
              minWidth: '$4',
              p: '0',
              '& > svg': { width: '$4', height: '$4' },
            }}
            onClick={() => onRemove(id)}
          >
            <CloseIcon />
          </Button>
        </Box>
      </Box>
      <NavigationLink
        key={eventId}
        onClick={onEventNameClick}
        to={{
          search: buildSearchQuery({
            [QUERY_PARAMS.SPORT_ID]: sportId,
            [QUERY_PARAMS.COUNTRY_ID]: countryId,
            [QUERY_PARAMS.LEAGUE_ID]: leagueId,
            [QUERY_PARAMS.EVENT_ID]: eventId,
            [QUERY_PARAMS.MENU]: isLive
              ? SPORT_MENUS.LIVE
              : SPORT_MENUS.PREMATCH,
          }),
        }}
        end
      >
        <Text
          color={isBetFinished ? 'whiteOpacity4' : 'white'}
          css={{ ...textStyles }}
        >
          {homeTeam} - {awayTeam}
        </Text>
      </NavigationLink>
      <Box justifyContentBetween flexRow>
        <Text
          underline
          ellipsis
          color={isBetFinished ? 'whiteOpacity4' : 'white'}
          css={{ ...textStyles, maxWidth: 'calc(100% - 80px)' }}
        >
          {marketName} - {selectionName}
        </Text>
        {(!isSingleMode || quickBet) && (
          <OddCrossedIndicator
            sidePrevValue
            odd={odd}
            disabled={isBetFinished}
            onOddChange={handleOddChange}
          />
        )}
      </Box>
    </>
  );
}

export default memo(BetslipStakeMainMobile);
