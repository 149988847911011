import React from 'react';

import { usePrematchMenu } from '../hooks/usePrematchMenu';
import { SportMenu } from '../SportMenu/SportMenu';

export const PrematchMenu = () => {
  const { lazyGetPrematchData, lazyGetPrematchError, sports } =
    usePrematchMenu();

  if (!lazyGetPrematchData || lazyGetPrematchError || !sports) {
    return null;
  }

  return <SportMenu />;
};
