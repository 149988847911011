import React from 'react';

import { OddCrossedIndicator } from '~components/atoms/BetCoefficientIndicator';
import { Box } from '~components/atoms/Box';
import { Button } from '~components/atoms/Button';
import { Flag } from '~components/atoms/Flag';
import { NavigationLink } from '~components/atoms/NavigationLink';
import { Text } from '~components/atoms/Typography';
import { EVENT_STATUS, QUERY_PARAMS } from '~constants/common';
import { CountryName } from '~constants/flag';
import { AlertIcon, CloseIcon } from '~icons';
import { useAppDispatch } from '~store';
import { setBetslipChanged } from '~store/slices/betslipSlice';
import { Market, Selection, StoreSportEvent } from '~types/events';
import { SPORT_MENUS } from '~types/sportMenus';
import { formatDateTime, TIME_FORMATS } from '~utils/formatDateTime';
import { splitStringOnVs } from '~utils/stringUtils';
import { buildSearchQuery } from '~utils/url';

export const betslipTextStyles = {
  fontSize: '$13',
  lineHeight: '$16',
  '@xs_sm': {
    fontSize: '$xxs',
    color: '$white',
    lineHeight: '$20',
  },
};

interface BetslipStakeMainProps {
  index: number;
  isDuplicated: boolean;
  isBetFinished: boolean;
  eventData: StoreSportEvent;
  selectionData: Selection;
  marketData: Market;
  onRemove: (selectionId: string) => void;
  isSingleMode?: boolean;
  quickBet?: boolean;
}

function BetslipStakeMain({
  isDuplicated,
  quickBet,
  isBetFinished,
  eventData,
  selectionData,
  marketData,
  onRemove,
  isSingleMode,
}: BetslipStakeMainProps) {
  const dispatch = useAppDispatch();
  const {
    id: eventId,
    leagueName,
    startDate,
    name,
    countryId,
    countryName,
    leagueId,
    status,
    sportId,
  } = eventData;
  const isActiveStatus = [
    EVENT_STATUS.ACTIVE,
    EVENT_STATUS.IN_PROGRESS,
    EVENT_STATUS.ABOUT_TO_START,
  ].includes(status);
  const startDateObj = new Date(startDate);
  const now = new Date();
  const isLive = isActiveStatus && startDateObj < now;
  const { name: marketName } = marketData;
  const { name: selectionName, odd, id } = selectionData;
  const [firstTeam, secondTeam] = splitStringOnVs(name);

  const handleOddChange = () => {
    dispatch(setBetslipChanged(id));
  };

  const BetSlipText = ({ text }: { text?: string }) => (
    <Text
      color={isBetFinished ? 'whiteOpacity4' : 'white'}
      css={{ ...betslipTextStyles }}
    >
      {text}
    </Text>
  );

  return (
    <>
      <Box
        justifyContentStart
        flexRow
        alignCenter
        css={{
          position: 'relative',
        }}
      >
        <Box flexRow gap={2} alignCenter fullWidth>
          <Flag countryName={countryName as CountryName} />
          <Text
            ellipsis
            color={isBetFinished ? 'grayMediumOpacity' : 'grayMedium'}
            css={{
              maxWidth: 'calc(100% - 40px)',
              fontSize: '$xs',
              lineHeight: '$16',
              '@xs_sm': {
                fontSize: '$xxs',
                lineHeight: '$24',
              },
            }}
          >
            {leagueName}
          </Text>
        </Box>
        <Box
          alignCenter
          flexRow
          gap={1}
          justifyContentEnd
          css={{
            color: '$red',
            '& > svg': { width: '$3', height: '$3' },
            position: 'absolute',
            right: 0,
          }}
        >
          {isDuplicated && <AlertIcon />}
          <Button
            iconButton
            noBorder
            noBackground
            css={{
              color: '$grayMedium',
              width: '$3',
              height: '$3',
              minWidth: '$3',
              p: '0',
              '& > svg': { width: '$3', height: '$3' },
            }}
            onClick={() => onRemove(id)}
          >
            <CloseIcon />
          </Button>
        </Box>
      </Box>
      {!quickBet && (
        <Text
          color={isBetFinished ? 'grayMediumOpacity' : 'grayMedium'}
          css={{
            fontSize: '$13',
            lineHeight: '$16',
            '@xs_sm': {
              fontSize: '$12',
              lineHeight: '$24',
            },
          }}
        >
          {formatDateTime(startDate, TIME_FORMATS.DATE_TIME_12H)}
        </Text>
      )}
      <NavigationLink
        css={{
          display: 'flex',
          flexDirection: 'column',
          gap: '$1',
        }}
        key={eventId}
        to={{
          search: buildSearchQuery({
            [QUERY_PARAMS.SPORT_ID]: sportId,
            [QUERY_PARAMS.COUNTRY_ID]: countryId,
            [QUERY_PARAMS.LEAGUE_ID]: leagueId,
            [QUERY_PARAMS.EVENT_ID]: eventId,
            [QUERY_PARAMS.MENU]: isLive
              ? SPORT_MENUS.LIVE
              : SPORT_MENUS.PREMATCH,
          }),
        }}
        end
      >
        <BetSlipText text={firstTeam} />
        <BetSlipText text={secondTeam} />
      </NavigationLink>
      <BetSlipText text={marketName} />
      <Box justifyContentBetween flexRow>
        <BetSlipText text={selectionName} />
        {(!isSingleMode || quickBet) && (
          <OddCrossedIndicator
            odd={odd}
            disabled={isBetFinished}
            onOddChange={handleOddChange}
          />
        )}
      </Box>
    </>
  );
}

export default BetslipStakeMain;
