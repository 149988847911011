import { useEffect, useMemo, useState } from 'react';

import { useLazyGetCountriesBySport } from '~api/category/categoryQueries';
import { Countries, CountriesBySportParams } from '~api/category/types';
import { QUERY_PARAMS } from '~constants/common';
import { useNextHourToDateTo } from '~hooks/useNextHourToDateTo';
import { useAppDispatch, useAppSelector } from '~store';
import {
  addOpenedSport,
  setOpenedSports,
} from '~store/slices/prematchMenuSlice';
import { conditionalAdd } from '~utils/objectHelpers';

import { MULTI_ACCORDION_ROLES } from '../../MultiAccordion';
import { handleOnValueChangeHelper } from '../../utils';

export const useSportMenu = () => {
  const dispatch = useAppDispatch();
  const sportMenuRole = MULTI_ACCORDION_ROLES.PARENT;
  const { openedSports, sports } = useAppSelector(
    (state) => state.prematchMenu,
  );
  const [loadingSportCountriesQueue, setLoadingSportCountriesQueue] = useState<
    string[]
  >([]);
  const [isLoading, setIsLoading] = useState(false);
  const [countriesData, setCountriesData] = useState<Record<string, Countries>>(
    {},
  );
  const { lazyGetCountriesBySportQuery } = useLazyGetCountriesBySport();
  const dateTo = useNextHourToDateTo();

  const getCountriesDataMemo = useMemo(() => {
    return (sportId: number) => {
      return sportId in countriesData
        ? (countriesData[sportId] as Countries)
        : [];
    };
  }, [countriesData]);

  const handleOnValueChange = async (e: string[]) => {
    const { item: sportId, shouldFetchData } = handleOnValueChangeHelper({
      e,
      openOptions: openedSports,
      existingData: countriesData,
    });

    if (shouldFetchData) {
      setLoadingSportCountriesQueue([...loadingSportCountriesQueue, sportId]);
    } else {
      setTimeout(() => {
        dispatch(setOpenedSports(e));
      }, 0);
    }
  };

  useEffect(() => {
    if (loadingSportCountriesQueue.length === 0 || isLoading) return;
    setIsLoading(true);
    const loadingSportCountriesQueueCopy = [...loadingSportCountriesQueue];
    const sportId = loadingSportCountriesQueueCopy.shift();

    if (!sportId) return;

    const loadCountries = async () => {
      const params: CountriesBySportParams = { sportId: parseInt(sportId) };

      conditionalAdd(params, QUERY_PARAMS.DATE_TO, dateTo);
      const countries: Countries =
        await lazyGetCountriesBySportQuery(params).unwrap();

      setCountriesData({ ...countriesData, [sportId]: countries });
      setTimeout(() => {
        dispatch(addOpenedSport(sportId));
      }, 0);
      setLoadingSportCountriesQueue((prev) =>
        prev.filter((item) => item !== sportId),
      );
      setIsLoading(false);
    };

    loadCountries();
  }, [loadingSportCountriesQueue, isLoading]);

  return {
    sports,
    sportMenuRole,
    openSports: openedSports,
    getCountriesDataMemo,
    handleOnValueChange,
  };
};
