import React from 'react';

import { OpenedBetDetails } from '~api/betslip/types';
import { Box } from '~components/atoms/Box';
import { NavigationLink } from '~components/atoms/NavigationLink';
import { Text } from '~components/atoms/Typography';
import { BetslipHistoryStatusCell } from '~components/molecules/BetslipHistorySection/Sports/BetslipHistoryTable/components/BetslipHistoryStatusCell';
import { MOBILE_MENU } from '~components/organisms/MobileTab';
import { QUERY_PARAMS } from '~constants/common';
import { useAppDispatch } from '~store';
import { closeDialog } from '~store/slices/globalDialogSlice';
import { setMobileActiveTab } from '~store/slices/mobileSlice';
import { SPORT_MENUS } from '~types/sportMenus';
import { formatDateTime, TIME_FORMATS } from '~utils/formatDateTime';
import { getSportIcon } from '~utils/getSportIcon';
import { formatNumber } from '~utils/numberUtils';
import { buildSearchQuery } from '~utils/url';

interface OpenBetsDetailsItemProps {
  detailsItem: OpenedBetDetails;
}

const textStyles = {
  fontSize: '$sm',
  lineHeight: '$16',
  '@xs_sm': {
    fontSize: '$xxs',
    lineHeight: '$20',
  },
};

export const OpenBetsDetailsItem = ({
  detailsItem,
}: OpenBetsDetailsItemProps) => {
  const dispatch = useAppDispatch();
  const {
    market,
    odd,
    selection,
    sport,
    league,
    startDate,
    status,
    event,
    eventId,
  } = detailsItem;

  const startDateObj = new Date(startDate);
  const now = new Date();
  const isLive = startDateObj < now;
  const sportIcon = getSportIcon(sport);

  const onEventNameClick = () => {
    dispatch(setMobileActiveTab(isLive ? MOBILE_MENU.LIVE : MOBILE_MENU.SPORT));
    dispatch(closeDialog());
  };

  return (
    <Box
      flexCol
      css={{
        p: '$2 $3',
        gap: '$2',
        borderRadius: '$8',
        backgroundColor: '$openBetItemBgColor',
      }}
    >
      <Box
        flexRow
        alignCenter
        gap={2}
        css={{
          color: '$grayMedium',
        }}
      >
        {sportIcon}
        <Text color="grayMedium" ellipsis css={{ ...textStyles }}>
          {league}
        </Text>
      </Box>
      <Box flexRow justifyContentBetween alignCenter>
        <Text color="grayMedium" css={{ ...textStyles }}>
          {formatDateTime(startDate, TIME_FORMATS.DATE_TIME_24H)}
        </Text>
        <BetslipHistoryStatusCell status={status} isInnerTable isLarge />
      </Box>
      <NavigationLink
        key={eventId}
        css={{
          display: 'flex',
          flexDirection: 'column',
          gap: '$1',
        }}
        onClick={onEventNameClick}
        to={{
          search: buildSearchQuery({
            [QUERY_PARAMS.EVENT_ID]: eventId,
            [QUERY_PARAMS.MENU]: isLive
              ? SPORT_MENUS.LIVE
              : SPORT_MENUS.PREMATCH,
          }),
        }}
        end
      >
        <Text css={{ ...textStyles, fontSize: '$xs', textWrap: 'balance' }}>
          {event}
        </Text>
      </NavigationLink>
      <Text css={{ ...textStyles }}>{market}</Text>
      <Box justifyContentBetween flexRow>
        <Text
          ellipsis
          css={{
            ...textStyles,
            maxWidth: '60%',
            fontSize: '$xs',
          }}
        >
          {selection}
        </Text>
        <Text color="yellow" css={{ ...textStyles, fontSize: '$xs' }}>
          {formatNumber(odd)}
        </Text>
      </Box>
    </Box>
  );
};
