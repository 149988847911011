import { Box } from '~components/atoms/Box';
import { LiveMenu } from '~components/molecules/LiveMenu/components/LiveMenu';
import { PrematchMenu } from '~components/molecules/PrematchMenu/PrematchMenu/PrematchMenu';
import { FiltersPanel } from '~components/molecules/SearchEvents/FiltersPanel';
import { LeftSidebar } from '~components/organisms/LeftSidebar';
import { SPORT_MENUS } from '~types/sportMenus';

import { useLeftSidebar } from './hooks/useLeftSidebar';

export const SportsLeftSidebar = () => {
  const { searchPerformed, activeMenu, searchQuery, handleSidebarToggle } =
    useLeftSidebar();

  const filtersPanel = (
    <FiltersPanel
      query={searchQuery}
      searchPerformed={searchPerformed}
      handleSidebarToggle={handleSidebarToggle}
    />
  );

  return (
    <LeftSidebar filtersPanel={filtersPanel}>
      <Box>
        {activeMenu === SPORT_MENUS.PREMATCH ? <PrematchMenu /> : <LiveMenu />}
      </Box>
    </LeftSidebar>
  );
};
