import React, { useEffect, useState } from 'react';
import { styled } from 'stitches.config';

import { BetAmount, BetslipItem } from '~api/betslip/types';
import { Box } from '~components/atoms/Box';
import { Separator } from '~components/atoms/Separator';
import { Text } from '~components/atoms/Typography';
import BetslipStakeMainMobile from '~components/molecules/Betslip/components/BetslipStakes/BetslipStakeMainMobile';
import { EVENT_STATUS, MARKET_STATUS } from '~constants/common';
import { useMedia } from '~hooks/useMedia';
import { useTranslation } from '~hooks/useTranslation';
import { useAppSelector } from '~store';
import { Market, Selection, StoreSportEvent } from '~types/events';
import { areTwoObjectsEqual } from '~utils/objectHelpers';

import { SPORT_BETSLIP_TYPE_OPTIONS } from '../../constants';

import { BetslipStakeAmount } from './BetslipStakeAmount';
import BetslipStakeMain from './BetslipStakeMain';

interface BetslipStakeProps {
  index: number;
  eventsCount: number;
  quickBet: boolean;
  isDuplicated: boolean;
  betslipItem: BetslipItem;
  stakeType: SPORT_BETSLIP_TYPE_OPTIONS;
  onRemove: (selectionId: string) => void;
  onAmountChange: ({ id, amount }: { id: string; amount: BetAmount }) => void;
  amount?: BetAmount;
}

const StakeWrapper = styled(Box, {
  display: 'flex',
  borderRadius: '$10',
  backgroundColor: '$grayDarkMain2',
  flexDirection: 'column',
  p: '$2 $3',
  gap: '$2',
});

const StakeWrapperMobile = styled(Box, {
  display: 'flex',
  borderTop: '1px solid $grayDarker',
  borderRadius: 0,
  backgroundColor: '$grayDarkMain2',
  flexDirection: 'column',
  p: '$2 $3',
  '&:first-child': {
    borderRadius: '$8 $8 0 0',
    borderTop: 'none',
  },
  '&:last-child': {
    borderRadius: '0 0 $8 $8',
  },
});

export const BetslipStake = ({
  index,
  eventsCount,
  quickBet,
  isDuplicated,
  amount = {},
  betslipItem,
  stakeType,
  onRemove,
  onAmountChange,
}: BetslipStakeProps) => {
  const { localized } = useTranslation();
  const { eventsMap, marketsMap } = useAppSelector((state) => state.events);
  const [eventData, setEventData] = useState<StoreSportEvent | null>(null);
  const [selectionData, setSelectionData] = useState<Selection | null>(null);
  const [marketData, setMarketData] = useState<Market | null>(null);
  const [isEventFinished, setIsEventFinished] = useState(false);
  const [isMarketFinished, setIsMarketFinished] = useState(false);
  const { isMobileOrTablet } = useMedia();
  const WrapperComponent = isMobileOrTablet ? StakeWrapperMobile : StakeWrapper;
  const isSingleMode = stakeType === SPORT_BETSLIP_TYPE_OPTIONS.SINGLE;

  useEffect(() => {
    const { eventId } = betslipItem;
    const mapEventData = eventsMap[eventId];

    if (!mapEventData) {
      setIsEventFinished(true);
    } else {
      if (!areTwoObjectsEqual(eventData, mapEventData)) {
        setEventData(mapEventData);
        const isActiveEvent = [
          EVENT_STATUS.ACTIVE,
          EVENT_STATUS.IN_PROGRESS,
          EVENT_STATUS.ABOUT_TO_START,
        ].includes(mapEventData.status);

        setIsEventFinished(!isActiveEvent);
      }
    }
  }, [eventsMap]);

  useEffect(() => {
    const { marketId, selectionId } = betslipItem;
    const mapMarketData = marketsMap[marketId];

    if (!mapMarketData) {
      setIsMarketFinished(true);
    } else {
      if (!areTwoObjectsEqual(marketData, mapMarketData)) {
        setMarketData(mapMarketData);
        const isMarketActive = mapMarketData.status === MARKET_STATUS.ACTIVE;

        setIsMarketFinished(!isMarketActive);
        const selectionIndex = mapMarketData?.selections.findIndex(
          (selection) => selection.id === selectionId,
        );
        const mapSelectionData = mapMarketData?.selections[selectionIndex];

        if (
          mapSelectionData &&
          !areTwoObjectsEqual(selectionData, mapSelectionData)
        ) {
          setSelectionData(mapSelectionData);
        }
      }
    }
  }, [marketsMap]);

  if (!eventData || !marketData || !selectionData) return null;

  return (
    <WrapperComponent
      css={{
        ...((eventsCount === 1 || isSingleMode) && {
          borderRadius: '$8 !important',
        }),
        ...(isSingleMode && { mt: '$2' }),
      }}
    >
      {isMobileOrTablet ? (
        <BetslipStakeMainMobile
          index={index}
          quickBet={quickBet}
          onRemove={onRemove}
          eventData={eventData}
          selectionData={selectionData}
          marketData={marketData}
          isDuplicated={isDuplicated}
          isSingleMode={isSingleMode}
          isBetFinished={isMarketFinished || isEventFinished}
        />
      ) : (
        <BetslipStakeMain
          index={index}
          quickBet={quickBet}
          onRemove={onRemove}
          eventData={eventData}
          selectionData={selectionData}
          marketData={marketData}
          isDuplicated={isDuplicated}
          isSingleMode={isSingleMode}
          isBetFinished={isMarketFinished || isEventFinished}
        />
      )}

      {eventData && isSingleMode && !(isEventFinished && isMarketFinished) && (
        <>
          <Separator
            verticalSpace={0}
            shrinkOut={3}
            css={{
              backgroundColor: '$grayDarker',
              '@xs_sm': {
                mt: '$1',
              },
            }}
          />
          <BetslipStakeAmount
            index={index}
            quickBet={quickBet}
            amount={amount}
            selectionData={selectionData}
            marketData={marketData}
            onAmountChange={onAmountChange}
          />
        </>
      )}
      {(isEventFinished || isMarketFinished) && (
        <>
          <Separator
            verticalSpace={0}
            shrinkOut={3}
            css={{
              backgroundColor: '$grayDarker',
            }}
          />

          <Text
            color="red"
            fontWeight="medium"
            textAlign="center"
            css={{
              fontSize: '$xs',
              lineHeight: '$16',
              '@xs_sm': {
                fontSize: '$xxs',
                lineHeight: '$20',
              },
            }}
          >
            {localized(
              `betslip.${isMarketFinished ? 'marketFinished' : 'betFinished'}`,
            )}
          </Text>
        </>
      )}
    </WrapperComponent>
  );
};
