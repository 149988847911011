import { useEffect } from 'react';

import { useLazyGetInfoPage } from '~api/content/contentQueries';
import { RulesContent } from '~components/molecules/Jackpot/Settled/components/Rules/RulesContent';
import { CHANNEL_TYPES } from '~constants/common';
import { useMedia } from '~hooks/useMedia';

export const Rules = () => {
  const { lazyGetInfoQuery, lazyGetInfoData } = useLazyGetInfoPage();
  const htmlCode = lazyGetInfoData?.content || '';
  const { isMobileOrTablet } = useMedia();
  const jackpotRulesName = 'jackpot_rules';

  useEffect(() => {
    lazyGetInfoQuery({
      name: jackpotRulesName,
      channelType: isMobileOrTablet ? CHANNEL_TYPES.MOBILE : CHANNEL_TYPES.WEB,
    });
  }, []);

  return <RulesContent content={htmlCode} />;
};
