import {
  useLazyGetLiveEventQuery,
  useLazyGetLiveQuery,
  useLazyGetPrematchEventQuery,
} from '~api/live/liveApi';

export const useLazyGetLive = () => {
  const [query, { data, error, isLoading, isFetching }] = useLazyGetLiveQuery();

  return {
    lazyGetLiveQuery: query,
    lazyGetLiveData: data,
    lazyGetLiveError: error,
    lazyGetLiveIsLoading: isLoading,
    lazyGetLiveIsFetching: isFetching,
  };
};

export const useLazyGetEventLive = () => {
  const [query, { data, error, isLoading, isFetching }] =
    useLazyGetLiveEventQuery();

  return {
    lazyGetEventLiveQuery: query,
    lazyGetEventLiveData: data,
    lazyGetEventLiveError: error,
    lazyGetEventLiveIsLoading: isLoading,
    lazyGetEventLiveIsFetching: isFetching,
  };
};

export const useLazyGetEventPrematch = () => {
  const [query, { data, error, isLoading, isFetching }] =
    useLazyGetPrematchEventQuery();

  return {
    lazyGetEventPrematchQuery: query,
    lazyGetEventPrematchData: data,
    lazyGetEventPrematchError: error,
    lazyGetEventPrematchIsLoading: isLoading,
    lazyGetEventPrematchIsFetching: isFetching,
  };
};
