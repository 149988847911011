import React from 'react';

import { Box } from '~components/atoms/Box';
import { Text } from '~components/atoms/Typography';
import { Row } from '~components/molecules/BetslipHistorySection/Sports/BetslipHistoryTable/useBetslipHistoryTable';
import { WinboostPopover } from '~components/WinboostPopover';
import { BETSLIP_STATUS, BETSLIP_WIN_STATUSES } from '~constants/common';
import { useAppSelector } from '~store';
import { oddFormatWithCurrency } from '~utils/numberUtils';

interface WinAmountCellProps {
  value: number;
  row: Row;
}

export const WinAmountCell = ({ value, row }: WinAmountCellProps) => {
  const { currency } = useAppSelector((state) => state.settings);

  const {
    status,
    possibleWin,
    winBoostAmount,
    winBoostMultiplier,
    originalWinAmount,
  } = row.original;

  const displayAmount = status === BETSLIP_STATUS.OPEN ? possibleWin : value;
  const isWinAmount = BETSLIP_WIN_STATUSES.includes(status);

  return (
    <Box flexRow gap={2} alignCenter>
      <Text color={isWinAmount ? 'yellow' : 'white'} level="12-20">
        {oddFormatWithCurrency(displayAmount, currency)}
      </Text>
      {!!winBoostAmount && status !== BETSLIP_STATUS.CASH_OUT && (
        <WinboostPopover
          isBetHistory
          status={status}
          winBoostAmount={winBoostAmount}
          winBoostMultiplier={winBoostMultiplier}
          originalWinAmount={originalWinAmount}
        />
      )}
    </Box>
  );
};
