import { ChangeEvent, useRef, useState } from 'react';

import { useLazyGetSearchEvents } from '~api/search/searchQueries';
import { SportEventItems } from '~api/sportEvent/types';
import { useDebouncedEffect } from '~hooks/useDebouncedEffect';
import { useOuterClick } from '~hooks/useOuterClick';

export const useSearch = () => {
  const [isSearchActive, setIsSearchActive] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const searchRef = useRef(null);
  const [searchedItems, setSearchedItems] = useState<SportEventItems>([]);

  const { lazyGetSearchEventsQuery } = useLazyGetSearchEvents();

  const handleSearchValueChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value);
  };

  const handleClearSearch = () => {
    setSearchValue('');
    setIsSearchActive(false);
  };

  const onToggleSearch = () => {
    setIsSearchActive((prev) => !prev);
  };

  const loadSearchEvents = async (term: string) => {
    try {
      const sportEvents: SportEventItems =
        await lazyGetSearchEventsQuery(term).unwrap();

      setSearchedItems(sportEvents);
    } catch (e) {
      console.error(e);
    }
  };

  useOuterClick(searchRef, () => {
    setIsSearchActive(false);
  });

  useDebouncedEffect(
    () => {
      if (searchValue.length > 2) {
        loadSearchEvents(searchValue);
      }
    },
    [searchValue],
    300,
  );

  return {
    searchRef,
    isSearchActive,
    searchValue,
    showSearchResults: searchValue.length > 2,
    searchedItems,
    handleSearchValueChange,
    handleClearSearch,
    onToggleSearch,
  };
};
