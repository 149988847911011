import { useEffect, useState } from 'react';

import { MainMarket } from '~api/market/types';
import { useMedia } from '~hooks/useMedia';
import { useAppSelector } from '~store';
import { selectEventById } from '~store/slices/eventsSlice';
import { Market, SportEvent } from '~types/events';
import { areTwoObjectsEqual } from '~utils/objectHelpers';

export interface EventsListItemHookProps {
  eventId: string;
  mainMarkets: MainMarket[];
  maxScoreWidth?: number;
  isFirst?: boolean;
  maxMarketsCount?: number;
}

export type MarketsData = (Market | undefined)[];

export const useEventsListItem = ({
  eventId,
  mainMarkets,
  maxMarketsCount: maxMarketsCountProp,
}: EventsListItemHookProps) => {
  const { maxMarketsCount } = useAppSelector((state) => state.prematchMenu);
  const event = useAppSelector((state) => selectEventById(state, eventId));
  const { isMobileOrTablet } = useMedia();
  const [eventsData, setEventData] = useState<SportEvent>(event as SportEvent);

  const getMarketsData = (markets: MarketsData): MarketsData => {
    let marketsData = mainMarkets.map((mainMarket) => {
      return markets.find(
        (market) => Number(market?.marketId || 0) === mainMarket?.id,
      );
    }) as MarketsData;

    if (!isMobileOrTablet) {
      marketsData = marketsData.slice(
        0,
        Math.max(0, maxMarketsCountProp || maxMarketsCount),
      );
    }

    return marketsData;
  };

  const [marketsData, setMarketsData] = useState<MarketsData>(
    getMarketsData(event.markets || []),
  );

  useEffect(() => {
    if (!areTwoObjectsEqual(event, eventsData)) {
      setEventData(event as SportEvent);
    }
  }, [event]);

  useEffect(() => {
    if (!eventsData?.markets) return;
    const markets = getMarketsData(eventsData?.markets);

    if (!areTwoObjectsEqual(markets, marketsData)) {
      setMarketsData(markets);
    }
  }, [eventsData?.markets, mainMarkets, maxMarketsCount]);

  return {
    eventsData,
    marketsData,
  };
};
