import { useCallback, useEffect, useLayoutEffect, useState } from 'react';

import { MainMarket } from '~api/market/types';
import { useLazyGetUpcomingEvents } from '~api/sportEvent/sportEventQueries';
import {
  MAIN_PAGE_SECTIONS,
  SELECTIONS_GAP,
  TopPrematchDateGroupsIds,
} from '~constants/mainPage';
import useDimensions from '~hooks/useDimensions';
import { useMedia } from '~hooks/useMedia';
import { useTranslation } from '~hooks/useTranslation';
import { useAppDispatch, useAppSelector } from '~store';
import { addEventsWithMarkets } from '~store/slices/eventsSlice';
import {
  setMaxMarketsCount,
  setUpcomingEvents,
  setUpcomingEventsLoading,
} from '~store/slices/prematchMenuSlice';

import {
  getMinMainEventDataWidth,
  getSelectionWidth,
  preparePrematchUpcomingHighlightsData,
  SportOptions,
} from '../helpers';

interface UpcomingPrematchHookProps {
  label: MAIN_PAGE_SECTIONS;
}

export const useUpcomingPrematch = ({ label }: UpcomingPrematchHookProps) => {
  const { localized } = useTranslation();
  const dispatch = useAppDispatch();
  const { mainMarkets } = useAppSelector((state) => state.mainMarkets);
  const { upcomingEvents, upcomingEventsLoading } = useAppSelector(
    (state) => state.prematchMenu,
  );
  const { data: preparedData, sports: sportsDefault } =
    preparePrematchUpcomingHighlightsData(upcomingEvents || []);
  const [activeSportId, setActiveSportId] = useState<string>();
  const [sports, setSports] = useState<SportOptions>(sportsDefault);
  const [highlightsOpen, setIsHighlightsOpen] = useState([label as string]);
  const [activeEventGroups, setActiveEventGroups] =
    useState<TopPrematchDateGroupsIds>(
      preparedData[activeSportId as string] as TopPrematchDateGroupsIds,
    );
  const [mainSportMarkets, setMainSportMarkets] = useState<MainMarket[]>([]);
  const { lazyGetUpcomingEventsQuery } = useLazyGetUpcomingEvents();
  const { isLaptop } = useMedia();

  const [eventsListRef, dimensions] = useDimensions();

  const getMaxMarketsCount = useCallback((): number => {
    const { width } = dimensions;

    if (width) {
      const allowedMarketsWidth = width - getMinMainEventDataWidth(isLaptop);

      const SINGLE_MARKET_WIDTH =
        getSelectionWidth(isLaptop) * 3 + SELECTIONS_GAP * 2;

      return Math.floor(allowedMarketsWidth / SINGLE_MARKET_WIDTH);
    }

    return 0;
  }, [dimensions, isLaptop]);

  useLayoutEffect(() => {
    const maxMarketsCount = getMaxMarketsCount();

    dispatch(setMaxMarketsCount(maxMarketsCount));
    const mainSport = mainMarkets.find(
      (sport) => sport.id === parseInt(activeSportId || ''),
    );

    if (mainSport) {
      let markets = [...mainSport.markets];

      markets = markets.slice(0, Math.max(0, maxMarketsCount));

      setMainSportMarkets(markets);
    }
  }, [dimensions, mainMarkets, activeSportId]);

  const handleAccordionValueChange = useCallback((value: string[]) => {
    setIsHighlightsOpen(value);
  }, []);

  const loadHighlights = async (sportId: string) => {
    dispatch(setUpcomingEventsLoading(true));
    try {
      const response = await lazyGetUpcomingEventsQuery(sportId).unwrap();

      dispatch(setUpcomingEvents(response));
    } catch (e) {
      console.error('Failed to load data: ', e);
    }
  };

  const handleActiveSportIdChange = useCallback(async (sportId: string) => {
    setActiveSportId(sportId);
    await loadHighlights(sportId);
  }, []);

  useEffect(() => {
    if (!upcomingEvents) return;
    const {
      data: preparedData,
      events,
      markets,
      sports,
    } = preparePrematchUpcomingHighlightsData(upcomingEvents);

    dispatch(addEventsWithMarkets({ events, markets }));
    const activeSportIdValue =
      activeSportId || (sports[0]?.id.toString() as string);

    setActiveSportId(activeSportIdValue);
    setSports(sports);
    setActiveEventGroups(
      preparedData[activeSportIdValue] as TopPrematchDateGroupsIds,
    );
    dispatch(setUpcomingEventsLoading(false));
  }, [upcomingEvents]);

  return {
    activeSportId,
    activeEventGroups,
    highlightsOpen,
    labelKey: label,
    mainSportMarkets,
    sports,
    isLoading: upcomingEventsLoading,
    localized,
    handleAccordionValueChange,
    handleActiveSportIdChange,
    eventsListRef,
  };
};
