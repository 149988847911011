import React from 'react';
import { MenuList } from 'src/components/molecules/Jackpot/MenuList';

import { BANNER_GROUP_NAMES, BannerList } from '~components/atoms/BannerList';
import { Box } from '~components/atoms/Box';
import { Menu } from '~components/molecules/Jackpot/Menu';
import { LeftSidebar } from '~components/organisms/LeftSidebar';
import { useMedia } from '~hooks/useMedia';
import { useAppSelector } from '~store';

export const LeftJackpotSidebar = () => {
  const { activeJackpots } = useAppSelector((state) => state.jackpot);

  const { isLaptop } = useMedia();

  return (
    <LeftSidebar transaparentBackground sticky>
      <Box flexCol gap={1}>
        {!!activeJackpots?.length && <MenuList jackpots={activeJackpots} />}
        <Menu />
        {isLaptop && (
          <BannerList
            names={[BANNER_GROUP_NAMES.JACKPOT_LEFT_SIDEBAR]}
            isVertical
          />
        )}
        <BannerList names={[BANNER_GROUP_NAMES.JACKPOT_RIGHT_SIDEBAR]} />
      </Box>
    </LeftSidebar>
  );
};
