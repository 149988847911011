import { useEffect } from 'react';

import { useLazyGetFavoriteEventPrematch } from '~api/sportEvent/sportEventQueries';
import { setCounts } from '~features/sportsMenu/sportsMenuSlice';
import { useAppDispatch, useAppSelector } from '~store';
import { setPrematchMenuData } from '~store/slices/prematchMenuSlice';
import { setFavoritePrematchEvents } from '~store/slices/sportGroupsSlice';

import { useSportWithCurrentLeague } from './useSportsWithCurrentLeague';

export const usePrematchMenu = () => {
  const dispatch = useAppDispatch();
  const { lazyGetFavoriteEventPrematchQuery } =
    useLazyGetFavoriteEventPrematch();

  const { prematchMenuData, sports } = useAppSelector(
    (state) => state.prematchMenu,
  );
  const { favoritePrematchEventsLoaded } = useAppSelector(
    (state) => state.sportGroupsState,
  );

  const { lazyGetPrematchData, lazyGetPrematchError, isLoading } =
    useSportWithCurrentLeague();
  const { prematch, inplay } = lazyGetPrematchData || {};
  const { isUserLoggedIn } = useAppSelector((state) => state.userState);

  useEffect(() => {
    if (lazyGetPrematchData) {
      dispatch(setPrematchMenuData(lazyGetPrematchData));
    }
  }, [lazyGetPrematchData]);

  useEffect(() => {
    if (prematch !== undefined && inplay !== undefined) {
      dispatch(setCounts({ inplay, prematch }));
    }
  }, [prematch, inplay]);

  useEffect(() => {
    const loadFavoriteEvents = async () => {
      const favoritePrematchEvents =
        await lazyGetFavoriteEventPrematchQuery().unwrap();

      dispatch(setFavoritePrematchEvents(favoritePrematchEvents));
    };

    if (isUserLoggedIn && !favoritePrematchEventsLoaded) {
      loadFavoriteEvents();
    }
  }, [isUserLoggedIn, favoritePrematchEventsLoaded]);

  return {
    sports,
    lazyGetPrematchData: prematchMenuData,
    lazyGetPrematchError,
    isNotLoaded: isLoading && !sports?.length,
    isLoading,
  };
};
