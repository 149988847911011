import { MainMarket, MainMarketSelection } from '~api/market/types';
import { Box } from '~components/atoms/Box';
import { SelectionItem } from '~components/molecules/Jackpot/Active/components/Event/SelectionItem';
import { JackpotEventMarket } from '~types/jackpot';

interface EventStakesProps {
  eventMarket: JackpotEventMarket;
  mainMarket: MainMarket;
  eventId: string;
}

export const EventStakes = ({
  eventMarket,
  mainMarket,
  eventId,
}: EventStakesProps) => (
  <Box
    css={{
      display: 'grid',
      gridTemplateColumns: `repeat(${eventMarket.selections.length}, 1fr)`,
      gap: '1px',
      overflow: 'hidden',
      background: '$inplayMarketsAccordionContentBgColor',
      border: '1px solid $inplayMarketsContentBorderColor',
      borderRadius: '$10',
    }}
  >
    {eventMarket.selections.map(
      (selection: MainMarketSelection, index: number) => (
        <SelectionItem
          key={selection.id}
          selection={selection}
          eventId={eventId}
          mainMarketName={mainMarket?.selections[index]?.name}
        />
      ),
    )}
  </Box>
);
