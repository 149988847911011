import { styled } from 'stitches.config';

import FallbackImage from '~assets/sportBackgrounds/Fallback.webp';
import { Box } from '~components/atoms/Box';
import { availableSportBackgroundsMap, SPORTS } from '~constants/sport';

const StyledBannerWrapper = styled(Box, {
  width: '100%',
  minHeight: '212px',
  borderRadius: '12px',
});

const BannerImage = styled(Box, {
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  backgroundRepeat: 'no-repeat',
  borderRadius: '$8',
  flexGrow: 1,
  width: '100%',
  minHeight: 'inherit',
});

interface JackpotInfoBackgroundBoxProps {
  sportName: SPORTS;
  customStyles?: Record<string, string>;
  children: React.ReactNode;
}

export const BannerWrapper = ({
  sportName,
  customStyles,
  children,
}: JackpotInfoBackgroundBoxProps) => {
  const backgroundImage =
    availableSportBackgroundsMap[sportName as SPORTS] || FallbackImage;

  return (
    <StyledBannerWrapper css={customStyles}>
      <BannerImage
        flexCol
        fullWidth
        fullHeight
        justifyCenter
        css={{
          backgroundImage: `url(${backgroundImage})`,
        }}
      >
        {children}
      </BannerImage>
    </StyledBannerWrapper>
  );
};
