import { useEffect } from 'react';

import { languagesMap } from '~constants/language';
import { oddsMap } from '~constants/odds';
import { timeZoneOffsets } from '~constants/timezones';
import { useAppDispatch, useAppSelector } from '~store';
import { updateProfile } from '~store/slices/userSlice';
import {
  setLanguage,
  setOdds,
  setTimezone,
} from '~store/slices/websiteSettings';
import { convertOdds } from '~utils/oddsFormatting';

export const useWebsiteSettings = () => {
  const dispatch = useAppDispatch();
  const { timezone, odds, language } = useAppSelector(
    (state) => state.websiteSettings,
  );

  const { isUserLoggedIn } = useAppSelector((state) => state.userState);

  const onTimezoneChange = (value: unknown) => {
    dispatch(setTimezone(value as string));
  };

  const onOddsChange = (value: unknown) => {
    dispatch(setOdds(value as string));
  };

  const onLanguageChange = (value: unknown) => {
    dispatch(setLanguage(value as string));
  };

  const getFormattedOdd = (value: number, odds: string): string => {
    const obj = convertOdds(value);

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return obj[odds];
  };

  useEffect(() => {
    dispatch(
      updateProfile({
        timezone: timezone as string,
        oddsFormat: oddsMap[odds],
        languageId: languagesMap[language],
      }),
    );
  }, [isUserLoggedIn, timezone, odds, language]);

  useEffect(() => {
    if (!timezone) {
      const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      const offsetTimezone =
        timeZoneOffsets[timezone as keyof typeof timeZoneOffsets];

      dispatch(setTimezone(offsetTimezone));
    }
  }, [timezone]);

  return {
    odds,
    timezone,
    language,
    onTimezoneChange,
    onOddsChange,
    onLanguageChange,
    getFormattedOdd,
  };
};
