import React from 'react';
import Draggable from 'react-draggable';

import { BetslipFooter } from '~components/molecules/Betslip/components/BetslipFooter';
import { BetslipProcessingBetLoader } from '~components/molecules/Betslip/components/BetslipProcessingBetLoader';
import { BetslipStakes } from '~components/molecules/Betslip/components/BetslipStakes';
import { useTranslation } from '~hooks/useTranslation';
import { useAppDispatch, useAppSelector } from '~store';
import { setIsSwipeOpen } from '~store/slices/mobileSlice';

import { Box } from '../Box';

import { useSwipe } from './hooks/useSwipe';
import {
  StyledDragContainer,
  StyledOverlay,
  StyledSwipeHeader,
  StyledTitle,
  StyledTitleLine,
} from './styled.components';

export enum SWIPE_COMPONENT_TYPES {
  QUICK_BET = 'quickBet',
}

const componentsMap: Record<SWIPE_COMPONENT_TYPES, React.ReactNode | null> = {
  [SWIPE_COMPONENT_TYPES.QUICK_BET]: (
    <>
      <BetslipStakes quickBet />
      <BetslipFooter quickBet isStatic />
      <BetslipProcessingBetLoader />
    </>
  ),
};

export const SwipeComponent = () => {
  const dispatch = useAppDispatch();
  const { handleStop, position } = useSwipe();
  const { localized } = useTranslation();
  const { isSwipeOpen, swipeContent } = useAppSelector(
    (state) => state.mobileState,
  );

  const onOverlayClick = () => {
    dispatch(setIsSwipeOpen(null));
  };

  if (!isSwipeOpen) {
    return null;
  }

  return (
    <>
      {isSwipeOpen && <StyledOverlay onClick={onOverlayClick} />}
      <Draggable
        axis="y"
        scale={1}
        handle=".handle"
        bounds={{ top: 0 }}
        defaultPosition={position}
        position={position}
        onStop={handleStop}
      >
        <StyledDragContainer>
          <Box className="handle">
            <StyledSwipeHeader>
              <StyledTitleLine />
              <StyledTitle>{localized('betslip.keepInBetslip')}</StyledTitle>
            </StyledSwipeHeader>
          </Box>
          {swipeContent && componentsMap[swipeContent]}
        </StyledDragContainer>
      </Draggable>
    </>
  );
};
