import { Bet } from '~api/betslip/types';
import { Selection } from '~api/sportEvent/types';
import { MARKET_STATUS } from '~constants/common';
import { useAppDispatch, useAppSelector } from '~store';
import { setOddsCount, setTotalOdd } from '~store/slices/betslipSlice';
import { getBetslipStake } from '~utils/betslip';
import { isEmptyObject } from '~utils/objectHelpers';

// TODO: change create interface for betslips
export const useFormatBetslipPayload = ({ betslips }: { betslips: any[] }) => {
  const dispatch = useAppDispatch();
  const { separateBonusBalance } = useAppSelector((state) => state.settings);
  const { aggregatedBetAmount, singleBetsAmountMap } = useAppSelector(
    (state) => state.betslip,
  );
  const formatMultipleBetPayload = (): Bet[] => {
    const oddsArray: number[] = [];

    const sportsId: number[] = [];
    const countriesId: number[] = [];
    const leaguesId: number[] = [];
    const marketsId: string[] = [];
    const selectionIds: string[] = [];

    betslips.forEach(({ selectionId, market, ...restEvent }) => {
      const { sportId = 0, countryId = 0, leagueId = 0 } = restEvent;
      const { selection = [], marketId = '' } = market;
      const { odd } = (selection || { odd: 0 }) as Selection;

      if (market.status !== MARKET_STATUS.ACTIVE) return;

      if (odd) {
        oddsArray.push(odd);
      }

      if (marketId.length && !marketsId.includes(marketId)) {
        marketsId.push(marketId);
      }

      if (sportId && !sportsId.includes(sportId)) {
        sportsId.push(sportId);
      }

      if (countryId && !countriesId.includes(countryId)) {
        countriesId.push(countryId);
      }

      if (leagueId && !leaguesId.includes(leagueId)) {
        leaguesId.push(leagueId);
      }

      if (selectionId) {
        selectionIds.push(selectionId);
      }
    });

    dispatch(setOddsCount(oddsArray.length));
    dispatch(setTotalOdd(oddsArray.reduce((acc, odd) => acc * odd, 1)));

    return [
      {
        sportsId,
        marketsId: marketsId.map((item) => +item),
        countriesId,
        leaguesId: leaguesId.map((item) => +item),
        selectionIds,
        odds: oddsArray,
        ...getBetslipStake({
          amount: aggregatedBetAmount,
          separateBonusBalance,
        }),
      },
    ];
  };

  const formatSingleBetPayload = (): Bet[] => {
    const payload: Bet[] = [];

    betslips.forEach(({ selectionId, market, ...restEvent }) => {
      const amount = singleBetsAmountMap[selectionId] || {};

      if (isEmptyObject(amount)) return;

      const { sportId = 0, countryId = 0, leagueId = 0 } = restEvent;
      const { selections = [], marketId = '' } = market;
      const { odd } = selections.find(
        (selection: Selection) => selection.id === selectionId,
      ) || { odd: 0 };

      if (market.status !== MARKET_STATUS.ACTIVE) return;

      const betItem: Bet = {
        selectionIds: [selectionId],
        odds: [odd],
        marketsId: [+marketId],
        sportsId: [sportId],
        countriesId: [countryId],
        leaguesId: [+leagueId],
        ...getBetslipStake({ amount, separateBonusBalance }),
      };

      payload.push(betItem);
    });

    return payload;
  };

  const formatSystemBetPayload = (): Bet[] => {
    const payload: Bet[] = [];

    betslips.forEach(({ selectionId, market, ...restEvent }) => {
      const { sportId = 0, countryId = 0, leagueId = 0 } = restEvent;
      const { selections = [], marketId = '' } = market;
      const { odd } = selections.find(
        (selection: Selection) => selection.id === selectionId,
      ) || { odd: 0 };

      if (market.status !== MARKET_STATUS.ACTIVE) return;

      const betItem: Bet = {
        selectionIds: [selectionId],
        odds: [odd],
        marketsId: [+marketId],
        sportsId: [sportId],
        countriesId: [countryId],
        leaguesId: [+leagueId],
        ...getBetslipStake({
          amount: aggregatedBetAmount,
          separateBonusBalance,
        }),
      };

      payload.push(betItem);
    });

    return payload;
  };

  return {
    formatMultipleBetPayload,
    formatSingleBetPayload,
    formatSystemBetPayload,
  };
};
