import React, { useRef } from 'react';
import { FieldErrors } from 'react-hook-form';
import dayjs from 'dayjs';
import { styled } from 'stitches.config';

import { Box } from '~components/atoms/Box';
import { DATE_PLACEHOLDERS_MAP } from '~components/atoms/DateRangePicker';
import { Tooltip } from '~components/atoms/Tooltip';
import { Text } from '~components/atoms/Typography';
import { CURRENCY_CODES, GENDER_OPTIONS } from '~constants/common';
import { useOuterClick } from '~hooks/useOuterClick';
import { useTranslation } from '~hooks/useTranslation';
import { EditAlterIcon, ProtectedChoiceIcon } from '~icons';
import { useAppSelector } from '~store';
import { ellipsis } from '~utils/cssUtils';
import { DATE_FORMATS } from '~utils/formatDateTime';

import {
  PERSONAL_DETAILS_FORM_FIELDS,
  PERSONAL_DETAILS_I18N_TITLES,
  PersonalDetailsFormInputs,
} from './PersonalDetailsForm/constants';
import { ReadOnlyPhoneNumberField } from './PersonalDetailsForm/ReadOnlyPhoneNumberField';
import { UserDetailField } from './UserDetailField';

export const StyledDetailField = styled(Box, {
  height: '32px',
  borderRadius: '$6',
  background: '$gray',
  p: '$1 $3',
  position: 'relative',
  '@xs_sm': {
    height: 'auto',
    p: '$2 $3',
  },
});

interface EditableFieldProps {
  fieldName: keyof PersonalDetailsFormInputs;
  fieldValue: string | null;
  children: React.ReactNode;
  setEditingField: (fieldName: string | null) => void;
  isEditing: boolean;
  isProtected?: boolean;
  closeOnOutsideClick?: boolean;
  errors?: FieldErrors<PersonalDetailsFormInputs>;
  localizedGenderOptions?: {
    label: string;
    value: GENDER_OPTIONS;
  }[];
}

export const UserDetailEditableField = ({
  fieldName,
  fieldValue,
  children,
  setEditingField,
  isProtected = false,
  isEditing,
  errors,
  closeOnOutsideClick = false,
  localizedGenderOptions = [],
}: EditableFieldProps) => {
  const { localized } = useTranslation();
  const { currency } = useAppSelector((state) => state.settings);
  const componentRef = useRef<HTMLDivElement>(null);

  let content = children;

  const toggleEditing = () => {
    setEditingField(isEditing ? null : fieldName);
  };

  const handleClickOutside = () => {
    if (!isEditing) {
      return;
    }

    setEditingField(null);
  };

  useOuterClick(componentRef, () => {
    if (closeOnOutsideClick && isEditing) {
      handleClickOutside();
    }
  });

  if (isProtected) {
    if (fieldName === PERSONAL_DETAILS_FORM_FIELDS.PHONE_NUMBER_FIELD) {
      return (
        <StyledDetailField
          flexRow
          alignCenter
          justifyContentBetween
          css={{
            '@xs_sm': {
              height: '$7',
            },
          }}
        >
          <Text level="sm-3">
            {localized(PERSONAL_DETAILS_I18N_TITLES[fieldName])}
          </Text>
          <Box flexRow alignCenter gap={3}>
            <ReadOnlyPhoneNumberField
              protectedPhoneNumber
              phoneNumber={fieldValue!}
            />
            <Tooltip
              text={localized(
                'userProfile.personalProfileInfo.yourFieldWasVerified',
                {
                  fieldName: localized(
                    PERSONAL_DETAILS_I18N_TITLES[fieldName],
                  ).toLowerCase(),
                },
              )}
            >
              <Box flexRow css={{ color: '$white' }}>
                <ProtectedChoiceIcon width={18} height={18} />
              </Box>
            </Tooltip>
          </Box>
        </StyledDetailField>
      );
    }

    return <UserDetailField title={fieldName} data={fieldValue} />;
  }

  if (!isEditing) {
    switch (fieldName) {
      case PERSONAL_DETAILS_FORM_FIELDS.PHONE_NUMBER_FIELD:
        content = (
          <ReadOnlyPhoneNumberField
            phoneNumber={fieldValue!}
            isValidPhoneNumber={!errors?.[fieldName]}
          />
        );
        break;

      case PERSONAL_DETAILS_FORM_FIELDS.BIRTH_DATE_FIELD:
        if (fieldValue) {
          content = (
            <Text
              color={!errors?.[fieldName] ? 'white' : 'red'}
              level="sm-3"
              ellipsis
            >
              {dayjs(fieldValue).format(DATE_FORMATS.DATE_WITH_DOT)}
            </Text>
          );
        } else {
          content = (
            <Text color="grayMedium" level="sm-3" ellipsis>
              {DATE_PLACEHOLDERS_MAP[DATE_FORMATS.DATE_WITH_DOT]}
            </Text>
          );
        }

        break;

      case PERSONAL_DETAILS_FORM_FIELDS.USER_GENDER_FIELD:
        content = (
          <Text level="sm-3" ellipsis>
            {
              localizedGenderOptions!.find((item) => item.value == fieldValue)
                ?.label
            }
          </Text>
        );
        break;

      default:
        content = (
          <Text
            ellipsis
            color={!errors?.[fieldName] ? 'white' : 'red'}
            level="sm-3"
          >
            {fieldValue}
          </Text>
        );
        break;
    }
  }

  const isNigeria = currency === CURRENCY_CODES.NGN;
  const isZambia = currency === CURRENCY_CODES.ZMK;

  return (
    <StyledDetailField flexRow alignCenter justifyContentBetween>
      <Text
        level="sm-3"
        css={{
          whiteSpace: 'nowrap',
        }}
      >
        {(isNigeria || isZambia) && fieldName === 'userIdField'
          ? 'NIN'
          : localized(PERSONAL_DETAILS_I18N_TITLES[fieldName])}
      </Text>
      <Box
        ref={componentRef}
        flexRow
        alignCenter
        gap={3}
        css={{
          width: '100%',
          justifyContent: 'flex-end',
          height: isEditing ? '$7' : 'unset',
          backgroundColor: '$gray',
          borderRadius: '$4',
          right: 0,
          ml: '$2',
          paddingRight: '$3',
          border: isEditing ? '1px solid $gray1' : 'none',
          ...ellipsis,
        }}
      >
        <Box
          flexRow
          css={{
            ...ellipsis,
          }}
        >
          {content}
        </Box>
        <Box
          flexRow
          onClick={toggleEditing}
          css={{ cursor: isEditing ? 'initial' : 'pointer' }}
        >
          {isEditing ? (
            <Box fullHeight fullWidth css={{ width: '$5', height: '$5' }} />
          ) : (
            <Box flexRow css={{ color: '$grayMedium' }}>
              <EditAlterIcon />
            </Box>
          )}
        </Box>
      </Box>
    </StyledDetailField>
  );
};
