import { styled } from 'stitches.config';

import { MainMarketSelection } from '~api/market/types';
import { Box } from '~components/atoms/Box';
import { Text } from '~components/atoms/Typography';
import { useMedia } from '~hooks/useMedia';
import { useAppDispatch, useAppSelector } from '~store';
import {
  addJackpotStake,
  clearJackpotMessages,
  getSelectedJackpot,
  removeJackpotStake,
  selectJackpotStakesById,
} from '~store/slices/jackpotSlice';

interface EventOddProps {
  selection: MainMarketSelection;
  eventId: string;
  mainMarketName?: string;
}

const StyledJackpotSelectionItem = styled(Box, {
  display: 'flex',
  flexDirection: 'row',
  width: '100%',
  alignContent: 'center',
  justifyContent: 'center',
  gap: '$2',
  background: '$gray',
  p: '$4 $4',
  '@xs': {
    p: '6px $2',
  },
  '@sm': {
    p: '6px $2',
  },
  cursor: 'pointer',
  '&[data-state=on]': {
    backgroundColor: '$oddBgColorActive',
    color: '$oddTextColorActive',
    '*': {
      color: '$oddTextColorActive',
    },
  },
  '&[data-state=off]:hover': {
    backgroundColor: '$oddBgColorHover',
  },
});

export const SelectionItem = ({
  selection,
  eventId,
  mainMarketName,
}: EventOddProps) => {
  const { isMobile, isLargeDesktopOrDesktop } = useMedia();
  const dispatch = useAppDispatch();
  const selectedJackpot = useAppSelector(getSelectedJackpot);
  const jackpotStakes = useAppSelector(
    selectJackpotStakesById(selectedJackpot?.id),
  );

  if (!selectedJackpot) return null;

  const selectionId = selection.id;
  const isSelectedItem = jackpotStakes.some(
    (stake) => stake.id === selectionId,
  );

  const handleBetClick = () => {
    if (selectionId) {
      dispatch(clearJackpotMessages());
      if (!isSelectedItem) {
        dispatch(
          addJackpotStake({
            jackpotId: selectedJackpot.id,
            selectionId,
            eventId,
          }),
        );
      } else {
        dispatch(
          removeJackpotStake({ jackpotId: selectedJackpot.id, selectionId }),
        );
      }
    }
  };

  return (
    <StyledJackpotSelectionItem
      onClick={handleBetClick}
      data-state={isSelectedItem ? 'on' : 'off'}
      css={{
        '@md': {
          justifyContent: 'space-between',
        },
        '@xs_sm': {
          justifyContent: 'space-between',
        },
      }}
    >
      {!isLargeDesktopOrDesktop && (
        <Text color="grayMedium" level={isMobile ? '13-20' : '16-28'}>
          {mainMarketName}
        </Text>
      )}
      <Text
        level={isMobile ? '13-20' : '16-28'}
        color="yellow"
        textAlign="center"
      >
        {selection.odd}
      </Text>
    </StyledJackpotSelectionItem>
  );
};
