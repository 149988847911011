import React from 'react';

import { Box } from '~components/atoms/Box';
import { Tabs, TabsList } from '~components/atoms/Tabs';
import { ToggleGroup, ToggleGroupItem } from '~components/atoms/ToggleGroup';
import { Text } from '~components/atoms/Typography';
import {
  JACKPOT_DETAILS_TABS,
  JACKPOT_DETAILS_TABS_LIST,
} from '~components/molecules/Jackpot/constants';
import { useMedia } from '~hooks/useMedia';

export const DetailsTabs = ({
  activeTab,
  setActiveTab,
}: {
  activeTab: JACKPOT_DETAILS_TABS;
  setActiveTab: React.Dispatch<React.SetStateAction<JACKPOT_DETAILS_TABS>>;
}) => {
  const { isMobileOrTablet } = useMedia();

  const handleTabChange = (value: JACKPOT_DETAILS_TABS) => {
    setActiveTab(value);
  };

  return isMobileOrTablet ? (
    <ToggleGroup
      type="single"
      variant="market"
      onValueChange={handleTabChange}
      value={activeTab}
      rovingFocus={false}
      loop={false}
    >
      {JACKPOT_DETAILS_TABS_LIST.map((tabName) => (
        <ToggleGroupItem key={tabName} type="market" value={tabName}>
          <Text as="span" level="14-20" textTransform="uppercase">
            {tabName}
          </Text>
        </ToggleGroupItem>
      ))}
    </ToggleGroup>
  ) : (
    <Box flexRow gap={6} css={{ padding: '$1 $3' }}>
      <Tabs
        defaultTab={JACKPOT_DETAILS_TABS.EVENTS}
        onValueChange={(value) => setActiveTab(value as JACKPOT_DETAILS_TABS)}
      >
        <TabsList uppercase tabs={JACKPOT_DETAILS_TABS_LIST} />
      </Tabs>
    </Box>
  );
};
