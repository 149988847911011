import React, { useEffect, useRef } from 'react';

import { Box } from '~components/atoms/Box';
import { ScrollArea } from '~components/atoms/ScrollArea';
import { SportsLeftSidebar } from '~components/molecules/SportsLeftSidebar';
import { SportsRightSidebar } from '~components/molecules/SportsRightSidebar';
import { Footer } from '~components/organisms/Footer';
import { IFRAME_HEADER_HEIGHT, IFRAME_HEADER_MARGIN } from '~constants/ui';
import useIsInIframe from '~hooks/useIsInIframe';
import { useQueryParams } from '~hooks/useQueryParams';
import { useSportsPage } from '~pages/hooks/useSportsPage';
import { StyledPage, StyledWrapper } from '~pages/styled.components';

export const SportsPage = () => {
  const { BoardComponent } = useSportsPage();
  const search = useQueryParams();
  const scrollRef = useRef<HTMLDivElement>(null);

  const isInIframe = useIsInIframe();

  useEffect(() => {
    if (scrollRef.current) {
      scrollRef.current.scrollTo(0, 0);
    }
  }, [search]);

  return (
    <Box fullWidth flexRow justifyCenter>
      <StyledPage>
        <SportsLeftSidebar />
        <StyledWrapper
          css={{
            height: isInIframe
              ? `calc(100vh - ${IFRAME_HEADER_HEIGHT}px - ${IFRAME_HEADER_MARGIN}px - 16px)`
              : 'calc(100vh - 156px)',
            width: '100%',
            overflow: 'auto',
            '@md': {
              height: isInIframe
                ? `calc(100vh - ${IFRAME_HEADER_HEIGHT}px - ${IFRAME_HEADER_MARGIN}px - 16px)`
                : 'calc(100vh - 144px)',
            },
            '@xs_sm': {
              height: 'auto',
            },
          }}
        >
          <ScrollArea ref={scrollRef}>
            <Box flexCol justifyContentBetween css={{ gap: '120px' }}>
              {BoardComponent && <BoardComponent />}
              {!isInIframe && <Footer />}
            </Box>
          </ScrollArea>
        </StyledWrapper>
        <SportsRightSidebar />
      </StyledPage>
    </Box>
  );
};
