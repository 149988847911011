import React from 'react';
import { VariantProps } from 'stitches.config';

import { ToggleGroup, ToggleGroupItem } from '~components/atoms/ToggleGroup';
import { Text } from '~components/atoms/Typography';
import { QUERY_PARAMS } from '~constants/common';
import { useMedia } from '~hooks/useMedia';
import { useRouterQuery } from '~hooks/useRouterQuery';
import { useTranslation } from '~hooks/useTranslation';
import { useAppSelector } from '~store';
import { SPORT_MENUS } from '~types/sportMenus';
import { QueryParams } from '~utils/url';

interface MenuToggle {
  value: SPORT_MENUS;
  label: string;
  count: number;
}

interface MenuToggleItemProps {
  label: string;
  count: number;
  isActive?: boolean;
}

export const MenuToggleItem = ({
  label,
  count = 0,
  isActive = false,
}: MenuToggleItemProps) => {
  const { isLaptop } = useMedia();

  const textStyle = {
    as: 'span',
    level: !isLaptop ? '14-20' : '12-16',
    color: '$white',
  };

  const countTextStyle = {
    ...textStyle,
  };

  if (isActive) {
    countTextStyle.level = '12-14';
  }

  return (
    <>
      <Text {...(textStyle as VariantProps<typeof Text>)} className="label">
        {label}
      </Text>
      <Text
        {...(countTextStyle as VariantProps<typeof Text>)}
        className="count"
      >
        ({count})
      </Text>
    </>
  );
};

interface SidebarToggleButtonsProps {
  onToggle: (value: SPORT_MENUS) => void;
}

export const SidebarToggleButtons = ({
  onToggle,
}: SidebarToggleButtonsProps) => {
  const { localized } = useTranslation();
  const { updateQueryParams } = useRouterQuery();
  const { activeMenu: storeActiveMenu } = useAppSelector(
    (state) => state.sportsMenuState,
  );
  const { counts, activeMenu } = useAppSelector(
    (state) => state.sportsMenuState,
  );
  const { inplay: inplayCount, prematch: prematchCount } = counts;

  const menusToggles: MenuToggle[] = [
    {
      value: SPORT_MENUS.LIVE,
      label: localized('sidebar.toggleButtons.live'),
      count: inplayCount,
    },
    {
      value: SPORT_MENUS.PREMATCH,
      label: localized('sidebar.toggleButtons.prematch'),
      count: prematchCount,
    },
  ];

  const interceptToggle = (activeMenu: string) => {
    if (!activeMenu) {
      if (storeActiveMenu === SPORT_MENUS.PREMATCH) {
        updateQueryParams(
          {
            [QUERY_PARAMS.MENU]: storeActiveMenu,
          } as QueryParams<typeof QUERY_PARAMS>,
          true,
        );
      }

      return;
    }

    onToggle(activeMenu as SPORT_MENUS);
  };

  if (!activeMenu) {
    return null;
  }

  return (
    <ToggleGroup
      type="single"
      onValueChange={interceptToggle}
      value={activeMenu}
      rovingFocus={false}
      loop={false}
    >
      {menusToggles.map(({ value, label, count }) => (
        <ToggleGroupItem key={label} value={value}>
          <MenuToggleItem
            isActive={activeMenu === value}
            label={label}
            count={count}
          />
        </ToggleGroupItem>
      ))}
    </ToggleGroup>
  );
};
