import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { QUERY_PARAMS } from '~constants/common';
import {
  setActiveMenu,
  setLiveParams,
  setPrematchParams,
} from '~features/sportsMenu/sportsMenuSlice';
import { useQueryParams } from '~hooks/useQueryParams';
import { useRouterQuery } from '~hooks/useRouterQuery';
import { useAppDispatch, useAppSelector } from '~store';
import { setSearchQuery } from '~store/slices/sportEventSlice';
import { SPORT_MENUS } from '~types/sportMenus';
import { getParamFromQuery, getQueryParams, QueryParams } from '~utils/url';

export const useLeftSidebar = () => {
  const { searchQuery, searchPerformed } = useAppSelector(
    (state) => state.sportEventState,
  );
  const { activeMenu, liveParams, prematchParams } = useAppSelector(
    (state) => state.sportsMenuState,
  );
  const { updateQueryParams } = useRouterQuery();
  const searchParams = useQueryParams();
  const { search } = useLocation();
  const dispatch = useAppDispatch();

  useEffect(() => {
    const activeSportMenu = getParamFromQuery(search, QUERY_PARAMS.MENU);

    if (
      activeSportMenu &&
      Object.values(SPORT_MENUS).includes(activeSportMenu as SPORT_MENUS)
    ) {
      dispatch(setActiveMenu(activeSportMenu as SPORT_MENUS));
    } else {
      updateQueryParams(
        {
          [QUERY_PARAMS.MENU]: SPORT_MENUS.PREMATCH,
        } as QueryParams<typeof QUERY_PARAMS>,
        true,
      );
      dispatch(setActiveMenu(SPORT_MENUS.PREMATCH));
    }
  }, []);

  const handleSidebarToggle = (value: SPORT_MENUS) => {
    const queryParams = getQueryParams(search);

    if (value === SPORT_MENUS.PREMATCH) {
      dispatch(setLiveParams(queryParams));

      updateQueryParams(
        {
          ...prematchParams,
          [QUERY_PARAMS.MENU]: value,
          [QUERY_PARAMS.EVENT_ID]: undefined,
          [QUERY_PARAMS.GROUP]: undefined,
        } as QueryParams<typeof QUERY_PARAMS>,
        true,
      );
    } else {
      dispatch(setPrematchParams(queryParams));

      updateQueryParams(
        {
          ...liveParams,
          [QUERY_PARAMS.MENU]: value,
          [QUERY_PARAMS.GROUP]: undefined,
        } as QueryParams<typeof QUERY_PARAMS>,
        true,
      );
    }

    dispatch(setActiveMenu(value));
  };

  useEffect(() => {
    if (!activeMenu) {
      dispatch(setActiveMenu(SPORT_MENUS.PREMATCH));
      updateQueryParams(
        {
          ...searchParams,
          [QUERY_PARAMS.MENU]: SPORT_MENUS.PREMATCH,
        } as QueryParams<typeof QUERY_PARAMS>,
        true,
      );
    }
  }, [activeMenu, searchParams]);

  useEffect(() => {
    const { menu } = searchParams;

    dispatch(setActiveMenu(menu as SPORT_MENUS));
  }, []);

  return {
    searchQuery,
    searchPerformed,
    activeMenu,
    setSearchQuery,
    handleSidebarToggle,
  };
};
