import { useEffect, useMemo } from 'react';

import { Box } from '~components/atoms/Box';
import { Message } from '~components/atoms/Message';
import { Text } from '~components/atoms/Typography';
import { MESSAGE_TYPES } from '~constants/common';
import { useMedia } from '~hooks/useMedia';
import { useAppDispatch, useAppSelector } from '~store';
import { setIsSwipeOpen } from '~store/slices/mobileSlice';

import { SPORT_BETSLIP_TYPE_OPTIONS } from '../../constants';
import { useBetslipStakes } from '../../hooks/useBetslipStakes';

import { BetslipEmptyStakes } from './BetslipEmptyStakes';
import { BetslipStake } from './BetslipStake';

export const BetslipStakes = ({ quickBet = false }) => {
  const {
    isProcessingFailed,
    duplicatedMultipleBetStakes,
    singleBetsAmountMap,
    stakeType,
    betErrorCode,
    betErrorEvent,
    events,
    showNotification,
    localized,
    localizedError,
    handleAmountChange,
    handleRemoveStake,
  } = useBetslipStakes(quickBet);

  const dispatch = useAppDispatch();
  const { betslipFooterHeight } = useAppSelector((state) => state.userUIState);
  const { eventsMap, marketsMap } = useAppSelector((state) => state.events);
  const { isMobileOrTablet } = useMedia();
  const { duplicateBetsCountLimit = 0 } = useAppSelector(
    (state) => state.settings,
  );

  useEffect(() => {
    if (quickBet && !showNotification && !events.length) {
      dispatch(setIsSwipeOpen(null));
    }
  }, [showNotification]);

  const betslipErrorMessage: string[] = useMemo(() => {
    const { eventId, marketId, selectionId } = events.find(
      (event) => event.eventId === betErrorEvent,
    ) || { eventId: '', marketId: '', selectionId: '' };

    if (!eventId || !marketId || !selectionId)
      return [localizedError('betslip.error')];
    const { name: eventName } = eventsMap[eventId] || { name: '' };
    const { name: marketName, selections } = marketsMap[marketId] || {
      name: '',
      selections: [],
    };
    const { name: selectionName } = selections.find(
      (selection) => selection.id === selectionId,
    ) || { name: '' };

    let metaMessageData: string[] = [];

    switch (betErrorCode) {
      case 520:
        metaMessageData = [eventName, marketName];
        break;
      case 521:
      case 522:
      case 523:
        metaMessageData = [eventName, marketName, selectionName];
        break;
      default:
        break;
    }

    if (betErrorEvent === 'userExceededDuplicateBetsLimit') {
      return [
        localizedError('betslip.errors.userExceededDuplicateBetsLimit', {
          max: duplicateBetsCountLimit,
        }),
      ];
    }

    return betErrorCode
      ? [localized(`betslip.errorCode.${betErrorCode}`), ...metaMessageData]
      : [localizedError('betslip.error')];
  }, [events, eventsMap, marketsMap, betErrorCode, betErrorEvent]);

  return (
    <Box
      css={{
        p: '$0 $4 $4',
        width: '100%',
        gap: '$2',
        mb: showNotification && isMobileOrTablet ? '$8' : 0,
      }}
    >
      {events.length ? (
        <>
          {!quickBet && (
            <Text
              css={{
                fontSize: '$14',
                lineHeight: '$16',
                mb: '$3',
                '@xs_sm': {
                  fontSize: '$12',
                },
              }}
            >
              {localized('betslip.myBets')}:
            </Text>
          )}
          <Box
            flexCol
            css={{
              gap: '$2',
              '@xs_sm': {
                gap: 'unset',
              },
            }}
          >
            {events.map((event, index) => {
              let isDuplicated = false;

              if (stakeType === SPORT_BETSLIP_TYPE_OPTIONS.MULTIPLE) {
                isDuplicated = duplicatedMultipleBetStakes.includes(
                  event.eventId,
                );
              }

              return (
                <BetslipStake
                  quickBet={quickBet}
                  key={event.selectionId}
                  index={index}
                  stakeType={stakeType}
                  isDuplicated={isDuplicated}
                  betslipItem={event}
                  eventsCount={events.length}
                  amount={singleBetsAmountMap[event.selectionId] || {}}
                  onAmountChange={handleAmountChange}
                  onRemove={handleRemoveStake}
                />
              );
            })}
          </Box>
        </>
      ) : (
        <>{!quickBet && <BetslipEmptyStakes />}</>
      )}

      {showNotification && (
        <Box
          css={{
            '@xs_sm': {
              position: 'fixed',
              bottom: quickBet ? '30px' : `${betslipFooterHeight + 10}px`,
              width: 'calc(100% - 32px)',
              background: '$panelBgColor',
            },
          }}
        >
          <Message
            css={{ marginTop: '$2' }}
            text={!isProcessingFailed ? localized('betslip.success') : ''}
            textArray={
              isProcessingFailed ? betslipErrorMessage : ([] as string[])
            }
            type={
              isProcessingFailed ? MESSAGE_TYPES.ERROR : MESSAGE_TYPES.SUCCESS
            }
          />
        </Box>
      )}
    </Box>
  );
};
