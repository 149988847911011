import { useEffect } from 'react';

import { useGetSportGroups } from '~components/molecules/PrematchMenu/hooks/useGetSportGroups';
import { useQueryParams } from '~hooks/useQueryParams';
import { MainPageMobile } from '~pages/MainPage/MainPageMobile';
import { useAppDispatch, useAppSelector } from '~store';
import { setMobileActiveTab } from '~store/slices/mobileSlice';
import { SPORT_GROUPS } from '~types/sportGroups';

import { MOBILE_MENU } from '../MobileTab';

import { GamesTab, LiveTab, MenuTab, SportTab } from './index';

export enum MOBILE_TAB_CONTENTS {
  LIVE = 'live',
  SPORT = 'prematch',
  GAMES = 'games',
  MENU = 'menu',
}

export const MobileTabContents = () => {
  const dispatch = useAppDispatch();
  const { mobileActiveTab } = useAppSelector((state) => state.mobileState);
  const { group, menu } = useQueryParams();

  useGetSportGroups();

  useEffect(() => {
    if (!mobileActiveTab) {
      dispatch(setMobileActiveTab(MOBILE_MENU.SPORT));
    }
  }, [mobileActiveTab]);

  const contentsMap = {
    [MOBILE_TAB_CONTENTS.LIVE]: <LiveTab />,
    [MOBILE_TAB_CONTENTS.SPORT]: <SportTab />,
    [MOBILE_TAB_CONTENTS.GAMES]: <GamesTab />,
    [MOBILE_TAB_CONTENTS.MENU]: <MenuTab />,
  };

  if (group === SPORT_GROUPS.TOP_EVENTS && menu === MOBILE_MENU.SPORT) {
    return <SportTab />;
  }

  if (group === SPORT_GROUPS.TOP_EVENTS && menu === MOBILE_MENU.LIVE) {
    return <LiveTab />;
  }

  return mobileActiveTab ? (
    contentsMap[mobileActiveTab as unknown as MOBILE_TAB_CONTENTS]
  ) : (
    <MainPageMobile />
  );
};
