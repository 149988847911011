import { useEffect, useState } from 'react';
import { HubConnection } from '@microsoft/signalr';

import {
  SIGNAL_R_SOCKET_MESSAGES,
  SIGNAL_R_SOCKET_NAMES,
} from '~constants/signalR';
import { useAppDispatch, useAppSelector } from '~store';
import {
  handleRemoveEvent,
  setEndedEventStatus,
  updateBetStopEvent,
  updateEventData,
  updateEventExtraData,
  updateEventMarket,
  updateEventMarketCount,
} from '~store/slices/eventsSlice';
import {
  addLiveMenuEvent,
  handleAddHightlightEvent,
  handleRemoveHightlightEvent,
  removeLiveMenuEvent,
} from '~store/slices/liveMenuSlice';
import { MarketWithEventId } from '~types/events';

export const useAppEventsUpdate = () => {
  const { sockets } = useAppSelector((state) => state.signalRSockets);
  const [socket, setSocket] = useState<HubConnection | null>(null);
  const dispatch = useAppDispatch();

  useEffect(() => {
    const inplaySocket = sockets?.[SIGNAL_R_SOCKET_NAMES.INPLAY]?.socket;

    if (inplaySocket) {
      setSocket(inplaySocket);
    }
  }, [sockets]);

  useEffect(() => {
    socket?.on(SIGNAL_R_SOCKET_MESSAGES.BET_STOP_MESSAGE, (response) => {
      dispatch(updateBetStopEvent(response));
    });
    socket?.on(SIGNAL_R_SOCKET_MESSAGES.EXTRA_DATA_MESSAGE, (response) => {
      dispatch(updateEventExtraData(response));
    });
    socket?.on(SIGNAL_R_SOCKET_MESSAGES.MARKET_UPDATE_MESSAGE, (response) => {
      dispatch(updateEventMarket(response));
    });
    socket?.on(SIGNAL_R_SOCKET_MESSAGES.MARKETS_UPDATE_MESSAGE, (response) => {
      response.forEach((market: MarketWithEventId) => {
        dispatch(updateEventMarket(market));
      });
    });
    socket?.on(SIGNAL_R_SOCKET_MESSAGES.ON_INPLAY_ADD_EVENT, (response) => {
      dispatch(updateEventData(response));
      dispatch(addLiveMenuEvent(response));
      dispatch(handleAddHightlightEvent(response));
    });
    socket?.on(
      SIGNAL_R_SOCKET_MESSAGES.MARKET_COUNT_UPDATE_MESSAGE,
      (response) => {
        dispatch(updateEventMarketCount(response));
      },
    );
    socket?.on(SIGNAL_R_SOCKET_MESSAGES.ON_INPLAY_REMOVE_EVENT, (response) => {
      const { id: eventId, ...rest } = response;

      dispatch(setEndedEventStatus(eventId));

      setTimeout(() => {
        dispatch(handleRemoveEvent(eventId));
        dispatch(removeLiveMenuEvent({ eventId, ...rest }));
        dispatch(handleRemoveHightlightEvent({ eventId, ...rest }));
      }, 10000);
    });
  }, [socket]);
};
