import { MainMarket } from '~api/market/types';
import {
  AccordionContent,
  AccordionTrigger,
  StyledAccordionItem,
} from '~components/atoms/Accordion';
import { Box } from '~components/atoms/Box';
import { EventsList } from '~components/molecules/MainHighlights/EventsList';
import { MatchHighlightsMarketsHeaders } from '~components/molecules/MainHighlights/MatchHighlightsMarketsHeaders';
import { SPORTS } from '~constants/sport';
import { SportEvent } from '~types/events';
import { getSportIcon } from '~utils/getSportIcon';

import { useLiveTopEventItem } from '../../hooks/useLiveTopEventItem';

interface LiveTopEventItemProps {
  sportId: number;
  sportName: string;
  matches: SportEvent[];
  mainMarkets: MainMarket[];
  maxMarketsCount: number | undefined;
}

export const LiveTopEventItem = ({
  sportId,
  sportName,
  matches,
  mainMarkets,
  maxMarketsCount,
}: LiveTopEventItemProps) => {
  const { mainSportMarkets } = useLiveTopEventItem(
    mainMarkets,
    maxMarketsCount,
  );

  return (
    <StyledAccordionItem
      value={sportId.toString()}
      css={{ p: '$1 $1 0', '&:last-child': { pb: '$1' } }}
    >
      <AccordionTrigger grayDark isAlwaysRoundCorners>
        <Box flexRow alignCenter gap={2}>
          <Box css={{ lineHeight: 0 }}>{getSportIcon(sportName as SPORTS)}</Box>
          <Box>{sportName}</Box>
        </Box>
      </AccordionTrigger>
      <AccordionContent
        css={{
          p: 0,
          backgroundColor: '$black',
          '& > .contentWrapper': {
            p: 0,
          },
        }}
      >
        <Box fullWidth flexCol css={{ background: '$black', p: '$1' }}>
          <MatchHighlightsMarketsHeaders mainMarkets={mainSportMarkets} />
          {matches && matches.length && (
            <EventsList
              isLive
              activeEventIds={matches.map((event) => event.id)}
              mainMarkets={mainSportMarkets}
            />
          )}
        </Box>
      </AccordionContent>
    </StyledAccordionItem>
  );
};
