import { MainMarket } from '~api/market/types';
import { useMedia } from '~hooks/useMedia';
import { useAppSelector } from '~store';
import { selectMainMarketsBySportId } from '~store/slices/mainMarketsSlice';
import { JackpotEvent } from '~types/jackpot';

import { EventLarge } from './EventLarge';
import { EventSmall } from './EventSmall';

export interface ActiveEventsProps {
  event: JackpotEvent;
}

export interface ActiveEventItemProps extends ActiveEventsProps {
  mainMarket: MainMarket;
}

export const Event = ({ event }: ActiveEventsProps) => {
  const { isLargeDesktopOrDesktop } = useMedia();
  const mainMarkets = useAppSelector(selectMainMarketsBySportId(event.sportId));
  const market = mainMarkets?.markets.find(
    (market) => market.id === event.market.marketId,
  );

  if (!market) return null;

  return isLargeDesktopOrDesktop ? (
    <EventLarge event={event} mainMarket={market} />
  ) : (
    <EventSmall event={event} mainMarket={market} />
  );
};
