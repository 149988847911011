import { useEffect, useRef } from 'react';

import { useLazyGetPrematch } from '~api/category/categoryQueries';
import { PrematchParams } from '~api/category/types';
import { QUERY_PARAMS } from '~constants/common';
import { useMedia } from '~hooks/useMedia';
import { useNextHourToDateTo } from '~hooks/useNextHourToDateTo';
import { useQueryParams } from '~hooks/useQueryParams';
import { useRouterQuery } from '~hooks/useRouterQuery';
import { useAppDispatch, useAppSelector } from '~store';
import {
  setLeaguesData,
  setOpenedCountries,
  setOpenedSports,
  setReloadPrematch,
  setSports,
} from '~store/slices/prematchMenuSlice';
import { SPORT_MENUS } from '~types/sportMenus';
import { conditionalAdd } from '~utils/objectHelpers';
import { QueryParams } from '~utils/url';

export const useSportWithCurrentLeague = () => {
  const { updateQueryParams } = useRouterQuery();
  const { isMobileOrTablet } = useMedia();
  const dispatch = useAppDispatch();
  const { countryId, group, leagueId, sportId } = useQueryParams();
  const {
    lazyGetPrematchQuery,
    lazyGetPrematchData,
    lazyGetPrematchError,
    lazyGetFavoriteEventsIsFetching,
    lazyGetPrematchIsLoading,
  } = useLazyGetPrematch();
  const { sports = [], reloadPrematch } = useAppSelector(
    (state) => state.prematchMenu,
  );
  const dateTo = useNextHourToDateTo();
  const prevDateToValue = useRef(dateTo);

  const sportIdRef = useRef(sportId);

  useEffect(() => {
    if (sportId !== sportIdRef.current) {
      dispatch(setReloadPrematch(true));
    }
  }, [sportId, group]);

  useEffect(() => {
    const handleLoadPrematch = async () => {
      const isClearedDateTo = !dateTo && !!prevDateToValue.current;

      prevDateToValue.current = dateTo;

      if (group && sports.length && !isMobileOrTablet && !isClearedDateTo) {
        return;
      }

      if (sportId && !isClearedDateTo) {
        const loadedSportCountries = sports.find(
          ({ id }) => id === parseInt(sportId),
        )?.countries;

        if (loadedSportCountries && loadedSportCountries.length) {
          const loadedCountriesIds = loadedSportCountries
            .filter(({ leagues }) => {
              return leagues.length > 0;
            })
            .map(({ id }) => id.toString());

          dispatch(setOpenedCountries(loadedCountriesIds));

          return;
        }

        const params: Partial<PrematchParams> = {};

        conditionalAdd(params, QUERY_PARAMS.SPORT_ID, sportId);
        conditionalAdd(params, QUERY_PARAMS.COUNTRY_ID, countryId);
        conditionalAdd(params, QUERY_PARAMS.LEAGUE_ID, leagueId);
        params[QUERY_PARAMS.MENU] = SPORT_MENUS.PREMATCH;

        const response = await lazyGetPrematchQuery(params).unwrap();
        const sportsData = response?.sports;

        if (!sports.length) {
          dispatch(setOpenedSports([sportId]));
          if (countryId) {
            dispatch(setOpenedCountries([countryId]));
          } else {
            const sports = sportsData.find(
              (sport) => sport.id === parseInt(sportId),
            );

            dispatch(
              setOpenedCountries([sports?.countries[0]?.id.toString() || '']),
            );
          }

          dispatch(setSports(sportsData));

          return;
        }

        const sportsCountries =
          sportsData.find((sport) => sport.id === parseInt(sportId))
            ?.countries || [];

        const resCountryId = sportsCountries[0]?.id.toString();
        const selectedCountryIndex = sportsCountries.findIndex(
          ({ id }) => parseInt(countryId || '') === id,
        );

        if (selectedCountryIndex !== -1) {
          dispatch(setOpenedCountries([countryId || '']));
        } else if (resCountryId) {
          updateQueryParams({
            [QUERY_PARAMS.COUNTRY_ID]: resCountryId,
          } as QueryParams<typeof QUERY_PARAMS>);
          dispatch(setOpenedCountries([resCountryId]));
        }

        (sportsCountries || []).forEach(({ id, leagues }) => {
          if (leagues.length) {
            dispatch(setLeaguesData({ id: id.toString(), leagues }));
          }
        });

        const resSports = sports.map((sport) => {
          if (sport.id === parseInt(sportId)) {
            return {
              ...sport,
              countries: sportsCountries || [],
            };
          }

          return sport;
        });

        dispatch(setSports(resSports));
      } else {
        const response = await lazyGetPrematchQuery({}).unwrap();
        const sportsData = response?.sports || [];

        if (!sports.length || isClearedDateTo) {
          dispatch(setSports(sportsData));

          const { id: sportId, countries } =
            sportsData.find((item) => item.countries.length > 0) || {};

          dispatch(setOpenedSports([sportId?.toString() || '']));
          if (countries && countries.length && !group) {
            dispatch(setOpenedCountries([countries[0]?.id.toString() || '']));
            const params = {} as Partial<PrematchParams>;

            conditionalAdd(params, QUERY_PARAMS.SPORT_ID, sportId);

            const countryId = countries[0]?.id.toString();

            conditionalAdd(params, QUERY_PARAMS.COUNTRY_ID, countryId);

            params[QUERY_PARAMS.MENU] = SPORT_MENUS.PREMATCH;

            updateQueryParams(params as QueryParams<typeof QUERY_PARAMS>);
          }
        }
      }
    };

    if (reloadPrematch) {
      handleLoadPrematch();
    }
  }, [reloadPrematch, sportId, group, dateTo]);

  useEffect(() => {
    const loadDateBy = async () => {
      if (!dateTo) return;
      try {
        const params: Partial<PrematchParams> = {};

        conditionalAdd(params, QUERY_PARAMS.SPORT_ID, sportId);
        conditionalAdd(params, QUERY_PARAMS.COUNTRY_ID, countryId);
        conditionalAdd(params, QUERY_PARAMS.LEAGUE_ID, leagueId);
        conditionalAdd(params, QUERY_PARAMS.DATE_TO, dateTo);
        params[QUERY_PARAMS.MENU] = SPORT_MENUS.PREMATCH;

        const response = await lazyGetPrematchQuery(params).unwrap();
        const sportsData = response?.sports;

        if (sportsData?.length) {
          dispatch(setSports(sportsData));
        }
      } catch (e) {
        console.error('Failed to load date by: ', e);
      }
    };

    loadDateBy();
  }, [sportId, dateTo, isMobileOrTablet]);

  return {
    sports,
    lazyGetPrematchData,
    lazyGetPrematchError,
    isLoading: lazyGetPrematchIsLoading,
    isFetching: lazyGetFavoriteEventsIsFetching,
  };
};
