export const MOBILE_TAB_HEIGHT = 52;

export const MOBILE_TAB_ROUND_DECORATION_HEIGHT = 18;

export const IFRAME_HEADER_HEIGHT = 72;

export const IFRAME_HEADER_MARGIN = 4;

export enum THEMES {
  DEFAULT = 'default',
  SEMA_BET = 'semaBet',
}
