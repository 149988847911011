import { useRef, useState } from 'react';

import { Box } from '~components/atoms/Box';
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '~components/atoms/Popover';
import { SOCIAL_MEDIA } from '~components/atoms/ShareBet/constants';
import { SharebetPopoverContent } from '~components/atoms/ShareBet/SharebetPopoverContent';
import { CURRENCY_CODES } from '~constants/common';
import { useCopyToClipboard } from '~hooks/useCopyToClipboard';
import { useMedia } from '~hooks/useMedia';
import { useOuterClick } from '~hooks/useOuterClick';
import { ShareIcon } from '~icons';
import { useAppSelector } from '~store';

export interface SocialMediaItem {
  icon: SOCIAL_MEDIA;
  label: string;
  onClick: () => void;
}

export interface ShareData {
  title?: string;
  text?: string;
  url?: string;
}

interface ShareBetProps {
  data: ShareData;
}

export const ShareBet = ({ data }: ShareBetProps) => {
  const { isMobileOrTablet } = useMedia();
  const { currency } = useAppSelector((state) => state.settings);
  const isUganda = currency === CURRENCY_CODES.UGX;
  const { handleShare, handleCopy } = useCopyToClipboard();
  const [isOpen, setIsOpen] = useState(false);
  const popoverRef = useRef<HTMLDivElement>(null);

  useOuterClick(
    popoverRef,
    () => {
      setTimeout(() => {
        setIsOpen(false);
      }, 0);
    },
    'click',
  );

  const socialMediaOptions: SocialMediaItem[] = [
    {
      icon: SOCIAL_MEDIA.WHATSAPP,
      label: 'WhatsApp',
      onClick: () => {
        const { url } = data;

        if (!url) return;
        const link = document.createElement('a');

        link.style.display = 'none';
        document.body.appendChild(link);

        link.href = `https://wa.me/?text=${encodeURIComponent(url)}`;
        link.target = '_blank';
        link.rel = 'noopener noreferrer';
        link.click();
        link.remove();
      },
    },
    {
      icon: SOCIAL_MEDIA.TELEGRAM,
      label: 'Telegram',
      onClick: () => {
        const { url } = data;

        if (!url) return;
        const link = document.createElement('a');

        link.style.display = 'none';
        document.body.appendChild(link);
        const message = `Check my bet at: ${url}`;

        link.href = `https://t.me/share/url?url=${encodeURIComponent(message)}`;

        link.target = '_blank';
        link.rel = 'noopener noreferrer';
        link.click();
        link.remove();
      },
    },
    {
      icon: SOCIAL_MEDIA.FACEBOOK,
      label: 'Facebook',
      onClick: () => {
        const { url } = data;

        if (!url) return;
        const link = document.createElement('a');

        link.style.display = 'none';
        document.body.appendChild(link);

        link.href = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
          url,
        )}`;
        link.target = '_blank';
        link.rel = 'noopener noreferrer';
        link.click();
        link.remove();
      },
    },
    {
      icon: SOCIAL_MEDIA.X,
      label: 'X',
      onClick: () => {
        const { url } = data;

        if (!url) return;
        const link = document.createElement('a');

        link.style.display = 'none';
        document.body.appendChild(link);

        link.href =
          'https://twitter.com/intent/tweet?url=' + encodeURIComponent(url);
        link.target = '_blank';
        link.rel = 'noopener noreferrer';
        link.click();
        link.remove();
      },
    },
    {
      icon: SOCIAL_MEDIA.COPY_LINK,
      label: 'Copy link',
      onClick: () => {
        handleCopy(data.url);
      },
    },
  ];

  return isMobileOrTablet ? (
    <Box
      css={{
        cursor: 'pointer',
        color: !isUganda ? '$blue' : '$white',
      }}
      flexRow
      onClick={() => handleShare(data)}
    >
      <ShareIcon />
    </Box>
  ) : (
    <Popover isOpen={isOpen}>
      <PopoverContent
        side="bottom"
        tone="secondary"
        css={{
          m: 0,
          p: 0,
        }}
      >
        <SharebetPopoverContent
          ref={popoverRef}
          socialMediaOptions={socialMediaOptions}
        />
      </PopoverContent>
      <PopoverTrigger asChild>
        <Box
          css={{
            cursor: 'pointer',
            color: !isUganda ? '$blue' : '$white',
          }}
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            setIsOpen(true);
          }}
        >
          <ShareIcon />
        </Box>
      </PopoverTrigger>
    </Popover>
  );
};
