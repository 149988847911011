import { Box } from '~components/atoms/Box';
import { Button } from '~components/atoms/Button';
import { DateRangePicker } from '~components/atoms/DateRangePicker';
import { Input } from '~components/atoms/Input';
import { Select } from '~components/atoms/Select';
import { Text } from '~components/atoms/Typography';

import { useBetslipHistoryFilters } from './useBetslipHistoryFilters';

export const BetslipHistoryFilters = () => {
  const {
    code,
    isValidationFailed,
    selectedDates,
    statusesOptions,
    statusValue,
    minimumAge,
    handleReset,
    handleSetCode,
    handleUpdateHistory,
    localized,
    setSelectedDates,
    setStatusValue,
  } = useBetslipHistoryFilters();

  return (
    <>
      <Box flexRow gap={2}>
        <Box flexRow gap={3}>
          <DateRangePicker
            isFutureDatesDisabled
            legalAge={minimumAge}
            onOkRange={(dates) => setSelectedDates(dates)}
            value={selectedDates}
          />
        </Box>
        <Select
          ariaLabel={localized('betslipHistory.status.all')}
          placeholder={localized('betslipHistory.status.all')}
          value={statusValue.toString()}
          onChange={(value) => setStatusValue(value as string)}
          options={statusesOptions}
          css={{
            width: '126px',
            height: '32px',
            borderRadius: '$8',
            backgroundColor: '$blueDark',
          }}
        />
        <Input
          placeholder={localized('betslipHistory.inputPlaceholder')}
          value={code}
          onChange={handleSetCode}
          css={{
            width: '330px',
            height: '32px',
            borderRadius: '$8',
            backgroundColor: '$blueDark',
            padding: '$1 $3',
          }}
        />
      </Box>
      <Box flexRow fullWidth justifyContentEnd>
        <Box flexRow gap={2}>
          <Button
            variant="secondary"
            size="small"
            css={{
              width: '100px',
              height: '32px',
              backgroundColor: '$historyFiltersResetButtonDesktopBgColor',
            }}
            onClick={handleReset}
          >
            <Text level="14-16" fontWeight="bold" textAlign="center">
              {localized('betslipHistory.button.reset')}
            </Text>
          </Button>
          <Button
            size="small"
            disabled={isValidationFailed}
            css={{ width: '100px', height: '32px' }}
            onClick={handleUpdateHistory}
          >
            <Text
              level="14-16"
              fontWeight="bold"
              textAlign="center"
              color="buttonTextColor"
            >
              {localized('betslipHistory.button.show')}
            </Text>
          </Button>
        </Box>
      </Box>
    </>
  );
};
