import React, { ReactNode, useContext, useEffect, useRef } from 'react';

import { Box } from '~components/atoms/Box';
import { Breadcrumb } from '~components/atoms/Breadcrumb';
import { ScrollToTopButton } from '~components/atoms/ScrollToTopButton';
import { BETSLIP_TOGGLE_TYPES } from '~components/molecules/Betslip/constants';
import { Header } from '~components/organisms/Header';
import {
  SharedRefContext,
  SharedRefContextProps,
} from '~contexts/SharedRefContext';
import { useComponentObserver } from '~hooks/useComponentObserver';
import useIsInIframe from '~hooks/useIsInIframe';
import { useAppDispatch, useAppSelector } from '~store';
import { setMobileHeaderInDialogHeight } from '~store/slices/mobileSlice';
import { zIndex } from '~utils/zIndex';

import { StyledDialogHeaderWrapper } from './styled.components';

interface DialogMobileContentProps {
  children: ReactNode;
  fixedHeight?: number;
  isContentState?: boolean;
  isSecondLevelDialog?: boolean;
}

export const DialogMobileContent = ({
  isContentState,
  children,
  fixedHeight = 0,
  isSecondLevelDialog,
}: DialogMobileContentProps) => {
  const { activeTab } = useAppSelector((state) => state.betslip);
  const dispatch = useAppDispatch();
  const headerWrapperRef = useRef<HTMLDivElement>(null);
  const contentWrapperRef = useRef<HTMLDivElement>(null);

  const context = useContext(SharedRefContext) as SharedRefContextProps;

  const isInFrame = useIsInIframe();

  useComponentObserver(headerWrapperRef, (height) => {
    if (isSecondLevelDialog) return;
    dispatch(setMobileHeaderInDialogHeight(height));
  });

  useEffect(() => {
    if (context) {
      const { setRef } = context;

      setRef(contentWrapperRef);
    }
  }, [contentWrapperRef]);

  const { mobileHeaderInDialogHeight } = useAppSelector(
    (state) => state.mobileState,
  );
  const height =
    window.innerHeight - (mobileHeaderInDialogHeight + fixedHeight);

  const marginTop = isSecondLevelDialog ? 61 : mobileHeaderInDialogHeight;

  return (
    <>
      <StyledDialogHeaderWrapper ref={headerWrapperRef}>
        {!isInFrame && <Header inModal />}
        {isContentState && <Breadcrumb />}
      </StyledDialogHeaderWrapper>
      <Box
        css={{
          mt: `${marginTop}px`,
          height:
            activeTab === BETSLIP_TOGGLE_TYPES.OPEN_BETS
              ? '100%'
              : `${height}px`,
          overflowY: 'auto',
          background: '$dialogMobileContentBgColor',
          pb: '100px',
        }}
        ref={contentWrapperRef}
      >
        {children}
        <ScrollToTopButton
          scrollableContainerRef={contentWrapperRef}
          elevation={zIndex.composition.modal.fixed}
        />
      </Box>
    </>
  );
};
