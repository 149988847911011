import React, { useEffect, useRef } from 'react';

import { Box } from '~components/atoms/Box';
import { ScrollArea } from '~components/atoms/ScrollArea';
import { contentMap } from '~components/molecules/Jackpot/constants';
import { JACKPOT_MENU_ITEMS } from '~components/molecules/Jackpot/Menu';
import { SmallJackpotSkeleton } from '~components/molecules/Jackpot/Skeletons';
import { LargeJackpotSkeleton } from '~components/molecules/Jackpot/Skeletons/LargeJackpotSkeleton';
import { useMedia } from '~hooks/useMedia';
import { useQueryParams } from '~hooks/useQueryParams';
import { StyledPage, StyledWrapper } from '~pages/styled.components';
import { useAppDispatch, useAppSelector } from '~store';
import { setSelectedJackpot } from '~store/slices/jackpotSlice';

import { LeftJackpotSidebar, RightJackpotSidebar } from './sidebars';

interface JackpotDesktopPageProps {
  isLoading: boolean;
}

export const DesktopPage = ({ isLoading }: JackpotDesktopPageProps) => {
  const dispatch = useAppDispatch();
  const { isLargeDesktopOrDesktop } = useMedia();
  const { menu, jackpotId } = useQueryParams();
  const scrollRef = useRef<HTMLDivElement>(null);
  const { activeJackpots, settledJackpots } = useAppSelector(
    (state) => state.jackpot,
  );
  const selectedJackpot = [...activeJackpots, ...settledJackpots].find(
    (jackpot) => jackpot.id === jackpotId,
  );

  useEffect(() => {
    if (selectedJackpot) {
      dispatch(setSelectedJackpot(selectedJackpot));
    }
  }, [selectedJackpot]);

  useEffect(() => {
    if (scrollRef.current) {
      scrollRef.current.scrollTo(0, 0);
    }
  }, [menu]);

  if (isLoading) {
    return <SmallJackpotSkeleton />;
  }

  if (isLoading) {
    return <LargeJackpotSkeleton />;
  }

  // On small desktop screens, we move the right sidebar to the bottom of the left sidebar
  return (
    <Box
      fullWidth
      flexRow
      justifyCenter
      css={{
        borderRadius: '$10',
      }}
    >
      <StyledPage isJackpot>
        <LeftJackpotSidebar />
        <StyledWrapper css={{ height: 'auto' }}>
          <ScrollArea ref={scrollRef}>
            <Box
              flexCol
              justifyContentBetween
              css={{ gap: '120px', minHeight: '100vh' }}
            >
              {contentMap[menu as JACKPOT_MENU_ITEMS]}
            </Box>
          </ScrollArea>
        </StyledWrapper>

        {isLargeDesktopOrDesktop && <RightJackpotSidebar />}
      </StyledPage>
    </Box>
  );
};
