import { MasterCardIcon, VisaIcon } from '~icons';
import {
  ActiveBankIcon,
  ActiveCardIcon,
  ActiveFlutterwaveIcon,
  ActivePaystackIcon,
  ActiveQrIcon,
  ActiveTransferIcon,
  ActiveUssdIcon,
  BankIcon,
  CardIcon,
  FlutterwaveIcon,
  PaystackIcon,
  QrIcon,
  TransferIcon,
  UssdIcon,
} from '~icons/payments';
import { SVGElement } from '~types/general';

export interface PaymentMethodToggleButton {
  icon: SVGElement;
  value: string;
  activeIcon: SVGElement;
  label: string;
}

export enum PAYMENT_METHODS {
  INSTANT_CARD = 'paymentMethods.instantCard',
  INSTANT_BANK = 'paymentMethods.instantBank',
}

export const nigeriaPaymentMethodToggleButtons: PaymentMethodToggleButton[] = [
  {
    icon: CardIcon,
    activeIcon: ActiveCardIcon,
    value: 'card',
    label: 'nigeriaPayments.card',
  },
  {
    icon: UssdIcon,
    activeIcon: ActiveUssdIcon,
    value: 'ussd',
    label: 'nigeriaPayments.ussd',
  },
  {
    icon: TransferIcon,
    activeIcon: ActiveTransferIcon,
    value: 'transfer',
    label: 'nigeriaPayments.transfer',
  },
  {
    icon: BankIcon,
    activeIcon: ActiveBankIcon,
    value: 'bank',
    label: 'nigeriaPayments.bank',
  },
  {
    icon: QrIcon,
    activeIcon: ActiveQrIcon,
    value: 'qr',
    label: 'nigeriaPayments.qr',
  },
];

export const nigeriaPaymentProviders: PaymentMethodToggleButton[] = [
  {
    label: 'Flutterwave',
    value: 'flutterwave',
    icon: FlutterwaveIcon,
    activeIcon: ActiveFlutterwaveIcon,
  },
  {
    label: 'Paystack',
    value: 'paystack',
    icon: PaystackIcon,
    activeIcon: ActivePaystackIcon,
  },
];

export enum PAYMENT_PROVIDER {
  FLUTTERWAVE = 1,
  PAYSTACK = 2,
  RELWORX = 3,
}

export const providerValueMap: Record<string, PAYMENT_PROVIDER> = {
  flutterwave: PAYMENT_PROVIDER.FLUTTERWAVE,
  paystack: PAYMENT_PROVIDER.PAYSTACK,
};

export const paymentProviderNameMap: Record<PAYMENT_PROVIDER, string> = {
  [PAYMENT_PROVIDER.FLUTTERWAVE]: 'Flutterwave',
  [PAYMENT_PROVIDER.PAYSTACK]: 'Paystack',
  [PAYMENT_PROVIDER.RELWORX]: 'Relworx',
};

export const paymentProviderValueMap: Record<string, PAYMENT_PROVIDER> = {
  Flutterwave: PAYMENT_PROVIDER.FLUTTERWAVE,
  Paystack: PAYMENT_PROVIDER.PAYSTACK,
  Relworx: PAYMENT_PROVIDER.RELWORX,
};

export enum CARD_TYPES {
  VISA = 'visa',
  MASTERCARD = 'mastercard',
}

export const cardsIconMap: Record<CARD_TYPES, SVGElement> = {
  [CARD_TYPES.VISA]: VisaIcon,
  [CARD_TYPES.MASTERCARD]: MasterCardIcon,
};

export const VISA_CARD_START = [4];

export const MASTERCARD_CARD_START = [2, 5];

export enum ADD_PAYMENT_ACCOUNT_FORM_STATUSES {
  BASE = 'base',
  ADDRESS = 'avs_noauth',
  OTP = 'otp',
  PIN = 'pin',
  DONE = 'done',
}

export const SUCCESS_STATUS = 'success';

export interface ErrorMessage {
  status: string;
  message: string;
  data: null;
  meta: null;
}

export interface ErrorData {
  status: number;
  data: {
    statusCode: number;
    message: string[];
  };
}
