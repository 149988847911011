import { MENU_ICON_TYPES } from '~components/organisms/Header/useHeaderMenu';

export enum BACKEND_CONTENT_TYPES {
  HEADER = 'header',
  FOOTER = 'footer',
}

export interface ContentMenuItem {
  name: string;
  url: string;
}

export interface ContentMenu {
  name: string;
  items: ContentMenuItem[];
  url?: string;
  icon?: MENU_ICON_TYPES;
}

export interface BannerGroup {
  name: string;
  banners: string[];
  rotationTime: number;
}

export type BannerGroups = BannerGroup[];

export interface BannerGroupsQuery {
  names: string[];
  channelType?: string;
}

export type ContentMenus = ContentMenu[];

export interface MenuContent {
  name: string;
  url?: string;
  icon?: string;
  columns: ContentMenus;
}

export type MenuContents = MenuContent[];

export interface InfoPage {
  content: string;
}

export interface InfoPageQueryData {
  name: string;
  channelType: string;
}
