import React from 'react';

import { Box } from '~components/atoms/Box';
import { Skeleton } from '~components/atoms/Skeleton';
import { JACKPOT_MENU_ITEMS } from '~components/molecules/Jackpot/Menu';
import { useMedia } from '~hooks/useMedia';
import { useQueryParams } from '~hooks/useQueryParams';
import { StyledPage, StyledWrapper } from '~pages/styled.components';

export const LargeJackpotSkeleton = () => {
  const { isLargeDesktopOrDesktop } = useMedia();
  const { menu } = useQueryParams();
  const jackpotArray = Array(4).fill(0);
  const defaultSkeletonStyles = { width: '100%', border: 'none' };

  return (
    <Box fullWidth flexRow justifyCenter>
      <StyledPage gap={2}>
        <Box flexCol gap={2} css={{ paddingLeft: '$2' }}>
          {jackpotArray.map((_, index) => (
            <Skeleton
              key={index}
              css={{ height: '70px', ...defaultSkeletonStyles }}
            />
          ))}
        </Box>
        {menu === JACKPOT_MENU_ITEMS.SETTLED_JACKPOTS ? (
          <StyledWrapper flexCol gap={2}>
            {jackpotArray.map((_, index) => (
              <Skeleton
                key={index}
                css={{ height: '228px', ...defaultSkeletonStyles }}
              />
            ))}
            <Skeleton css={{ height: '228px', ...defaultSkeletonStyles }} />
          </StyledWrapper>
        ) : (
          <StyledWrapper flexCol gap={2}>
            <Skeleton css={{ height: '228px', ...defaultSkeletonStyles }} />
            <Skeleton css={{ height: '70vh', ...defaultSkeletonStyles }} />
          </StyledWrapper>
        )}

        {isLargeDesktopOrDesktop && (
          <Box flexCol gap={2} css={{ paddingRight: '$2' }}>
            {jackpotArray.map((_, index) => (
              <Skeleton
                key={index}
                css={{ height: '100px', ...defaultSkeletonStyles }}
              />
            ))}
          </Box>
        )}
      </StyledPage>
    </Box>
  );
};
