import React, { useLayoutEffect } from 'react';
import * as Accordion from '@radix-ui/react-accordion';
import { styled } from 'stitches.config';

import { Box } from '~components/atoms/Box';
import { Breadcrumb } from '~components/atoms/Breadcrumb';
import { ActiveJackpotDetails } from '~components/molecules/Jackpot/Active/components/Content/ActiveJackpotDetails';
import { JACKPOT_MENU_ITEMS } from '~components/molecules/Jackpot/Menu';
import { MenuListSelector } from '~components/molecules/Jackpot/MenuList/MenuListSelector';
import { SettledJackpotDetailsSmall } from '~components/molecules/Jackpot/Settled/components/SettledContent/SettledJackpotDetailsSmall';
import { SmallJackpotDetailsWrapper } from '~components/molecules/Jackpot/styled.components';
import { QUERY_PARAMS } from '~constants/common';
import { useQueryParams } from '~hooks/useQueryParams';
import { useRouterQuery } from '~hooks/useRouterQuery';
import { useTranslation } from '~hooks/useTranslation';
import { useAppDispatch, useAppSelector } from '~store';
import { setBreadcrumbTrail } from '~store/slices/breadcrumbSlice';
import { selectJackpotById } from '~store/slices/jackpotSlice';
import { Jackpots } from '~types/jackpot';
import { slideDown, slideUp } from '~utils/keyframes';

export const StyledSmallJackpotAccordionTrigger = styled(Accordion.Trigger, {
  width: '100%',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  backgroundColor: 'transparent',
  borderBottom: '1px solid $black',
  color: '$grayMedium',
  padding: '$2',
  cursor: 'pointer',
});

export const StyledSmallJackpotAccordionContent = styled(Accordion.Content, {
  overflow: 'hidden',
  '&[data-state="open"]': {
    animation: `${slideDown} 200ms ease-in`,
  },
  '&[data-state="closed"]': {
    animation: `${slideUp} 75ms ease-out`,
  },
});

interface SmallScreenJackpotProps {
  jackpots: Jackpots;
  menu: JACKPOT_MENU_ITEMS;
}

export const SmallScreenJackpot = ({
  jackpots,
  menu,
}: SmallScreenJackpotProps) => {
  const { localized } = useTranslation();
  const { removeQueryParams } = useRouterQuery();
  const dispatch = useAppDispatch();
  const { jackpotId } = useQueryParams();
  const selectedJackpot = useAppSelector(selectJackpotById(jackpotId));

  useLayoutEffect(() => {
    const newTrail = [
      {
        label: localized('buttons.back'),
        onClickCallback: () => {
          removeQueryParams([QUERY_PARAMS.JACKPOT_ID]);
        },
      },
      {
        label: selectedJackpot?.name ?? '',
      },
    ];

    dispatch(setBreadcrumbTrail(newTrail));
  }, []);

  if (!selectedJackpot) {
    return null;
  }

  return (
    <Box flexCol gap={1}>
      <SmallJackpotDetailsWrapper>
        <Box flexCol css={{ paddingBottom: '$4' }}>
          <Breadcrumb customStyles={{ padding: '$2 0 $4 0' }} />
          <MenuListSelector
            jackpots={jackpots}
            selectedJackpot={selectedJackpot}
            menu={menu}
          />
        </Box>

        {menu === JACKPOT_MENU_ITEMS.ACTIVE_JACKPOTS ? (
          <ActiveJackpotDetails selectedJackpot={selectedJackpot} />
        ) : (
          <SettledJackpotDetailsSmall selectedJackpot={selectedJackpot} />
        )}
      </SmallJackpotDetailsWrapper>
    </Box>
  );
};
