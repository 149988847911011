import { MainMarketSelection } from '~api/market/types';
import { useMedia } from '~hooks/useMedia';
import { useAppSelector } from '~store';
import { selectMainMarketsBySportId } from '~store/slices/mainMarketsSlice';
import { JackpotEvent } from '~types/jackpot';

import { EventLarge } from './EventLarge';
import { EventSmall } from './EventSmall';

export interface SettledEventsProps {
  event: JackpotEvent;
  mainMarketSelection?: MainMarketSelection;
}

export const Event = ({ event }: SettledEventsProps) => {
  const { isLargeDesktopOrDesktop } = useMedia();
  const mainMarkets = useAppSelector(selectMainMarketsBySportId(event.sportId));

  const { result, market: eventMarket } = event;

  const mainMarket = mainMarkets?.markets.find(
    (market) => market.id === event.market.marketId,
  );
  // We compare the result (ex. if market 1x2, result is the name of a team or 'draw') and get the event selection
  const winEventSelection = eventMarket.selections.find(
    (selection) => selection.name === result,
  );

  if (!mainMarket || !result || !winEventSelection) return null;

  // Market selections (ex. 'X', '1', '2'), they are always the same for all events
  // We map market selection to our event result selection to get the correct selection label
  const mainMarketSelection = mainMarket.selections.find(
    (selection) => selection.id === winEventSelection.selectionId,
  );

  return isLargeDesktopOrDesktop ? (
    <EventLarge event={event} mainMarketSelection={mainMarketSelection} />
  ) : (
    <EventSmall event={event} mainMarketSelection={mainMarketSelection} />
  );
};
