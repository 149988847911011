import React, { memo } from 'react';

import { Box } from '~components/atoms/Box';
import { OddButtonGroup } from '~components/molecules/LiveMenu/components/OddButtonGroup';
import { useTwoTeamScore } from '~hooks/useTwoTeamScore';
import { Market } from '~types/events';
import { BasketballExtraData } from '~types/sportExtraData/Basketball';
import { getBasketballDetails } from '~utils/sportHelpers';

import { LiveEventDetails, useLiveEventDetails } from '../liveEventDetails';
import { TwoTeamScore } from '../liveEventScores';

export interface BasketballContentProps {
  isMobile: boolean;
  isLoading: boolean;
  eventId: string;
  sportId: number;
  extraData: BasketballExtraData;
  eventName: string | undefined;
  startDate: string | undefined;
  marketsCount: number | undefined;
  market: Market | undefined;
}

export const BasketballContent = memo(
  ({
    isMobile,
    isLoading,
    eventId,
    sportId,
    extraData,
    eventName,
    marketsCount,
    startDate,
    market,
  }: BasketballContentProps) => {
    const { homeTeamScore, awayTeamScore } = useTwoTeamScore(extraData);
    const { formattedMarketsCount, formattedStartDate, formattedMatchStatus } =
      useLiveEventDetails(marketsCount, startDate, extraData);
    const { details } = getBasketballDetails(extraData, true) || {};

    return (
      <Box flexCol gap={2}>
        <Box
          gap={3}
          css={{
            display: 'grid',
            gridTemplateColumns: 'auto min-content',
          }}
        >
          <TwoTeamScore
            eventName={eventName}
            homeScore={homeTeamScore}
            awayScore={awayTeamScore}
          />
          {isMobile && (
            <Box flexRow justifyContentEnd>
              <OddButtonGroup
                fullHeight
                sportId={sportId}
                isLoading={isLoading}
                market={market}
                eventId={eventId}
              />
            </Box>
          )}
        </Box>
        <LiveEventDetails
          eventId={eventId}
          isMobile={isMobile}
          marketsCount={formattedMarketsCount}
          startDate={formattedStartDate}
          matchStatus={formattedMatchStatus}
          details={details}
        />
      </Box>
    );
  },
);
