import { SportMainMarkets } from '~api/market/types';
import { useQueryParams } from '~hooks/useQueryParams';
import { useAppSelector } from '~store';
import { formatDateTime, TIME_FORMATS } from '~utils/formatDateTime';
import { splitStringOnVs } from '~utils/stringUtils';

import { LeagueEventContentMobile } from './LeagueEventContentMobile';
import { useLeagueEvent } from './useLeagueEvent';

interface LeagueEventPropsMobile {
  eventId: string;
  marketIds: string[];
  isFirstEvent: boolean;
  isFirstDate: boolean;
  mainMarkets?: SportMainMarkets;
}

export const LeagueEventMobile = ({
  eventId,
  marketIds,
  isFirstEvent,
  isFirstDate,
  mainMarkets,
}: LeagueEventPropsMobile) => {
  const { sportId: sportIdFromUrl } = useQueryParams();
  const sportId = parseInt(sportIdFromUrl || '');
  const { mainMarketsSelected } = useAppSelector((state) => state.liveMenu);

  const { eventData, marketData, handleFavoriteClick } = useLeagueEvent({
    eventId,
    marketIds,
    mainMarkets,
    sportId,
  });

  if (!eventData || !marketData) return null;

  const { name, startDate, marketsCount } = eventData;
  const [homeTeamName, awayTeamName] = splitStringOnVs(name);
  const time = formatDateTime(startDate, TIME_FORMATS.TIME_12H);
  const mainSportMarketId = mainMarketsSelected[sportId];

  const selectedMarket = marketData.find(
    (market) => parseInt(market?.marketId) === mainSportMarketId,
  );

  return (
    <LeagueEventContentMobile
      time={time}
      eventId={eventId}
      homeTeamName={homeTeamName}
      awayTeamName={awayTeamName}
      marketsCount={marketsCount}
      selectedMarket={selectedMarket}
      isFirstEvent={isFirstEvent}
      isFirstDate={isFirstDate}
      handleFavoriteClick={handleFavoriteClick}
    />
  );
};
