import { styled } from 'stitches.config';

import { Box } from '~components/atoms/Box';
import { Skeleton } from '~components/atoms/Skeleton';

const StyledContainer = styled(Box, {
  padding: '$2 $3',
  background: '$black',
  borderRadius: '$12',
});

const StyledSkeletonFullLine = () => (
  <Skeleton
    css={{
      height: '$6',
      border: 'none',
    }}
  />
);

interface MainPageSkeletonProps {
  withSlider?: boolean;
}

export const MainPageSkeleton = ({
  withSlider = true,
}: MainPageSkeletonProps) => {
  return (
    <>
      {Array.from({ length: 3 }, (_, key) => {
        return (
          <Box
            key={key}
            flexCol
            css={{
              padding: '$1',
              borderRadius: '$12',
              gap: '1px',
            }}
          >
            {withSlider && (
              <>
                <Box
                  css={{
                    p: '14px $4',
                    background: '$black',
                    borderTopLeftRadius: '$8',
                    borderTopRightRadius: '$8',
                  }}
                >
                  <StyledSkeletonFullLine />
                </Box>
                <Box css={{ p: '14px $4', background: '$black' }}>
                  <StyledSkeletonFullLine />
                </Box>
              </>
            )}
            <Box
              flexCol
              css={{
                background: '$black',
                padding: '$1',
                gap: '1px',
                borderBottomLeftRadius: '$12',
                borderBottomRightRadius: '$12',
              }}
            >
              <Box
                css={{
                  p: '14px $4',
                  background: '$gray',
                  borderTopLeftRadius: '$8',
                  borderTopRightRadius: '$8',
                }}
              >
                <StyledSkeletonFullLine />
              </Box>
              <Box css={{ p: '14px $4', background: '$gray' }}>
                <StyledSkeletonFullLine />
              </Box>
              <Box
                flexCol
                gap={2}
                css={{
                  p: '$2 $3',
                  background: '$gray',
                  borderBottomLeftRadius: '$8',
                  borderBottomRightRadius: '$8',
                }}
              >
                {Array.from({ length: 2 }, (_, key) => {
                  return (
                    <StyledContainer
                      key={key}
                      flexCol
                      gap={1}
                      css={{ background: '$black' }}
                    >
                      <Skeleton
                        css={{
                          width: '100%',
                          height: '$4',
                          mb: '10px',
                          borderRadius: '$4',
                        }}
                      />
                      <Skeleton
                        css={{
                          width: '100%',
                          height: '68px',
                          padding: '$3',
                          borderRadius: '$8',
                        }}
                      />
                    </StyledContainer>
                  );
                })}
              </Box>
            </Box>
          </Box>
        );
      })}
    </>
  );
};
