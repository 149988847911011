import { ChangeEvent, useEffect, useRef, useState } from 'react';

import { SEARCH_TERM_MIN_LENGTH, SEARCH_TIMEOUT_MS } from '~constants/common';
import { useTranslation } from '~hooks/useTranslation';

export const useSearch = (
  query: string,
  setQuery: (value: string) => void,
  onSearch: (value: string) => void,
  onClear: () => void,
) => {
  const [isLoading, setIsLoading] = useState(false);
  const searchTimeoutRef = useRef<NodeJS.Timeout | null>(null);
  const { localized } = useTranslation();

  const handleSearch = (e: ChangeEvent<HTMLInputElement>) => {
    const currentValue = e.target.value;

    setQuery(currentValue);

    if (currentValue.length === 0) {
      handleClear();
      setIsLoading(false);

      return;
    }

    if (searchTimeoutRef.current) {
      clearTimeout(searchTimeoutRef.current);
    }

    if (currentValue.length >= SEARCH_TERM_MIN_LENGTH) {
      setIsLoading(true);
    }

    searchTimeoutRef.current = setTimeout(async () => {
      if (currentValue && currentValue.length >= SEARCH_TERM_MIN_LENGTH) {
        await onSearch(currentValue);
        setIsLoading(false);
      }
    }, SEARCH_TIMEOUT_MS);
  };

  const handleClear = () => {
    setQuery('');
    setIsLoading(false);
    onClear();

    if (searchTimeoutRef.current) {
      clearTimeout(searchTimeoutRef.current);
    }
  };

  useEffect(() => {
    return () => {
      if (searchTimeoutRef.current) {
        clearTimeout(searchTimeoutRef.current);
      }
    };
  }, []);

  return {
    query,
    isLoading,
    localized,
    handleClear,
    handleSearch,
  };
};
