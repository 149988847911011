import { memo } from 'react';

import { MainMarket } from '~api/market/types';
import { Box } from '~components/atoms/Box';
import { useMedia } from '~hooks/useMedia';

import { EventsListItem } from './EventsListItem';

export interface EventsListProps {
  activeEventIds: string[];
  mainMarkets: MainMarket[];
  isLive?: boolean;
  withTopPadding?: boolean;
  maxMarketsCount?: number;
}

export const EventsList = memo(
  ({
    activeEventIds,
    mainMarkets,
    isLive,
    withTopPadding,
    maxMarketsCount,
  }: EventsListProps) => {
    const { isMobileOrTablet } = useMedia();

    return (
      <Box
        css={
          !mainMarkets.filter((item) => item).length
            ? {
                background: '$gray',
                pt: '.5rem !important',
              }
            : {}
        }
      >
        <Box
          flexCol
          fullWidth
          fullHeight
          gap={3}
          css={{
            background: '$gray',
            padding: withTopPadding ? '$2 $3 $2 !important' : '$0 $3 $3',
            borderBottomRightRadius: '$6',
            borderBottomLeftRadius: '$6',
            pt: !isMobileOrTablet || !mainMarkets.length ? '$3' : 0,
            width: '100%',
            position: 'relative',
          }}
        >
          {activeEventIds.map((eventId, index) => (
            <EventsListItem
              maxMarketsCount={maxMarketsCount}
              isFirst={index === 0}
              isLive={isLive}
              key={eventId}
              eventId={eventId as string}
              mainMarkets={mainMarkets}
            />
          ))}
        </Box>
      </Box>
    );
  },
);
