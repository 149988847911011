import { ComponentType, useCallback, useEffect, useMemo } from 'react';
import { useLocation } from 'react-router-dom';

import { DIALOGS } from '~components/atoms/AbsoluteDialogs';
import { MAIN_PAGE_TABS } from '~components/molecules/MainHighlights/helpers';
import { SPORT_TAB_GROUPS } from '~components/molecules/PrematchMenu/constants';
import {
  MENU_ICON_TYPES,
  useHeaderMenu,
} from '~components/organisms/Header/useHeaderMenu';
import { CountLabel } from '~components/organisms/MobileTab/components/CountLabel';
import { QUERY_PARAMS } from '~constants/common';
import useIsInIframe from '~hooks/useIsInIframe';
import { useQueryParams } from '~hooks/useQueryParams';
import { useRouterQuery } from '~hooks/useRouterQuery';
import { useTranslation } from '~hooks/useTranslation';
import { InplayIcon, MenuIcon, SportsIcon } from '~icons/menu';
import { BetslipTabIcon, GamesTabIcon } from '~icons/mobileTab';
import { useAppDispatch, useAppSelector } from '~store';
import { openDialog } from '~store/slices/globalDialogSlice';
import { setPreviousLocation } from '~store/slices/historySlice';
import { resetLiveMenu } from '~store/slices/liveMenuSlice';
import {
  MenuState,
  updateLiveMenuState,
  updatePrematchMenuState,
} from '~store/slices/menusSlice';
import {
  clearLocationScrollPositions,
  setActiveSportTabGroup,
  setMobileActiveTab,
  setMobileMainPageTab,
} from '~store/slices/mobileSlice';
import { resetPrematchData } from '~store/slices/prematchMenuSlice';
import { setShouldOpenTopTournaments } from '~store/slices/sportGroupsSlice';
import { SPORT_MENUS } from '~types/sportMenus';
import { QueryParams } from '~utils/url';

import { BetslipTabItem } from './components/BetslipTabItem';
import {
  MenuTabItem,
  MenuTabRoot,
  StyledMobileTabContainer,
  StyledMobileTabItem,
  StyledMobileTabItemCentral,
  StyledMobileTabItemIcon,
  StyledMobileTabItemText,
  StyledMobileTabRoundDecoration,
} from './styled.components';

interface MobileMenuItems {
  value: MOBILE_MENU;
  label: string;
  icon: ComponentType;
  inactive?: boolean;
}

export enum MOBILE_MENU {
  LIVE = 'live',
  SPORT = 'prematch',
  BETSLIP = 'betslip',
  GAMES = 'games',
  MENU = 'menu',
}

export const MobileTab = () => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const search = useQueryParams();
  const { updateQueryParams } = useRouterQuery();
  const { menu } = useHeaderMenu();
  const { mobileActiveTab } = useAppSelector((state) => state.mobileState);
  const { liveMenuState, prematchMenuState } = useAppSelector(
    (state) => state.menus,
  );
  const { counts } = useAppSelector((state) => state.sportsMenuState);
  const { inplay: inplayCount, prematch: prematchCount } = counts;
  const { localized } = useTranslation();
  const isInIframe = useIsInIframe();

  const updateStoreTabState = useCallback(
    (value: string) => {
      if (value === MOBILE_MENU.SPORT) {
        const prematchMenuStateWithSportMenu = {
          ...prematchMenuState,
          menu: SPORT_MENUS.PREMATCH,
        };

        dispatch(
          updateLiveMenuState({
            ...search,
            menu: MOBILE_MENU.LIVE,
          } as MenuState),
        );
        prematchMenuStateWithSportMenu &&
          updateQueryParams(
            prematchMenuStateWithSportMenu as QueryParams<typeof QUERY_PARAMS>,
            true,
          );
      } else if (value === MOBILE_MENU.LIVE) {
        const searchCopy = { ...search };

        delete searchCopy.eventId;

        const liveMenuStateWithLiveMenu = {
          ...searchCopy,
          ...liveMenuState,
          menu: SPORT_MENUS.LIVE,
        };

        dispatch(
          updatePrematchMenuState({
            ...search,
            menu: MOBILE_MENU.SPORT,
          } as MenuState),
        );
        liveMenuStateWithLiveMenu &&
          updateQueryParams(
            liveMenuStateWithLiveMenu as QueryParams<typeof QUERY_PARAMS>,
            true,
          );
      }
    },
    [search, liveMenuState, prematchMenuState, updateQueryParams, dispatch],
  );

  const handleTabChange = useCallback(
    (value: string) => {
      if (!value) return;

      dispatch(clearLocationScrollPositions());
      window.scrollTo(0, 0);

      if (value === MOBILE_MENU.GAMES) {
        const { url } = menu.find(
          ({ icon }) => icon === MENU_ICON_TYPES.Casino,
        ) || { url: '' };

        if (url && url.length > 0) {
          window.location.href = url;
        }

        return;
      }

      // do reset live data when we toggle mobile menu from prematch to some another tab
      if (mobileActiveTab === MOBILE_MENU.LIVE && value === MOBILE_MENU.SPORT) {
        dispatch(resetLiveMenu());
        dispatch(setPreviousLocation(null));
      }

      // do reset prematch data when we toggle mobile menu from prematch to some another tab
      if (
        mobileActiveTab === MOBILE_MENU.SPORT &&
        value !== MOBILE_MENU.SPORT
      ) {
        dispatch(resetPrematchData());
        dispatch(setPreviousLocation(null));
        dispatch(setActiveSportTabGroup(SPORT_TAB_GROUPS.UPCOMMING));
      }

      if (value === MOBILE_MENU.BETSLIP) {
        dispatch(openDialog(DIALOGS.BETSLIP));
      }

      if (value === MOBILE_MENU.MENU) {
        dispatch(openDialog(DIALOGS.MENU));

        return;
      }

      if (value !== MOBILE_MENU.BETSLIP) {
        updateStoreTabState(value);
        dispatch(setMobileActiveTab(value as MOBILE_MENU));
        dispatch(setMobileMainPageTab(MAIN_PAGE_TABS.UPCOMING));
      }

      if (value === MOBILE_MENU.SPORT) {
        dispatch(setShouldOpenTopTournaments(true));
      }
    },
    [menu],
  );

  const mobileMenuItemsMemo: MobileMenuItems[] = useMemo(() => {
    const items = [
      {
        value: MOBILE_MENU.LIVE,
        label: localized('mobile.tabMenu.live'),
        icon: InplayIcon,
      },
      {
        value: MOBILE_MENU.SPORT,
        label: localized('mobile.tabMenu.sport'),
        icon: SportsIcon,
      },
      {
        value: MOBILE_MENU.BETSLIP,
        label: localized('mobile.tabMenu.betslip'),
        icon: BetslipTabIcon,
      },
    ];

    return isInIframe
      ? items
      : [
          ...items,
          {
            value: MOBILE_MENU.GAMES,
            label: localized('mobile.tabMenu.games'),
            icon: GamesTabIcon,
            inactive: true,
          },
          {
            value: MOBILE_MENU.MENU,
            label: localized('mobile.tabMenu.menu'),
            icon: MenuIcon,
            inactive: true,
          },
        ];
  }, [isInIframe]);

  useEffect(() => {
    const { search, pathname } = location;

    if (search === '' && pathname === '/') {
      dispatch(setMobileActiveTab(MOBILE_MENU.SPORT));
    }
  }, [location]);

  return (
    <StyledMobileTabContainer>
      <MenuTabRoot
        onValueChange={handleTabChange}
        value={mobileActiveTab || ''}
      >
        {mobileMenuItemsMemo.map(({ value, label, icon: Icon }) => (
          <MenuTabItem key={value} value={value}>
            {value === MOBILE_MENU.BETSLIP ? (
              <StyledMobileTabItemCentral>
                <StyledMobileTabItemIcon css={{ height: '32px' }}>
                  <BetslipTabItem>
                    <Icon />
                  </BetslipTabItem>
                </StyledMobileTabItemIcon>
                <StyledMobileTabItemText>{label}</StyledMobileTabItemText>
              </StyledMobileTabItemCentral>
            ) : (
              <StyledMobileTabItem>
                <StyledMobileTabItemIcon>
                  <CountLabel
                    count={
                      value === MOBILE_MENU.SPORT
                        ? prematchCount
                        : value === MOBILE_MENU.LIVE
                          ? inplayCount
                          : 0
                    }
                  >
                    <Icon />
                  </CountLabel>
                </StyledMobileTabItemIcon>
                <StyledMobileTabItemText>{label}</StyledMobileTabItemText>
              </StyledMobileTabItem>
            )}
          </MenuTabItem>
        ))}
      </MenuTabRoot>
      <StyledMobileTabRoundDecoration />
    </StyledMobileTabContainer>
  );
};
