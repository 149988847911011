import { useCallback, useEffect, useState } from 'react';

import { useLazyGetEventPrematch } from '~api/live/liveQueries';
import { useLiveEventConnection } from '~components/molecules/InplayEvents/hooks/useLiveEventConnection';
import { MARKET_STATUS } from '~constants/common';
import { useMedia } from '~hooks/useMedia';
import { useQueryParams } from '~hooks/useQueryParams';
import { useReconnectedRequest } from '~hooks/useReconnectedRequest';
import { useAppDispatch, useAppSelector } from '~store';
import { rewriteEvent, selectEventById } from '~store/slices/eventsSlice';
import { setLoadingEventId } from '~store/slices/liveMenuSlice';
import { Market, SportEvent } from '~types/events';
import { groupByMarketId } from '~utils/markets';
import { areTwoObjectsEqual } from '~utils/objectHelpers';

export const usePrematchEvent = () => {
  const dispatch = useAppDispatch();
  const { eventId, sportId } = useQueryParams();
  const event = useAppSelector((state) =>
    selectEventById(state, eventId || ''),
  );
  const { isMobileOrTablet } = useMedia();
  const { favoriteMarkets } = useAppSelector((state) => state.mainMarkets);
  const [isEventLoaded, setIsEventLoaded] = useState(false);
  const [eventData, setEventData] = useState<SportEvent | undefined>(
    event as SportEvent,
  );
  const [marketGroups, setMarketGroups] = useState<Record<string, Market[]>>(
    {},
  );
  const { lazyGetEventPrematchQuery, lazyGetEventPrematchIsFetching } =
    useLazyGetEventPrematch();
  const { language } = useAppSelector((state) => state.websiteSettings);

  const handleLoadEventData = useCallback(async (eventId: string) => {
    try {
      dispatch(setLoadingEventId(eventId));
      const responseEventData: SportEvent =
        await lazyGetEventPrematchQuery(eventId).unwrap();
      const { markets = [] } = responseEventData || {};

      setEventData(responseEventData);
      setMarketGroups(groupByMarketId(markets));
      dispatch(rewriteEvent(responseEventData));
    } catch (e) {
      console.log(e);
    } finally {
      dispatch(setLoadingEventId(null));
      setIsEventLoaded(true);
    }
  }, []);

  useEffect(() => {
    if (eventId) {
      handleLoadEventData(eventId);
    }

    return () => {
      setIsEventLoaded(false);
      setEventData(undefined);
      setMarketGroups({});
    };
  }, [eventId, language]);

  useReconnectedRequest(() => eventId && handleLoadEventData(eventId));

  useEffect(() => {
    if (!areTwoObjectsEqual(event, eventData)) {
      setEventData(event as SportEvent);
    }
  }, [event]);

  useEffect(() => {
    if (eventData && isEventLoaded) {
      const mapUpdatedMarkets = (eventData?.markets || []).filter(
        (item) =>
          item &&
          item.status === MARKET_STATUS.ACTIVE &&
          item.selections.length,
      );

      let mapUpdatedMarketsFavorites: (Market | undefined)[] = [];

      if (sportId || eventData.sportId) {
        const ongoingSportFavorites = favoriteMarkets?.find(
          (favoriteMarket) =>
            favoriteMarket.sportId === +(sportId || eventData.sportId || 0),
        );

        mapUpdatedMarketsFavorites = mapUpdatedMarkets.map((market) => {
          if (market?.marketId) {
            return {
              ...market,
              favorite: ongoingSportFavorites?.marketIds.includes(
                parseInt(market.marketId),
              ),
            };
          }

          return market;
        });
      }

      const resGroups = groupByMarketId(mapUpdatedMarketsFavorites);

      if (!areTwoObjectsEqual(resGroups, marketGroups)) {
        setMarketGroups(resGroups);
      }
    }
  }, [eventData, favoriteMarkets, isEventLoaded]);

  useLiveEventConnection({ loaded: !!eventData });

  return {
    isMobileOrTablet,
    marketGroups,
    eventId,
    eventData,
    isEventLoaded,
    isFetching: lazyGetEventPrematchIsFetching,
  };
};
