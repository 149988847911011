import { CSS, styled } from 'stitches.config';

import { Box } from '~components/atoms/Box';
import { Skeleton } from '~components/atoms/Skeleton';

const styledTouchSliderWrapperPseudoCSS: CSS = {
  content: '""',
  position: 'absolute',
  top: 0,
  bottom: 0,
  width: '$8',
  zIndex: 1,
  pointerEvents: 'none',
};

export const StyledTouchSliderWrapper = styled(Box, {
  position: 'relative',
  background: '$black',

  '&:after': {
    ...styledTouchSliderWrapperPseudoCSS,
    right: 0,
    background: 'linear-gradient(270deg, $black 0%, $blackOpacity0 100%)',
  },
  '&:before': {
    ...styledTouchSliderWrapperPseudoCSS,
    left: 0,
    background: 'linear-gradient(270deg, $blackOpacity0 0%, $black 100%)',
  },
  variants: {
    isDesktop: {
      true: {
        '& > *': {
          padding: '$1 $2',
        },
      },
    },
  },
});

export const StyledTouchSliderInnerWrapper = styled(Box, {
  backgroundColor: '$black',
  width: 'fit-content',
  display: 'flex',
  flexWrap: 'nowrap',
  whiteSpace: 'nowrap',
  alignItems: 'center',
  '& > *': {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    flexShrink: 0,
    flexGrow: 0,
    p: '$2 $3 $1',
    color: '$white',
    '&.active': {
      position: 'sticky',
      left: '-1px',
      right: '-1px',
      zIndex: 2,
      '& .icon *, & .tag': {
        fill: '$yellowLight',
        color: '$yellowLight',
      },
      '& .text': {
        color: '$yellow',
      },
    },
  },
  variants: {
    type: {
      desktop: {
        '& > *': {
          flexDirection: 'row',
          gap: '$2',
        },
      },
    },
  },
});

export const ScrollableBox = styled(Box, {
  overflowX: 'auto',
  scrollbarWidth: 'none',
  '-webkit-overflow-scrolling': 'touch',
  '&::-webkit-scrollbar': {
    display: 'none',
  },
});

export const StyledContainer = styled(Box, {
  gap: '1px',
  padding: '$2 $3',
});

export const StyledRow = styled(Box, {
  width: '50%',
  gap: '1px',
  pb: '$3',
});

export const StyledItemRight = styled(Box, {
  background: '$gray',
  padding: '$3 $4',
  '&:first-child': {
    borderTopRightRadius: '$6',
  },
  '&:last-child': {
    borderBottomRightRadius: '$6',
  },
});

export const StyledItemLeft = styled(Box, {
  background: '$gray',
  padding: '$3 $4',
  '&:first-child': {
    borderTopLeftRadius: '$6',
  },
  '&:last-child': {
    borderBottomLeftRadius: '$6',
  },
});

export const StyledSkeleton = () => (
  <Skeleton
    css={{
      flexGrow: 1,
      height: '28px',
      border: 'none',
    }}
  />
);

export const StyledSkeletonFullLine = () => (
  <Skeleton
    css={{
      margin: '$2 $3',
      height: '$6',
      border: 'none',
    }}
  />
);
