import React, { useState } from 'react';
import { styled } from '@stitches/react';

import { ActiveBonusesContentMobile } from '~components/molecules/Bonuses/components/ActiveBonusesContent';
import { BonusHistoryMobile } from '~components/molecules/Bonuses/components/BonusHistoryMobile';
import { USER_BONUSES_TABS } from '~components/molecules/UserProfile/components/UserProfileDialog';
import { useTranslation } from '~hooks/useTranslation';

const StyledIframeBonusesMobileHeader = styled('div', {
  display: 'flex',
  gap: '$6',
  margin: '$3 0 $1',
});

const StyledIframeBonusesMobileTab = styled('div', {
  textTransform: 'uppercase',
});

export const IframeBonusesMobile = () => {
  const { localized } = useTranslation();

  const [isActiveBonuses, setActiveBonuses] = useState<boolean>(true);

  return (
    <div>
      <StyledIframeBonusesMobileHeader>
        <StyledIframeBonusesMobileTab
          css={{ color: isActiveBonuses ? '$yellow' : '$white' }}
          onClick={() => setActiveBonuses(true)}
        >
          {localized(USER_BONUSES_TABS.ACTIVE_BONUSES)}
        </StyledIframeBonusesMobileTab>
        <StyledIframeBonusesMobileTab
          css={{ color: !isActiveBonuses ? '$yellow' : '$white' }}
          onClick={() => setActiveBonuses(false)}
        >
          {localized(USER_BONUSES_TABS.BONUS_HISTORY)}
        </StyledIframeBonusesMobileTab>
      </StyledIframeBonusesMobileHeader>
      {isActiveBonuses ? (
        <ActiveBonusesContentMobile />
      ) : (
        <BonusHistoryMobile />
      )}
    </div>
  );
};
