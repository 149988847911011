import { useCallback, useEffect, useLayoutEffect, useState } from 'react';

import { useLazyGetTopEventPrematch } from '~api/sportEvent/sportEventQueries';
import {
  getMinMainEventDataWidth,
  getSelectionWidth,
  preparePrematchUpcomingHighlightsData,
} from '~components/molecules/MainHighlights/helpers';
import { PrematchTopEventsData, SELECTIONS_GAP } from '~constants/mainPage';
import useDimensions from '~hooks/useDimensions';
import { useMedia } from '~hooks/useMedia';
import { useAppDispatch, useAppSelector } from '~store';
import { addEventsWithMarkets } from '~store/slices/eventsSlice';
import { setMaxMarketsCount } from '~store/slices/prematchMenuSlice';
import {
  setTopPrematchEventsOpenDateGroupsBySports,
  setTopPrematchEventsOpenSports,
} from '~store/slices/sportGroupsSlice';

export const useTopPrematchEvents = () => {
  const dispatch = useAppDispatch();
  const { mainMarkets } = useAppSelector((state) => state.mainMarkets);
  const {
    topSportEvents,
    topPrematchEventsOpenSports,
    topPrematchEventsOpenDateGroupsBySport,
  } = useAppSelector((state) => state.sportGroupsState);
  const { maxMarketsCount } = useAppSelector((state) => state.prematchMenu);
  const [topEventsData, setTopEventsData] = useState<PrematchTopEventsData>({});
  const [eventsListRef, dimensions] = useDimensions();
  const { isLaptop } = useMedia();
  const { lazyGetTopEventPrematchIsFetching } = useLazyGetTopEventPrematch();

  const getPreparedTopSportEvents = () => {
    return topSportEvents.map((topSportEvent) => {
      const { sportId, sportName, dateGroups } = topSportEvent;

      const { markets: sportMainMarkets = [] } =
        mainMarkets.find(({ id }) => {
          return id === sportId;
        }) || {};

      const openDateGroups = topPrematchEventsOpenDateGroupsBySport.find(
        ({ sportId: id }) => id === sportId.toString(),
      )?.dateGroups;

      return {
        sportId,
        sportName,
        dateGroups,
        sportMainMarkets,
        openDateGroups,
      };
    });
  };

  const handleAccordionValueChange = (value: string[]) => {
    dispatch(setTopPrematchEventsOpenSports(value));
  };

  useEffect(() => {
    if (topSportEvents?.length && !topPrematchEventsOpenSports.length) {
      dispatch(
        setTopPrematchEventsOpenSports([topSportEvents[0]!.sportId.toString()]),
      );

      const allDateGroupsInSports = topSportEvents.map(
        ({ sportId, dateGroups }) => {
          return {
            sportId: sportId.toString(),
            dateGroups: dateGroups.map((dateGroup) => dateGroup.date),
          };
        },
      );

      dispatch(
        setTopPrematchEventsOpenDateGroupsBySports(allDateGroupsInSports),
      );
    }
  }, [topSportEvents]);

  useEffect(() => {
    if (!topSportEvents) return;
    const {
      data: preparedData,
      events,
      markets,
    } = preparePrematchUpcomingHighlightsData(topSportEvents);

    dispatch(addEventsWithMarkets({ events, markets }));
    setTopEventsData(preparedData);
  }, [topSportEvents]);

  const getMaxMarketsCount = useCallback((): number => {
    const { width } = dimensions;

    if (width) {
      const allowedMarketsWidth = width - getMinMainEventDataWidth(isLaptop);

      const SINGLE_MARKET_WIDTH =
        getSelectionWidth(isLaptop) * 3 + SELECTIONS_GAP * 2;

      return Math.floor(allowedMarketsWidth / SINGLE_MARKET_WIDTH);
    }

    return 0;
  }, [dimensions, isLaptop]);

  useLayoutEffect(() => {
    const maxMarketsCount = getMaxMarketsCount();

    dispatch(setMaxMarketsCount(maxMarketsCount));
  }, [dimensions, mainMarkets]);

  return {
    preparedTopSportEvents: getPreparedTopSportEvents(),
    topEventsData,
    maxMarketsCount,
    topPrematchEventsOpenSports,
    topPrematchEventsOpenDateGroupsBySport,
    isFetching: lazyGetTopEventPrematchIsFetching,
    eventsListRef,
    handleAccordionValueChange,
  };
};
