import { Box } from '~components/atoms/Box';
import { Button } from '~components/atoms/Button';
import { Text } from '~components/atoms/Typography';
import { useTranslation } from '~hooks/useTranslation';
import { useAppSelector } from '~store';

import { useBetslipFooter } from '../../hooks/useBetslipFooter';

const buttonStyles = {
  p: '$2',
  width: '100%',
  '@xs_sm': {
    lineHeight: '$20',
    fontSize: '$12',
    p: '$1',
    borderRadius: '$4',
  },
};

const buttonTextStyles = {
  '@xs_sm': {
    lineHeight: '$20',
    fontSize: '$12',
  },
};

export const BetslipSubmitButton = ({ quickBet = false }) => {
  const { localized } = useTranslation();
  const {
    onClick,
    onDeleteClick,
    rebet,
    isDisabled,
    isUserLoggedIn,
    isBetslipChanged,
    localizationPath,
  } = useBetslipFooter(quickBet);
  const { isProcessing, isBalanceChangesLoading } = useAppSelector(
    (state) => state.betslip,
  );

  return (
    <Box
      flexRow
      gap={2}
      css={{
        p: '$4',
        '@xs_sm': {
          p: '$3 $4',
        },
      }}
    >
      {quickBet && (
        <Button
          disabled={isProcessing || isBalanceChangesLoading}
          variant="secondary"
          css={buttonStyles}
          onClick={onDeleteClick}
        >
          <Text
            level={
              isBetslipChanged.length && isUserLoggedIn ? '14-20' : '16-24'
            }
            fontWeight="bold"
            textAlign="center"
            css={buttonTextStyles}
          >
            {localized('buttons.delete')}
          </Text>
        </Button>
      )}
      <Button
        disabled={
          (isUserLoggedIn && isDisabled && !rebet.length) ||
          isProcessing ||
          isBalanceChangesLoading
        }
        css={{
          p: '$2',
          width: '100%',
          '@xs_sm': {
            p: quickBet ? '$1' : '$2',
            borderRadius: '$4',
          },
          ...(isBetslipChanged.length && isUserLoggedIn && !rebet.length
            ? {
                backgroundColor: '$yellowDark !important',
                color: '$yellow !important',
              }
            : {}),
        }}
        onClick={onClick}
      >
        <Text
          level={
            isBetslipChanged.length && isUserLoggedIn && !rebet.length
              ? '14-20'
              : '16-24'
          }
          color={
            !!isBetslipChanged.length && isUserLoggedIn && !rebet.length
              ? 'yellow'
              : 'buttonTextColor'
          }
          fontWeight="bold"
          textAlign="center"
          css={{
            '@xs_sm': {
              lineHeight: quickBet ? '$20' : '$24',
              fontSize: quickBet ? '$12' : '$17',
            },
          }}
        >
          {localized(localizationPath)}
        </Text>
      </Button>
    </Box>
  );
};
