import React from 'react';

import { ActiveJackpotDetails } from '~components/molecules/Jackpot/Active/components/Content/ActiveJackpotDetails';
import { SmallScreenJackpotList } from '~components/molecules/Jackpot/Settled';
import { useMedia } from '~hooks/useMedia';
import { useAppSelector } from '~store';
import { getSelectedJackpot } from '~store/slices/jackpotSlice';

export const Content = () => {
  const { isMobileOrTablet } = useMedia();
  const selectedJackpot = useAppSelector(getSelectedJackpot);

  if (!isMobileOrTablet && !selectedJackpot) return null;

  return isMobileOrTablet ? (
    <SmallScreenJackpotList isActive />
  ) : (
    <ActiveJackpotDetails selectedJackpot={selectedJackpot!} />
  );
};
