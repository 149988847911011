import { styled } from 'stitches.config';

import { Box } from '~components/atoms/Box';
import { Text } from '~components/atoms/Typography';
import { CURRENCY_SYMBOLS } from '~constants/common';
import { useMedia } from '~hooks/useMedia';
import { useAppSelector } from '~store';
import { Jackpot } from '~types/jackpot';
import { formatDateTime, TIME_FORMATS } from '~utils/formatDateTime';
import { getSportIcon } from '~utils/getSportIcon';
import { formatNumberToStringWithSpaces } from '~utils/numberUtils';

import { BannerWrapper } from '../../BannerWrapper';

import { WinningLines } from './WinningLines';

const InfoText = styled(Text, {
  textShadow: '0px 2px 4px #0A0F14',
  textTransform: 'uppercase',
});

const BannerItemsWrapper = styled(Box, {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  height: '100%',
  padding: '$6',
  gap: '$2',
});

interface JackpotSettledBannerProps {
  jackpot: Jackpot;
}

export const Banner = ({ jackpot }: JackpotSettledBannerProps) => {
  const { isMobile, isLargeDesktopOrDesktop } = useMedia();
  const { currency } = useAppSelector((state) => state.settings);
  const { name, sportName, details, firstEventStartDate, amount, price } =
    jackpot;
  const iconSize = { width: 18, height: 18 };

  const currencySymbol = CURRENCY_SYMBOLS[currency];

  return (
    <BannerWrapper
      sportName={sportName}
      customStyles={{
        minHeight: isMobile ? '120px' : '212px',
      }}
    >
      <BannerItemsWrapper>
        <Box fullHeight flexCol gap={3}>
          <Box flexRow alignCenter gap={2}>
            <Box
              css={{
                ...iconSize,
                color: '$white',
              }}
            >
              {getSportIcon(sportName, iconSize)}
            </Box>
            <Text
              level={isMobile ? '12-16' : ''}
              className="sport"
            >{`${sportName}. ${formatDateTime(
              firstEventStartDate,
              TIME_FORMATS.DATE_TIME_12H,
            )}`}</Text>
          </Box>
          <Box>
            <InfoText level={isMobile ? '14-20' : '24-40'}>{name}</InfoText>
            <InfoText
              color="yellow"
              fontWeight="bold"
              level={isMobile ? '16-24' : '32-44'}
            >
              {`${formatNumberToStringWithSpaces(amount)} ${currencySymbol}`}
            </InfoText>
            <InfoText
              level={isMobile ? '14-20' : '24-40'}
            >{`Ticket price: ${price} ${currencySymbol}`}</InfoText>
          </Box>
        </Box>
        {isLargeDesktopOrDesktop && <WinningLines lines={details.lines} />}
      </BannerItemsWrapper>
    </BannerWrapper>
  );
};
