import { MainMarket } from '~api/market/types';
import { Box } from '~components/atoms/Box';
import { Flag } from '~components/atoms/Flag';
import { Text } from '~components/atoms/Typography';
import { CountryName } from '~constants/flag';
import { useMedia } from '~hooks/useMedia';
import { useAppSelector } from '~store';

import { useEventsListItem } from './hooks/useEventsListItem';
import { MAIN_PAGE_TABS } from './helpers';
import { MainEventLiveData } from './MainEventLiveData';
import { MainEventPrematchData } from './MainEventPrematchData';
import { MarketsEventData } from './MarketsEventData';
import { MobileEventFooterData } from './MobileEventFooterData';
import { MobileLiveEventData } from './MobileLiveEventData';
import { MobilePrematchEventData } from './MobilePrematchEventData';

export interface EventsListItemProps {
  eventId: string;
  mainMarkets: MainMarket[];
  isLive?: boolean;
  isFirst?: boolean;
  maxMarketsCount?: number;
}

export const EventsListItem = ({
  eventId,
  mainMarkets,
  isLive,
  isFirst,
  maxMarketsCount,
}: EventsListItemProps) => {
  const { isMobileOrTablet } = useMedia();
  const { mobileMainPageTab } = useAppSelector((state) => state.mobileState);
  const { eventsData, marketsData } = useEventsListItem({
    eventId,
    mainMarkets,
    isFirst,
    maxMarketsCount,
  });

  if (!eventsData || !marketsData) return null;

  const { countryName, leagueName } = eventsData;

  let eventContent;

  if (!isMobileOrTablet) {
    eventContent = isLive ? (
      <MainEventLiveData eventData={eventsData} />
    ) : (
      <MainEventPrematchData eventData={eventsData} />
    );
  } else {
    eventContent =
      mobileMainPageTab === MAIN_PAGE_TABS.LIVE_HIGHLIGHTS ? (
        <MobileLiveEventData eventData={eventsData} />
      ) : (
        <MobilePrematchEventData eventData={eventsData} />
      );
  }

  return (
    <Box
      flexCol
      fullWidth
      css={{
        background: '$blackDarker',
        borderRadius: '$12',
      }}
    >
      <Box
        fullWidth
        flexRow
        alignCenter
        justifyContentStart
        gap={2}
        css={{ p: '$2 $4', borderBottom: '1px solid $gray' }}
      >
        <Flag countryName={countryName as CountryName} />
        <Text level="14-24">
          {countryName} - {leagueName}
        </Text>
      </Box>
      <Box flexCol fullWidth>
        <Box
          fullWidth
          gap={2}
          css={{
            p: '$1 $2',
            display: 'grid',
            gridTemplateColumns: 'minmax(0, 1fr) min-content',
          }}
        >
          {eventContent}
          <Box>
            <MarketsEventData
              isLive={isLive}
              marketsData={marketsData}
              eventId={eventId}
              mainMarkets={mainMarkets}
            />
          </Box>
        </Box>
        {isMobileOrTablet && (
          <MobileEventFooterData eventData={eventsData} isLive={isLive} />
        )}
      </Box>
    </Box>
  );
};
