import React, { useEffect, useState } from 'react';
import { styled } from 'stitches.config';

import { Box } from '~components/atoms/Box';
import { Text } from '~components/atoms/Typography';
import { useMedia } from '~hooks/useMedia';
import { calculateTimeLeft } from '~utils/time';

interface CountdownProps {
  targetDate: string;
}

interface TimeLeft {
  days: number;
  hours: number;
  minutes: number;
  seconds: number;
}

const TimeWrapper = styled(Box, {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '60px',
  height: '60px',
  borderRadius: '12px',
  border: '1px solid $green',
  background: '$countdownBgColor',

  '@xs': {
    width: '28px',
    height: '28px',
    borderRadius: '6px',
  },
});

const TimeText = styled(Text, {
  color: '$white !important',
  textShadow: '0px 2px 4px #0A0F14 !important',
  fontSize: '28px !important',
  fontWeight: 'bold !important',
  lineHeight: '44px !important',
  textTransform: 'uppercase !important',

  '@xs': {
    fontSize: '14px !important',
    lineHeight: '24px !important',
  },
});

const TimeDescription = styled(Text, {
  fontSize: '16px !important',
  lineHeight: '24px !important',
  textShadow: '0px 2px 4px #0A0F14',

  '@xs': {
    fontSize: '9px !important',
    lineHeight: '12px !important',
  },
});

const StartInText = styled(Text, {
  fontSize: '16px !important',
  lineHeight: '24px !important',
  fontWeight: '$medium',
  textShadow: '0px 2px 4px #0A0F14',

  '@xs': {
    fontSize: '10px !important',
    lineHeight: '16px !important',
  },
});

export const Countdown = ({ targetDate }: CountdownProps) => {
  const { isMobile } = useMedia();
  const endDate = new Date(targetDate).getTime();

  const [timeLeft, setTimeLeft] = useState<TimeLeft | null>(
    calculateTimeLeft(endDate),
  );

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft(calculateTimeLeft(endDate));
    }, 1000);

    return () => clearInterval(timer);
  }, [targetDate]);

  return (
    <Box flexCol alignCenter gap={isMobile ? 1 : 3}>
      <StartInText textShadow="default">Start in:</StartInText>
      {timeLeft && (
        <Box flexRow gap={2}>
          <Box flexCol alignCenter gap={2}>
            <TimeWrapper>
              <TimeText>{timeLeft.days.toString().padStart(2, '0')}</TimeText>
            </TimeWrapper>
            <TimeDescription>days(s)</TimeDescription>
          </Box>
          <TimeText css={{ marginTop: isMobile ? 0 : '8px' }}>:</TimeText>
          <Box flexCol alignCenter gap={2}>
            <TimeWrapper>
              <TimeText>{timeLeft.hours.toString().padStart(2, '0')}</TimeText>
            </TimeWrapper>
            <TimeDescription>hrs</TimeDescription>
          </Box>
          <TimeText css={{ marginTop: isMobile ? 0 : '8px' }}>:</TimeText>
          <Box flexCol alignCenter gap={2}>
            <TimeWrapper>
              <TimeText>
                {timeLeft.minutes.toString().padStart(2, '0')}
              </TimeText>
            </TimeWrapper>
            <TimeDescription>min</TimeDescription>
          </Box>
        </Box>
      )}
    </Box>
  );
};
