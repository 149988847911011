import { useEffect, useState } from 'react';

import { useBalanceChangePrediction } from '~api/betslip/betslipMutations';
import { BalancePredictionPayload, Bet } from '~api/betslip/types';
import { useFormatBetslipPayload } from '~components/molecules/Betslip/hooks/useFormatBetslipPayload';
import { useDebouncedEffect } from '~hooks/useDebouncedEffect';
import { useAppDispatch, useAppSelector } from '~store';
import {
  setBalanceChangesOnBetData,
  setIsBonusChangesLoading,
} from '~store/slices/betslipSlice';
import { betslipStateByEventsData } from '~store/slices/eventsSlice';
import { calculateSystemBetOptions } from '~utils/betslip';
import { areTwoObjectsEqual } from '~utils/objectHelpers';

import {
  BETSLIP_ERRORS,
  MIN_SYSTEM_BET_EVENTS,
  SPORT_BETSLIP_TYPE_OPTIONS,
} from '../constants';

export const useBetslipPossibleWinCheck = () => {
  const dispatch = useAppDispatch();
  const { balanceChangePredictionMutation } = useBalanceChangePrediction();
  const { defaultBalance } = useAppSelector((state) => state.userState);
  const { isUserLoggedIn } = useAppSelector((state) => state.userState);
  const {
    events,
    stakeType,
    aggregatedBetAmount,
    singleBetsAmountMap,
    systemBetOption,
    betslipErrors,
  } = useAppSelector((state) => state.betslip);
  const eventsData = useAppSelector((state) =>
    betslipStateByEventsData(state, events),
  );
  const { separateBonusBalance } = useAppSelector((state) => state.settings);
  const aggregatedBetsDefaultError =
    (stakeType !== SPORT_BETSLIP_TYPE_OPTIONS.SINGLE &&
      betslipErrors.includes(BETSLIP_ERRORS.NOT_COMBINABLE)) ||
    betslipErrors.includes(BETSLIP_ERRORS.MIN_SELECTIONS);

  const [betslips, setBetslips] = useState(eventsData);
  const {
    formatMultipleBetPayload,
    formatSingleBetPayload,
    formatSystemBetPayload,
  } = useFormatBetslipPayload({ betslips });

  const isSystemBet = stakeType === SPORT_BETSLIP_TYPE_OPTIONS.SYSTEM;

  useEffect(() => {
    if (!areTwoObjectsEqual(betslips, eventsData)) {
      setBetslips(eventsData);
    }
  }, [eventsData]);

  // Effect that make a call to the BE, to calculate possible win and validate the bet
  useDebouncedEffect(
    () => {
      if (aggregatedBetsDefaultError) return;

      const selectionIds = events.map((event) => event.selectionId);

      if (selectionIds.length === 0) {
        return;
      }

      let bets: Bet[];

      if (stakeType === SPORT_BETSLIP_TYPE_OPTIONS.MULTIPLE) {
        bets = formatMultipleBetPayload();
      } else if (stakeType === SPORT_BETSLIP_TYPE_OPTIONS.SYSTEM) {
        bets = formatSystemBetPayload();
      } else {
        bets = formatSingleBetPayload();
      }

      const sumStake = bets.reduce(
        (acc, bet) =>
          acc + (bet?.stake || bet?.realStake || bet?.bonusStake || 0),
        0,
      );

      dispatch(setIsBonusChangesLoading(true));
      dispatch(
        setBalanceChangesOnBetData({
          isBalanceSufficient: true,
          balanceChanges: [],
          totalActiveBonusesCount: 0,
        }),
      );

      if (!bets.length || !sumStake) {
        dispatch(setIsBonusChangesLoading(false));

        return;
      }

      const payload: BalancePredictionPayload = {
        bets,
        separateBonusBalance,
        isSystemBet,
      };

      if (isSystemBet && events.length >= MIN_SYSTEM_BET_EVENTS) {
        payload.systemBetCombinationSize = systemBetOption
          ? Number(systemBetOption.value)
          : Number(calculateSystemBetOptions(events.length)[0]!.value);
      }

      balanceChangePredictionMutation(payload).then((response) => {
        if ('data' in response) {
          dispatch(setBalanceChangesOnBetData(response.data));
        }

        dispatch(setIsBonusChangesLoading(false));
      });
    },
    [
      stakeType,
      aggregatedBetAmount,
      singleBetsAmountMap,
      betslips,
      defaultBalance,
      systemBetOption,
      isUserLoggedIn,
    ],
    300,
  );
};
