import { memo } from 'react';

import { Box } from '~components/atoms/Box';

import {
  StyledContainer,
  StyledItemLeft,
  StyledItemRight,
  StyledRow,
  StyledSkeleton,
  StyledSkeletonFullLine,
} from './styled.components';

export const EventMarketsSkeleton = memo(() => {
  return (
    <Box flexCol css={{ background: '$black', p: '$1', borderRadius: '$12' }}>
      <Box css={{ p: '$2 $3', background: '$gray', borderRadius: '$8' }}>
        <StyledSkeletonFullLine />
      </Box>
      <Box
        css={{
          p: '$2 $3',
          background: '$gray',
          borderRadius: '$8 $8 0 0',
          mt: '$1',
        }}
      >
        <StyledSkeletonFullLine />
      </Box>
      {Array.from({ length: 8 }, (_, key) => {
        return (
          <StyledContainer key={key} flexRow css={{ background: '$grayDark' }}>
            <StyledRow flexCol>
              <StyledItemLeft>
                <StyledSkeleton />
              </StyledItemLeft>
              <StyledItemLeft>
                <StyledSkeleton />
              </StyledItemLeft>
              <StyledItemLeft>
                <StyledSkeleton />
              </StyledItemLeft>
            </StyledRow>
            <StyledRow flexCol>
              <StyledItemRight>
                <StyledSkeleton />
              </StyledItemRight>
              <StyledItemRight>
                <StyledSkeleton />
              </StyledItemRight>
              <StyledItemRight>
                <StyledSkeleton />
              </StyledItemRight>
            </StyledRow>
          </StyledContainer>
        );
      })}
    </Box>
  );
});
